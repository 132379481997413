import React from "react";
import ConfirmAlert from "../../../commons/uicomponents/confirm";
import { deleteNode, submit } from "./formActions";
import { FormButtons } from "./formbuttons";

export class StartNode extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmAlert: false,
      displayToast: false,
      messageToast: "",
    };
  }

  static getDerivedStateFromProps(nextProps, currentState) {
    let propsState;
    if (
      currentState.formUniqueId &&
      currentState.formUniqueId === nextProps.formUniqueId
    ) {
      propsState = { ...(nextProps.data.data || {}), ...currentState };
    } else {
      propsState = { ...currentState, ...(nextProps.data.data || {}) };
    }
    propsState.formUniqueId = nextProps.formUniqueId;
    return propsState;
  }

  componentDidMount() {}

  componentWillUnmount() {
    this.setState({});
  }

  onCancelBtn = () => {
    const collapseForm = this.props.formAction;
    collapseForm();
  };
  onConfirmButtonClick = () => {
    deleteNode(this.props);
    this.setState({ displayToast: true, messageToast: "Deleted Successfully" });
  };

  handleTextInputChange = (e) => {
    let updatedState = { ...this.state };
    updatedState[e.target.name] = e.target.value;
    this.setState(updatedState);
  };

  render() {
    return (
      <>
        {this.state.confirmAlert == true && (
          <ConfirmAlert
            onConfirmBtn={this.onConfirmButtonClick}
            onCancelBtn={this.onCancelBtn}
            heading=""
            paragraph="Are you sure you want to delete component ?"
          />
        )}
        <form className="form-style" onSubmit={(e) => submit(e, this.props, this.state)}>
          <div>
            <label>Transferable Vars</label>
            <input
              type="text"
              name="transferableVars"
              defaultValue={this.state.transferableVars}
              key={this.state.formUniqueId}
              onChange={this.handleTextInputChange}
              autoFocus
            />
          </div>
          <FormButtons formProps={this.props} />
        </form>
      </>
    );
  }
}
