import React, {Component} from "react";
import {FieldControl, FieldGroup, FormBuilder, Validators} from "react-reactive-form";
import {save, update, deleteConvRes} from "./jmApiHandler";
import deletewhiteIcon from "../../assets/images/deletewhite.png";
import './journeyroutes.scss';
import ConfirmAlert from "../../commons/uicomponents/confirm";
import PositionedSnackbar from "../../commons/uicomponents/toast";
import {deleteData} from "../../dataProvider/apiHandler";
import FindError from "../../dataProvider/errorHandler";
import copyIcon from "../../assets/images/copy_theme.png";
import {CopyToClipboard} from 'react-copy-to-clipboard';


const jmUrl = window._env_.REACT_APP_JOURNEY_MANAGER_URL;

class ConversationResume extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showEditConvRes: false,
      allSecret: {},
      resumeUrl: `${jmUrl}/app/${localStorage.getItem('pId')}/user/{userId}/conversation/{conversationId}/resume`,
    };
  }

  componentDidMount = () => {
  };

  hideNewConfig = (val) => {
    this.props.callBackConfig(false, val);
  }

  conversationResumeFormBuilder = FormBuilder.group({
    xSecret: ["", Validators.required]
  })

  jRouteFormSubmit = async (e, value) => {
    e?.preventDefault();
    try {
      const allOptions = {
        url: jmUrl + '/app/' + localStorage.getItem('pId') + '/conversation/resume/url/generate',
        secret: value.xSecret
      };
      await save(allOptions);
      this.hideNewConfig(true);
      this.setState({
        displayToast: true,
        messageToast: "Conversation Resume Configured",
        severity: "success",
        confirmAlert: false,
      });
    } catch (e) {
      const errMessage = e.response.data.message;
      this.setState({
        displayToast: true,
        messageToast: errMessage,
        severity: "error",
        confirmAlert: false,
      });
    }
  }

  updateConverRes = async () => {
    if (this.state.allSecret.oldXSecret === undefined) {
      this.setState({
        displayToast: true,
        messageToast: "Old Secret required",
        severity: "error",
      });
      return;
    }
    if (this.state.allSecret.newXSecret === undefined) {
      this.setState({
        displayToast: true,
        messageToast: "New Secret required",
        severity: "error",
      });
    } else {
      try {
        const allOptions = {
          url: jmUrl + '/app/' + localStorage.getItem('pId') + '/conversation/resume/url/generate',
          oldSecret: this.state.allSecret.oldXSecret,
          newSecret: this.state.allSecret.newXSecret,
        };
        await update(allOptions);
        this.setState({
          showEditConvRes: false,
          allSecret: {},
          displayToast: true,
          messageToast: "Conversation Resume Updated",
          severity: "success",
          confirmAlert: false,
        });
      } catch (e) {
        const errMessage = e.response.data.message;
        this.setState({
          displayToast: true,
          messageToast: errMessage,
          severity: "error",
          confirmAlert: false,
        });
      }
    }
  }

  editConvResume = () => {
    this.setState({showEditConvRes: true})
  }
  hideEditConvRes = () => {
    this.setState({showEditConvRes: false})
  }

  removeJourneyRoute = () => {
    this.setState({
      confirmAlert: true,
    });
  }

  confirmBtn = async () => {
    try {
      const allOptions = {
        url: jmUrl + '/app/' + localStorage.getItem('pId') + '/conversation/resume/url/generate',
      };
      await deleteConvRes(allOptions);
      this.setState({
        confirmAlert: false,
      });
      this.hideNewConfig(false);
      this.setState({
        displayToast: true,
        messageToast: "Conversation Resume Deleted",
        severity: "success",
        confirmAlert: false,
      });
    } catch (e) {
      const errMessage = e.response.data.message;
      this.setState({
        displayToast: true,
        messageToast: errMessage,
        severity: "error",
        confirmAlert: false,
      });
    }
  }

  handleOldSecret = (e) => {
    let secrets = {...this.state.allSecret};
    secrets.oldXSecret = e.target.value;
    this.setState({allSecret: secrets})
  }
  handleNewSecret = (e) => {
    let secrets = {...this.state.allSecret};
    secrets.newXSecret = e.target.value;
    this.setState({allSecret: secrets})
  }

  closeToast = () => {
    this.setState({displayToast: false});
  };

  // to close confirmation dialogue
  cancelBtn = () => {
    this.setState({confirmAlert: false});
  };

  copyText = async (data, result) => {
    if(result)
    {
        this.setState({
            displayToast: true,
            messageToast: "Copied to Clipboard",
            severity: "success",
        });
    }
    else{
      console.log(result)
      this.setState({
          displayToast: true,
          messageToast: "Failed to Copy",
          severity: "error",
      });
    }
  };


  render() {
    return (
      <div>
        {this.state.confirmAlert === true && (
          <ConfirmAlert
            indexValue={this.state.slabsIndex}
            mainIndex={this.state.globalVarNIndex}
            onConfirmBtn={this.confirmBtn}
            onCancelBtn={this.cancelBtn}
            heading="Are you sure ?"
            paragraph="If you proceed you will loose the data. Are you sure you want to delete ?"
          />
        )}
        <PositionedSnackbar
          display={this.state.displayToast}
          msg={this.state.messageToast}
          closeToast={this.closeToast}
          severity={this.state.severity}
        />
        {this.props.newConfig && (
          <div className="formField pl0 p0">
            <FieldGroup
              control={this.conversationResumeFormBuilder}
              render={({get, invalid, reset, value}) => (
                <form
                  className="flex newVar"
                  onSubmit={(e) => this.jRouteFormSubmit(e, value)}
                  style={{justifyContent: "center"}}
                >
                  <h4 className="varHead">Add Conversation Resume: </h4>
                  <FieldControl
                    name="xSecret"
                    options={{validators: Validators.required}}
                    render={({handler, touched, hasError}) => (
                      <div>
                        <input
                          {...handler()}
                          id="secretCR"
                          type="password"
                          placeholder="Secret"
                          className={`${
                            touched && hasError("required") && "errorInput"
                          }`}
                        />
                        <span
                          className={`${
                            touched && hasError("required") && "errorFieldMsg"
                          }`}
                        >
                          {touched && hasError("required") && "Required Filed"}
                        </span>
                      </div>
                    )}
                  />

                  <button
                    className={`sxp-btn btn-primary ${
                      invalid && "disablePagBtn"
                    }`}
                    type="submit"
                    id="saveGlobalVar"
                    style={{height: "34px", marginRight: "10px"}}
                    disabled={invalid}
                  >
                    Save
                  </button>
                  <span
                    id="cancelGlobalVar"
                    className="sxp-btn btn-danger"
                    onClick={() => this.hideNewConfig(false)}
                    style={{height: "34px", paddingTop: '9px'}}
                  >
                    Cancel
                  </span>
                </form>
              )}
            />
          </div>
        )}

        {this.props.configured &&
          <div className="configList pos-rel">
            {!this.state.showEditConvRes ?
              <div className="display-flex">
                <h5>External URL: &nbsp;  
                  <span 
                    style={{wordBreak:'break-word'}}
                    >{this.state.resumeUrl}</span>
                    <CopyToClipboard text={this.state.resumeUrl} onCopy={this.copyText}>
                      <img
                        alt="CopyIcon"
                        id={`copyConvResLink`}
                        src={copyIcon}
                        style={{ 
                          width:'14px',
                          verticalAlign:'-3px',
                          marginLeft:'7px',
                          cursor:'pointer'
                        }}
                    />
                  </CopyToClipboard>
                </h5>
                <div>
                  <button className="sxp-btn editBtn" onClick={this.editConvResume}>Edit</button>
                  <img
                    src={deletewhiteIcon}
                    alt="trash Icon"
                    id="deleteConverResImg"
                    onClick={this.removeJourneyRoute}
                    className="deleteIcon"
                  />
                </div>
              </div>
              :
              <div className="text-center jwtFields">
                <input type="password" placeholder="Enter Old Secret" name="oldXSecret"
                       onChange={(e) => this.handleOldSecret(e)}/>
                <input type="password" placeholder="Enter New Secret" name="newXSecret"
                       onChange={(e) => this.handleNewSecret(e)}/>
                <button className="sxp-btn btn-primary" onClick={this.updateConverRes}
                        style={{height: "34px", marginLeft: '10px', marginRight: "10px"}}>Update
                </button>
                <button className="sxp-btn btn-danger" onClick={this.hideEditConvRes} style={{height: "34px"}}>Cancel
                </button>
              </div>
            }
          </div>
        }
      </div>
    );
  }
}

export default ConversationResume;
