import React, { Component } from "react";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import AddIcon from "@material-ui/icons/Add";
import {
  FormBuilder,
  FieldGroup,
  FieldControl,
  Validators,
} from "react-reactive-form";
import moment from "moment";
import {
  deleteData,
  getData,
  saveData,
  updateData,
} from "../../dataProvider/apiHandler";
import Pagination from "../../commons/pagination";
import PositionedSnackbar from "../../commons/uicomponents/toast";
import LoaderSpinner from "../../commons/uicomponents/loader";
import EmptyCard from "../../commons/uicomponents/emptycard";
import ConfirmAlert from "../../commons/uicomponents/confirm";
import jrnyEdit from "../../assets/images/jrny-edit.png";
import calender from "../../assets/images/calender.png";
import deletewhiteIcon from "../../assets/images/deletewhite.png";
import FindError from "../../dataProvider/errorHandler";

const projectId = localStorage.getItem("pId");
const resetPwd = window._env_.REACT_APP_SERVER_URL + `api/auth/forgot-password`;
const serverUrl = window._env_.REACT_APP_SXP_URL;

class Users extends Component {
  constructor(props) {
    super(props);
    this.state = {
      journeyIndex: "",
      newConv: false,
      journeyIndexEdit: "",
      jrnyBorder: false,
      confirmAlert: false,
      displayToast: false,
      deleteJrnyId: "",
      users: [],
      loadingCard: false,
      newUser: false,
      roles: [],
      pageNo: 1,
      userID: "",
      rowPageSize: localStorage.getItem("rowPerPage"),
      totalPages: "",
      permissions: {},
      isAdmin: localStorage.getItem("isAdmin"),
    };
    this.conversation = React.createRef();
  }

  componentDidMount() {
    this.getAllUsers();
  }

  userFormBuilder = FormBuilder.group({
    username: ["", Validators.required],
    email: ["", Validators.required],
  });

  getAllUsers = async (paramsmet) => {
    this.setState({ loadingCard: true });
    try {
      const initialVal = {
        perPage: localStorage.getItem("rowPerPage"),
        ...paramsmet,
      };
      const allOption = {
        url: serverUrl + "projects/"+localStorage.getItem('pId')+"/users/",
        params: { ...initialVal },
      };
      let initialLoad = await getData(allOption);
      this.setState({
        users: initialLoad.data,
        // totalPages: initialLoad.data.data.pages,
        loadingCard: false,
      });
    } catch (e) {
      const errorObj = FindError(e)
      this.setState({
        displayToast: true,
        messageToast: errorObj.message,
        severity: "error",
        confirmAlert: false,
      });
    }
    this.setState({ loadingCard: false });
  };

  createNewUser = async (e, value) => {
    e.preventDefault();
    value.enabled = true;
    try {
      const allOption = {
        url: serverUrl + "projects/"+localStorage.getItem('pId')+"/users",
        body: value
      };
      await saveData(allOption);
      this.setState({
        loadingCard: false,
        displayToast: true,
        messageToast: "New User Created Successfully",
        severity: "success",
      });
    } catch (e) {
      const errorObj = FindError(e)
      this.setState({
        displayToast: true,
        messageToast: errorObj.message,
        severity: "error",
        confirmAlert: false,
      });
    }
  };

  // function to show input field for the required card field
  showInPlaceEdit = (index) => {
    let userList = [...this.state.users];
    userList[index].inplaceEdit = !userList[index].inplaceEdit;
    this.setState({ users: userList });
  };

  // function to show input field for the required card field
  InputPlaceEdit = (index) => {
    this.showInPlaceEdit(index);
  };

  changeName = async (evt, index) => {
    let userList = [...this.state.users];
    userList[index].name = evt.target.value;
    if (evt.keyCode === 13) {
      try {
        const allOptions = {
          url: serverUrl + "users/" + userList[index]._id,
          body: { name: userList[index].name },
        };
        await updateData(allOptions);
        this.setState({
          loadingCard: false,
          displayToast: true,
          messageToast: "User Name Updated Successfully",
          severity: "success",
        });
        userList[index].inplaceEdit = !userList[index].inplaceEdit;
      } catch (e) {
        const errorObj = FindError(e)
        this.setState({
          displayToast: true,
          messageToast: errorObj.message,
          severity: "error",
          confirmAlert: false,
        });
      }
    }
    this.setState({ users: userList });
  };

  onTitleBlur = async (index) => {
    let userList = [...this.state.users];
    try {
      const allOptions = {
        url: serverUrl + "users/" + userList[index]._id,
        body: { name: userList[index].name },
      };
      await updateData(allOptions);
      this.setState({
        loadingCard: false,
        displayToast: true,
        messageToast: "User Name Updated Successfully",
        severity: "success",
      });
      userList[index].inplaceEdit = !userList[index].inplaceEdit;
    } catch (e) {
      const errorObj = FindError(e)
      this.setState({
        displayToast: true,
        messageToast: errorObj.message,
        severity: "error",
        confirmAlert: false,
      });
    }
    userList[index].inplaceEdit = !userList[index].inplaceEdit;
    this.setState({ users: userList });
  };

  onNewUser = () => {
    this.setState({ newUser: true });
  };

  // to close a new form  filed of globar variable
  hideNewUser = () => {
    this.setState({ newUser: false });
  };

  setToggle = async (index, value) => {
    let userList = [...this.state.users];
    if (value) {
      userList[index].active = false;
    } else {
      userList[index].active = true;
    }
    try {
      const allOptions = {
        url: serverUrl + "users/" + userList[index]._id,
        body: { active: userList[index].active },
      };
      await updateData(allOptions);
      this.setState({
        loadingCard: false,
        displayToast: true,
        messageToast: "User Status Updated Successfully",
        severity: "success",
      });
    } catch (e) {
      const errorObj = FindError(e)
      this.setState({
        displayToast: true,
        messageToast: errorObj.message,
        severity: "error",
        confirmAlert: false,
      });
    }

    this.setState({ users: userList });
  };

  removeUser = (index, obj) => {
    this.setState({ confirmAlert: true, userID: obj.email });
  };

  confirmBtn = () => {
    this.confirmDelete(this.state.userID);
  };

  confirmDelete = async (id) => {
    if (id) {
      try {
        const allOptions = {
          url: serverUrl + `projects/` + localStorage.getItem('pId') + `/users/` + id,
        };
        await deleteData(allOptions);
        this.setState({
          confirmAlert: false,
          loadingCard: false,
          displayToast: true,
          messageToast: "User Deleted Successfully",
          severity: "success",
        });
      } catch (e) {
        const errorObj = FindError(e)
        this.setState({
          displayToast: true,
          messageToast: errorObj.message,
          severity: "error",
          confirmAlert: false,
        });
      }
    }
  };

  onResetPwd = async (email) => {
    try {
      const allOptions = {
        url: resetPwd,
        body: { email: email },
      };
      await saveData(allOptions);
      this.setState({
        loadingCard: false,
        displayToast: true,
        messageToast: "Email Sent to Registered Email ID",
        severity: "success",
      });
    } catch (e) {
      const errorObj = FindError(e)
      this.setState({
        displayToast: true,
        messageToast: errorObj.message,
        severity: "error",
        confirmAlert: false,
      });
    }
  };

  // to go to next page
  nextPage = () => {
    let PreviousSetNo = this.state.pageNo + 1;
    this.setState({ pageNo: PreviousSetNo });
    const paramsmet = {
      page: PreviousSetNo,
    };
    this.getAllUsers(paramsmet);
  };

  // to go to previousPage
  previousPage = () => {
    let PreviousSetNo = this.state.pageNo - 1;
    this.setState({ pageNo: PreviousSetNo });
    const paramsmet = {
      page: PreviousSetNo,
    };
    this.getAllUsers(paramsmet);
  };

  rowPerPageChange = async (e) => {
    this.setState({ loadingCard: true });
    localStorage.setItem("rowPerPage", e.target.value);
    const paramsmet = {
      perPage: e.target.value,
      version: localStorage.getItem("version"),
    };
    this.getAllUsers(paramsmet);
  };

  // Close Toast
  closeToast = () => {
    this.setState({ displayToast: false });
  };

  createUserForm = () => {
    return (
      <div className="formField pl0">
        <FieldGroup
          control={this.userFormBuilder}
          render={({ invalid, value }) => (
            <form
              className="flex newVar"
              onSubmit={(e) => this.createNewUser(e, value)}
              style={{ justifyContent: "center" }}
            >
              <h4 className="varHead">Create a User </h4>
              <FieldControl
                name="username"
                options={{ validators: Validators.required }}
                render={({ handler, touched, hasError }) => (
                  <div className="">
                    <input
                      {...handler()}
                      placeholder="Name"
                      className={`${
                        touched && hasError("required") && "errorInput"
                      }`}
                    />
                    <span
                      className={`${
                        touched && hasError("required") && "errorFieldMsg"
                      }`}
                    >
                      {touched && hasError("required") && "Required Filed"}
                    </span>
                  </div>
                )}
              />
              <FieldControl
                name="email"
                options={{ validators: Validators.required }}
                render={({ handler, touched, hasError }) => (
                  <div className="">
                    <input
                      {...handler()}
                      placeholder="Email"
                      className={`${
                        touched && hasError("required") && "errorInput"
                      }`}
                    />
                    <span
                      className={`${
                        touched && hasError("required") && "errorFieldMsg"
                      }`}
                    >
                      {touched && hasError("required") && "Required Filed"}
                    </span>
                  </div>
                )}
              />
              &nbsp;
              <button
                className={`sxp-btn btn-primary  left-space ${
                  invalid && "disablePagBtn"
                }`}
                type="submit"
                style={{ height: "34px", marginRight: "10px" }}
                disabled={invalid}
              >
                Save
              </button>
              <button
                className="sxp-btn btn-danger "
                onClick={this.hideNewUser}
                style={{ height: "34px" }}
              >
                Cancel
              </button>
            </form>
          )}
        />
      </div>
    );
  };

  bannerHeader = () => {
    return (
      <div className="display-flex table_head font13 fontWeight700 cardFull cardSpaceArrow">
        <div className="table_grid">
          <p>Name </p>
        </div>
        <div className="table_grid">
          <p>Email</p>
        </div>
        {/* <div className="table_grid">
          <p>Created on</p>
        </div>
        <div className="table_grid">
          <p>Last Updated on</p>
        </div> */}
        <div className="table_grid">
          <p>Action</p>
        </div>
        <div className="table_grid">
          <p>Active / InActive</p>
        </div>
      </div>
    );
  };

  bannerCard = (item, index) => {
    return (
      <div className="display-flex text_black font13 w100 pr15 pl15">
        <LoaderSpinner loadingCard={this.state.loadingCard} />
        <div className="table_grid">
          {item.inplaceEdit !== false ? (
            <div>
              <p
                className={`in-block overflowTextdots ${
                  this.state.permissions ? "" : "disable-click"
                }`}
                // onClick={() => this.InputPlaceEdit(index)}
                style={{ maxWidth: "118px" }}
              >
                {item.name}
              </p>
              &nbsp;
              {/* {this.state.permissions ? (
                <img
                  alt="editIcon"
                  src={jrnyEdit}
                  className="pointer-coursor"
                  onClick={() => this.showInPlaceEdit(index)}
                  style={{ verticalAlign: "13px" }}
                />
              ) : null} */}
            </div>
          ) : (
            <p className="">
              <input
                type="text"
                value={item.name}
                name="name"
                style={{
                  height: "34px",
                  border: "1px solid #999",
                  borderRadius: "8px",
                }}
                onChange={(e) => this.changeName(e, index)}
                onBlur={() => this.onTitleBlur(index)}
                onKeyDown={(e) => this.changeName(e, index)}
              />
            </p>
          )}
        </div>
        <div className="table_grid">
          <p className="overflowTextdots" onClick={this.copyText}>
            {item.email}
          </p>
        </div>
        {/* <div className="table_grid">
          <p>
            {item.created_at ? (
              <>
                <img
                  src={calender}
                  className="vertical-7"
                  alt="calendar Icon"
                />
                <span
                  style={{
                    color: "#000",
                    marginTop: "6px",
                  }}
                >
                  {moment(item.created_at).format("DD-MMM-YYYY")}
                </span>
              </>
            ) : (
              "N/A"
            )}
          </p>
        </div>
        <div className="table_grid">
          <p>
            <img src={calender} className="vertical-7" alt="calendar Icon" />
            <span
              style={{
                color: "#000",
                marginTop: "6px",
              }}
            >
              {moment(item.updated_at).format("DD-MMM-YYYY")}
            </span>
          </p>
        </div> */}
        <div className="table_grid">
          <button
            className="resetBtn"
            onClick={this.onResetPwd.bind(this, item.email)}
          >
            Reset Password
          </button>
        </div>
        <div className="table_grid">
          <div
            className={`toggle-parent-modeller ${
              item.active ? "" : "inactiveToggle"
            }`}
            onClick={this.setToggle.bind(this, index, item.active)}
            style={{ marginTop: "7px" }}
          >
            <div
              className={` ${
                item.active ? "toggle-button" : "disabled-toggle"
              }`}
            >
              {" "}
            </div>
          </div>
        </div>
        <img
          src={deletewhiteIcon}
          alt="trash Icon"
          onClick={() => this.removeUser(index, item)}
          className="deleteIcon"
        />
      </div>
    );
  };

  render() {
    return (
      <div className="parent mt10">
        {this.state.confirmAlert === true && (
          <ConfirmAlert
            onConfirmBtn={this.confirmBtn}
            onCancelBtn={this.cancelBtn}
            heading="Are you sure you want delete the user from this project?"
            paragraph="If you delete the user will no longer  ?"
          />
        )}
        <PositionedSnackbar
          display={this.state.displayToast}
          msg={this.state.messageToast}
          closeToast={this.closeToast}
          severity={this.state.severity}
        />
        <div className="display-flex">
          <h4 className="m0">
            Users <span className="label_pink">Create a new user</span>
          </h4>
          <div>
            {this.state.permissions ? (
              <button
                className="sxp-btn btn-primary"
                id="addNewUser"
                onClick={this.onNewUser.bind(this)}
              >
                <AddIcon className="vertical-7" /> New User
              </button>
            ) : null}
          </div>
        </div>

        {this.state.newUser ? this.createUserForm() : null}

        {this.bannerHeader()}

        {this.state.users.length ? (
          <div className="flex-jrny">
            {this.state.users.map((item, index) => (
              <ExpansionPanel
                className="CustomexpansionPannel"
                key={item._id}
                expanded={
                  this.state.expansionJrnyPanelOpen &&
                  this.state.journeyIndex === index
                }
              >
                <ExpansionPanelSummary className="boxShadow">
                  {this.bannerCard(item, index)}
                </ExpansionPanelSummary>
              </ExpansionPanel>
            ))}
          </div>
        ) : (
          <EmptyCard />
        )}

        <Pagination
          pageNo={this.state.pageNo}
          totalPages={this.state.totalPages}
          rowPerPageChange={this.rowPerPageChange}
          previousPage={this.previousPage}
          nextPage={this.nextPage}
        />
      </div>
    );
  }
}

export default Users;
