import React, { Component } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import Layout from "./layout";
import LoadSxpChat from "./sxp-chat";

class App extends Component {
  render() {
    return (
      <Router>
        <Layout />
        <LoadSxpChat />
      </Router>
    );
  }
}

export default App;
