import React from "react";
import Select from "react-select";
import uuid from "react-uuid";
import { submit } from "./formActions";
import { FormButtons } from "./formbuttons";
import Tooltip from "@material-ui/core/Tooltip";
import cheveronDownRotate from './../../../assets/images/chevron-down.png';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

const style = {
  control: (base, state) => ({
    ...base,
    border: state.isFocused ? 0 : 0,
    // This line disable the blue border
    boxShadow: state.isFocused ? 0 : 0,
    "&:hover": {
      border: state.isFocused ? 0 : 0,
    },
  }),
};

export class Dialog extends React.Component {
  state = {
    formUniqueId:uuid(),
  };

  checkValidForm = (e) => {
    e.preventDefault();
    if (this.props.data.data.startDialogId) {
      submit(e, this.props, this.state);
    } else {
      let updatedState = { ...this.state };
      updatedState.errorMsg = true;
      this.setState(updatedState);
    }
  };

  handleInput = (e) => {
    let updatedState = {...this.state};
    updatedState[e.target.name] = e.target.value;
    this.props.data.data[e.target.name] = e.target.value;
    this.setState(updatedState)
  }

  handleChange = (e) => {
    let updatedState = { ...this.state };
    this.props.data.data.startDialogId = e.value;
    this.setState(updatedState);
  };

  handleForm = (e) => {
    let updatedState = { ...this.state };
    this.props.data.data.form = e.target.checked;
    this.setState(updatedState)
  }

  handleEdit= (e) => {
    let updatedState = { ...this.state };
    this.props.data.data.edit = e.target.checked;
    this.setState(updatedState)
  }

  handleDynamicJour = (e) => {
    let updatedState = { ...this.state };
    this.props.data.data.dynamicJourney = e.target.checked;
    this.setState(updatedState)
  }

  handleToGoTask = (val) => {
    this.props.callBackJName(val)
  }

  render() {
    if (this.props.conversations) {
      let conversation = this.props.conversations.find(
        (x) => x.value === this.props.data.data.startDialogId
      );
      if (conversation) this.props.data.conversationObj = conversation;
    }
    return (
      <form className="form-style" onSubmit={this.checkValidForm}>
        <div>
          <label> Name</label>
          <input
            type="text"
            name="name"
            defaultValue={this.props.data.data.name}
            key={this.state.formUniqueId}
            onChange={this.handleInput}
            value={this.props.data.data.name}
            required
          />
        </div>
        <div className="flex" style={{alignItems:'center'}}>
          <div style={{flexGrow:1}}>
            <label> Conversation </label>
            <Select
              styles={style}
              name="startDialogId"
              className={`searchSelectDDCS customSelect ${
                this.state.errorMsg ? "error-Input" : ""
              }`}
              options={this.props.conversations}
              defaultValue={this.props.data.conversationObj}
              key={this.state.formUniqueId}
              isSearchable
              onChange={this.handleChange}
            />
            {this.state.errorMsg ? (
              <p className="errorFieldMsg mt0" style={{ fontWeight: 600 }}>
                Select Conversation
              </p>
            ) : null}
          </div>
          <div className="gotoAnchor">
            <Tooltip className="modelerToolTip"  title={`Open "${this.props.data.conversationObj?.label}" conversation`} arrow placement="right">
              <span onClick={() => this.handleToGoTask(this.props.data.conversationObj)}>
              <ChevronRightIcon/>
              </span>
            </Tooltip>
          </div>
        </div>
        <div>
          <input
            type="checkbox"
            key={this.state.formUniqueId}
            defaultChecked={this.props.data.data.form}
            name="form"
            onChange={this.handleForm}
          />
          <label>Is Form </label>
        </div>
        {this.props.data.data.form && (
          <>
            <div>
              <label> Form Title </label>
              <input 
                type="text" 
                name="formHeading" 
                key={this.state.formUniqueId} 
                defaultValue={this.props.data.data.formHeading}
                onChange={this.handleInput}
                value={this.props.data.data.formHeading}
                required
                />
            </div>
            <div>
              <label>Form Description</label>
              <textarea
               type="text" 
               name="formDescription" 
               key={this.state.formUniqueId} 
               defaultValue={this.props.data.data.formDescription}
               onChange={this.handleInput}
               value={this.props.data.data.formDescription}
              ></textarea>
            </div>
          </>
        )}
        <div>
          <input
            type="checkbox"
            key={this.state.formUniqueId}
            defaultChecked={this.props.data.data.edit}
            name="edit"
            onChange={this.handleEdit}
          />
          <label>Edit </label>
        </div>
        <div>
          <input
            type="checkbox"
            key={this.state.formUniqueId}
            defaultChecked={this.props.data.data.dynamicJourney}
            name="dynamicJourney"
            onChange={this.handleDynamicJour}
          />
          <label>Dynamic Journey </label>
        </div>
        {this.props.data.data.dynamicJourney === true && (
          <div>
          <label> Dynamic References</label>
          <input 
            type="text"
            key={this.state.formUniqueId}
            defaultValue={this.props.data.data.dynamicReferences}
            name="dynamicReferences"
            onChange={this.handleInput} 
            value={this.state.dynamicReferences}
            />
        </div>
        )}
        <FormButtons formProps={this.props} />
      </form>
    );
  }
}
