import React, { Component } from "react";
import axios from "axios";

import PositionedSnackbar from "../../commons/uicomponents/toast";
import { getSingleData, saveData } from "../../dataProvider/apiHandler";
import VersionSelectDropDown from "../../commons/versions-dropdown";
import FindError from "../../dataProvider/errorHandler";
import UFEditor from "../../commons/uf-editor";

const serverUrl = window._env_.REACT_APP_SXP_URL;
axios.defaults.headers.common["Accept"] = "application/json";

class UserFunction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: "",
      loadedData: false,
    };
  }

  componentDidMount = () => {
    this.loadData();
  };

  loadData = async () => {
    try {
      const allOptions = {
        url: serverUrl + "be/userfunctions/" + localStorage.getItem('pId') + '/functions/'+localStorage.getItem('version'),
        params: { version: localStorage.getItem("version"),
            projectId: localStorage.getItem("pId"), },
      };
      let initialLoad = await getSingleData(allOptions)
      this.setState({ data: initialLoad.data.data, loadedData: true });
    } catch (e) {
      const errorObj = FindError(e)
      this.setState({
        displayToast: true,
        messageToast: errorObj.message,
        severity: "error",
        confirmAlert: false,
      });
    }
  };

  onChange = (newValue) => {
    this.setState({ data: newValue });
  };

  closeToast = () => {
    this.setState({ displayToast: false });
  };

  userFunctionSave = async () => {
    try {
      const dataObj = {
        fileData: this.state.data,
        version: localStorage.getItem('version'),
        projectId: localStorage.getItem('pId'),
      };
      const allOptions = {
        url: serverUrl + "be/userfunctions/" + localStorage.getItem('pId'),
        body: {data:dataObj},
      };
      await saveData(allOptions);
      this.setState({
        displayToast: true,
        messageToast: "Updated Successfully",
        severity: "success",
      });
      this.loadData();
    } catch (e) {
      const errorObj = FindError(e)
      this.setState({
        displayToast: true,
        messageToast: errorObj.message,
        severity: "error",
        confirmAlert: false,
      });
    }
  };

  render() {
    return (
      <div>
        <PositionedSnackbar
          display={this.state.displayToast}
          msg={this.state.messageToast}
          closeToast={this.closeToast}
          severity={this.state.severity}
        />
        <VersionSelectDropDown final={this.loadData} />
        <UFEditor
          funcData={this.state.data}
          onChange={this.onChange}
          onSave={this.userFunctionSave}
          mode="all"
          loaded={this.state.loadedData}
        />
      </div>
    );
  }
}

export default UserFunction;
