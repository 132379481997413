export default (state, action) => {
  switch (action.type) {
    case "rotate":
      return {
        toggleMenu: action.payload,
      };
    default:
      return state;
  }
};
