import React from "react";
import Select from "react-select";
import { getServices } from "../api/api";
import { FormButtons } from "./formbuttons";
import { submit } from "./formActions";

const style = {
  control: (base, state) => ({
    ...base,
    border: state.isFocused ? 0 : 0,
    // This line disable the blue border
    boxShadow: state.isFocused ? 0 : 0,
    "&:hover": {
      border: state.isFocused ? 0 : 0,
    },
  }),
};

export class DYNAMIC extends React.Component {
  state = {};


  static getDerivedStateFromProps(nextProps, currentState) {
    let propsState;
    if (
      currentState.formUniqueId &&
      currentState.formUniqueId === nextProps.formUniqueId
    ) {
      propsState = { ...(nextProps.data.data || {}), ...currentState };
    } else {
      propsState = { ...currentState, ...(nextProps.data.data || {}) };
    }
    propsState.formUniqueId = nextProps.formUniqueId;

    if (propsState.services && propsState.serviceId) {
      let service = propsState.services.find(
        (x) => x.value === propsState.serviceId
      );
      if (service) propsState.servicesObj = service;
    }
    return propsState;
  }

  componentDidMount() {
    let onLoadState = { ...this.state };
    getServices().then((response) => {
      let services = response.data.data.docs.map((service) => {
        return {
          value: service.id,
          label: service.name,
          bodyJson: service.bodyJson,
        };
      });
      onLoadState.services = services;
      this.setState(onLoadState);
    });
  }

  componentWillUnmount() {
    this.setState({});
  }


  handleChange = (e) => {
    if (this.state.services) {
      this.state.services.forEach((service) => {
        if (e.value === service.value) {
          let updatedState = { ...this.state };
          updatedState.serviceId = e.value;
          this.setState(updatedState);
        }
      });
    }
  };

  handleTextInputChange = (e) => {
    let updatedState = { ...this.state };
    updatedState[e.target.name] = e.target.value;
    this.setState(updatedState);
  };

  render() {
    return (
      <form className="form-style" onSubmit={(e) => submit(e, this.props)}>
        <div>
          <label> Name </label>
          <input
            type="text"
            name="name"
            defaultValue={this.state.name}
            key={this.state.formUniqueId}
            onChange={this.handleTextInputChange}
            required
            autoFocus
          />
        </div>

        <div>
          <label> Variables </label>
          <input
            type="text"
            name="variables"
            defaultValue={this.state.variables}
            key={this.state.formUniqueId}
            onChange={this.handleTextInputChange}
            required
          />
        </div>
        <label> API Service: </label>
        <Select
          styles={style}
          name="serviceId"
          className="searchSelectDDCS customSelect"
          options={this.state.services}
          defaultValue={
            this.state.servicesObj
              ? this.state.servicesObj
              : { value: "", label: "" }
          }
          value={this.state.servicesObj}
          key={this.state.formUniqueId}
          isSearchable
          onChange={this.handleChange}
        />

        <FormButtons formProps={this.props} />
      </form>
    );
  }
}
