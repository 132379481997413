import React, { Component } from "react";
import {
  FieldControl,
  FieldGroup,
  FormBuilder,
  Validators,
} from "react-reactive-form";
import { FormHelperText, MenuItem, Select } from "@material-ui/core";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import AddIcon from "@material-ui/icons/Add";
import { getData } from "../../dataProvider/apiHandler";
import DeleteIcon from "../../assets/images/subtract.png";
import SettingIcon from "../../assets/images/Intersect.png";
import CloseIcon from "../../assets/images/close.png";
import RecoTypeMultipleSelect from "./recoTypeMultiSelect";
import FormFields from "./formfields";
import FindError from "../../dataProvider/errorHandler";

const serverUrl = window._env_.REACT_APP_SXP_URL;

// Responsive for list of journeys to show number of list according to device or screen size
const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 3,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

class Conversation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      journeys: [],
      typeList: [],
      activeIndex: "",
      selectedRecomendation: [],
      journeysParams: [],
      instanceVal: "",
      selectedRecoVal: "",
      showEle: true,
      callApi: true,
    };
  }

  componentDidMount = () => {
    this.getJourneysList();
    this.getRecoList();
    this.getAllRecoReferences();
  };

  componentDidUpdate = () => {
    let jId = this.props.singleConversation.id;
    this.getJpram(jId);
    if (this.props.singleConversation.name) {
      this.buildingConversationForm(this.props.singleConversation);
    }
  };

  getJourneysList = async () => {
    try {
      const allOption = {
        url: serverUrl + "tasks/" + localStorage.getItem('pId'),
        params: { 
          version: localStorage.getItem("version"),
          projectId: localStorage.getItem("pId"),
        },
      };
      let tasks = await getData(allOption);
      this.setState({ journeys: tasks.data });
    } catch (e) {
      const errorObj = FindError(e)
      this.setState({
        displayToast: true,
        messageToast: errorObj.message,
        severity: "error",
        confirmAlert: false,
      });
    }
  };

  getJpram = async (id) => {
    if (id && !this.state.journeysParams?.length && this.state.callApi) {
      const allOption = {
        url: serverUrl + "components/" + localStorage.getItem('pId'),
        params: {
          journeyId: id,
          component: "UTTERANCE"
        }
      };
      let allJourneyParams = await getData(allOption);
      this.setState({
        journeysParams: allJourneyParams.data.map((d) => d.reference),
        callApi: false,
      });
    }
  };

  getRecoList = async () => {
    try {
      const allOption = {
        url: serverUrl + "reco-config/type/"+ localStorage.getItem('pId'),
      };
      let type = await getData(allOption);
      let selectedTypeList = type.data.data.docs;
      let selectedRecoType =
        this.props.singleConversation &&
        this.props.singleConversation.selectedRecommendationTypes;
      if (selectedRecoType) {
        for (let i = 0; i < selectedTypeList.length; i++) {
          for (let j = 0; j < selectedRecoType.length; j++) {
            if (selectedTypeList[i].name === selectedRecoType[j].type) {
              selectedTypeList[i]["isSelected"] = true;
            }
          }
        }
      }
      this.setState({
        typeList: selectedTypeList,
        selectedRecomendation: selectedRecoType,
      });
      this.conversationFormBuilder.patchValue({});
    } catch (e) {
      const errorObj = FindError(e)
      this.setState({
        displayToast: true,
        messageToast: errorObj.message,
        severity: "error",
        confirmAlert: false,
      });
    }
  };

  getAllRecoReferences = async () => {
    try {
      const allOption = {
        url: serverUrl + "reco-config/references/"+localStorage.getItem('pId'),
      };
      let allRef = await getData(allOption);
      this.setState({ instanceVal: allRef.data.data });
      this.conversationFormBuilder.patchValue({});
    } catch (e) {
      const errorObj = FindError(e)
      this.setState({
        displayToast: true,
        messageToast: errorObj.message,
        severity: "error",
        confirmAlert: false,
      });
    }
  };

  conversationFormBuilder = FormBuilder.group({
    name: ["", Validators.required],
    intent: ["", Validators.required],
    preTask: [""],
    description: ["", Validators.required],
    selectedRecommendationTypes: FormBuilder.group({}),
    conversationStarter: false,
    version: [this.props.currentVersion],
  });

  submitConversation = (e, control) => {
    this.conversationFormBuilder.value.selectedRecommendationTypes =
      this.state.selectedRecomendation;
    e.preventDefault();
    control.handleSubmit();
    this.props.convform === "edit"
      ? this.props.updateJourneyApi({
          ...this.conversationFormBuilder.value,
          id: this.props.singleConversation.id,
          selectedRecommendationTypes: this.state.selectedRecomendation,
          active: this.props.singleConversation.active,
        })
      : this.props.createJourneyApi(
          this.conversationFormBuilder.value,
          this.state.selectedRecomendation
        );
  };

  onCancel = () => {
    this.props.closeForm();
  };

  buildingConversationForm = (data) => {
    if (data) {
      this.conversationFormBuilder.patchValue({
        name: data.name,
        intent: data.intent,
        preTask: data.preTask,
        description: data.description,
        recommend: data.recommend,
        conversationStarter: data.conversationStarter,
      });
    }
  };

  selectedRecoTab = async (data, i) => {
    let indexofjourney = data.target.getAttribute("data-index");
    this.setState({
      selectedRecoVal: i.type,
      activeIndex: indexofjourney,
    });
    this.conversationFormBuilder.patchValue({});
  };

  addNewParam = (e, i) => {
    let allParams = [...this.state.selectedRecomendation];
    let selectedType = allParams.find(
      (a) => a.type === this.state.selectedRecoVal
    );
    selectedType.params.push({});
    this.setState({ selectedRecomendation: allParams });
    this.conversationFormBuilder.patchValue({});
  };

  handleChangeRecoParam = (i, event) => {
    let allParams = [...this.state.selectedRecomendation];
    let selectedType = allParams.find(
      (a) => a.type === this.state.selectedRecoVal
    );
    selectedType.params[i].recoParam = event.target.value;
    this.setState({ selectedRecomendation: allParams });
    this.conversationFormBuilder.patchValue({});
  };

  handleChangeJourneyParam = (i, event) => {
    let allParams = [...this.state.selectedRecomendation];
    let selectedType = allParams.find(
      (a) => a.type === this.state.selectedRecoVal
    );
    selectedType.params[i].journeyParam = event.target.value;
    this.setState({ selectedRecomendation: allParams });
    this.conversationFormBuilder.patchValue({});
  };

  deleteRecoParam = (i, index) => {
    let allParams = [...this.state.selectedRecomendation];
    let selectedType = allParams.find(
      (a) => a.type === this.state.selectedRecoVal
    );
    let rmoveSlected = selectedType.params;
    rmoveSlected.splice(index, 1);
    this.setState({ selectedRecomendation: allParams });
    this.conversationFormBuilder.patchValue({});
  };

  RecoSelectCallBck = (childData) => {
    this.setState({ selectedRecoVal: childData[0] });
    let recoSelectedObj = [];
    this.conversationFormBuilder.patchValue({});
    for (let i = 0; i < childData.length; i++) {
      let paramsData = this.state.selectedRecomendation
        ? this.state.selectedRecomendation.find((a) => a.type === childData[i])
        : {};
      let paramsVal = paramsData?.params;
      let obj = {
        type: childData[i],
        params: paramsVal || [],
      };
      recoSelectedObj.push(obj);
    }
    this.setState({ selectedRecomendation: recoSelectedObj });
  };

  toggleElement = async () => {
    if (this.props.singleConversation && this.props.singleConversation.id) {
      this.getRecoList();
      if(this.props.singleConversation?.selectedRecommendationTypes)
      { 
        let selectedR =
        this.props.singleConversation &&
        this.props.singleConversation?.selectedRecommendationTypes[0] &&
        this.props.singleConversation?.selectedRecommendationTypes[0].type;
        this.setState({ selectedRecoVal: selectedR });
      }
    }
    this.setState({ showEle: !this.state.showEle });
    this.conversationFormBuilder.patchValue({});
  };

  conversationForm = () => {
    return (
      <FieldGroup
        control={this.conversationFormBuilder}
        render={(control, invalid) => (
          <form
            className="form-style"
            onSubmit={(e) => this.submitConversation(e, control)}
          >
            <FieldControl
              name="name"
              render={FormFields.TextInput}
              meta={{ label: "name" }}
            />
            <FieldControl
              name="intent"
              render={FormFields.TextInput}
              meta={{ label: "Intent" }}
            />
            <div className="customDDW">
              <FieldControl
                name="preTask"
                render={FormFields.pretaskDropdown}
                meta={{
                  label: "select Pre-task",
                  services: this.props.alljourneys,
                }}
              />
            </div>
            {/* <FieldControl
              name="description"
              render={FormFields.TextArea}
              meta={{ label: "Description" }}
            /> */}
            
            <FieldControl
              name="description"
              render={FormFields.TextInputNoValidation}
              meta={{ label: "Keywords" }}
              noValidate
            />
            <div className="reco-Column">
              <RecoTypeMultipleSelect
                recoTypeList={this.state.typeList}
                selectedRecoTypes={
                  this.props.singleConversation &&
                  this.props.singleConversation.selectedRecommendationTypes
                }
                getCallBackData={this.RecoSelectCallBck}
              />
              {this.state.showEle == true ? (
                <img
                  src={SettingIcon}
                  className="IconBorderCircular"
                  alt="setting icon"
                  onClick={this.toggleElement}
                />
              ) : (
                <img
                  src={CloseIcon}
                  className="IconBorderCircular"
                  alt="close icon"
                  onClick={this.toggleElement}
                />
              )}
              <br />
              {this.state.showEle == false ? (
                <div>
                  <span style={{ fontSize: "13px", color: "#086284" }}>
                    Click on one Reco Type tab below to Map the Reco Params with
                    Journey Params.
                  </span>
                  <Carousel
                    className="journeySlider customeScrollCar"
                    responsive={responsive}
                  >
                    {this.state.selectedRecomendation
                      ? this.state.selectedRecomendation.map((d, i) => {
                          return (
                            <>
                              <div
                                key={i}
                                data-index={i}
                                className={`text-overfl-card ${
                                  this.state.activeIndex == i
                                    ? "activejour"
                                    : ""
                                }`}
                                onClick={(e) => this.selectedRecoTab(e, d)}
                              >
                                {d.type}
                              </div>
                            </>
                          );
                        })
                      : []}
                  </Carousel>
                  <div>
                    {this.state.selectedRecomendation &&
                    this.state.selectedRecomendation.length
                      ? this.addRecoParamsUI()
                      : null}
                  </div>
                </div>
              ) : null}
            </div>

            <div className="flex-box">
              <FieldControl
                name="conversationStarter"
                render={FormFields.DefaultCheckbox}
              />
              <p className="in-block m0 recommend-text">Is Service</p>
            </div>
            <button type="submit" className="applyBtn">
              {" "}
              Apply{" "}
            </button>
            <button type="button" onClick={this.onCancel} className="deleBtn">
              {" "}
              Cancel{" "}
            </button>
          </form>
        )}
      />
    );
  };

  addRecoParamsUI = () => {
    let selectedParam = this.state.selectedRecomendation.find(
      (a) => a.type === this.state.selectedRecoVal
    );
    let selectedParamparams = selectedParam && selectedParam.params;
    return (
      <div className="text-center selectReco_jour">
        <span style={{ fontSize: "13px", color: "#086284" }}>
          NOTE : (You can Add a new Reco Parameter and even delete the existing
          one).
        </span>
        <br />
        <span className="addRecoParamBtn" onClick={(e) => this.addNewParam(e)}>
          <AddIcon className="vertical-7" />
          Add Reco params
        </span>
        {selectedParamparams
          ? selectedParamparams.map((recoObj, j) => (
              <div
                className="display-flex selectDDinMulti"
                key={j}
                style={{ alignItems: "baseline" }}
              >
                <div
                  className={`pos-rel ${
                    this.props.convform === "edit" ? "" : "w100"
                  }`}
                >
                  <Select
                    name="recoParam"
                    className="sxp-defaultDropDown_filed w100 recoParam"
                    value={recoObj.recoParam}
                    onChange={this.handleChangeRecoParam.bind(this, j)}
                  >
                    <MenuItem>Select Reco Param</MenuItem>
                    {this.state.instanceVal
                      ? this.state.instanceVal.map((d, oo) => (
                          <MenuItem key={oo} value={d}>
                            {d}
                          </MenuItem>
                        ))
                      : ""}
                  </Select>
                  {recoObj.recoParam ? null : (
                    <FormHelperText className="projSelectPlaceholder modelParamsDD">
                      {" "}
                      Select Reco Params{" "}
                    </FormHelperText>
                  )}
                </div>
                {this.props.convform === "edit" ? (
                  <div className="pos-rel">
                    <Select
                      name="journeyParam"
                      className="sxp-defaultDropDown_filed w100 jouParam"
                      value={recoObj.journeyParam ? recoObj.journeyParam : ""}
                      onChange={this.handleChangeJourneyParam.bind(this, j)}
                    >
                      <MenuItem>Select Journey Param</MenuItem>
                      {this.state.journeysParams.length ? (
                        this.state.journeysParams.map((journeyData, i) => (
                          <MenuItem key={i} value={journeyData}>
                            {journeyData}
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem disabled>No params found</MenuItem>
                      )}
                    </Select>
                    {recoObj.journeyParam ? null : (
                      <FormHelperText className="projSelectPlaceholder">
                        {" "}
                        Select Jour Params{" "}
                      </FormHelperText>
                    )}
                  </div>
                ) : (
                  ""
                )}
                <img
                  src={DeleteIcon}
                  alt="delete icon"
                  onClick={(e) => this.deleteRecoParam(e, j)}
                />
              </div>
            ))
          : ""}
      </div>
    );
  };

  createConversationUI = () => {
    return (
      <div className="create-conv">
        <p className="form-title m0">
          {this.props.convform === "edit" ? "Edit Journey" : "Create Journey"}
        </p>
        <h5 className="jouTitleEdit">{this.props.singleConversation.name}</h5>
        {this.conversationForm(this.props.singleConversation)}
      </div>
    );
  };

  render() {
    return <div className="createConvDiv">{this.createConversationUI()}</div>;
  }
}

export default Conversation;
