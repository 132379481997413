import React, { Component } from "react";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AddIcon from "@material-ui/icons/Add";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import moment from "moment";
import { FormBuilder, FieldGroup, Validators } from "react-reactive-form";
import ReactJson from "react-json-view";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { getData, saveData, updateData } from "../../dataProvider/apiHandler";
import PositionedSnackbar from "../../commons/uicomponents/toast";
import LoaderSpinner from "../../commons/uicomponents/loader";
import EmptyCard from "../../commons/uicomponents/emptycard";
import searchIcon from "../../assets/images/search.png";
import calender from "../../assets/images/calender.png";
import FindError from "../../dataProvider/errorHandler";

const serverUrl = window._env_.REACT_APP_SXP_URL;

const channelLists = ["facebook", "slack", "webchat", "email", "whatsapp", "telegram", "teams"];
const initialValues = {
  facebook: {
    config: {
      validationToken:"",
      pageToken:"",
      pageID:"",
      appID:"",
      appSecret:""
    },
  },
  slack: {
    config: {
      slack_access_token: "",
      slack_signing_secret: "",
    },
  },
  webchat:{
    config:{
      active:'',
    }
  },
  email:{
    config:{
      active:'',
    }
  },
  whatsapp:{
    config:{
      active:"",
      accessToken:"",
      phoneNumberId:"",
      validationToken:"",
      appSecret:"",
    }
  },
  telegram:{
    config:{
      active:'',
      botToken:'',
    }
  },
  teams:{
    config:{
      active: "",
      appId:"",
      appPassword:"",
      tenantId:"",
      appType:"",
      serviceUrl:"",
    }
  },
};

class ChannelConfig extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expansionJrnyPanelOpen: false,
      expansionIGPanelOpen: false,
      expansionCCPanelOpen: false,
      channelIndex: "",
      loadingCard: false,
      pageNo: 1,
      rowPageSize: localStorage.getItem("rowPerPage"),
      channels: [],
      headers: [],
      newAddedChannelName: "",
      filteredChannelList: [],
      displayMenu: false,
      permissions: {},
      skipData:'',
      searchTextData:'',
    };
    this.conversation = React.createRef();
  }

  componentDidMount = () => {
    this.getAllData();
  };

  // EXPANSIONS HANDLERS
  switchJrnyExpansionpanel(index, action) {
    if (this.state.channelIndex === index) {
      this.setState({
        expansionJrnyPanelOpen: false,
        channelIndex: "",
      });
    } else {
      this.setState({
        expansionJrnyPanelOpen: true,
        channelIndex: index,
        newAddedChannelName: action && action.name,
      });
      this.buildingConversationForm(index);
    }
  }

  // form builder initial values
  conversationFormBuilder = FormBuilder.group({
    name: ["", Validators.required],
    config: FormBuilder.group({
      url: ["", Validators.required],
      access_token: ["", Validators.required],
      verify_token: ["", Validators.required],
      fb_app_secret: ["", Validators.required],
      slack_access_token: ["", Validators.required],
      slack_signing_secret: ["", Validators.required],
    }),
  });

  buildingConversationForm = (index) => {
    let channelsRen = [...this.state.channels];
    if (channelsRen[index].id) {
      this.conversationFormBuilder.patchValue({
        name: channelsRen[index]?.name,
        config: channelsRen[index]?.config,
      });
    }
  };

  filterChannelList = () => {
    let allChannelsData = this.state.channels.map((d) => d.name);
    const allchannels = channelLists;
    let setAllChannelsData = new Set(allChannelsData);
    let filteredChannel = allchannels.filter((x) => !setAllChannelsData.has(x));
    this.setState({ filteredChannelList: filteredChannel });
  };

  // to add a new channel card
  addNewChannel = (data) => {
    let channelsRen = channelLists;
    let selectedChannel = channelsRen.filter((item) => item === data);
    this.onNewChannel(selectedChannel[0]);
  };

  onNewChannel = async (channelName) => {
    let channelsRen = [...this.state.channels];
    channelsRen.push({ name: channelName });
    this.setState({
      newAddedChannelName: channelName,
      channels: channelsRen,
      displayMenu: false,
      channelListdiv: false,
      displayToast: true,
      messageToast: "New Channel card added in bottom",
      severity: "success",
    });
  };

  // Close Toast
  closeToast = () => {
    this.setState({ displayToast: false });
  };

  rvjheadersChange = (newValue, index) => {
    let channelsRen = [...this.state.channels];
    this.conversationFormBuilder.value["config"] = newValue;
    channelsRen[index]["config"] = newValue;
    this.setState({ channels: channelsRen });
  };

  // to get all global variable data
  getAllData = async (paramsmet) => {
    try {
      this.setState({ loadingCard: true });
      const initialVal = {
        paginate: true,
        page: paramsmet?.page || this.state.skipData,
        projectId: localStorage.getItem("pId"),
        searchWord: this.state.searchTextData === '.*' ? null: this.state.searchTextData,
        searchKey: 'name'
      };
      paramsmet = {
        ...initialVal,
        ...paramsmet,
      };
      const allOptions = {
        url: serverUrl + "channel-config/"+ localStorage.getItem('pId'),
        params: { ...paramsmet },
      };
      let initialLoad = await getData(allOptions);
      this.setState({
        channels: initialLoad.data.data.docs,
        totalPages: initialLoad.data.data.pages,
        loadingCard: false,
      });
    } catch (e) {
      const errorObj = FindError(e)
      this.setState({
        displayToast: true,
        messageToast: errorObj.message,
        severity: "error",
        confirmAlert: false,
        loadingCard: false,
      });
    }
    this.filterChannelList();
  };

  searchCard = (e) => {
    let searchText = e.target.value;
    const paramsmet = {
      filter: {
        name: searchText,
      },
    };
    this.setState({searchTextData: searchText + '.*'}, () =>
    this.getAllData(paramsmet)
    )
  };

  setToggle = async (index, value) => {
    let channelsRen = [...this.state.channels];
    if (value) {
      channelsRen[index].enable = false;
    } else {
      channelsRen[index].enable = true;
    }
    try {
      const allOptions = {
        url: serverUrl + "channel-config/" + localStorage.getItem('pId') + '/'+channelsRen[index].id,
        body: { enable: channelsRen[index].enable },
      };
      await updateData(allOptions);
      this.setState({
        displayToast: true,
        messageToast: "Updated Successfully",
        severity: "success",
      });
    } catch (e) {
      const errorObj = FindError(e)
      this.setState({
        displayToast: true,
        messageToast: errorObj.message,
        severity: "error",
        confirmAlert: false,
      });
    }
    this.setState({ channels: channelsRen });
  };

  DisplayingMenu = () => {
    this.setState({ displayMenu: !this.state.displayMenu });
  };

  // function to add a new business module or update a existing businessModule
  formSubmit = async (e, index, value, isNewForm = false) => {
    value.name = this.state.newAddedChannelName;
    let channelsRen = [...this.state.channels];
    let dataId = channelsRen[index]["id"];
    e?.preventDefault();
    if (Object.values(value.config).every((x) => x.length > 1)) {
      try {
        this.setState({ loadingCard: true });
        if (!isNewForm) {
          value.enable = true;
          value.projectId= localStorage.getItem('pId');
          const allOptions = {
            url: serverUrl + "channel-config/" + localStorage.getItem('pId'),
            body: value,
          };
          await saveData(allOptions);
          this.setState({
            displayToast: true,
            messageToast: "Created Successfully",
            severity: "success",
            loadingCard: false,
          });
        } else {
          value.id = dataId;
          const allOptions = {
            url: serverUrl + "channel-config/" + localStorage.getItem('pId') +'/'+ dataId,
            body: value,
          };
          await updateData(allOptions);
          this.setState({
            displayToast: true,
            messageToast: "Updated Successfully",
            severity: "success",
            loadingCard: false,
            channels: channelsRen,
          });
        }
        this.getAllData();
      } catch (e) {
        const errorObj = FindError(e)
        this.setState({
          displayToast: true,
          messageToast: errorObj.message,
          severity: "error",
          confirmAlert: false,
          loadingCard: false,
        });
      }
    } else {
      this.setState({
        loadingCard: false,
        displayToast: true,
        messageToast: "Enter Valid Details",
        severity: "error",
      });
    }
    this.setState({ channels: channelsRen, expansionJrnyPanelOpen: false });
  };

  cancelExpand = () => {
    this.setState({
      expansionJrnyPanelOpen: false,
      journeyIndex: "",
      value: 0,
    });
  };

  bannerHeader = () => {
    return (
      <div className="display-flex table_head font13 fontWeight700 cardFull cardSpaceArrow">
        <div className="table_grid">
          <p>Name</p>
        </div>
        <div className="table_grid">
          <p>Created By</p>
        </div>
        <div className="table_grid">
          <p>Created On</p>
        </div>
        <div className="table_grid">
          <p>Last Updated On</p>
        </div>
        <div>
          <p>Enable / Disable</p>
        </div>
      </div>
    );
  };

  bannerCard = (item, index) => {
    return (
      <div className="display-flex text_black font13 w100 pr15 pl15">
        <LoaderSpinner loadingCard={this.state.loadingCard} />
        <div className="table_grid">
          <p>{item.name}</p>
        </div>
        <div className="table_grid">
          <p>{item.createdBy}</p>
        </div>
        <div className="table_grid">
          <p className="flex" style={{ marginTop: "8px" }}>
            <img src={calender} alt="calendar Icon" />
            <span
              style={{ color: "#000", marginTop: "5px" }}
              className="date-text"
            >
              {moment(item.createdOn).format("DD-MMM-YYYY")}
            </span>
          </p>
        </div>
        <div className="table_grid">
          <p className="flex" style={{ marginTop: "8px" }}>
            <img src={calender} alt="calendar Icon" />
            <span
              style={{ color: "#000", marginTop: "5px" }}
              className="date-text"
            >
              {moment(item.modifiedOn).format("DD-MMM-YYYY")}
            </span>
          </p>
        </div>
        <div className="table_grid">
          <div
            className={`toggle-parent-modeller ${
              item.enable ? "" : "inactiveToggle"
            }`}
            id={`channelToggle${index}`}
            onClick={this.setToggle.bind(this, index, item.enable)}
            style={{ marginTop: "7px" }}
          >
            <div
              className={` ${
                item.enable ? "toggle-button" : "disabled-toggle"
              }`}
            >
              {" "}
            </div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    return (
      <div className="parent mt10">
        <PositionedSnackbar
          display={this.state.displayToast}
          msg={this.state.messageToast}
          closeToast={this.closeToast}
          severity={this.state.severity}
        />
        <div className="display-flex">
          <h4>
            Channel Config &nbsp;
            <span className="label_pink">Configure the Channels</span>
          </h4>
          <div>
            <div className="pos-rel in-block searchFilter">
              <img alt="searchIcon" src={searchIcon} className="searchIcon" />
              <input
                type="text"
                id="channelSearchInput"
                placeholder="Search Channel"
                onChange={this.searchCard}
              />
            </div>
            <div className="pos-rel in-block">
              <button
                className="recommend-btn"
                id="addNewChannelConfig"
                onClick={this.DisplayingMenu.bind(this)}
              >
                <AddIcon className="vertical-7" />
                Channel Config
              </button>
              <Menu
                id="simple-menu"
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                transformOrigin={{ vertical: "top", horizontal: "right" }}
                open={this.state.displayMenu}
                onClose={this.DisplayingMenu.bind(this)}
              >
                {this.state.filteredChannelList.length ? (
                  this.state.filteredChannelList.map((item, i) => (
                    <MenuItem
                      id={`channelList${i}`}
                      onClick={this.addNewChannel.bind(this, item)}
                      className="menuitem"
                      value={item}
                      key={i}
                    >
                      {item}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem className="menuitem" disabled>
                    No Channels to add
                  </MenuItem>
                )}
              </Menu>
            </div>
          </div>
        </div>

        {this.bannerHeader()}
        {this.state.channels.length ? (
          <div>
            {this.state.channels.map((item, index) => (
              <ExpansionPanel
                className="CustomexpansionPannel"
                key={item.id + index}
                expanded={
                  this.state.expansionJrnyPanelOpen &&
                  this.state.channelIndex === index
                }
              >
                <ExpansionPanelSummary
                  expandIcon={
                    <ExpandMoreIcon
                      className="iconExpand"
                      id={`expandCardArrow${index}`}
                      onClick={this.switchJrnyExpansionpanel.bind(
                        this,
                        index,
                        item
                      )}
                    />
                  }
                  style={{ order: -1 }}
                  className="boxShadow"
                >
                  {this.bannerCard(item, index)}
                </ExpansionPanelSummary>
                <ExpansionPanelDetails className="inner_expandBlock bussinesMExpan">
                  <div className="">
                    <FieldGroup
                      control={this.conversationFormBuilder}
                      render={({ value }) => (
                        <form
                          id="submitForm"
                          onSubmit={(e) =>
                            this.formSubmit(
                              e,
                              index,
                              value,
                              item.id ? true : false
                            )
                          }
                        >
                          <div className="formField">
                            <div className="flex">
                              <div className="wordBreack">
                                <ReactJson
                                  src={
                                    (item && item.config) ||
                                    initialValues[
                                      this.state.newAddedChannelName
                                    ].config
                                  }
                                  className="rvjStyles"
                                  name={false}
                                  displayDataTypes={false}
                                  defaultValue={""}
                                  enableClipboard={false}
                                  onEdit={(e) =>
                                    this.rvjheadersChange(e.updated_src, index)
                                  }
                                />
                              </div>
                            </div>
                          </div>
                          <div className="buttonClmns text-center">
                            <button
                              className="sxp-btn btn-primary primaryLarge"
                              type="submit"
                              style={{ height: "34px" }}
                            >
                              Save
                            </button>
                            <span
                              className="sxp-btn btn-danger"
                              onClick={() => this.cancelExpand(index)}
                              style={{
                                height: "34px",
                                width: "220px",
                                textAlign: "center",
                                margin: "0 5px",
                                padding: "9px 0",
                                display: "inline-block",
                                verticalAlign: "top",
                              }}
                            >
                              Cancel
                            </span>
                          </div>
                        </form>
                      )}
                    />
                  </div>
                </ExpansionPanelDetails>
              </ExpansionPanel>
            ))}
          </div>
        ) : (
          <EmptyCard />
        )}
      </div>
    );
  }
}

export default ChannelConfig;
