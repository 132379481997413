import React from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import searchIcon from "../../../assets/images/search.png";

export class Filter extends React.Component {
  state = {
    journeys: "",
    jrny_name: "",
    activeList: null,
    statusData: [
      { sName: "Active", aStatus: false },
      { sName: "InActive", aStatus: false },
    ],
  };

  componentWillReceiveProps(props) {
    this.removeName(props.currentFilters && props.currentFilters.name);
    this.removeStatus(props.currentFilters && props.currentFilters.active);
  }

  removeName = (val) => {
    this.setState({ jrny_name: val });
  };

  removeStatus = (val) => {
    let status = [...this.state.statusData];
    if (val == null) {
      for (let j = 0; j < status.length; j++) {
        status[j].aStatus = false;
      }
      this.setState({ activeList: null });
    }
    this.setState({
      statusData: status,
    });
  };

  handleReset = () => {
    this.props.clearAllFilter();
    this.props.onClose();
  };

  changeStatus = async (e, i) => {
    let status = [...this.state.statusData];
    for (let j = 0; j < status.length; j++) {
      status[j].aStatus = false;
    }
    status[i].aStatus = !status[i].aStatus;
    if (status[0].aStatus == true) {
      this.setState({ activeList: true });
    } else {
      this.setState({ activeList: false });
    }
    this.setState({
      statusData: status,
    });
  };

  onSelectJrny = (e) => {
    this.setState({ jrny_name: e.currentTarget.textContent });
  };
  onCancel = () => {
    this.props.onClose();
  };

  onSubmit = () => {
    let data = {
      name: this.state.jrny_name,
      active: this.state.activeList,
    };
    this.props.onClose();
    this.props.applyFilter(data, "filter");
  };

  render() {
    return (
      <div
        className={` ${
          this.props.displayFilter ? "activeFilter" : ""
        } filterMainBlock`}
        onSubmit={(e) => this.submit(e)}
      >
        <div className="search-div display-flex">
          <span className="search-title">Search Filter</span>
          <span onClick={this.handleReset} className="clearAllText">
            Clear all
          </span>
        </div>
        <div className="pos-rel searchInput">
          <img alt="searchIcon" src={searchIcon} className="searchIcon" />
          <Autocomplete
            id="custom-input-demo"
            options={this.props.journeys?.map((a) => a.name)}
            onChange={(event) => this.onSelectJrny(event)}
            value={this.state.jrny_name}
            renderInput={(params) => (
              <div ref={params.InputProps.ref}>
                <input
                  className="searchFilterInput white-bg"
                  type="text"
                  placeholder="Search Journey Name"
                  {...params.inputProps}
                />
              </div>
            )}
          />
        </div>
        <div className="form-style">
          <div className="search-div">
            {this.state.statusData.map((d, i) => (
              <span
                key={i}
                className={`statusDiv ${
                  d.aStatus === true ? "activeStatus" : ""
                }`}
                onClick={(e) => this.changeStatus(e, i)}
              >
                {d.sName}
              </span>
            ))}
          </div>
          <button type="submit" onClick={this.onSubmit} className="applyBtn">
            Apply
          </button>
          <button type="button" onClick={this.onCancel} className="deleBtn">
            Cancel
          </button>
        </div>
      </div>
    );
  }
}
