import { combineReducers } from "redux";
import undoable from "redux-undo";

const nodes = (state = [], node) => {
  return [...state, node];
};

const undoableNodes = undoable(nodes);
const flowApp = combineReducers({
  undoableNodes,
});
export default flowApp;
