import React, { Component } from "react";
import { FieldControl, FieldGroup, FormBuilder, Validators } from "react-reactive-form";
import ConfirmAlert from "../../commons/uicomponents/confirm";
import PositionedSnackbar from "../../commons/uicomponents/toast";
import { deleteData, getData } from "../../dataProvider/apiHandler";
import AddIcon from "@material-ui/icons/Add";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import LoaderSpinner from "../../commons/uicomponents/loader";
import { FormHelperText, MenuItem, Select } from "@material-ui/core";
import { deleteConvRes, save, update } from "./jmApiHandler";
import EmptyCard from "../../commons/uicomponents/emptycard";
import copyIcon from "../../assets/images/copy_theme.png";
import deletewhiteIcon from "../../assets/images/deletewhite.png";
import ConversationResume from "./conversationResume";
import FindError from "../../dataProvider/errorHandler";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import TestState from "./TestState";

const serverUrl = window._env_.REACT_APP_SXP_URL;
const jmUrl = window._env_.REACT_APP_JOURNEY_MANAGER_URL;

class JourneyRoutes extends Component {
    constructor(props){
        super(props);
        this.state = {
            allJourneyRoutes : [],
            routeIndex: "",
            expansionJrnyPanelOpen: false,
      expansionIGPanelOpen: false,
      expansionCCPanelOpen: false,
            allJourneys:[],
            newRouteBlock:false,
            selectedTask:'',
            activeTabExternalUrls: true,
            activeTabConversResume: false,
            activeTabTestState: false,
            configured: false,
            newConfig:false,
            jRouteId:'',
            allSecret:{}
        }
    }

    componentDidMount = () => {
        this.loadAllRoutes();
        this.loadAllTasks();
        this.loadConvResumeConfig();
    }

    loadAllRoutes = async() => {
        try{
            const allOptions = {
                url: serverUrl + "journey-routes/" + localStorage.getItem('pId'),
            };
            let initialLoad = await getData(allOptions);
            this.setState({allJourneyRoutes: initialLoad.data})
        }
        catch(e)
        {
            const errorObj = FindError(e)
            this.setState({
                displayToast: true,
                messageToast: errorObj.message,
                severity: "error",
                confirmAlert: false,
            });
        }       
    }

    loadAllTasks = async() => {
        try{
            const allOptions = {
                url: serverUrl + "tasks/" + localStorage.getItem("pId"),
                params:{
                    version: localStorage.getItem("version"),
                    projectId: localStorage.getItem("pId"),
                }
            };
            let initialLoad = await getData(allOptions);
            this.setState({allJourneys: initialLoad.data.data.docs})
        }
        catch(e)
        {
            const errorObj = FindError(e)
            this.setState({
                displayToast: true,
                messageToast: errorObj.message,
                severity: "error",
                confirmAlert: false,
            });
        }  
    }

    loadConvResumeConfig = async() => {
        try{
            const allOptions = {
                url: jmUrl + "/app/" + localStorage.getItem("pId") +'/conversation/resume/url/generate',
            };
            await getData(allOptions);
            this.setState({
                configured: true,
            });
        }
        catch(e)
        {
            const { code, message } = e.response.data;
            const errorObj = { status: code, message };
            this.setState({
                displayToast: true,
                messageToast: errorObj.message,
                severity: "error",
                confirmAlert: false,
                configured: false,
            });
        }
    }

    // EXPANSIONS HANDLERS
    switchJrnyExpansionpanel(index, action) {
        if (this.state.routeIndex === index) {
            this.setState({
                expansionJrnyPanelOpen: false,
                routeIndex: "",
            });
        } else {
            this.setState({
                expansionJrnyPanelOpen: true,
                routeIndex: index,
                newAddedChannelName: action && action.name,
            });
            this.getExtenalUrl(action);
        }
    }

    cancelExpand = () => {
        this.setState({
            expansionJrnyPanelOpen: false,
            routeIndex: "",
        });
    };

    conversationFormBuilder = FormBuilder.group({
        routeName:["", Validators.required],
        journeyId:["", Validators.required],
        xSecret:["", Validators.required]
    });

    onNewJourneyRoute = () => {
        this.setState({newRouteBlock: true})
    }

    hideNewVariable = () => {
        this.setState({ newRouteBlock: false });
    };

    hideConfig = (ncFlag, cFlag) => {
        this.setState({newConfig : ncFlag, configured: cFlag});
    }

    handleMethod = (e) => {
        this.setState({selectedTask: e.target.value})
        this.conversationFormBuilder.value["journeyId"] = e.target.value
        this.conversationFormBuilder.patchValue({
            journeyId: e.target.value,
        });
    }

    tabExternalUrls = () => {
        this.setState({
            activeTabExternalUrls:true,
            activeTabConversResume:false,
            activeTabTestState: false,
            newConfig: false
        })
    }
    tabConversResum = () => {
        this.setState({
            activeTabExternalUrls:false,
            activeTabConversResume:true,
            activeTabTestState: false,
            newRouteBlock: false
        })
    }

    tabTestState = () => {
        this.setState({
            activeTabExternalUrls:false,
            activeTabConversResume:false,
            activeTabTestState: true,
            newRouteBlock: false,
            newConfig: false
        })
    }

    jRouteFormSubmit = async(e, value) => {
        e?.preventDefault();
        try{
            const allOptions = {
                url: jmUrl + '/app/' + localStorage.getItem('pId') + '/journey/' + value.journeyId + '/url/generate',
                body: {name: value.routeName},
                secret: value.xSecret
            };
            await save(allOptions);
            this.setState({
                displayToast: true,
                messageToast: "New Journey Route Created",
                severity: "success",
            })
            await this.loadAllRoutes();
            this.setState({
                newRouteBlock: false,
            });
        }
        catch(e)
        {
            const errMessage = e.response.data.message;
            this.setState({
                displayToast: true,
                messageToast: errMessage,
                severity: "error",
                confirmAlert: false,
            });
        }        
    }

    getExtenalUrl = async(item) => {
        try{
            const allOption = {
                url: jmUrl + '/app/' + item.projectId + '/journey/' + item.journeyId + '/url/generate'
            }
            await getData(allOption);
        }
        catch(e)
        {
            const errorObj = FindError(e)
            this.setState({
              displayToast: true,
              messageToast: errorObj.message,
              severity: "error",
              confirmAlert: false,
            });
        }
    }

    handleUpdateJM = async(item, index) => {
        if(this.state.allSecret.oldXSecret === undefined)
        {   
            this.setState({
                displayToast: true,
                messageToast: "Old Secret required",
                severity: "error",
            });
            return;
        }
        if(this.state.allSecret.newXSecret === undefined)
        {
            this.setState({
                displayToast: true,
                messageToast: "New Secret required",
                severity: "error",
            });
        }
        else
        {
            try{
                const allOptions = {
                    url: jmUrl + '/app/' + item.projectId + '/journey/' + item.journeyId + '/url/generate',
                    body:{name : item.routeName },
                    oldSecret: this.state.allSecret.oldXSecret,
                    newSecret: this.state.allSecret.newXSecret,
                }
                await update(allOptions);
                this.setState({
                    expansionJrnyPanelOpen: false,
                    routeIndex: "",
                    displayToast: true,
                    messageToast: "Updated Journey Route",
                    severity: "success",
                });
            }
            catch(e)
            {
                const errMessage = e.response.data.message;
                this.setState({
                    displayToast: true,
                    messageToast: errMessage,
                    severity: "error",
                    confirmAlert: false,
                });
            }
        }        
    }

    handleRouteName = (e, index) => {
        let journeyRoutes = [...this.state.allJourneyRoutes];
        journeyRoutes[index].routeName = e.target.value;
        this.setState({allJourneyRoutes: journeyRoutes})
    }

    handleOldSecret = (e, index) => {
        let journeyRoutesSecret = {...this.state.allSecret};
        journeyRoutesSecret.oldXSecret = e.target.value;
        this.setState({allSecret: journeyRoutesSecret})
    }
    handleNewSecret = (e, index) => {
        let journeyRoutesSecret = {...this.state.allSecret};
        journeyRoutesSecret.newXSecret = e.target.value;
        this.setState({allSecret: journeyRoutesSecret})
    }

    copyText = async (data, result) => {
        if(result)
        {
            this.setState({
                displayToast: true,
                messageToast: "Copied to Clipboard",
                severity: "success",
            });
        }
        else{
            console.log(result)
            this.setState({
                displayToast: true,
                messageToast: "Failed to Copy",
                severity: "error",
            });
        }
    };

       // Close Toast
    closeToast = () => {
        this.setState({ displayToast: false });
    };

    onNewConvResConfig = () => {
        this.setState({newConfig: true});
    }

    removeJourneyRoute = (index, journeyId) => {
        if (journeyId) {
            this.setState({
              confirmAlert: true,
              jRouteId: journeyId,
            });
          }
    }

    confirmBtn = () => {
        this.ConfirmDelete(this.state.jRouteId);
    }

    ConfirmDelete = async(id) => {
        try {
            const allOptions = {
              url: jmUrl + '/app/' + localStorage.getItem('pId') + '/journey/' + id + '/url/generate'
            };
            await deleteConvRes(allOptions);
            this.setState({
              displayToast: true,
              messageToast: "Deleted Successfully",
              severity: "success",
              confirmAlert: false
            });
            await this.loadAllRoutes();
          } catch (e) {
            const errMessage = e.response.data.message;
            this.setState({
              displayToast: true,
              messageToast: errMessage,
              severity: "error",
              confirmAlert: false,
            });
        }
    }
   
    // to close confirmation dialogue
    cancelBtn = () => {
        this.setState({ confirmAlert: false });
    };

    bannerHeader = () => (
        <div className="display-flex table_head font13 fontWeight700 cardFull cardSpaceArrow">
        <div className="table_grid">
          <p>Name</p>
        </div>
        <div className="table_grid">
          <p>Created By</p>
        </div>
        <div className="table_grid">
          <p>Created On</p>
        </div>
        <div className="table_grid">
          <p>Last Updated On</p>
        </div>
      </div>
    )

    bannerCard = (item, index) => (
        <div className="display-flex text_black font13 w100 pr15 pl15">
        <LoaderSpinner loadingCard={this.state.loadingCard} />
        <div className="table_grid">
          <p>{item.routeName}</p>
        </div>
        <div className="table_grid">
          <p>{item.createdBy ? item.createdBy : '~N/A~'}</p>
        </div>
        <div className="table_grid">
          <p className="flex" style={{ marginTop: "8px" }}>
            {/* <img src={calender} alt="calendar Icon" /> */}
            <span
              style={{ color: "#000", marginTop: "5px" }}
              className="date-text"
            >
              {/* {moment(item.createdOn).format("DD-MMM-YYYY")} */}
            </span>
          </p>
        </div>
        <div className="table_grid">
          <p className="flex" style={{ marginTop: "8px" }}>
            {/* <img src={calender} alt="calendar Icon" /> */}
            <span
              style={{ color: "#000", marginTop: "5px" }}
              className="date-text"
            >
              {/* {moment(item.modifiedOn).format("DD-MMM-YYYY")} */}
            </span>
          </p>
        </div>
        <img
          src={deletewhiteIcon}
          alt="trash Icon"
          id={`apiServiceDeleteImg${index}`}
          onClick={() => this.removeJourneyRoute(index, item.journeyId)}
          className="deleteIcon"
        />
      </div>
    )

    render(){
        return(
            <div className="parent mt10">
                {this.state.confirmAlert === true && (
                    <ConfirmAlert
                        indexValue={this.state.slabsIndex}
                        mainIndex={this.state.globalVarNIndex}
                        onConfirmBtn={this.confirmBtn}
                        onCancelBtn={this.cancelBtn}
                        heading="Are you sure ?"
                        paragraph="If you proceed you will loose the data. Are you sure you want to delete ?"
                    />
                )}
                <PositionedSnackbar
                    display={this.state.displayToast}
                    msg={this.state.messageToast}
                    closeToast={this.closeToast}
                    severity={this.state.severity}
                />
                <div className="display-flex">
                    <div className="flex">
                        <h4 className="m0">
                            Journey Routes 
                            <span className="label_pink">Assign the Journey Routes</span>
                        </h4>
                        <div className="twotabsUI in-block">
                            <ul className="p0 m0">
                                <li
                                onClick={this.tabExternalUrls}
                                id="enterPriseTypeTab"
                                className={`${
                                    this.state.activeTabExternalUrls ? "activeApi" : ""
                                }`}
                                >
                                External URLs
                                </li>
                                <li
                                id="thirdPartyTypeTab"
                                onClick={this.tabConversResum}
                                className={`${
                                    this.state.activeTabConversResume ? "active3Api" : ""
                                }`}
                                >
                                Conversation Resume
                                </li>
                                <li
                                  id="NLUTypeTab"
                                  onClick={this.tabTestState}
                                  className={`${
                                    this.state.activeTabTestState ? "activeNLUApi" : ""
                                  }`}
                                >
                                    Test State
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div>
                        {this.state.activeTabExternalUrls ?
                            <button
                                className="sxp-btn btn-primary"
                                id="addNewGlobalVar"
                                onClick={this.onNewJourneyRoute}
                            >
                                <AddIcon className="vertical-7" /> External URL
                            </button> 
                            :
                          this.state.activeTabConversResume && !this.state.configured ?
                            <button
                                className="sxp-btn btn-primary"
                                id="addNewGlobalVar"
                                style={{ backgroundColor: "#F58819" }}
                                onClick={this.onNewConvResConfig}
                            >
                                <AddIcon className="vertical-7" /> Config
                            </button>
                            : null
                        }
                    </div>
                </div>
                {this.state.newRouteBlock ? (
                <div className="formField pl0 p0">
                    <FieldGroup
                    control={this.conversationFormBuilder}
                    render={({ get, invalid, reset, value }) => (
                        <form
                        className="flex newVar"
                        onSubmit={(e) => this.jRouteFormSubmit(e, value)}
                        style={{ justifyContent: "center" }}
                        >
                        <h4 className="varHead">Add Journey Route: </h4>
                        <FieldControl
                            name="routeName"
                            options={{ validators: Validators.required }}
                            render={({ handler, touched, hasError }) => (
                            <div>
                                <input
                                {...handler()}
                                id="routeNameInput"
                                placeholder="Route Name"
                                className={`${
                                    touched && hasError("required") && "errorInput"
                                }`}
                                />
                                <span
                                className={`${
                                    touched && hasError("required") && "errorFieldMsg"
                                }`}
                                >
                                {touched && hasError("required") && "Required Filed"}
                                </span>
                            </div>
                            )}
                        />
                        <FieldControl
                            name="journeyId"
                            options={{ validators: Validators.required }}
                            render={({ handler, touched, hasError }) => (
                                <div className="pos-rel">                                      
                                <Select
                                  value={this.state.selectedTask}
                                  id="journeySelect"
                                  {...handler()}
                                  className={`${
                                    touched &&
                                    hasError("required") &&
                                    "errorInput"
                                  } sxp-defaultDropDown_filed pos-rel`}
                                  onChange={(e)=>this.handleMethod(e)}
                                >
                                  <MenuItem value="" disabled>
                                    Select Journey
                                  </MenuItem>
                                  {this.state.allJourneys.map((d,i) => (
                                      <MenuItem value={d.uid}>{d.name}</MenuItem>
                                  ))}                                  
                                </Select>
                                {this.state.selectedTask === "" ?
                                <FormHelperText className="apiServiceSelectPlaceHolder">
                                  Select Method
                                </FormHelperText>
                                 : null }
                                <span
                                  className={`${
                                    touched &&
                                    hasError("required") &&
                                    "errorFieldMsg"
                                  }`}
                                >
                                  {touched &&
                                    hasError("required") &&
                                    "Required Filed"}
                                </span>
                              </div>
                            )}
                        />
                        
                        <FieldControl
                            name="xSecret"
                            options={{ validators: Validators.required }}
                            style={{ width: "194px", marginRight:'38px', marginBottom:'20px' }}
                            render={({ handler, touched, hasError }) => (
                            <div>
                                <input
                                {...handler()}
                                type="password"
                                id="routeNameInput"
                                placeholder="Secret"
                                className={`${
                                    touched && hasError("required") && "errorInput"
                                }`}
                                />
                                <span
                                className={`${
                                    touched && hasError("required") && "errorFieldMsg"
                                }`}
                                >
                                {touched && hasError("required") && "Required Filed"}
                                </span>
                            </div>
                            )}
                        />
                        <button
                            className={`sxp-btn btn-primary ${invalid && 'disablePagBtn'}`}
                            type="submit"
                            id="saveGlobalVar"
                            style={{ height: "34px", marginRight: "10px" }}
                            disabled={invalid}
                        >
                            Save
                        </button>
                        <button
                            id="cancelGlobalVar"
                            className="sxp-btn btn-danger"
                            onClick={this.hideNewVariable}
                            style={{ height: "34px" }}
                        >
                            Cancel
                        </button>
                        </form>
                    )}
                    />
                </div>
                ) : (
                ""
                )}
                {this.state.activeTabExternalUrls ? 
                <>
                {this.bannerHeader()}
                { this.state.allJourneyRoutes.length ? 
                    this.state.allJourneyRoutes.map((item, index) => (
                        <div>
                    <ExpansionPanel
                        className="CustomexpansionPannel"
                        key={item.id + index}
                        expanded={
                        this.state.expansionJrnyPanelOpen &&
                        this.state.routeIndex === index
                        }
                    >
                        <ExpansionPanelSummary
                            expandIcon={
                                <ExpandMoreIcon
                                className="iconExpand"
                                id={`expandCardArrow${index}`}
                                onClick={this.switchJrnyExpansionpanel.bind(
                                    this,
                                    index,
                                    item
                                )}
                                />
                            }
                            style={{ order: -1 }}
                            className="boxShadow"
                            >
                            {this.bannerCard(item, index)}
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails className="inner_expandBlock">
                            {/* <h5>{item.url}</h5>                         */}
                            <h5> 
                                External Link : &nbsp;  
                                <b className="m0" style={{color:'#086284'}}>
                                    {`${jmUrl}/journey/${item.uid}/start`}
                                    <CopyToClipboard text={`${jmUrl}/journey/${item.uid}/start`} onCopy={this.copyText}>
                                    <img
                                        alt="CopyIcon"
                                        id={`copyUserId${index}`}
                                        src={copyIcon}
                                        style={{ 
                                            width:'14px',
                                            verticalAlign:'-3px',
                                            marginLeft:'7px',
                                            cursor:'pointer'
                                         }}
                                     />
                                    </CopyToClipboard>
                                </b>
                            </h5>                        
                            <div className="jwtFields">
                                <div>
                                    <input type="text" placeholder="Enter Router Name" value={item.routeName} onChange={(e) =>this.handleRouteName(e, index)} name="routeName"/>
                                    <input type="text" value={this.state.allJourneys && this.state.allJourneys.find((d) => d.uid === item.journeyId)?.name } name="journeyId" disabled/>
                                
                                    <input type="password" placeholder="Enter Old Secret" name="oldXSecret" onChange={(e) =>this.handleOldSecret(e, index)}/>
                                    <input type="password" placeholder="Enter New Secret" name="newXSecret" onChange={(e) =>this.handleNewSecret(e, index)}/>
                                </div>
                                <div className="buttonClmns text-center" style={{marginTop:'10px'}}>
                                    <button
                                        className={`sxp-btn btn-primary primaryLarge ml5 mr5`}
                                        id={`apiServiceSubmit${index}`}
                                        type="submit"
                                        style={{ height: "34px" }}
                                        onClick={() => this.handleUpdateJM(item, index)}
                                    >
                                        Save
                                    </button>
                                    <span
                                        onClick={() => this.cancelExpand(index)}
                                        id={`apiServiceCancelBtn${index}`}
                                        className="sxp-btn btn-danger primaryLarge ml5 mr5"
                                        style={{ height: "34px", width: "220px",
                                        textAlign: "center",
                                        margin: "0 5px",
                                        padding: "9px 0",
                                        display: "inline-block",
                                        verticalAlign: "top", }}
                                    >
                                        Cancel
                                    </span>
                                </div>
                            </div>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                    </div>
                )):<EmptyCard />
                        }
                </>
                : this.state.activeTabConversResume ?
                <ConversationResume newConfig={this.state.newConfig} configured={this.state.configured} callBackConfig={this.hideConfig}/>
                : <TestState />
            }
            </div>
        )
    }
}

export default JourneyRoutes;