import { FormHelperText, MenuItem, Select } from "@material-ui/core";
import React from "react";
import "./formfield.scss";

export default {
  TextInput: ({ handler, touched, hasError, meta, submitted }) => (
    <div>
      <input type="text" placeholder={`Enter ${meta.label}`} {...handler()} />
      <div className="errormsg">
        {submitted && hasError("required") && `${meta.label} is required`}
      </div>
    </div>
  ),

  TextInputNoValidation : ({handler, touched, meta, submitted }) => (
    <div>
      <input type="text" placeholder={`Enter ${meta.label}`} {...handler()} />
    </div>
  ),

  pretaskDropdown: ({ handler, touched, hasError, meta, value }) => (
    <div className="pos-rel">
      {value ? null  : (
        <FormHelperText className="pretask_placeholder">
          {meta.label}
        </FormHelperText>
      )}
      <Select
        className="select sxp-defaultDropDown_filed"
        labelId="label"
        disableUnderline
        id="select"
        {...handler()}
      >
        <MenuItem value="" style={{opacity:0.5}}>{meta.label}</MenuItem>
        {meta.services.map((item) => {
          return <MenuItem value={item.id}>{item.name}</MenuItem>;
        })}
      </Select>
    </div>
  ),

  TextArea: ({ handler, touched, hasError, meta, submitted }) => (
    <div>
      <textarea
        className="text-area"
        placeholder={`Enter ${meta.label}`}
        {...handler()}
      >
        {" "}
      </textarea>
      <div className="errormsg">
        {submitted && hasError("required") && `${meta.label} is required`}
      </div>
    </div>
  ),

  DefaultCheckbox: ({ handler, touched, hasError, meta }) => (
    <div className="in-block">
      <input
        type="checkbox"
        className="checkbox-customized"
        {...handler("checkbox")}
      />
    </div>
  ),
};
