import HttpService from "../../HttpService";

const axios = HttpService.getAxiosClient();


export const fileUpload = (options) => {
  const url = options.url;
  const formData = new FormData();
  formData.append("file", options.file);
  formData.append('projectId', localStorage.getItem('pId'));
  formData.append('userid', localStorage.getItem('botUser'));
  const config = {
    headers: {
      "content-type": "multipart/form-data",
      token: localStorage.getItem("token"),
    },
    // params:options.params
  };
  return axios.post(url, formData);
};
