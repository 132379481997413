import React from "react";
import { ActionCreators as UndoActionCreators } from "redux-undo";
import { connect } from "react-redux";
import KeyboardEventHandler from "react-keyboard-event-handler";
import Undo from "../../../assets/images/modeler/undo.png";
import Redo from "../../../assets/images/modeler/redo.png";
import UndoEnable from "../../../assets/images/modeler/undo-enable.png";
import RedoEnable from "../../../assets/images/modeler/redo-enable.png";

let UndoRedo = ({ canUndo, canRedo, onUndo, onRedo }) => (
  <span className="in-block">
    <KeyboardEventHandler
      handleKeys={["ctrl+z"]}
      onKeyEvent={(key, e) => {
        e.preventDefault();
        onUndo();
      }}
    />
    <KeyboardEventHandler
      handleKeys={["ctrl+y"]}
      onKeyEvent={(key, e) => {
        e.preventDefault();
        onRedo();
      }}
    />
    <img
      alt="undo icon"
      src={canUndo ? UndoEnable : Undo}
      className="left ml5"
      onClick={onUndo}
    />
    <img
      alt="redo icon"
      src={canRedo ? RedoEnable : Redo}
      className="left ml5"
      onClick={onRedo}
      disabled={!canRedo}
    />
  </span>
);

const mapStateToProps = (state) => ({
  canUndo: state.undoableNodes.past.length > 0,
  canRedo: state.undoableNodes.future.length > 0,
});

const mapDispatchToProps = {
  onUndo: UndoActionCreators.undo,
  onRedo: UndoActionCreators.redo,
};

UndoRedo = connect(mapStateToProps, mapDispatchToProps)(UndoRedo);

export default UndoRedo;
