import React, { Component } from "react";
import SelectSearch from "react-select";
import Select from "@material-ui/core/Select";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { FormHelperText, MenuItem } from "@material-ui/core";
import { getFunctions } from "../api/api";
import "react-tabs/style/react-tabs.css";
import { FormButtons } from "./formbuttons";
import { submit } from "./formActions";
import closeRed from "../../../assets/images/subtract.png";
import FunctionPreview from "./FunctionPreview";
import closeIconRed from "./../../../assets/images/close_red.png";
import infoIcon from "./../../../assets/images/info.png"

const style = {
  control: (base, state) => ({
    ...base,
    border: state.isFocused ? 0 : 0,
    // This line disable the blue border
    boxShadow: state.isFocused ? 0 : 0,
    "&:hover": {
      border: state.isFocused ? 0 : 0,
    },
  }),
};

const langLists =[
  { label:'English', value:'en'},
  { label:'Telugu', value:'te'},
  { label: 'Hindi', value:'hi'}
]

class Question extends Component {
  state = {
    recommendList: [],
    buttonFields: [{label:'', value:''}],
    isButtonsDynamic: false,
    optional:false,
    utterance:[{lang:'en'}],
    filteredLangList:[],
    selectedLangInOption:'',
    viewMore:false
  };

  static getDerivedStateFromProps(nextProps, currentState) {
    let propsState;
    if(nextProps.data.data.utterance){
      if(!Array.isArray(nextProps.data.data.utterance) ){
        let objectttt = Object?.keys(nextProps.data.data.utterance).map(lang => ({ lang, label: nextProps.data.data.utterance[lang] }));
        nextProps.data.data.utterance =  objectttt;
      }
    }

    if (
      currentState.formUniqueId &&
      currentState.formUniqueId === nextProps.formUniqueId
    ) {
      propsState = { ...(nextProps.data.data || {}), ...currentState };
    } else {
      propsState = { ...currentState, ...(nextProps.data.data || {}) };
    }
    propsState.formUniqueId = nextProps.formUniqueId;
    if (propsState.functions) {
      let func = propsState.functions.find(
        (x) => x.value === propsState.funcName
      );
      if (func) propsState.functionObj = func;
    }   
    return propsState;
  }

  componentDidMount() {
    getFunctions().then((response) => {
      let functions = response.data.functionNames.map((functionName) => {
        return { value: functionName, label: functionName };
      });
      Array.prototype.unshift.apply(functions, [{value:'', label:'Select a Function'}]);
      this.setState({ functions });
    });
    this.fetchRecommendData(this.props.singleConvData);
    this.filterLangList();
  }

  componentWillUnmount() {
    this.setState({});
  }

  fetchRecommendData = (recoListDate) => {
    let recomedData =
      recoListDate &&
      recoListDate.selectedRecommendationTypes?.map((d) => d.type);
    this.setState({ recommendList: recomedData });
  };

  handleEvalAnswerChange = (e) => {
    let selected = e.target.value;
    if (selected === "BUTTON" || selected === "RADIO" || selected === "BUTTON_OR_TEXT") {
      this.setState({ evalAnswer: selected, showButtonField: true });
      if(this.state.buttonFields.length === 0){
        this.handleAddNonDynamicBtn();
      }
    } else {
      this.setState({ evalAnswer: selected, showButtonField: false });
    }
  };

  handleTextInputChange = (e) => {
    let updatedState = { ...this.state };
    updatedState[e.target.name] = e.target.value;
    this.setState(updatedState);
  };

  handleLanguageChange = (e, i, lang) => {
    let arra = [...this.state.utterance];
    arra[i].label = e.target.value;
    this.setState({utterance: arra})
  }

  recommendHandle = (e) => {
    let statusVal = e.target.checked;
    this.setState({ recommend: statusVal });
  };

  viewMoreHandle = (e) => {
    let statusVal = e.target.checked;
    this.setState({ viewMore: statusVal });
  };

  optionalHandler = (e) => {
    let statusVal = e.target.checked;
    this.setState({ optional: statusVal });
  }

  handleDynamicBtn = (e) => {
    let dynamicVal = e.target.checked;
    if(e.target.checked)
    {
      this.setState({buttonFields:[{}]})
    }
    else{
      this.setState({buttonsDynamicValue: ''})
    }
    this.setState({ isButtonsDynamic: dynamicVal });
  };

  handleOnChange = (e) => {
    let statusVal = e.target.checked;
    this.setState({ onChange: statusVal });
  };

  handleIsSearchable = (e) => {
    let statusVal = e.target.checked;
    this.setState({ isSearchable: statusVal });
  };

  handleIsmultiSelect = (e) => {
    let statusVal = e.target.checked;
    this.setState({ multiSelect: statusVal });
  }

  handleSelectChange = (e) => {
    let updatedState = { ...this.state };
    updatedState.funcName = e.value;
    this.setState(updatedState);
  };

  handleFunctionName = (e) => {
    let updatedState = { ...this.state };
    updatedState.funcName = e.currentTarget.textContent;
    // updatedState.functionObj = {label: e.currentTarget.textContent, value: e.currentTarget.textContent }
    this.setState(updatedState);
  };

  handleRecommed = (e) => {
    let selectedVal = e.target.value;
    this.setState({ recommendationType: selectedVal });
  };

  handleAddNonDynamicBtn = () => {
    let nonDynBtn = [...this.state.buttonFields]
    nonDynBtn.push({ label:"", value:"" });
    this.setState({buttonFields: nonDynBtn})
  }

  deleteNonDynBtn = (i) => {
    let nonDynBtn = [...this.state.buttonFields]
    nonDynBtn.splice(i, 1);
    this.setState({buttonFields: nonDynBtn})
  }

  handlelabel = (e, i) => {
    let nonDynBtn = [...this.state.buttonFields]
    nonDynBtn[i].label = e.target.value;
    this.setState({buttonFields: nonDynBtn})
  }

  handleKeyValue = (e, i) => {
    let nonDynBtn = [...this.state.buttonFields]
    nonDynBtn[i].value = e.target.value;
    this.setState({buttonFields: nonDynBtn})
  }

  handleDynamicField = (e) => {
    let updatedState = { ...this.state };
    updatedState[e.target.name] = e.target.value;
    this.setState(updatedState);
  }

  handleOnCreate = async (functionName) => {
    if (functionName) {
      const fnData = await getFunctions();
      const allFunctions = fnData.data.functionNames.map((fn) => {
        return { value: fn, label: fn };
      });
      this.setState({
        functions: allFunctions
      });
      const fnIsPresent = allFunctions.some(fn => fn.value === functionName);
      if (fnIsPresent) {
        this.setState({
          funcName: functionName,
          functionObj: { value: functionName, label: functionName },
        });
      }
    }
  };

  handleAddLang = (e) => {
    if(e.target.value !== ''){
      let arr = [...this.state.utterance];
      arr.push({lang:e.target.value});
      this.setState({utterance : arr}, () => this.filterLangList());
      this.setState({selectedLangInOption: e.target.value})
   } 
  }

  filterLangList = () => {
    let allExistsLangs = this.state.utterance.map(d => d.lang);
    const allLangs = langLists.map(d => d.value);
    let setAllLangData = new Set(allExistsLangs);
    let filteredLangList = allLangs.filter((x) => !setAllLangData.has(x));
    this.setState({filteredLangList: filteredLangList})
  }

  removeLang = (i) => {
    let arr = [...this.state.utterance];
    arr.splice(i, 1);
    this.setState({utterance : arr}, () => this.filterLangList());
  }

  fakeClick = () => {
    console.log('haha..! Bingo !! Click not allowed here...')
  }

  render() {
    return (
      <form className="form-style" onSubmit={(e) => submit(e, this.props, this.state)}>
        <div>
          <label> Name </label>
          <input
            type="text"
            name="name"
            defaultValue={this.state.name}
            onChange={this.handleTextInputChange}
            key={this.state.formUniqueId}
            required
          />
        </div>
        <div>
          <label> Reference </label>
          <input
            type="text"
            name="reference"
            defaultValue={this.state.reference}
            onChange={this.handleTextInputChange}
            key={this.state.formUniqueId}
            required
          />
        </div>
        <div>

        <div>
          <label> Add a Question Language : </label>
          <div className="pos-rel">
            <Select
              className="sxp-defaultDropDown_filed w100"
              value={this.state.selectedLangInOption}
              onChange={this.handleAddLang}
            >
              <MenuItem value="">Select a Language</MenuItem>
              {this.state.filteredLangList.map((d,i) => (
                <MenuItem key={i} value={d}>{d}</MenuItem>
              ))}
              
            </Select>
            <FormHelperText className="projSelectPlaceholder">
              Select a Language
            </FormHelperText>
          </div>
        </div>


          <div className="display-flex">
            <label>Question </label>
          </div>
            {this.state.utterance.map((d,i) => (
              <div className="flex langQuestion" style={{alignItems:'baseline'}}>
              <span className="spanTextHolder">{d.lang}</span>
              <input
                type="text"
                name="utterance"
                id={`utterance${d.lang}`}
                defaultValue={d?.label}
                value={d?.label}
                onChange={(e) => this.handleLanguageChange(e, i, d.lang)}
                key={this.state.formUniqueId}
                required
              />
              <span 
                className={`langCloseIcon ${d.lang === 'en' ? 'disablePagBtn':null}`} 
                onClick={() => d.lang === 'en' ? this.fakeClick() : this.removeLang(i)}>
                  <img src={closeIconRed} /></span>
            </div>
            ))}
        </div>
        <div>
          <input
              type="checkbox"
              key={this.state.formUniqueId}
              defaultChecked={this.state.optional === true ? true : false}
              name="optional"
              onChange={this.optionalHandler}
            />
            <label> Optional </label>
        </div>

        <Tabs>
          <TabList>
            <Tab>Input</Tab>
            <Tab>Preview</Tab>
          </TabList>
          <TabPanel>
            <label>
              Formatted Text
              <textarea
                name="richText"
                defaultValue={this.state.richText?.en}
                onChange={(e) =>
                  this.setState({
                    richText: {
                      ...this.state.richText?.en,
                      en: e.target.value,
                    },
                  })
                }
                key={this.state.formUniqueId}
              />
            </label>
          </TabPanel>
          <TabPanel>
            {this.state.richText && (
              <div
                style={{ border: "2px solid black", padding: 5 }}
                dangerouslySetInnerHTML={{ __html: this.state.richText.en }}
              />
            )}
          </TabPanel>
        </Tabs>

        <div>
          <input
            type="checkbox"
            key={this.state.formUniqueId}
            defaultChecked={
              this.state.viewMore === true ? true : false
            }
            name="viewMore"
            onChange={this.viewMoreHandle}
          />
          <label>View More </label>
        </div>

        <div>
          <input
            type="checkbox"
            key={this.state.formUniqueId}
            defaultChecked={
              this.state.conversationRefresh === true ? true : false
            }
            name="conversationRefresh"
          />
          <label>Conversation Refresh </label>
        </div>

        <div>
          <input
            type="checkbox"
            key={this.state.formUniqueId}
            defaultChecked={this.state.recommend === true ? true : false}
            name="recommend"
            onChange={this.recommendHandle}
          />
          <label> Recommend </label>
        </div>

        {this.state.recommend === true ? (
          <div className="pos-rel">
            <Select
              name="recommendationType"
              className="sxp-defaultDropDown_filed w100"
              defaultValue={this.state.recommendationType}
              value={this.state.recommendationType}
              onChange={this.handleRecommed}
            >
              {this.state.recommendList?.length ? (
                this.state.recommendList.map((d, i) => (
                  <MenuItem key={i} value={d}>
                    {d}
                  </MenuItem>
                ))
              ) : (
                <MenuItem value="">no</MenuItem>
              )}
            </Select>
            {this.state.recommendationType ? null : (
              <FormHelperText className="projSelectPlaceholder">
                Select Recomendation
              </FormHelperText>
            )}
          </div>
        ) : null}

        <div>
          <input
            type="checkbox"
            key={this.state.formUniqueId}
            defaultChecked={this.state.alwaysAsk === true ? true : false}
            name="alwaysAsk"
          />
          <label> Always Ask? </label>
        </div>
        <div>
          <label> Response Type: </label>
          <div className="pos-rel">
            <Select
              name="evalAnswer"
              className="sxp-defaultDropDown_filed w100"
              defaultValue={this.state.evalAnswer}
              onChange={this.handleEvalAnswerChange}
              key={this.state.formUniqueId}
            >
              <MenuItem value="STRING">String</MenuItem>
              <MenuItem value="BUTTON">Button</MenuItem>
              <MenuItem value="RADIO">Radio</MenuItem>
              <MenuItem value="CONFIRM">Confirm</MenuItem>
              <MenuItem value="ATTACHMENT">Attachment</MenuItem>
              <MenuItem value="Location">Location</MenuItem>
              <MenuItem value="BUTTON_OR_TEXT">Button / Text</MenuItem>
              <MenuItem value="DATE">Date</MenuItem>
              <MenuItem value="TIME">Time</MenuItem>
              <MenuItem value="DATE_AND_TIME">Date and Time</MenuItem>
              <MenuItem value="TEXT_AREA">Text Area</MenuItem>
              <MenuItem value="NUMBER">Number</MenuItem>
            </Select>
            {this.state.evalAnswer ? null : (
              <FormHelperText className="projSelectPlaceholder">
                Select Response Type
              </FormHelperText>
            )}
          </div>
        </div>
        {(this.state.evalAnswer === 'DATE' || this.state.evalAnswer === 'DATE_AND_TIME') && (
          <div>
            <label className="pos-rel">
              Date / Date and Time format 
              <span className="infoIcon"><img src={infoIcon}/>
              <div className="toolTip">
                <p>The below format you can use below for date format.</p>
                <ul>
                  <li>DD-MM-YYYY</li>
                  <li>DD/MM/YYYY</li>
                  <li>DD-MMM-YYYY</li>
                  <li>DD/MMM/YYYY</li>
                  <li>YYYY-MM-DD</li>
                  <li>YYYY/MM/DD</li>
                  <li>YYYY-MMM-DD</li>
                  <li>YYYY/MMM/DD</li>
                </ul>
              </div>
            </span>
            </label>
            <input 
                type="text"
                name="dateAndTimeFormat"
                key={this.state.formUniqueId}
                onChange={this.handleTextInputChange}
                placeholder="ex: DD/MM/YYYY or DD-MM-YYYY"
                defaultValue={this.state.dateAndTimeFormat}
              />
          </div>
        )}
      
        {(this.state.evalAnswer === 'STRING') && (
          <>
          <div>
            <input
              type="checkbox"
              name="isSearchable"
              key={this.state.formUniqueId}
              onClick={this.handleIsSearchable}
              defaultChecked={
                this.state.isSearchable === true ? true : false
              }
            />
            <label> Is Searchable </label>
          </div>
          <div>
          <label> Placeholder </label>
          <input
            type="text"
            name="placeholder"
            onChange={this.handleTextInputChange}
            defaultValue={this.state.placeholder}
            key={this.state.formUniqueId}
          />
        </div>
        </>
        )}
        
        <div>
          <label> Function: </label>
          <FunctionPreview
            functionKey={this.state.funcName}
            onCreate={this.handleOnCreate}
          />
          <SelectSearch
            styles={style}
            name="funcName"
            className="searchSelectDDCS customSelect"
            options={this.state.functions}
            defaultValue={
              this.state.functionObj
                ? this.state.functionObj
                : { value: "", label: "" }
            }
            value={this.state.functionObj}
            key={this.state.formUniqueId}
            onChange={this.handleSelectChange}
            isSearchable
          />

          {/* <Autocomplete
                id="custom-input-demo"
                name="funcName"
                options={this.state.functionsData?.map(a => a)}
                // onChange={(event) => this.autoComplete(event)}
                onChange={this.handleFunctionName}
                // defaultValue = {this.state.functionObj ? this.state.functionObj: null}
                value={this.state.functionObj ? this.state.functionObj: null}
                renderInput={(params) => (
                    <div ref={params.InputProps.ref}>
                    <input type="text" placeholder="Search Function Name" {...params.inputProps} />
                    </div>
                )}
              /> */}
        </div>
        <div>

        <div>
            <input
              type="checkbox"
              name="onChange"
              key={this.state.formUniqueId}
              onClick={this.handleOnChange}
              defaultChecked={
                this.state.onChange === true ? true : false
              }
            />
          <label> On Change </label>
        </div>
        
        {(this.state.showButtonField || this.state.evalAnswer === "BUTTON" || this.state.evalAnswer === "BUTTON_OR_TEXT") && (
          <>
          <div>
            <input
              type="checkbox"
              name="isButtonsDynamic"
              key={this.state.formUniqueId}
              onClick={this.handleDynamicBtn}
              // checked = {this.state.isButtonsDynamic}
              defaultChecked={this.state.isButtonsDynamic === true ? true : false}
            />
            <label> Dynamic Buttons </label>
          </div>
          
          <div>
            <input
              type="checkbox"
              name="isSearchable"
              key={this.state.formUniqueId}
              onClick={this.handleIsSearchable}
              defaultChecked={
                this.state.isSearchable === true ? true : false
              }
            />
            <label> Is Searchable </label>
          </div>
          <div>
            <input
              type="checkbox"
              name="multiSelect"
              key={this.state.formUniqueId}
              onClick={this.handleIsmultiSelect}
              defaultChecked={
                this.state.multiSelect === true ? true : false
              }
            />
            <label> MultiSelect </label>
          </div>

            {this.state.isButtonsDynamic ? (
              <>
              <div>
                <label> Button Fields </label>
                <input
                  type="text"
                  name="buttonsDynamicValue"
                  defaultValue={this.state.buttonsDynamicValue}
                  key={this.state.formUniqueId}
                  onChange={this.handleDynamicField}
                  required
                />
              </div>
                <input
                  type="hidden"
                  name="buttonsDynamicValue"
                  defaultValue={this.state.buttonsDynamicValue}
                  key={this.state.formUniqueId}
                />
                </>
            ) : (
              <div className="nonDynBtnObj" name="compeleteBtns">
                <div className="text-right"><span onClick={this.handleAddNonDynamicBtn} className="addNonDyBtn">+ Add </span></div>
                {this.state.buttonFields.map((d,i) => (
                  <div className="flex">
                    <input type="text" placeholder="label" onChange={(e) => this.handlelabel(e, i)} name="label" value={d.label}/>
                    <input type="text" placeholder="value" onChange={(e) => this.handleKeyValue(e, i)} name="value" value={d.value}/>
                    <span onClick={()=>this.deleteNonDynBtn(i)}>
                      <img
                        src={closeRed}
                        alt="close Icon"
                        className="closeIconM"
                      />
                    </span>
                  </div>
                ))}
              </div>
            )}
          </>
        )}
        </div>
        <div>
          <label> Transaction Name </label>
          <input
            type="text"
            name="transactionName"
            onChange={this.handleTextInputChange}
            defaultValue={this.state.transactionName}
            key={this.state.formUniqueId}
          />
        </div>
        <div>
          <input
            type="checkbox"
            key={this.state.formUniqueId}
            defaultChecked={this.state.isBillable === true ? true : false}
            name="isBillable"
          />
          <label>Billable</label>
        </div>
        <FormButtons formProps={this.props} />
      </form>
    );
  }
}

export default Question;
