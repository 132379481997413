import React, { Component } from "react";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import moment from "moment";
import Tooltip from "@material-ui/core/Tooltip";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Pagination from "../../commons/pagination";
import PositionedSnackbar from "../../commons/uicomponents/toast";
import EmptyCard from "../../commons/uicomponents/emptycard";
import LoaderSpinner from "../../commons/uicomponents/loader";
import { getData } from "../../dataProvider/apiHandler";
import "./journey.scss";
import filterIcon from "../../assets/images/filter_list.png";
import calender from "../../assets/images/calender.png";
import searchIcon from "../../assets/images/search.png";
import closeIcon from "../../assets/images/close-theme.png";
import watsapp from "../../assets/images/watsapp.png";
import slack from "../../assets/images/slack.png";
import webchatIcon from "../../assets/images/webchat.png";
import chevronDown from "../../assets/images/chevron-down.png";
import copyIcon from "../../assets/images/copy_theme.png";
import fileIcon from "../../assets/images/file.png";
import sortIcon from "../../assets/images/sort.png";
import sortUpIcon from "../../assets/images/sorttop.png";
import sortDownIcon from "../../assets/images/sortdown.png";
import GenerateReport from "./generate-report";
import JourneyDetails from "./journey-details";

const projectId = localStorage.getItem("pId");
const serverUrl =
  window._env_.REACT_APP_SERVER_URL + `api/projects/` + projectId;

class JourneyListing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      journeys: [],
      channelList: [
        { name: "whatsapp", selectedChannel: false },
        { name: "webchat", selectedChannel: false },
        { name: "slack", selectedChannel: false },
        { name: "Messenger", selectedChannel: false },
        { name: "Line", selectedChannel: false },
        { name: "Hike", selectedChannel: false },
        { name: "Message", selectedChannel: false },
      ], //channels static Lists
      statusList: [
        { name: "Successful", selectedChannel: false },
        { name: "In Progress", selectedChannel: false },
        { name: "Abandoned", selectedChannel: false },
      ], // status static Lists for filter use
      expansionJrnyPanelOpen: false,
      journeyIndex: "",
      sortNormal: true,
      sortUp: false,
      sortDown: false,
      sortNormalName: true,
      sortUpName: false,
      sortDownName: false,
      startDate: "", // startDate  to assign a start date value to state
      endDate: "",
      genderData: "",
      searchData: "",
      searchDataName: "",
      channelData: [],
      statusData: [],
      loadingCard: false,
      conversationData: {},
      pageNo: 1,
      showFilterBlock: false,
      showGenereateReport: false,
      startDateBadge: "",
      endtDateBadge: "",
      journeyList: [],
      rowPageSize: localStorage.getItem("rowPerPage"),
    };
  }

  componentDidMount = () => {
    this.getAllData();
    this.getAllJourneyList();
  };

  // EXPANSIONS HANDLERS
  switchJrnyExpansionpanel(index) {
    this.setState({ conversationID: "" });
    if (this.state.journeyIndex === index) {
      this.setState({
        expansionJrnyPanelOpen: false,
        journeyIndex: "",
      });
    } else {
      this.setState({
        expansionJrnyPanelOpen: true,
        journeyIndex: index,
      });
    }
    this.getSingleJourney(index);
  }

  getAllData = async (paramss) => {
    let startDate = moment(this.state.startDate).format("YYYY-MM-DD");
    let endDate = moment(this.state.endDate).format("YYYY-MM-DD");
    try {
      this.setState({ loadingCard: true });
      const otherParams = {
        paginate: true,
        page: this.state.pageNo,
        perPage: this.state.rowPageSize,
        filter: {
          ...(this.state.searchDataName && {
            userId: this.state.searchDataName,
          }),
          ...(this.state.searchData && { name: this.state.searchData }),
          ...(this.state.startDate && { startDate: startDate }),
          ...(this.state.endDate && { endDate: endDate }),
          ...(this.state.channelData.length && {
            channels: this.state.channelData,
          }),
          ...(this.state.statusData.length && {
            status: this.state.statusData,
          }),
        },
      };
      paramss = {
        ...otherParams,
        ...paramss,
      };
      const allOptions = {
        url: serverUrl + "/journey-logs",
        params: { ...paramss },
      };
      let initialLoad = await getData(allOptions);
      this.setState({
        journeys: initialLoad.data.data.docs,
        totalPages: initialLoad.data.data.pages,
        loadingCard: false,
      });
    } catch (error) {
      console.log(error);
    }
  };

  // to render single journey
  getSingleJourney = async (index) => {
    let jrnys = [...this.state.journeys];
    let journeyID = jrnys[index].jId;
    try {
      const allOptions = {
        url: serverUrl + "/journey-logs/" + journeyID,
      };
      let initialLoad = await getData(allOptions);
      this.setState({ conversationData: initialLoad.data.data });
    } catch (error) {
      console.log(error);
    }
  };

  getAllJourneyList = async () => {
    this.setState({ loadingCard: true });
    try {
      const allOptions = {
        url: serverUrl + "/tasks/",
      };
      let initialLoad = await getData(allOptions);
      this.setState({ journeyList: initialLoad.data.data, loadingCard: false });
    } catch (e) {
      console.log(e);
    }
    this.setState({ loadingCard: false });
  };

  // to show filter
  showFilter = () => {
    this.setState({ showSearchFilter: true });
  };

  // to close filter
  closeFilter = () => {
    this.setState({ showSearchFilter: false });
  };

  // to generate report
  generateReport = () => {
    this.setState({ showGenereateReport: true });
  };

  // to close report
  closeReport = () => {
    this.setState({ showGenereateReport: false });
  };

  previousPage = () => {
    let PreviousSetNo = this.state.pageNo - 1;
    this.setState({ pageNo: PreviousSetNo });
    const paramsSet = {
      page: PreviousSetNo,
    };
    this.getAllData(paramsSet);
  };

  nextPage = () => {
    let PreviousSetNo = this.state.pageNo + 1;
    this.setState({ pageNo: PreviousSetNo });
    const paramsSet = {
      page: PreviousSetNo,
    };
    this.getAllData(paramsSet);
  };

  rowPerPageChange = (e) => {
    const perPage = e.target.value;
    localStorage.setItem("rowPerPage", e.target.value);
    const paramsSet = {
      perPage: e.target.value,
    };
    this.getAllData(paramsSet);
    this.setState({ rowPageSize: perPage });
  };

  normalSort = () => {
    const paramsSet = {
      sort: { jId: "" },
    };
    this.getAllData(paramsSet);
    this.setState({
      sortNormal: true,
      sortUp: false,
      sortDown: false,
    });
  };

  ascSort = () => {
    const paramsSet = {
      sort: { jId: "ASC" },
    };
    this.getAllData(paramsSet);
    this.setState({
      sortNormal: false,
      sortUp: true,
      sortDown: false,
    });
  };

  descSort = () => {
    const paramsSet = {
      sort: { jId: "DESC" },
    };
    this.getAllData(paramsSet);
    this.setState({
      sortNormal: false,
      sortUp: false,
      sortDown: true,
    });
  };

  normalSortName = () => {
    const paramsSet = {
      sort: { name: "ASC" },
    };
    this.getAllData(paramsSet);
    this.setState({
      sortNormalName: true,
      sortUpName: false,
      sortDownName: false,
    });
  };

  ascSortName = () => {
    const paramsSet = {
      sort: { name: "ASC" },
    };
    this.getAllData(paramsSet);
    this.setState({
      sortNormalName: false,
      sortUpName: true,
      sortDownName: false,
    });
  };

  descSortName = () => {
    const paramsSet = {
      sort: { name: "DESC" },
    };
    this.getAllData(paramsSet);
    this.setState({
      sortNormalName: false,
      sortUpName: false,
      sortDownName: true,
    });
  };

  // to select journeyname filter
  searchDataFilter = async (event) => {
    let searchText = event.currentTarget.textContent;
    this.setState({ searchData: searchText });
  };

  // to select username filter
  searchUserFilter = async (event) => {
    let searchText = event.target.value;
    this.setState({ searchDataName: searchText });
  };

  onChangeDateRange = async (value) => {
    this.setState({ dateRangevalue: value });
    this.setState({
      startDate: moment(value[0]).format("YYYY-MM-DD"), // setting startDate value in yyyy-mm-dd format
      endDate: moment(value[1]).format("YYYY-MM-DD"), // setting endDate value in yyyy-mm-dd format
    });
  };

  selectFilterChannel = async (e, index) => {
    let list = [...this.state.channelList];
    list[index].selectedbtn = !list[index].selectedbtn;
    var selectedoptions = [];
    for (let _loopvar = 0; _loopvar < list.length; _loopvar++) {
      if (list[_loopvar].selectedbtn === true)
        selectedoptions.push(list[_loopvar].name);
    }
    this.setState({
      channelList: list,
      channelData: selectedoptions,
    });
  };

  // to select status filter
  selectFilterStaus = async (e, index) => {
    let list = [...this.state.statusList];
    list[index].selectedbtn = !list[index].selectedbtn;
    var selectedoptions = [];
    for (let _loopvar = 0; _loopvar < list.length; _loopvar++) {
      if (list[_loopvar].selectedbtn === true)
        selectedoptions.push(list[_loopvar].name);
    }
    this.setState({
      statusList: list,
      statusData: selectedoptions,
    });
  };

  applyFilter = () => {
    let startDate = moment(this.state.startDate).format("YYYY-MM-DD");
    let endDate = moment(this.state.endDate).format("YYYY-MM-DD");
    this.setState({
      loadingCard: true,
      startDateBadge: startDate,
      endtDateBadge: endDate,
      showSearchFilter: false,
      showFilterBlock: true,
    });
    this.getAllData();
  };

  clearAll = () => {
    this.removeStatusData();
    this.removeChannelData();
    this.removeField1Data();
    this.removeField2Data();
    this.removeDate();
    let listStatus = [...this.state.statusList];
    let listChannel = [...this.state.channelList];
    Object.keys(listStatus).forEach((v) => (listStatus[v].selectedbtn = false));
    Object.keys(listChannel).forEach(
      (v) => (listChannel[v].selectedbtn = false)
    );
    var selectedoptions = [];
    this.setState({
      statusList: listStatus,
      statusData: selectedoptions,
      channelList: listChannel,
      channelData: selectedoptions,
      showFilterBlock: false,
    });
  };
  removeStatusData = async (selectedName) => {
    let list = [...this.state.statusList];
    // removing the selected flag for selected item
    for (let _loopvar = 0; _loopvar < list.length; _loopvar++) {
      if (list[_loopvar].name === selectedName) {
        list[_loopvar].selectedbtn = !list[_loopvar].selectedbtn;
      }
    }
    //getting the list of selected items after deleting the clicked items
    var selectedoptions = [];
    for (let _loopvar = 0; _loopvar < list.length; _loopvar++) {
      if (list[_loopvar].selectedbtn === true)
        selectedoptions.push(list[_loopvar].name);
    }
    this.setState({ statusList: list });
    this.setState({ statusData: selectedoptions }, () => {
      this.applyFilter();
    });
  };

  // to remove channel filter
  removeChannelData = async (selectedName) => {
    let list = [...this.state.channelList];
    for (let _loopvar = 0; _loopvar < list.length; _loopvar++) {
      if (list[_loopvar].name === selectedName) {
        list[_loopvar].selectedbtn = !list[_loopvar].selectedbtn;
      }
    }
    var selectedoptions = [];
    for (let _loopvar = 0; _loopvar < list.length; _loopvar++) {
      if (list[_loopvar].selectedbtn === true)
        selectedoptions.push(list[_loopvar].name);
    }
    this.setState({ channelList: list });
    this.setState({ channelData: selectedoptions }, () => {
      this.applyFilter();
    });
  };

  // to remove journeyList Data
  removeField1Data = async () => {
    this.setState({ searchData: "" }, () => {
      this.applyFilter();
    });
  };

  // to remove user Data
  removeField2Data = async () => {
    this.setState({ searchDataName: "" }, () => {
      this.applyFilter();
    });
  };

  //to remove date
  removeDate = async () => {
    this.setState(
      {
        startDate: "",
        startDateBadge: "",
        endDate: "",
        endtDateBadge: "",
        dateRangevalue: "",
      },
      () => {
        this.applyFilter();
      }
    );
  };

  // Close Toast
  closeToast = () => {
    this.setState({ displayToast: false });
  };

  // copy text from card
  copyText = async (data) => {
    let getValueId = data.target.getAttribute("data-index");
    try {
      await navigator.clipboard.writeText(getValueId);
      this.setState({
        displayToast: true,
        messageToast: "Copied to Clipboard",
        severity: "success",
      });
    } catch (err) {
      console.log(err);
      this.setState({
        displayToast: true,
        messageToast: "Failed to Copy",
        severity: "error",
      });
    }
  };

  journeyBanner = () => {
    return (
      <div className="display-flex table_head font13 fontWeight700 cardFull cardSpaceArrow">
        <div className="table_grid billSummBody">
          <p>
            Journey Name
            <span className="sortIconbg">
              {this.state.sortNormalName ? (
                <img
                  alt="NormalSortIcon"
                  src={sortIcon}
                  onClick={this.ascSortName}
                />
              ) : null}
              {this.state.sortUpName ? (
                <img
                  alt="DescSortIcon"
                  src={sortUpIcon}
                  onClick={this.descSortName}
                />
              ) : null}
              {this.state.sortDownName ? (
                <img
                  alt="AscSortIcon"
                  src={sortDownIcon}
                  onClick={this.normalSortName}
                />
              ) : null}
            </span>
          </p>
        </div>
        <div className="table_grid billSummBody">
          <p>UserName / ID</p>
        </div>
        <div className="table_grid billSummBody">
          <p>
            Journey ID
            <span className="sortIconbg">
              {this.state.sortNormal ? (
                <img
                  alt="NormalSortIcon"
                  src={sortIcon}
                  onClick={this.ascSort}
                />
              ) : null}
              {this.state.sortUp ? (
                <img
                  alt="DescSortIcon"
                  src={sortUpIcon}
                  onClick={this.descSort}
                />
              ) : null}
              {this.state.sortDown ? (
                <img
                  alt="AscSortIcon"
                  src={sortDownIcon}
                  onClick={this.normalSort}
                />
              ) : null}
            </span>
          </p>
        </div>
        <div className="table_grid text-center billSummBody">
          <p>Channels</p>
        </div>
        <div className="table_grid text-right billSummBody">
          <p>Start Date</p>
        </div>
        <div className="table_grid text-right billSummBody">
          <p>Last Updated</p>
        </div>
        <div className="table_grid text-right billSummBody">
          <p>Status</p>
        </div>
      </div>
    );
  };

  journeyHeader = (item, index) => {
    return (
      <div className="display-flex text_black font13 w100 pr15 pl15">
        <LoaderSpinner loadingCard={this.state.loadingCard} />
        <div className="table_grid billSummHead">
          <p>{item.name}</p>
        </div>
        <div className="table_grid billSummHead">
          <Tooltip title={item.userId} className="in-block">
            <p
              className="in-block overflowTextdots"
              style={{ maxWidth: "114px" }}
            >
              {item.userId}
            </p>
          </Tooltip>
          <img
            alt="CopyIcon"
            id={`copyUserId${index}`}
            src={copyIcon}
            onClick={this.copyText}
            style={{ verticalAlign: "9px" }}
            data-index={item.userId}
          />
        </div>
        <div className="table_grid text-cuursor billSummHead">
          <div style={{ paddingLeft: "7px" }}>
            <Tooltip title={item.jId}>
              <p
                className="in-block overflowTextdots"
                style={{ maxWidth: "114px" }}
              >
                {item.jId}
              </p>
            </Tooltip>
          </div>
        </div>
        <div className="table_grid text-center billSummHead">
          <p className="" style={{ marginBottom: 0 }}>
            {item.channels?.map((data) => {
              return (
                <>
                  {data === "webchat" && (
                    <img
                      alt="webChatIcon"
                      style={{ width: "26px" }}
                      src={webchatIcon}
                    />
                  )}
                  {data === "whatsapp" && (
                    <img
                      alt="WhatsAppIcon"
                      style={{ width: "26px" }}
                      src={watsapp}
                    />
                  )}
                  {data === "slack" && (
                    <img
                      alt="SlackIcon"
                      style={{ width: "26px" }}
                      src={slack}
                    />
                  )}
                </>
              );
            })}
          </p>
        </div>
        <div className="table_grid billSummHead">
          <p className="flex" style={{ marginTop: "8px" }}>
            <img alt="CalenderIcon" src={calender} className="vertical-7" />
            <span
              style={{ color: "#000", marginTop: "5px", marginLeft: "4px" }}
              className="date-text"
            >
              {moment(item.startDate).format("DD-MMM-YYYY")}
            </span>
          </p>
        </div>
        <div className="table_grid billSummHead">
          <p className="flex" style={{ marginTop: "8px" }}>
            <img alt="CalenderIcon" src={calender} className="vertical-7" />
            <span
              style={{ color: "#000", marginTop: "5px", marginLeft: "4px" }}
              className="date-text"
            >
              {moment(item.endDate).format("DD-MMM-YYYY")}
            </span>
          </p>
        </div>
        <div
          className="table_grid billSummHead"
          style={{ marginLeft: "15px", flex: 0 }}
        >
          <p className={`nowrap statusMsg ${item.status}`}>{item.status}</p>
        </div>
      </div>
    );
  };

  render() {
    return (
      <div className="parent mt10">
        <PositionedSnackbar
          display={this.state.displayToast}
          msg={this.state.messageToast}
          closeToast={this.closeToast}
          severity={this.state.severity}
        />
        <div className="display-flex">
          <h4 className="m0">
            Journey Listing <span className="label_pink">List of Journeys</span>
          </h4>
          <div>
            <div className="pos-rel in-block">
              <button
                className="sxp-btn generetBtn"
                onClick={this.generateReport}
                style={{ marginRight: "10px" }}
              >
                <img alt="fileIcon" src={fileIcon} />
                Generate Report
              </button>
              <button
                className="sxp-btn btn-primary filterBtn"
                onClick={this.showFilter}
              >
                <img alt="filterIcon" src={filterIcon} /> FILTER
              </button>
            </div>
          </div>
        </div>

        <div
          className={`${
            this.state.showSearchFilter ? "activeFilter" : ""
          } filterMainBlock`}
        >
          <div className="display-flex" style={{ alignItems: "baseline" }}>
            <h3 className="m0">Search Filter</h3>
            <span className="clearAllText" onClick={this.clearAll}>
              Clear all
            </span>
          </div>
          <div className="pos-rel searchInput">
            <img alt="SearchIcon" src={searchIcon} className="searchIcon" />
            <Autocomplete
              id="custom-input-demo"
              options={this.state.journeyList.map((a) => a.name)}
              noOptionsText="No journeys found with this name"
              onChange={(event) => this.searchDataFilter(event)}
              value={this.state.searchData}
              renderInput={(params) => (
                <div ref={params.InputProps.ref}>
                  <input
                    type="text"
                    className="w100"
                    placeholder="Search Journey Name"
                    {...params.inputProps}
                  />
                </div>
              )}
            />
            <img
              alt="chevronDownIcon"
              src={chevronDown}
              className="downArrrowChev"
            />
          </div>

          <div className="searchInput">
            <img alt="chevronUpIcon" src={searchIcon} className="searchIcon" />
            <input
              type="text"
              placeholder="Search User Name / ID"
              value={this.state.searchDataName}
              onChange={this.searchUserFilter}
            />
          </div>
          <div className="recentSearches">
            <div className="display-flex" style={{ alignItems: "baseline" }}>
              <h4 className="m0 searchhead2">Select From data and to date</h4>
            </div>
            <div className="dateBlockPicker">
              <DateRangePicker
                className="sxp-defaultDatePicker "
                onChange={this.onChangeDateRange}
                clearIcon={null}
                maxDate={new Date()}
                value={this.state.dateRangevalue}
                dayPlaceholder="DD"
                monthPlaceholder="MM"
                yearPlaceholder="YYYY"
                style={{ width: "100%" }}
              />
            </div>
          </div>

          <div className="recentSearches">
            <div className="display-flex" style={{ alignItems: "baseline" }}>
              <h4 className="m0 searchhead2">Channels</h4>
            </div>
            {this.state.channelList.map((data, i) => (
              <span
                key={i}
                className={`${
                  data.selectedbtn ? "activeSelectedFilter" : ""
                } labelFilter`}
                onClick={(data) => this.selectFilterChannel(data, i)}
              >
                {data.name}
              </span>
            ))}
          </div>
          <div className="recentSearches">
            <div className="display-flex" style={{ alignItems: "baseline" }}>
              <h4 className="m0 searchhead2">Status</h4>
            </div>
            {this.state.statusList.map((data, i) => (
              <span
                key={i}
                className={`${
                  data.selectedbtn ? "activeSelectedFilter" : ""
                } labelFilter`}
                onClick={(data) => this.selectFilterStaus(data, i)}
              >
                {data.name}
              </span>
            ))}
          </div>

          <button
            className="sxp-btn btn-primary block-btn"
            onClick={this.applyFilter}
            style={{ marginBottom: "10px" }}
          >
            APPLY
          </button>
          <button
            className="sxp-btn btn-danger block-btn"
            onClick={this.closeFilter}
          >
            CANCEL
          </button>
        </div>

        <div
          className={`${
            this.state.showGenereateReport ? "activeFilter" : ""
          } filterMainBlock`}
        >
          <GenerateReport
            journeyList={this.state.journeyList}
            channelList={this.state.channelList}
            statusList={this.state.statusList}
            closeReport={this.closeReport}
            searchDataFilter={(event) => this.searchDataFilter(event)}
          />
        </div>

        {/* predefined to filters */}
        {this.state.showFilterBlock ? (
          <div className="predefinedFilter">
            <p>
              <span style={{ fontStyle: "italic" }}>Preview Filters</span>
              {this.state.searchData === "" ? (
                ""
              ) : (
                <span className="FilterBadge">
                  {this.state.searchData}
                  <img
                    alt="closeIcon"
                    src={closeIcon}
                    onClick={this.removeField1Data}
                  />
                </span>
              )}
              {this.state.searchDataName === "" ? (
                ""
              ) : (
                <span className="FilterBadge">
                  {this.state.searchDataName}
                  <img
                    alt="closeIcon"
                    src={closeIcon}
                    onClick={this.removeField2Data}
                  />
                </span>
              )}
              {this.state.startDateBadge === "Invalid date" ? (
                ""
              ) : (
                <span className="FilterBadge">
                  From: {this.state.startDateBadge} ~ To:{" "}
                  {this.state.endtDateBadge}
                  <img
                    alt="closeIcon"
                    src={closeIcon}
                    onClick={this.removeDate}
                  />
                </span>
              )}
              {this.state.statusData === []
                ? ""
                : this.state.statusData.map((data, i) => {
                    return (
                      <span className="FilterBadge" key={i}>
                        {data}
                        <img
                          alt="closeIcon"
                          src={closeIcon}
                          onClick={() => this.removeStatusData(data)}
                        />
                      </span>
                    );
                  })}

              {this.state.channelData === ""
                ? ""
                : this.state.channelData.map((data, i) => (
                    <span className="FilterBadge" key={i}>
                      {data}
                      <img
                        alt="closeIcon"
                        src={closeIcon}
                        onClick={() => this.removeChannelData(data)}
                      />
                    </span>
                  ))}
            </p>
          </div>
        ) : null}

        {this.journeyBanner()}
        {this.state.journeys.length ? (
          <div className="">
            {this.state.journeys.map((item, index) => (
              <ExpansionPanel
                className="CustomexpansionPannel"
                key={item.id}
                expanded={
                  this.state.expansionJrnyPanelOpen &&
                  this.state.journeyIndex === index
                }
              >
                <ExpansionPanelSummary
                  expandIcon={
                    <ExpandMoreIcon
                      className="iconExpand"
                      onClick={this.switchJrnyExpansionpanel.bind(
                        this,
                        index
                      )}
                    />
                  }
                  style={{ order: -1 }}
                  className="boxShadow"
                >
                  {this.journeyHeader(item, index)}
                </ExpansionPanelSummary>
                <ExpansionPanelDetails className="inner_expandBlock">
                  <JourneyDetails
                    journeyDetails={this.state.conversationData}
                  />
                </ExpansionPanelDetails>
              </ExpansionPanel>
            ))}
          </div>
        ) : (
          <EmptyCard />
        )}

        <Pagination
          pageNo={this.state.pageNo}
          totalPages={this.state.totalPages}
          rowPerPageChange={this.rowPerPageChange}
          previousPage={this.previousPage}
          nextPage={this.nextPage}
          pageSize={this.state.rowPageSize}
        />
      </div>
    );
  }
}

export default JourneyListing;
