import React, { useState, useEffect } from "react";
import KeyboardEventHandler from "react-keyboard-event-handler";
import uuid from "react-uuid";
import { Style } from "../constants/style";
import { ComponentType } from "../constants/componentType";
import { saveElements } from "../canvas";
import Question from "../forms/question";
import { API } from "../forms/api";
import { Connection } from "../forms/connection";
import { Dialog } from "../forms/startDialog";
import { Component } from "../forms/startComponent";
import { StartNode } from "../forms/startNode";
import { WaitNode } from "../forms/waitNode";
import { Notification } from "../forms/notification";
import AB from "../forms/ab";
import { ABConnection } from "../forms/abconnection";
import { DYNAMIC } from "../forms/dynamic";
import { UserRegister } from "../forms/userRegister";
import chevronDown from "../../../assets/images/chevron-down.png";
import UndoRedo from "./undoredo";
import { EndNode } from "../forms/endNode";
import { SubmitNode } from "../forms/submitNode";
import { APIResponse } from "../forms/apiResponse";

export const Header = (props) => {
  let singleConvData = props.singleConv;
  const [state, setState] = useState({
    rightToggle: false,
    overlay: false,
    createConversation: false,
    editConversation: false,
    nodeState: { element: {} },
  });
  const [alert, setAlert] = useState(false);
  const [toast, setToast] = useState(false);
  const [messageToast, setMessageToast] = useState("");

  useEffect(() => {
    setState(props);
  }, [props]);
  const collapseRightForm = () => {
    let updatedState = { ...state };
    // updatedState.nodeState.rightToggle = false;
    updatedState.nodeState.rightToggle = !updatedState.nodeState.rightToggle;
    setState(updatedState);
  };

  const callBackJName = (name) => {
    props.callBackJName1(name);
  }

  return (
    <div>
      <br />
      <div className="displayflex">
        <KeyboardEventHandler
          handleKeys={["ctrl+s"]}
          onKeyEvent={(key, e) => {
            e.preventDefault();
            saveElements();
          }}
          isExclusive={false}
        />

        <UndoRedo />
        <button
          className="sxp-btn btn-primary verticalTop ml5"
          onClick={saveElements}
        >
          Save
        </button>
      </div>
      <div
        className={
          Style.RIGHTFORMCOLLAPSE +
          " " +
          (state.nodeState.rightToggle ? "" : "ConfigPanelClose")
        }
      >
        <>
          <div className="rightOpenArrow">
            <span className="pannelTExt">Config Panel</span>
            <img
            alt="close arrow chevron"
              className="chevron-range chevron-side to-close-panel-arrow"
              onClick={collapseRightForm}
              src={chevronDown}
            />
          </div>
          <h4 className="panelHeaderText">
            {state.nodeState.element?.data?.component ===
              ComponentType.QUESTION &&
              !state.nodeState.element.data.isUserRegistrationComponent &&
              "Question Node"}
            {state.nodeState.element?.data?.component === ComponentType.API &&
              "API Node"}
            {state.nodeState.element?.data?.component === ComponentType.API_RESPONSE &&
              "API Response"}
            {state.nodeState.element?.data?.component ===
              ComponentType.START_COMPONENT && "Component Node"}
            {state.nodeState.element?.data?.component ===
              ComponentType.NOTIFICATION && "Notification Node"}
            {state.nodeState.element?.data?.component ===
              ComponentType.START_NODE && "Start Node"}
            {state.nodeState.element?.data?.component ===
              ComponentType.END_NODE && "End Node"}
            {state.nodeState.element?.data?.component === ComponentType.WAIT_NODE && "Wait Node"}
            {state.nodeState.element?.data?.component === ComponentType.SUBMIT_NODE && "Submit Node"}
            {state.nodeState.element?.data?.component === ComponentType.AB &&
              "AB Node"}
            {state.nodeState.element?.data?.component ===
              ComponentType.ABEDGE && "AB EDGE Node"}
            {state.nodeState.element?.data?.component ===
              ComponentType.DYNAMIC && "Dynamic Node"}
            {state.nodeState.element?.data?.component ===
              ComponentType.START_DIALOG && "Dialog Node"}
            {state.nodeState.element?.data?.component === ComponentType.EDGE &&
              "Connection Node"}
            {state.nodeState.element?.data?.isUserRegistrationComponent
              ? "User Register Node"
              : null}
          </h4>
          {state.nodeState.rightToggle && (
            <div className="scrollableContent">
              {/*  */}
              {state.nodeState.element ? null : (
                <p className="makeItCenter">
                  No Value Loaded, please click a node to load values
                </p>
              )}
              {state.nodeState.element &&
                state.nodeState.element.data &&
                state.nodeState.element.data.component ===
                  ComponentType.QUESTION &&
                !state.nodeState.element.data.isUserRegistrationComponent && (
                  <Question
                    formAction={collapseRightForm}
                    refreshElements={state.nodeState.refreshElements}
                    removeElement={state.nodeState.removeElement}
                    data={state.nodeState.element}
                    formUniqueId={uuid()}
                    functions={state.nodeState.functions}
                    singleConvData={singleConvData}
                  />
                )}
              {state.nodeState.element &&
                state.nodeState.element.data &&
                state.nodeState.element.data.component ===
                  ComponentType.QUESTION &&
                state.nodeState.element.data.isUserRegistrationComponent && (
                  <UserRegister
                    formAction={collapseRightForm}
                    refreshElements={state.nodeState.refreshElements}
                    removeElement={state.nodeState.removeElement}
                    data={state.nodeState.element}
                    formUniqueId={uuid()}
                  />
                )}
              {state.nodeState.element &&
                state.nodeState.element.data &&
                state.nodeState.element.data.component ===
                  ComponentType.API && (
                  <API
                    formAction={collapseRightForm}
                    refreshElements={state.nodeState.refreshElements}
                    removeElement={state.nodeState.removeElement}
                    data={state.nodeState.element}
                    formUniqueId={uuid()}
                    functions={state.nodeState.functions}
                    services={state.nodeState.services}
                  />
                )}

              {state.nodeState.element &&
                state.nodeState.element.data &&
                state.nodeState.element.data.component ===
                  ComponentType.API_RESPONSE && (
                  <APIResponse
                    formAction={collapseRightForm}
                    refreshElements={state.nodeState.refreshElements}
                    removeElement={state.nodeState.removeElement}
                    data={state.nodeState.element}
                    formUniqueId={uuid()}
                    functions={state.nodeState.functions}
                    services={state.nodeState.services}
                  />
                )}
              {state.nodeState.element &&
                state.nodeState.element.data &&
                state.nodeState.element.data.component ===
                  ComponentType.EDGE && (
                  <Connection
                    formAction={collapseRightForm}
                    refreshElements={state.nodeState.refreshElements}
                    removeElement={state.nodeState.removeElement}
                    data={state.nodeState.element}
                    functions={state.nodeState.functions}
                    formUniqueId={uuid()}
                  />
                )}
              {state.nodeState.element &&
                state.nodeState.element.data &&
                state.nodeState.element.data.component ===
                  ComponentType.START_DIALOG && (
                  <Dialog
                    formAction={collapseRightForm}
                    refreshElements={state.nodeState.refreshElements}
                    removeElement={state.nodeState.removeElement}
                    data={state.nodeState.element}
                    formUniqueId={uuid()}
                    conversations={state.nodeState.conversations}
                    callBackJName={callBackJName}
                  />
                )}
              {state.nodeState.element &&
                state.nodeState.element.data &&
                state.nodeState.element.data.component ===
                  ComponentType.START_COMPONENT && (
                  <Component
                    formAction={collapseRightForm}
                    refreshElements={state.nodeState.refreshElements}
                    removeElement={state.nodeState.removeElement}
                    data={state.nodeState.element}
                    formUniqueId={uuid()}
                    conversations={state.nodeState.conversations}
                    components={state.nodeState.components}
                  />
                )}
              {state.nodeState.element &&
                state.nodeState.element.data &&
                state.nodeState.element.data.component ===
                  ComponentType.NOTIFICATION && (
                  <Notification
                    formAction={collapseRightForm}
                    refreshElements={state.nodeState.refreshElements}
                    removeElement={state.nodeState.removeElement}
                    data={state.nodeState.element}
                    formUniqueId={uuid()}
                    conversations={state.nodeState.conversations}
                  />
                )}
              {state.nodeState.element &&
                state.nodeState.element.data &&
                state.nodeState.element.data.component ===
                  ComponentType.START_NODE && (
                  <StartNode
                    formAction={collapseRightForm}
                    refreshElements={state.nodeState.refreshElements}
                    removeElement={state.nodeState.removeElement}
                    data={state.nodeState.element}
                    formUniqueId={uuid()}
                  />
                )}
              {state.nodeState.element && 
                state.nodeState.element.data &&
                state.nodeState.element.data.component === 
                  ComponentType.END_NODE && (
                  <EndNode
                    formAction={collapseRightForm}
                    refreshElements={state.nodeState.refreshElements}
                    removeElement={state.nodeState.removeElement}
                    data={state.nodeState.element}
                    formUniqueId={uuid()}
                  />
                )}
                {state.nodeState.element && 
                state.nodeState.element.data &&
                state.nodeState.element.data.component === 
                  ComponentType.WAIT_NODE && (
                  <WaitNode
                    formAction={collapseRightForm}
                    refreshElements={state.nodeState.refreshElements}
                    removeElement={state.nodeState.removeElement}
                    data={state.nodeState.element}
                    formUniqueId={uuid()}
                  />
                )}
                {state.nodeState.element && 
                state.nodeState.element.data &&
                state.nodeState.element.data.component === 
                  ComponentType.SUBMIT_NODE && (
                  <SubmitNode
                    formAction={collapseRightForm}
                    refreshElements={state.nodeState.refreshElements}
                    removeElement={state.nodeState.removeElement}
                    data={state.nodeState.element}
                    formUniqueId={uuid()}
                  />
                )}
              {state.nodeState.element &&
                state.nodeState.element.data &&
                state.nodeState.element.data.component === ComponentType.AB && (
                  <AB
                    formAction={collapseRightForm}
                    refreshElements={state.nodeState.refreshElements}
                    removeElement={state.nodeState.removeElement}
                    data={state.nodeState.element}
                    formUniqueId={uuid()}
                  />
                )}
              {state.nodeState.element &&
                state.nodeState.element.data &&
                state.nodeState.element.data.component ===
                  ComponentType.ABEDGE && (
                  <ABConnection
                    formAction={collapseRightForm}
                    refreshElements={state.nodeState.refreshElements}
                    removeElement={state.nodeState.removeElement}
                    data={state.nodeState.element}
                    formUniqueId={uuid()}
                  />
                )}
              {state.nodeState.element &&
                state.nodeState.element.data &&
                state.nodeState.element.data.component ===
                  ComponentType.DYNAMIC && (
                  <DYNAMIC
                    formAction={collapseRightForm}
                    refreshElements={state.nodeState.refreshElements}
                    removeElement={state.nodeState.removeElement}
                    data={state.nodeState.element}
                    formUniqueId={uuid()}
                  />
                )}
            </div>
          )}
        </>
      </div>
      {/* )} */}
    </div>
  );
};
