import HttpService from "../../HttpService";

const axios = HttpService.getAxiosClient();

let headers = {
    "X-Parse-Application-Id": localStorage.getItem('pId'),
    "X-Parse-Master-Key": '111111',
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*"
}

const getBackendProjectInfo = async(projectId) => {
    const response = await axios.get(window._env_.REACT_APP_PARSE_DASHBOARD_URL+'backend-services/app-info/'+projectId+'/project-details');
    return response;
}

// function to parse server data using GET method
const getBackendData = async (allOptions) => {
    let options = {
      url: allOptions.url,
      params: allOptions.params,
    };
    let mKey = await getBackendProjectInfo(localStorage.getItem('pId'))
    headers["X-Parse-Master-Key"] = mKey.data.data.masterKey;
    const response = await axios.get(options.url, {
      headers: headers,
      params: options.params,
    });
    return response;
};  

const updateBackendData = async (allOptions) => {
    const options = {
      url: allOptions.url,
      body: allOptions.body,
      headers: allOptions.headers
    };
    const response = axios.put(options.url, options.body, { headers: headers });
    return response;
};

const addBackendData = async (allOptions) => {
    const options = {
        url: allOptions.url,
        body: allOptions.body,
        headers: allOptions.headers
    };
    const response = axios.post(options.url, options.body, { headers: headers });
    return response;
};

const deleteBackendData = async (allOptions) => {
    const options = {
        url: allOptions.url,
        headers: allOptions.headers,
        params: allOptions.params,
    };
    const response = axios.delete(options.url, {
        headers:headers,
        params: options.params,
    });
    return response;
};

export {
    getBackendData, 
    getBackendProjectInfo,
    updateBackendData,
    addBackendData,
    deleteBackendData
}
  
  