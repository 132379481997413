import React, { Component } from "react";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AddIcon from "@material-ui/icons/Add";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import moment from "moment";
import ReactJson from "react-json-view";
import {
  FormBuilder,
  FieldGroup,
  FieldControl,
  Validators,
} from "react-reactive-form";
import Tooltip from "@material-ui/core/Tooltip";
import { FormHelperText, MenuItem, Select } from "@material-ui/core";
import {
  deleteData,
  getData,
  getSingleData,
  saveData,
  updateDataPut,
} from "../../dataProvider/apiHandler";
import ConfirmAlert from "../../commons/uicomponents/confirm";
import Pagination from "../../commons/pagination";
import EmptyCard from "../../commons/uicomponents/emptycard";
import PositionedSnackbar from "../../commons/uicomponents/toast";
import LoaderSpinner from "../../commons/uicomponents/loader";
import "./api.scss";
import jrnyEdit from "../../assets/images/jrny-edit.png";
import calender from "../../assets/images/calender.png";
import deletewhiteIcon from "../../assets/images/deletewhite.png";
import searchIcon from "../../assets/images/search.png";
import FindError from "../../dataProvider/errorHandler";

const serverUrl = window._env_.REACT_APP_SXP_URL;

class ApiServices extends Component {
  constructor(props) {
    super(props);
    this.state = {
      apiServices: [],
      expansionJrnyPanelOpen: false,
      journeyIndex: "",
      confirmAlert: false,
      apiServicesNIndex: "",
      headers: {},
      bodyJson: {},
      formToggleSwith: false,
      pageNo: 1,
      activeTabEnterprise: true,
      activeTabThridParty: false,
      activeTabNLU: false,
      totalPages: "",
      apiType: "ENTERPRISE",
      loadingCard: false,
      displayToast: false,
      serviceApiId: "",
      rowPageSize: localStorage.getItem("rowPerPage"),
      permissions: {},
      searchTextData:'',
      userFunctionData:[],
      oauthData:[],
      selectedOAuthData:{},
    };
  }

  componentDidMount = () => {
    this.getAllServices();
    this.loadUserFunction();
    this.loadOAuthData();
  };

  // EXPANSIONS HANDLERS
  switchJrnyExpansionpanel(index) {
    if (this.state.journeyIndex === index) {
      this.setState({
        expansionJrnyPanelOpen: false,
        journeyIndex: "",
      });
    } else {
      this.setState({
        expansionJrnyPanelOpen: true,
        journeyIndex: index,
      });
    }
    this.setState({selectedOAuthData: {}})
    this.conversationFormBuilder.reset();
    this.buildingConversationForm(index);
  }

  // initial form load
  conversationFormBuilder = FormBuilder.group({
    name: ["", Validators.required],
    method: ["", Validators.required],
    url: ["", Validators.required],
    type: [""],
    usageType: [""],
    isUrlEncoded: false,
    headers: [{}],
    bodyJson: [{}],
    isFormData: false,
    userFunction:[""],
    oAuthRequired: [Boolean],
    oauthConfigId:[""],
    authenticationHeader:[""]
  });

  // to patch the value in form field when the card is expanded
  buildingConversationForm = (data) => {
    let servData = [...this.state.apiServices];
    if (servData[data]?.id) {     
      this.conversationFormBuilder.patchValue({
        id: servData[data].id,
        name: servData[data].name,
        method: servData[data].method,
        url: servData[data].url,
        isFormData: servData[data].isFormData,
        headers: servData[data].headers,
        bodyJson: servData[data].bodyJson,
        usageType: servData[data].usageType,
        userFunction: servData[data].userFunction,
        oAuthRequired: servData[data].oAuthRequired,
        oauthConfigId: servData[data].oauthConfigId,
        authenticationHeader: servData[data].authenticationHeader,
      });
      let findSelectedObj = this.state.oauthData.find(x => x.id === servData[data].oauthConfigId)
      this.setState({selectedOAuthData:findSelectedObj})
    }
  };

  getAllServices = async (paramsmet) => {
    try {
      this.setState({ loadingCard: true });
      const initialVal = {
        paginate: true,
        // page: this.state.pageNo,
        type: this.state.apiType ? this.state.apiType : "ENTERPRISE",
        perPage: localStorage.getItem("rowPerPage"),
        projectId: localStorage.getItem("pId"),
        searchWord: this.state.searchTextData === '.*' ? null: this.state.searchTextData,
        searchKey: 'name'
      };
      paramsmet = {
        ...initialVal,
        ...paramsmet,
      };
      const allOptions = {
        url: serverUrl + "services/" + localStorage.getItem('pId'),
        params: { ...paramsmet },
      };

      let initialLoad = await getData(allOptions);
      this.setState({
        apiServices: initialLoad.data.data.docs,
        totalPages: Math.ceil(initialLoad.data.data.total / initialLoad.data.data.limit),
        loadingCard: false,
      });
    } catch (e) {
      const errorObj = FindError(e)
      this.setState({
        displayToast: true,
        messageToast: errorObj.message,
        severity: "error",
        confirmAlert: false,
        loadingCard: false
      });
    }
  };

  loadUserFunction = async() => {
    try{
      const allOptions = {
        url: serverUrl + 'be/userfunctions/'+localStorage.getItem('pId')+'/keys/'+localStorage.getItem('version')
      }
      let initialLoad = await getSingleData(allOptions);
      this.setState({userFunctionData: initialLoad.data.functionNames})
    }
    catch(e){
      const errorObj = FindError(e)
      console.log(errorObj)
      this.setState({
        displayToast: true,
        messageToast: errorObj.message,
        severity: "error",
        confirmAlert: false,
      });
    }   
  }

  loadOAuthData = async() => {
    try{
      const allOptions = {
        url: serverUrl + "oauth-config/" + localStorage.getItem('pId'),
      };
      let initialLoad = await getData(allOptions);
      this.setState({
        oauthData: initialLoad.data,
        loadingCard: false,
      });
    } catch (e) {
      const errorObj = FindError(e)
      this.setState({
        displayToast: true,
        messageToast: errorObj.message,
        severity: "error",
        confirmAlert: false,
      });
    }
  } 

  // to add a new service
  aNewApi = () => {
    let apiServs = [...this.state.apiServices];
    apiServs.push({ _id: 1, method: "", usageType:"", userFunction:"" });
    this.setState({
      apiServices: apiServs,
      displayToast: true,
      messageToast: "New Service added in bottom",
      severity: "success",
    });
    this.conversationFormBuilder.reset();
  };

  // to toggle enterprise <-> thridparty api services
  tabapis = () => {
    this.setState({
      activeTabEnterprise: true,
      activeTabThridParty: false,
      activeTabNLU: false,
      apiType: "ENTERPRISE",
    });
    const paramsmet = {
      paginate: true,
      // page: 1,
      type: "ENTERPRISE",
    };
    this.getAllServices(paramsmet);
    this.switchJrnyExpansionpanel();
  };

  // to toggle enterprise <-> thridparty api services
  tab3api = () => {
    this.setState({
      activeTabEnterprise: false,
      activeTabNLU: false,
      activeTabThridParty: true,
      apiType: "THIRD_PARTY",
    });
    const paramsmet = {
      paginate: true,
      // page: this.state.pageNo,
      type: "THIRD_PARTY",
    };
    this.getAllServices(paramsmet);
    this.switchJrnyExpansionpanel();
  };

  tabNLU = () => {
    this.setState({
      activeTabEnterprise: false,
      activeTabNLU: true,
      activeTabThridParty: false,
      apiType: "NLU",
    });
    const paramsmet = {
      paginate: true,
      // page: this.state.pageNo,
      type: "NLU",
    };
    this.getAllServices(paramsmet);
    this.switchJrnyExpansionpanel();
  }

  // to search card of enterprise apis
  searchCardEnterprise = (event) => {
    let searchText = event.target.value;
    const paramsmet = {
      type: "ENTERPRISE",
      filter: {
        name: searchText,
      },
    };
    this.setState({searchTextData: searchText + '.*'},() => 
    this.getAllServices(paramsmet)
    )
  };

  // to search card of thridparty apis
  searchCardThrid = (event) => {
    let searchText = event.target.value;
    const paramsmet = {
      page: this.state.pageNo,
      type: "THIRD_PARTY",
      filter: {
        name: searchText,
      },
    };
    this.setState({searchTextData: searchText + '.*'}, () => 
    this.getAllServices(paramsmet)
    )
  };

  searchNLU = (event) => {
    let searchText = event.target.value;
    const paramsmet = {
      page: this.state.pageNo,
      type: "NLU",
      filter: {
        name: searchText,
      },
    };
    this.setState({searchTextData: searchText + '.*'}, () => 
    this.getAllServices(paramsmet)
    )
  }

  rowPerPageChange = (e) => {
    localStorage.setItem("rowPerPage", e.target.value);
    const paramsmet = {
      perPage: e.target.value,
      type: "ENTERPRISE",
    };
    this.getAllServices(paramsmet);
  };

  // function to go to next Page of Enterprise service
  nextPage = () => {
    let PreviousSetNo = this.state.pageNo + 1;
    this.setState({ pageNo: PreviousSetNo });
    const paramsmet = {
      page: (PreviousSetNo-1) * Number(localStorage.getItem('rowPerPage')),
    };
    this.getAllServices(paramsmet);
  };

  // function to go to previous Page of Enterprise service
  previousPage = () => {
    let PreviousSetNo = this.state.pageNo - 1;
    this.setState({ pageNo: PreviousSetNo });
    const paramsmet = {
      page: (PreviousSetNo-1) * Number(localStorage.getItem('rowPerPage')),
    };
    this.getAllServices(paramsmet);
  };

  // to copy the url from card
  copyText = (e) => {
    let copyText = e.currentTarget.textContent;
    try {
      navigator.clipboard.writeText(copyText);
      this.setState({
        displayToast: true,
        messageToast: "Copied to Clipboard",
        severity: "success",
      });
    } catch (err) {
      this.setState({
        displayToast: true,
        messageToast: "Failed to Copy",
        severity: "error",
      });
    }
  };

  // to toggle is it a form data or not
  serviceFormToggle = (index, value) => {
    let apiServs = [...this.state.apiServices];
    if (!value) {
      apiServs[index].isFormData = true;
      apiServs[index].isUrlEncoded = false;
    } else {
      apiServs[index].isFormData = false;
    }
    this.setState({ apiServices: apiServs });
    this.conversationFormBuilder.patchValue({
      isFormData: apiServs[index].isFormData,
      isUrlEncoded: apiServs[index].isUrlEncoded,
    });
  };

  serviceURLToggle = (index, value) => {
    let apiServs = [...this.state.apiServices];
    if (!value) {
      apiServs[index].isUrlEncoded = true;
      apiServs[index].isFormData = false;
    } else {
      apiServs[index].isUrlEncoded = false;
    }
    this.setState({ apiServices: apiServs });
    this.conversationFormBuilder.patchValue({
      isUrlEncoded: apiServs[index].isUrlEncoded,
      isFormData: apiServs[index].isFormData,
    });
  }

  // header value updating
  rvjheadersChange = (newValue, index) => {
    let apiServs = [...this.state.apiServices];
    this.conversationFormBuilder.value["headers"] = newValue;
    apiServs[index]["headers"] = newValue;
    this.setState({ apiServices: apiServs });
  };

  // json value updating
  rvjBodyJsonChange = (newValue, index) => {
    let apiServs = [...this.state.apiServices];
    this.conversationFormBuilder.value["bodyJson"] = JSON.stringify(newValue);
    apiServs[index]["bodyJson"] = JSON.stringify(newValue);
    this.setState({ apiServices: apiServs });
  };

  // to update the name in input field of seleted card
  changeName = (evt, index) => {
    let apiServs = [...this.state.apiServices];
    apiServs[index].name = evt.target.value;
    if (evt.keyCode === 13) {
      this.serviceFormSubmit(null, index, apiServs[index], true);
    }
    this.setState({
      apiServices: apiServs,
    });
  };

  journetTitleBlur = (index) => {
    let apiServs = [...this.state.apiServices];
    this.serviceFormSubmit(null, index, apiServs[index], true);
  };

  // Close Toast
  closeToast = () => {
    this.setState({ displayToast: false });
  };

  // create / update api data
  serviceFormSubmit = async (e, index, value, isNewForm = false) => {
    e?.preventDefault();
    let apiServs = [...this.state.apiServices];
    let dataId = apiServs[index]["id"];
    let rvjHeaders = apiServs[index]["headers"];
    let rvjBody = apiServs[index]["bodyJson"];
    value.headers = rvjHeaders;
    value.bodyJson = rvjBody;
    value.type = this.state.apiType;
    value.projectId = localStorage.getItem('pId')
    if(value.authenticationHeader == null){
      value.authenticationHeader = '';
    }
    if(value.headers == null){
      value.headers = {};
    }
    if(value.bodyJson == null){
      value.bodyJson = JSON.stringify({});
    }
    if (value.isFormData === null)
    {
      value.isFormData = false
    }
    if (value.isUrlEncoded === null)
    {
      value.isUrlEncoded = false;
    } 
    if(value.oAuthRequired === null)
    {
      value.oAuthRequired = false;
      delete value.oauthConfigId;
      delete value.authenticationHeader;
    }
    if(value.oAuthRequired === true){
      if(value.oauthConfigId === null)
      {
        this.setState({
          displayToast: true,
          messageToast: "OAuth Config ID Required",
          severity: "error",
        });
        return;
      }      
    }

    if(this.state.apiType === 'ENTERPRISE')
    {
      delete value.usageType;
      delete value.userFunction;
    } 
    if(this.state.apiType === 'THIRD_PARTY')
    {
      delete value.userFunction;
      if(value.usageType === null)
      {
        this.setState({
          displayToast: true,
          messageToast: "Usage Type should be Required",
          severity: "error",
        });
        return;
      }      
    }
    if(this.state.apiType === 'NLU')
    {
      delete value.usageType;
      if(value.userFunction === null)
      {
        this.setState({
          displayToast: true,
          messageToast: "UserFunction should be Required",
          severity: "error",
        });
        return;
      }      
    }
    try {
      this.setState({ loadingCard: true });
      if (!isNewForm) {
        const allOptions = {
          url: serverUrl + "services/" + localStorage.getItem('pId'),
          body: value,
        };
        await saveData(allOptions);
        this.setState({
          displayToast: true,
          messageToast: "Created Successfully",
          severity: "success",
        });
        this.switchJrnyExpansionpanel();
        this.getAllServices();
      } else {
        value.id = dataId;
        delete value.createdBy;
        delete value.createdOn;
        delete value.modifiedOn;
        delete value.inplaceEdit;
        delete value.modifiedBy;
        delete value.id;
        const allOptions = {
          url: serverUrl + "services/" + localStorage.getItem('pId') + '/' + dataId,
          body: {
            ...value,
            projectId: localStorage.getItem("pId")
          },
        };
        await updateDataPut(allOptions);
        this.setState({
          displayToast: true,
          messageToast: "Updated Successfully",
          severity: "success",
          apiServices: apiServs,
        });
        this.switchJrnyExpansionpanel();
        this.getAllServices();
      }
      this.setState({ loadingCard: false });
    } catch (e) {
      const errorObj = FindError(e)
      this.setState({
        displayToast: true,
        messageToast: errorObj.message,
        severity: "error",
        confirmAlert: false,
        loadingCard: false
      });
    }
  };

  // to remove a service show confirmation
  removeService = (index, serviceId) => {
    let apiServs = [...this.state.apiServices];
    if (serviceId) {
      this.setState({
        confirmAlert: true,
        apiServicesNIndex: index,
        serviceApiId: serviceId,
      });
    } else {
      apiServs.splice(index, 1);
      this.setState({ apiServices: apiServs });
    }
  };

  onConfirmButtonClick = () => {
    this.confirmBtnDelete(this.state.serviceApiId);
  };

  // to close confirmation dialogue
  cancelBtn = () => {
    this.setState({ confirmAlert: false });
  };

  // function to delete selected service using api
  confirmBtnDelete = async (id) => {
    this.setState({ confirmAlert: true });
    try {
      const allOptions = {
        url: serverUrl + "services/" + localStorage.getItem('pId') + '/'+ id,
      };
      await deleteData(allOptions);
      this.setState({
        displayToast: true,
        messageToast: "Deleted Successfully",
        severity: "success",
        confirmAlert: false
      });
    } catch (e) {
      const errorObj = FindError(e)
      this.setState({
        displayToast: true,
        messageToast: errorObj.message,
        severity: "error",
        confirmAlert: false,
      });
    }
    this.getAllServices();
  };

  // to show input filed inplace edit
  showInPlaceEdit = (index) => {
    let apiServs = [...this.state.apiServices];
    apiServs[index].inplaceEdit = !apiServs[index].inplaceEdit;
    this.setState({ apiServices: apiServs });
  };

  // to show input filed inplace edit
  InputPlaceEdit = (index) => {
    this.showInPlaceEdit(index);
  };

  handleMethod = (e, index) => {
    let apiServs = [...this.state.apiServices];
    apiServs[index].method = e.target.value;
    this.setState({apiServices: apiServs})
    this.conversationFormBuilder.patchValue({
      method: apiServs[index].method,
    });
  }

  handleOAuthConfig = (e, index) => {
    let apiServs = [...this.state.apiServices];
    apiServs[index].oauthConfigId = e.target.value;
    let findSelectedObj = this.state.oauthData.find(x => x.id === e.target.value)
    this.setState({apiServices: apiServs, selectedOAuthData:findSelectedObj})
    this.conversationFormBuilder.patchValue({
      oauthConfigId: apiServs[index].oauthConfigId,
    });
  }

  handleUsageType = (e, index) => {
    let apiServs = [...this.state.apiServices];
    apiServs[index].usageType = e.target.value;
    this.setState({apiServices: apiServs})
    this.conversationFormBuilder.patchValue({
      usageType: apiServs[index].usageType,
    });
  }

  handleUserFunction = (e, index) => {
    let apiServs = [...this.state.apiServices];
    apiServs[index].userFunction = e.target.value;
    this.setState({apiServices: apiServs})
    this.conversationFormBuilder.patchValue({
      userFunction: apiServs[index].userFunction,
    });
  }

  handleOAuthCheck = (i, value) => {
    let apiServs = [...this.state.apiServices];
    apiServs[i].oAuthRequired = !value;
    this.setState({apiServices: apiServs})
    this.conversationFormBuilder.patchValue({
      oAuthRequired: apiServs[i].oAuthRequired,
    });
  }

  cancelExpand = () => {
    this.setState({
      expansionJrnyPanelOpen: false,
      journeyIndex: "",
      value: 0,
    });
  };

  bannerHeader = () => {
    return (
      <div className="display-flex table_head font13 fontWeight700 cardFull cardSpaceArrow">
        <div className="table_grid">
          <p>API Name </p>
        </div>
        <div className="table_grid">
          <p>URL</p>
        </div>
        <div className="table_grid">
          <p>Created By</p>
        </div>
        <div className="table_grid">
          <p>Created on</p>
        </div>
        {this.state.activeTabThridParty ? (
          <div className="table_grid">
            <p className="">Paid/Free</p>
          </div>
        ) : null}
        <div className="table_grid">
          <p>Last Modified on</p>
        </div>
      </div>
    );
  };

  bannerCard = (item, index) => {
    return (
      <div className="display-flex text_black font13 w100 pr15 pl15">
        <LoaderSpinner loadingCard={this.state.loadingCard} />
        {item.name ? (
          <div className="table_grid">
            {item.inplaceEdit !== false ? (
              <div>
                <p
                  id={`apiServiceName${index}`}
                  className={`in-block ${
                    this.state.permissions ? "" : "disable-click"
                  }`}
                  onClick={() => this.InputPlaceEdit(index)}
                >
                  {item.name}
                </p>
                &nbsp;
                {this.state.permissions ? (
                  <img
                    id={`apiServiceEditImg${index}`}
                    alt="editIcon"
                    src={jrnyEdit}
                    className="pointer-coursor"
                    onClick={() => this.showInPlaceEdit(index)}
                  />
                ) : null}
              </div>
            ) : (
              <input
                type="text"
                value={item.name}
                name="name"
                style={{
                  height: "34px",
                  border: "1px solid #999",
                  borderRadius: "8px",
                }}
                id={`apiServiceInput${index}`}
                onChange={(e) => this.changeName(e, index)}
                onBlur={() => this.journetTitleBlur(index)}
                onKeyDown={(e) => this.changeName(e, index)}
              />
            )}
          </div>
        ) : (
          <p style={{ fontSize: "13px" }}>New Service</p>
        )}
        <div className="table_grid">
          <Tooltip title={item.url}>
            <p className="overflowTextdots" style={{width:'170px'}} id={`apiServiceOnCopy${index}`} onClick={this.copyText}>
              {item.url}
            </p>
          </Tooltip>
        </div>
        <div className="table_grid">
          <p className="">{item.createdBy} </p>
        </div>
        <div className="table_grid">
          <p>
            <img src={calender} className="vertical-7" alt="calendar Icon" />
            <span
              style={{
                color: "#000",
                marginTop: "6px",
              }}
            >
              {moment(item.createdOn).format("DD-MMM-YYYY")}
            </span>
          </p>
        </div>
        {this.state.activeTabThridParty ? (
          <div className="table_grid">
            <p className="">{item.usageType}</p>
          </div>
        ) : null}
        <div className="table_grid">
          <p className="flex" style={{ marginTop: "8px" }}>
            <img src={calender} className="vertical-7" alt="calendar Icon" />
            <span
              style={{
                color: "#000",
                marginTop: "6px",
              }}
            >
              {moment(item.modifiedOn).format("DD-MMM-YYYY")}
            </span>
          </p>
        </div>

        <img
          src={deletewhiteIcon}
          alt="trash Icon"
          id={`apiServiceDeleteImg${index}`}
          onClick={() => this.removeService(index, item.id)}
          className="deleteIcon"
        />
      </div>
    );
  };

  render() {    
    return (
      <div className="parent mt10">
        {this.state.confirmAlert === true && (
          <ConfirmAlert
            onConfirmBtn={this.onConfirmButtonClick}
            onCancelBtn={this.cancelBtn}
            heading="Are you Sure?"
            paragraph="If you proceed you will loose the data. Are you sure you want to delete ?"
          />
        )}
        <PositionedSnackbar
          display={this.state.displayToast}
          msg={this.state.messageToast}
          closeToast={this.closeToast}
          severity={this.state.severity}
        />
        <div className="display-flex">
          <div style={{flex:1}}>
            <h4 className="mt0 in-block">
              APIs <span className="label_pink">Configure the APIs</span>
            </h4>
            <div className="twotabsUI in-block">
              <ul className="p0 m0">
                <li
                  onClick={this.tabapis}
                  id="enterPriseTypeTab"
                  className={`${
                    this.state.activeTabEnterprise ? "activeApi" : ""
                  }`}
                >
                  Enterprise APIs
                </li>
                <li
                  id="thirdPartyTypeTab"
                  onClick={this.tab3api}
                  className={`${
                    this.state.activeTabThridParty ? "active3Api" : ""
                  }`}
                >
                  Third Party APIs
                </li>
                <li
                  id="NLUTypeTab"
                  onClick={this.tabNLU}
                  className={`${
                    this.state.activeTabNLU ? "activeNLUApi" : ""
                  }`}
                >
                  NLU APIs
                </li>
              </ul>
            </div>
          </div>
          <div>
            {this.state.activeTabEnterprise  && (
              <>
                <div className="pos-rel in-block searchFilter">
                  <img
                    alt="SearchIcon"
                    src={searchIcon}
                    className="searchIcon"
                  />
                  <input
                    type="text"
                    id="searchEnterpriceApi"
                    placeholder="Search API Name"
                    onChange={this.searchCardEnterprise}
                  />
                </div>
                {this.state.permissions ? (
                  <button
                    className="sxp-btn btn-primary"
                    id="createNewEnterpriseAPI"
                    onClick={this.aNewApi.bind(this)}
                  >
                    <AddIcon className="vertical-7" /> CREATE API
                  </button>
                ) : null}
              </>
            )}
            {this.state.activeTabThridParty && (
              <>
                <div className="pos-rel in-block searchFilter">
                  <img
                    alt="searchIcon"
                    src={searchIcon}
                    className="searchIcon"
                  />
                  <input
                    type="text"
                    id="searchThirdPartyApi"
                    placeholder="Search API Name"
                    onChange={this.searchCardThrid}
                  />
                </div>
                {this.state.permissions ? (
                  <button
                    className="sxp-btn btn-primary"
                    style={{ backgroundColor: "#F58819" }}
                    onClick={this.aNewApi.bind(this)}
                    id="createNewThirdPartyAPI"
                  >
                    <AddIcon className="vertical-7" /> CREATE API
                  </button>
                ) : null}
              </>
            )}

            {this.state.activeTabNLU && (
              <>
              <div className="pos-rel in-block searchFilter">
                <img
                  alt="searchIcon"
                  src={searchIcon}
                  className="searchIcon"
                />
                <input
                  type="text"
                  id="searchNLUApi"
                  placeholder="Search API Name"
                  onChange={this.searchNLU}
                />
              </div>
              {this.state.permissions ? (
                <button
                  className="sxp-btn btn-primary"
                  style={{ backgroundColor: "#14b3d7" }}
                  onClick={this.aNewApi.bind(this)}
                  id="createNewNLUAPI"
                >
                  <AddIcon className="vertical-7" /> CREATE API
                </button>
              ) : null}
            </>
            )}
          </div>
        </div>

        <div className="enterpriseApi">
          
          {this.bannerHeader()}
          {this.state.apiServices.length ? (
            <div>
              {this.state.apiServices.map((item, index) => {
                let bodyJson;
                try{
                  bodyJson = JSON.parse(item.bodyJson);
                }
                catch(e){
                  bodyJson = item.bodyJson || {};
                }
                return(
                <ExpansionPanel
                  className="CustomexpansionPannel"
                  key={item.id + index}
                  expanded={
                    this.state.expansionJrnyPanelOpen &&
                    this.state.journeyIndex === index
                  }
                >
                  <ExpansionPanelSummary
                    expandIcon={
                      <ExpandMoreIcon
                        className="iconExpand"
                        id={`expansionArrow${index}`}
                        onClick={this.switchJrnyExpansionpanel.bind(
                          this,
                          index
                        )}
                      />
                    }
                    style={{ order: -1 }}
                    className="boxShadow"
                  >
                    {this.bannerCard(item, index)}
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails className="inner_expandBlock">
                    <FieldGroup
                      control={this.conversationFormBuilder}
                      render={({ invalid, value }) => (
                        <form
                          onSubmit={(e) =>
                            this.serviceFormSubmit(
                              e,
                              index,
                              value,
                              item.id ? true : false
                            )
                          }
                        >
                          <div className="">
                            <div className="formField">
                              <div className="flex" style={{flexWrap: 'wrap'}}>
                                <FieldControl
                                  name="name"
                                  options={{ validators: Validators.required }}
                                  render={({ handler, touched, hasError }) => (
                                    <div className="">
                                      <input
                                        {...handler()}
                                        id={`apiServiceName${index}`}
                                        placeholder="Service Name"
                                        className={`${
                                          touched &&
                                          hasError("required") &&
                                          "errorInput"
                                        }`}
                                      />
                                      <span
                                        className={`${
                                          touched &&
                                          hasError("required") &&
                                          "errorFieldMsg"
                                        }`}
                                      >
                                        {touched &&
                                          hasError("required") &&
                                          "Required Filed"}
                                      </span>
                                    </div>
                                  )}
                                />
                                <FieldControl
                                  name="url"
                                  options={{ validators: Validators.required }}
                                  render={({ handler, touched, hasError }) => (
                                    <div className="">
                                      <input
                                        {...handler()}
                                        id={`apiServiceUrl${index}`}
                                        placeholder="Url"
                                        className={`${
                                          touched &&
                                          hasError("required") &&
                                          "errorInput"
                                        }`}
                                      />
                                      <span
                                        className={`${
                                          touched &&
                                          hasError("required") &&
                                          "errorFieldMsg"
                                        }`}
                                      >
                                        {touched &&
                                          hasError("required") &&
                                          "Required Filed"}
                                      </span>
                                    </div>
                                  )}
                                />
                                <FieldControl
                                  name="method"
                                  options={{ validators: Validators.required }}
                                  render={({ handler, touched, hasError }) => (
                                    <div className="pos-rel">                                      
                                      <Select
                                        value={item.method}
                                        id={`apiServiceMethod${index}`}
                                        style={{ width: "194px", marginRight:'38px', marginBottom:'20px' }}
                                        {...handler()}
                                        className={`${
                                          touched &&
                                          hasError("required") &&
                                          "errorInput"
                                        } sxp-defaultDropDown_filed pos-rel`}
                                        onChange={(e)=>this.handleMethod(e, index)}
                                      >
                                        <MenuItem value="" disabled>
                                          Method
                                        </MenuItem>
                                        <MenuItem value="GET">GET</MenuItem>
                                        <MenuItem value="POST">POST</MenuItem>
                                        <MenuItem value="PUT">PUT</MenuItem>
                                        <MenuItem value="DELETE">DELETE</MenuItem>
                                      </Select>
                                      {item.method === "" ?
                                      <FormHelperText className="apiServiceSelectPlaceHolder">
                                        Select Method
                                      </FormHelperText>
                                      : null}
                                      <span
                                        className={`${
                                          touched &&
                                          hasError("required") &&
                                          "errorFieldMsg"
                                        }`}
                                      >
                                        {touched &&
                                          hasError("required") &&
                                          "Required Filed"}
                                      </span>
                                    </div>
                                  )}
                                />
                                <div className="flex">
                                {(this.state.activeTabEnterprise || this.state.activeTabThridParty) &&(
                                  <>
                                <div className="customInputStyle">
                                  <label
                                    className="mt0"
                                    style={{ verticalAlign: "super" }}
                                  >
                                    Is form data
                                  </label>
                                  <div
                                    className={`toggle-parent-modeller insideCard ${
                                      item.isFormData ? "" : "inactiveToggle"
                                    }`}
                                    id={`apiServiceFormData${index}`}
                                    style={{ marginTop: "3px" }}
                                    onClick={() =>
                                      this.serviceFormToggle(
                                        index,
                                        item.isFormData
                                      )
                                    }
                                  >
                                    <div
                                      className={` ${
                                        item.isFormData
                                          ? "toggle-button"
                                          : " disabled-toggle"
                                      }`}
                                    ></div>
                                  </div>
                                </div>

                                <div className="customInputStyle">
                                  <label
                                    className="mt0"
                                    style={{ verticalAlign: "super" }}
                                  >
                                    Is Url Encoded
                                  </label>
                                  <div
                                    className={`toggle-parent-modeller insideCard ${
                                      item.isUrlEncoded ? "" : "inactiveToggle"
                                    }`}
                                    id={`apiServiceUrlEncoded${index}`}
                                    style={{ marginTop: "3px" }}
                                    onClick={() =>
                                      this.serviceURLToggle(
                                        index,
                                        item.isUrlEncoded
                                      )
                                    }
                                  >
                                    <div
                                      className={` ${
                                        item.isUrlEncoded
                                          ? "toggle-button"
                                          : " disabled-toggle"
                                      }`}
                                    ></div>
                                  </div>
                                </div>
                                </>
                              )}
                              <div className="customInputStyle">
                                  <label className="mt0" style={{ verticalAlign: "super" }}> Is OAuth </label>
                                  <div 
                                    className={`toggle-parent-modeller insideCard ${item.oAuthRequired ? "" : "inactiveToggle"}`}
                                    id={`apiServiceoAuthRequired${index}`}
                                    style={{ marginTop: "3px" }}
                                    onClick={() =>
                                      this.handleOAuthCheck(
                                        index,
                                        item.oAuthRequired
                                      )
                                    }
                                  >
                                    <div
                                      className={` ${
                                        item.oAuthRequired
                                          ? "toggle-button"
                                          : " disabled-toggle"
                                      }`}
                                    ></div>
                                  </div>
                                </div>

                                <div className="customInputStyle">
                                {item.oAuthRequired && 
                                <div className="flex">
                                  <FieldControl
                                    name="oauthConfigId"
                                    options={{ validators: Validators.required }}
                                    render={({ handler, touched, hasError }) => (
                                      <div className="pos-rel">                                      
                                        <Select
                                          value={item.oauthConfigId}
                                          id={`apiServiceMethod${index}`}
                                          style={{ width: "194px", marginRight:'38px' }}
                                          {...handler()}
                                          className={`${
                                            touched &&
                                            hasError("required") &&
                                            "errorInput"
                                          } sxp-defaultDropDown_filed pos-rel`}
                                          onChange={(e)=>this.handleOAuthConfig(e, index)}
                                        >
                                          <MenuItem value="" disabled>
                                          Select OAuth Config Id
                                          </MenuItem>
                                          {this.state.oauthData.length ? (
                                            this.state.oauthData.map((oauthObj, i) => (
                                              <MenuItem value={oauthObj.id} key={i}>{oauthObj.name}</MenuItem>
                                            ))                                          
                                          ): <MenuItem value="">~ No OAuth ~</MenuItem> }

                                        </Select>
                                        {item.oauthConfigId === "" ?
                                        <FormHelperText className="apiServiceSelectPlaceHolder">
                                          Select OAuth Config Id
                                        </FormHelperText>
                                        : null}
                                        <span
                                          className={`${
                                            touched &&
                                            hasError("required") &&
                                            "errorFieldMsg"
                                          }`}
                                        >
                                          {touched &&
                                            hasError("required") &&
                                            "Required Filed"}
                                        </span>
                                      </div>
                                    )}
                                  />

                                  <FieldControl
                                  name="authenticationHeader"
                                  options={{ validators: Validators.required }}
                                  render={({ handler, touched, hasError }) => (
                                    <div className="">
                                      <input
                                        {...handler()}
                                        id={`apiServiceName${index}`}
                                        placeholder="authenticationHeader"
                                        className={`${
                                          touched &&
                                          hasError("required") &&
                                          "errorInput"
                                        }`}
                                      />
                                      <span
                                        className={`${
                                          touched &&
                                          hasError("required") &&
                                          "errorFieldMsg"
                                        }`}
                                      >
                                        {touched &&
                                          hasError("required") &&
                                          "Required Filed"}
                                      </span>
                                    </div>
                                  )}
                                />
                                </div>
                                }
                              </div>
                              </div>
                              </div>
                              
                              {this.state.activeTabThridParty && (
                                <div className="pos-rel">
                                <FieldControl
                                  name="usageType"
                                  options={{ validators: Validators.required }}
                                  render={({ handler, touched, hasError }) => (
                                    <div
                                      className=""
                                      style={{ marginTop: "10px" }}
                                    >
                                      <Select
                                        {...handler()}
                                        id={`apiServiceUsageType${index}`}
                                        onChange={(e)=>this.handleUsageType(e, index)}
                                        className={`${
                                          touched &&
                                          hasError("required") &&
                                          "errorInput"
                                        } sxp-defaultDropDown_filed`}
                                      >
                                        <MenuItem value="" disabled>
                                          Usage Type
                                        </MenuItem>
                                        <MenuItem value="Paid">Paid</MenuItem>
                                        <MenuItem value="Free">Free</MenuItem>
                                      </Select>
                                      {item.usageType === "" ? 
                                      <FormHelperText className="apiServiceSelectPlaceHolder">
                                        Select Usage Type
                                      </FormHelperText>
                                      : null}
                                      <span
                                        className={`${
                                          touched &&
                                          hasError("required") &&
                                          "errorFieldMsg"
                                        }`}
                                      >
                                        {touched &&
                                          hasError("required") &&
                                          "Required Filed"}
                                      </span>
                                    </div>
                                  )}
                                />

                                </div>
                              )}
                              {this.state.activeTabNLU && (
                                <div className="pos-rel">
                                <FieldControl
                                name="userFunction"
                                options={{ validators: Validators.required }}
                                render={({ handler, touched, hasError }) => (
                                  <div
                                    className=""
                                    style={{ marginTop: "10px" }}
                                  >
                                    <Select
                                      {...handler()}
                                      onChange={(e)=>this.handleUserFunction(e, index)}
                                      id={`apiServiceuserFunction${index}`}
                                      className={`${
                                        touched &&
                                        hasError("required") &&
                                        "errorInput"
                                      } sxp-defaultDropDown_filed`}
                                    >
                                      <MenuItem value="" disabled>
                                        User Function
                                      </MenuItem>
                                      {this.state.userFunctionData.map((fnctn, fnctnI) => (
                                      <MenuItem value={fnctn}>{fnctn}</MenuItem>
                                      ))}
                                    </Select>
                                    {item.userFunction === "" ? 
                                      <FormHelperText className="apiServiceSelectPlaceHolder">
                                        Select User Function
                                      </FormHelperText>
                                      : null}
                                    <span
                                      className={`${
                                        touched &&
                                        hasError("required") &&
                                        "errorFieldMsg"
                                      }`}
                                    >
                                      {touched &&
                                        hasError("required") &&
                                        "Required Filed"}
                                    </span>
                                  </div>
                                )}
                              />
                              </div>
                              )}
                              <div className="JsonHeader">
                                <label>Headers</label>
                                <ReactJson
                                  rjvId={`apiServiceJSONHeader${index}`}
                                  src={item.headers || {}}
                                  className="rvjStyles"
                                  name={false}
                                  onAdd={(e) =>
                                    this.rvjheadersChange(e.updated_src, index)
                                  }
                                  displayDataTypes={false}
                                  defaultValue={""}
                                  enableClipboard={false}
                                  onEdit={(e) =>
                                    this.rvjheadersChange(e.updated_src, index)
                                  }
                                  onDelete={(e) =>
                                    this.rvjheadersChange(e.updated_src, index)
                                  }
                                />
                              </div>
                              <div className="JsonHeader">
                                <label>Body JSON</label>
                                <ReactJson
                                  id={`apiServiceBodyJson${index}`}
                                  src={ bodyJson }
                                  name={false}
                                  onAdd={(e) =>
                                    this.rvjBodyJsonChange(e.updated_src, index)
                                  }
                                  displayDataTypes={false}
                                  defaultValue={""}
                                  enableClipboard={false}
                                  onEdit={(e) =>
                                    this.rvjBodyJsonChange(e.updated_src, index)
                                  }
                                  onDelete={(e) =>
                                    this.rvjBodyJsonChange(e.updated_src, index)
                                  }
                                />
                              </div>
                            </div>
                            {item.oAuthRequired &&                             
                              <div className="OauthSelectedData">
                                <h4>Selected OAuth:</h4>
                                <p>ServerUrl: <span>{this.state.selectedOAuthData?.serverUrl}</span></p> 
                                <p>Token EndPoint: <span>{this.state.selectedOAuthData?.tokenEndpoint}</span></p>
                                <p>Client ID: <span>{this.state.selectedOAuthData?.clientId}</span></p>
                                <p>Scopes: <span>{JSON.stringify(this.state.selectedOAuthData?.scopes)}</span></p>
                              </div>
                            }
                            <div className="buttonClmns footer text-center">
                              <button
                                className={`sxp-btn btn-primary primaryLarge ml5 mr5 ${
                                  invalid && "disablePagBtn"
                                }`}
                                id={`apiServiceSubmit${index}`}
                                type="submit"
                                style={{ height: "34px" }}
                                disabled={invalid}
                              >
                                Save
                              </button>
                              <span
                                onClick={() => this.cancelExpand(index)}
                                id={`apiServiceCancelBtn${index}`}
                                className="sxp-btn btn-danger primaryLarge ml5 mr5"
                                style={{ height: "34px", width: "220px",
                                textAlign: "center",
                                margin: "0 5px",
                                padding: "9px 0",
                                display: "inline-block",
                                verticalAlign: "top", }}
                              >
                                Cancel
                              </span>
                            </div>
                          </div>
                        </form>
                      )}
                    />
                  </ExpansionPanelDetails>
                </ExpansionPanel>                
              )})}
            </div>
          ) : (
            <EmptyCard />
          )}
        </div>
        <Pagination
          pageNo={this.state.pageNo}
          totalPages={this.state.totalPages}
          rowPerPageChange={this.rowPerPageChange}
          previousPage={this.previousPage}
          nextPage={this.nextPage}
        />
      </div>
    );
  }
}

export default ApiServices;
