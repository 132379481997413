import React, { Component } from "react";
import PositionedSnackbar from "../../commons/uicomponents/toast";
import { getData, saveData } from "../../dataProvider/apiHandler";
import FindError from "../../dataProvider/errorHandler";
import AddIcon from "@material-ui/icons/Add";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import moment from "moment";
import EmptyCard from "../../commons/uicomponents/emptycard";
import LoaderSpinner from "../../commons/uicomponents/loader";
import calender from "../../assets/images/calender.png";
import "./versioning.scss";
import Modal from "./modal";
import TranslationTable from "./translation";
import { ExpansionPanelDetails } from "@material-ui/core";

const serverUrl = window._env_.REACT_APP_SXP_URL;

class Versioning extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingCard: false,
      versions: [],
      expandedAppDetails:[],
      modalToggle: false,
      fullFormsList: [],
      loaderFlag: false,
      langArr: [],
      addNewDialogue: false,
      textAreaPublishNote:"",
      passingObj : {
        projectId: localStorage.getItem("pId"),
        version: localStorage.getItem("version"),
      }
    };
  }

  

  componentDidMount = () => {
    this.getAllVersHistry();
    this.languageFullForms();
  };

  getAllVersHistry = async () => {
    this.setState({ loadingCard: true });
    try {
      const allOption = {
        url: serverUrl + "version/" + localStorage.getItem("pId") + "/version-history",
      };
      let initialLoad = await getData(allOption);
      this.setState({ versions: initialLoad.data.data.docs, loadingCard: false });
    } catch (e) {
      const errorObj = FindError(e);
      this.setState({
        displayToast: true,
        messageToast: errorObj.message,
        severity: "error",
        confirmAlert: false,
        loadingCard: false,
        showModalContent: false,
      });
    }
  };

  languageFullForms = async() => {
      try{
        const allOption = {
            url: serverUrl + "languages/" + this.state.passingObj.projectId,
        };
        const res = await getData(allOption);
        this.setState({
        fullFormsList: res.data.data,
        });
      }
      catch(e){
        const errorObj = FindError(e)
        this.setState({
          displayToast: true,
          messageToast: errorObj.message,
          severity: "error",
          confirmAlert: false,
        });
      }
  }

  publishAppDetails = () => {
    this.setState({ addNewDialogue: !this.state.addNewDialogue });
  };

  modalHandler = async(e) => {
    e.preventDefault();
    if (!this.state.modalToggle) {
        this.setState({ loaderFlag: true, modalToggle: true });
        const allOption = {
            url: serverUrl + "components/translations/" + this.state.passingObj.projectId,
        };
        const values = await getData(allOption); // api call to get lang translations
        this.setState({
            loaderFlag: false,
            showModalContent: true,
            langArr: values.data.data,
        });
    }
    else{
        this.setState({ modalToggle: false })
    }
  }

  textAreaHandle = (e) => {
      this.setState({ textAreaPublishNote:e.target.value })
  }

  submitPublish = async() => {
      this.setState({loadingCard:true});
      let obj = {
          ...this.state.passingObj,
          releaseNotes: this.state.textAreaPublishNote,
          description:this.state.textAreaPublishNote
      }
      try{
        const allOption = {
            url: serverUrl + "versions/" +localStorage.getItem('pId')+ "/release",
            body: obj,
          };
          await saveData(allOption);
          this.setState({
            displayToast: true,
            messageToast: "Created Successfully",
            severity: "success",
            loadingCard: false,
            addNewDialogue: false,
            textAreaPublishNote:'',
        });
      }
      catch(e){
        const errorObj = FindError(e)
        this.setState({
          displayToast: true,
          messageToast: errorObj.message,
          severity: "error",
          confirmAlert: false,
          loadingCard: false
        });
      }
      this.getAllVersHistry();
  }

  closeToast = () => {
    this.setState({ displayToast: false });
  };

  publishRelease = async(callBackdata, metaData) => {
    if (this.state.fullFormsList.length >= 1) {
        this.setState({ loaderFlag: true, showModalContent: false });
    } else {
        this.setState({ loadingCard: true });
    }
    let obj = {
        ...this.state.passingObj,
        metadata:metaData,
        releaseNotes : callBackdata || this.state.textAreaPublishNote,
        description : callBackdata || this.state.textAreaPublishNote, 
    }
    try{
        const allOption = {
            url: serverUrl + "versions/"+localStorage.getItem('pId')+"/release",
            body: obj,
        };
        await saveData(allOption);
        if (this.state.fullFormsList.length >= 1) {
            this.setState({
              loaderFlag: false,
              modalToggle: false,
              displayToast: true,
              messageToast: "Version Released Successfully",
              severity: "success",
            });
          } else {
            this.setState({
              displayToast: true,
              messageToast: "Created Successfully",
              severity: "success",
              loadingCard: false,
            });
        }
        this.getAllVersHistry();
    }
    catch(e)
    {
      const errorObj = FindError(e)
      this.setState({
        displayToast: true,
        messageToast: errorObj.message,
        severity: "error",
        confirmAlert: false,
        loadingCard: false
      });   
    }
  }

  langUpdateHandler = async(callBackdata) => {
    try{
      this.setState({ loaderFlag: true, showModalContent: false });
      const allOption = {
        url: serverUrl + "components/translations/"+localStorage.getItem('pId')+"/update",
        body: Object.assign({}, { 
          modifiedBy: this.state.passingObj.userid,
          components: callBackdata 
        }),
      };
      const res = await saveData(allOption);
      if (res?.data?.message === "Successfully saved the translated components") {
        this.setState({
          loaderFlag: false,
          showModalContent: true,
          displayToast: true,
          messageToast: "Successfully Updated",
          severity: "success",
        });
      } else {
        this.setState({
          loaderFlag: false,
          showModalContent: true,
          displayToast: true,
          messageToast: "Could Not Update",
          severity: "error",
        });
      }
    }
    catch(e){
      const errorObj = FindError(e)
      this.setState({
        displayToast: true,
        messageToast: errorObj.message,
        severity: "error",
        confirmAlert: false,
      }); 
    }
  }

  bannerHeader = () => {
    return (
      <div className="display-flex table_head font13 p15 fontWeight700 cardSpaceArrow">
        <div className="table_grid">
          <p>Release Version</p>
        </div>
        <div className="table_grid">
          <p>Release Notes</p>
        </div>
        <div className="table_grid">
          <p>Published By</p>
        </div>
        <div className="table_grid">
          <p>Published on</p>
        </div>
        <div className="table_grid">
          <p>MetaData</p>
        </div>
      </div>
    );
  };

  bannerCard = (item, index) => {
    return (
      <div className="display-flex text_black font13 w100 pr15 pl15">
        <LoaderSpinner loadingCard={this.state.loadingCard} />
        <div className="table_grid">
          <p>{item.version}</p>
        </div>
        <div className="table_grid">
          <p className="overflowTextdots">{item.releaseNotes}</p>
        </div>
        <div className="table_grid">
          <p className="flex" style={{ marginTop: "8px" }}>
            <img src={calender} alt="calendar Icon" />
            <span
              style={{ color: "#000", marginTop: "5px" }}
              className="date-text"
            >
              {moment(item.createdOn).format("DD-MMM-YYYY")}
            </span>
          </p>
        </div>
        <div className="table_grid">
          <p className="flex" style={{ marginTop: "8px" }}>
            <img src={calender} alt="calendar Icon" />
            <span
              style={{ color: "#000", marginTop: "5px" }}
              className="date-text"
            >
              {moment(item.modifiedOn).format("DD-MMM-YYYY")}
            </span>
          </p>
        </div>
      </div>
    );
  };

  render() {
    return (
      <div className="parent mt10">
        <PositionedSnackbar
          display={this.state.displayToast}
          msg={this.state.messageToast}
          closeToast={this.closeToast}
          severity={this.state.severity}
        />
        <div className="display-flex">
          <div>
            <h4 className="m0 in-block">
              Release Versions &nbsp;
              <span className="label_pink">Configure the Release</span>
            </h4>
          </div>
          <div className="pos-rel">
            <button
              className="sxp-btn btn-primary"
              id="publishButton"
              onClick={(e) => {
                if (this.state.fullFormsList.length >= 1) {
                  this.modalHandler(e);
                } else {
                  this.publishAppDetails();
                }
              }}
            >
              <AddIcon className="vertical-7" /> Publish
            </button>
            {this.state.addNewDialogue && (
                <div className="modalCard">
                    <h4 className="m0">Notes</h4>
                    <textarea onChange={(e) => this.textAreaHandle(e)}></textarea>
                    <button onClick={this.submitPublish} className="sxp-btn btn-primary w100">PUBLISH</button>
                </div>
            )}
          </div>
        </div>
        <div className="enterpriseApi">
          {this.bannerHeader()}
          {this.state.versions.length ? (
            <div className="flex-jrny pos-rel">
              {this.state.versions.map((item, index) => (
                <ExpansionPanel
                  className="CustomexpansionPannel"
                  key={item.id + index}
                  expanded
                >
                    <ExpansionPanelSummary className="boxShadow">
                        {this.bannerCard(item, index)}
                    </ExpansionPanelSummary>
                    {item.metadata?.length ? (                    
                    <ExpansionPanelDetails className="inner_expandBlock versnInnerBlock">
                        <div className="innerCardBody">
                           <div className="text-center">
                              <div className="" style={{margin:'0 auto'}}>
                                <h3>Meta Data Details</h3>
                                <div className="verMetaDa text-center">
                                  <div className="labelsGrid text-left">
                                    <span>MetaData Label</span>
                                    <span>MetaData Value</span>
                                  </div>
          
                                  {item.metadata?.map((dataa, indx) => (
                                    <div className="formField">
                                      <input type="text" placeholder="Enter Label" disabled value={dataa.label} name="label" onChange={(e) => this.handleMetaDataLabel(e, indx)}/>
                                      <input type="text" placeholder="Enter Value" disabled value={dataa.value} name="value" onChange={(e) => this.handleMetaDataValue(e, indx)}/>
                                    </div>
                                  ))}
                                  <div style={{
                                    alignItems: "center",
                                    justifyContent: "center",
                                    display: "flex",
                                    paddingTop: "1.6vh",
                                  }}
                                >
                                </div>
                              </div>
                              </div>
                           </div>
                        </div> 
                    </ExpansionPanelDetails> 
                    ):null}
                </ExpansionPanel>
              ))}
            </div>
          ) : (
            <EmptyCard />
          )}

          {this.state.modalToggle && (
            <div className="customModalStyles">
                <Modal
                    show={this.state.modalToggle}
                    modalClosed={this.modalHandler}
                    modalContent={
                    this.state.showModalContent ? (
                        <TranslationTable 
                            fullFormsList={this.state.fullFormsList} 
                            langArr={this.state.langArr} 
                            handlePublishReleaseCallBack={this.publishRelease}   
                            langUpdateHandlerCallBack={this.langUpdateHandler}
                        />
                    ) : (
                        <div style={{ height: "100vh" }}>
                        <LoaderSpinner loadingCard={this.state.loaderFlag} />
                        </div>
                    )
                    }
                />
            </div>
           )}
        </div>
      </div>
    );
  }
}

export default Versioning;
