import React, { Component } from "react";
import { BrowserRouter as Router, withRouter } from "react-router-dom";
import { createBrowserHistory } from "history";
import HeaderComponent from "../commons/header";
import LeftMenu from "../commons/sidebar";
import { connect } from "react-redux";
import FirstProject from "../commons/header/firstProject/firstProject";

const history = createBrowserHistory();
history.listen((location, action) => {
  // console.log("inside");
  // console.log(`new location via ${action}`, location);
});

class DashboardRenderer extends Component {
  
  state = {
    showFirstProject : false,
    callHeaderCom:{},
  }

  handleIsFirstProj=(isFirstProject)=>{
    this.setState({showFirstProject: isFirstProject })
  }

  checkResponse = (callBackRes) => {
    this.setState({showFirstProject: callBackRes })
    this.setState({callHeaderCom:{'value':true}})
  }

  render() {
    const { children } = this.props;
    return (
      <div>
        <Router history={history}>
          <HeaderComponent callBackToHeader={this.handleIsFirstProj} callHeder={this.state.callHeaderCom}/>
          <LeftMenu />
          {this.state.showFirstProject && <FirstProject callBackRes = {this.checkResponse}/> }
          {!this.state.showFirstProject && <div className={`layoutContent  ${localStorage.getItem('smallSidbar') === 'true' ? '' : "largeLayoutContent"}`}>{children}</div>}
        </Router>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state,
});
export default withRouter(connect(mapStateToProps)(DashboardRenderer));
