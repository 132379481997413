import React, { Component } from "react";
import "./analytics.scss";
import SuperSetFrame from "./supersetframe";


class AnalyticsDashboard extends Component {
  
  render() {
    return (
      <div className="dashboardComponent">
        <div className="heading display-flex">
          <h3 className="m0">Analytics Overview</h3>
        </div>
        <SuperSetFrame />
      </div>
    );
  }
}
export default AnalyticsDashboard;
