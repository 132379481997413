import axios from 'axios';
import UserService from './auth/KeycloakLogin';

const HttpMethods = {
    GET: 'GET',
    POST: 'POST',
    DELETE: 'DELETE',
    PUT: 'PUT'
};

const _axios = axios.create();

const configure = () => {
    _axios.interceptors.request.use((config) => {
        if (UserService.isLoggedIn()) {
            const cb = () => {
              sessionStorage.setItem('react-token', UserService.getToken());
              config.headers.Authorization = `Bearer ${UserService.getToken()}`;
              return Promise.resolve(config);
            };
            return UserService.updateToken(cb);
        }
    })
}

const getAxiosClient = () => _axios;

const HttpService = {
    HttpMethods,
    configure,
    getAxiosClient
};

export default HttpService;
