import React, { Component } from "react";
import "./uicomponent.scss";

class EmptyCard extends Component {
  render() {
    return (
      <div className="emptyCard">
        <h5> ~ No Data ~ </h5>
      </div>
    );
  }
}

export default EmptyCard;
