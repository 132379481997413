import React, { useEffect, useState } from "react";
import "./FunctionPreview.css";
import UFEditor from "../../../commons/uf-editor";
import { getSingleData, saveData } from "../../../dataProvider/apiHandler";

const serverUrl = window._env_.REACT_APP_SXP_URL;

const FunctionPreview = ({ functionKey, onCreate }) => {
  const [showPreview, setShowPreview] = useState(false);
  const [showEditor, setShowEditor] = useState(false);
  const [functionName, setFunctionName] = useState("");
  const [mode, setMode] = useState("");
  const [funcData, setFuncData] = useState("");

  useEffect(() => {
    if (functionKey) {
      setMode("edit");
    } else {
      setMode("create");
    }
  }, [functionKey]);

  const openPreview = async () => {
    await loadData();
    if (mode === "edit") {
      setShowEditor(true);
    } else {
      setShowEditor(false);
    }
    setShowPreview(true);
  };

  const hidePreview = (event) => {
    if (event.target !== event.currentTarget) return;
    setFunctionName("");
    setShowPreview(false);
  };

  const loadData = async () => {
    try {
      const allOptions = {
        url:
          serverUrl +
          "be/userfunctions/" +
          localStorage.getItem("pId") +
          "/functions/" +
          localStorage.getItem("version"),
        params: {
          version: localStorage.getItem("version"),
          projectId: localStorage.getItem("pId"),
        },
      };
      let initialLoad = await getSingleData(allOptions);
      setFuncData(initialLoad.data.data);
    } catch (e) {
      console.log(e);
    }
  };

  const onChange = (newValue) => {
    setFuncData(newValue);
  };

  const createNewFunc = (e) => {
    if (functionName) {
      setShowEditor(true);
    }
    e.preventDefault();
  };

  const saveUserFunctions = async () => {
    try {
      const dataObj = {
        fileData: funcData,
        version: localStorage.getItem('version'),
        projectId: localStorage.getItem('pId'),
      };
      const allOptions = {
        url: serverUrl + "be/userfunctions/" + localStorage.getItem('pId'),
        body: {data:dataObj},
      };
      await saveData(allOptions);
      onCreate(functionName);
      setShowPreview(false);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className="wrapper">
      <div className="preview-btn" onClick={openPreview}>
        {functionKey ? "Edit" : "Create"}
      </div>
      {showPreview && (
        <div className="modal" onClick={hidePreview}>
          {showEditor ? (
            <div className="editor-content">
              <UFEditor
                loaded={true}
                funcData={funcData}
                funcName={mode === "edit" ? functionKey : functionName}
                onSave={saveUserFunctions}
                onChange={onChange}
                mode={mode}
              />
            </div>
          ) : (
            <div className="create-content">
              <label>
                <span>Function Name</span>
                <input
                  name="functionName"
                  type="text"
                  value={functionName}
                  onChange={(e) => setFunctionName(e.target.value)}
                />
              </label>
              <button
                className="sxp-btn btn-primary"
                style={{ margin: "10px 0" }}
                onClick={createNewFunc}
                id="createNewFunctionBtn"
              >
                Create
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default FunctionPreview;
