import React, { Component } from "react";
import { Link } from "react-router-dom";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import moment from "moment";
import Slider from "@material-ui/core/Slider";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Picker from "react-month-picker";
import { getData } from "../../dataProvider/apiHandler";
import "./billing.scss";
import "./picker.css";
import Pagination from "../../commons/pagination";
import LoaderSpinner from "../../commons/uicomponents/loader";
import EmptyCard from "../../commons/uicomponents/emptycard";
import filterIcon from "../../assets/images/filter_list.png";
import calender from "../../assets/images/calender.png";
import chevronDown from "../../assets/images/chevron-down.png";
import searchIcon from "../../assets/images/search.png";
import sortIcon from "../../assets/images/sort.png";
import sortUpIcon from "../../assets/images/sorttop.png";
import sortDownIcon from "../../assets/images/sortdown.png";
import closeIcon from "../../assets/images/close-theme.png";

const projectId = localStorage.getItem("pId");
const serverUrl =
  window._env_.REACT_APP_SERVER_URL + `api/projects/` + projectId;

// to set range for amount range
const marks = [
  {
    value: 0,
    label: "0",
  },
  {
    value: 5000,
    label: "5000",
  },
];

// monthbox component element
class MonthBox extends Component {
  constructor(props: any, context: any) {
    super(props, context);
    this.state = { value: this.props.value || "N/A" };
    this._handleClick = this._handleClick.bind(this);
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ value: nextProps.value || "N/A" });
  }
  _handleClick(e) {
    this.props.onClick && this.props.onClick(e);
  }
  render() {
    return (
      <div className="box" onClick={this._handleClick}>
        <label>{this.state.value}</label>
      </div>
    );
  }
}

class BillingSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      billingSummary: [],
      expansionJrnyPanelOpen: false,
      journeyList: [],
      sortNormal: true,
      sortUp: false,
      sortDown: false,
      startDate: "", // startDate  to assign a start date value to state
      endDate: "",
      loadingCard: false,
      showSearchFilter: false,
      amountRange: [0, 0],
      amountValue: "",
      totalAmount: "",
      totalJrnys: "",
      pageNo: 1,
      searchJoutName: "",
      rangeValue: {
        from: { year: moment().format("yyyy"), month: 1 },
        to: { year: moment().format("yyyy"), month: 1 },
      },
      rowPageSize: localStorage.getItem("rowPerPage"),
    };
    this.conversation = React.createRef();
    this.pickRange = React.createRef();
  }

  componentDidMount = () => {
    this.getAllData();
    this.getAllJourneyList();
  };

  // load all journey lists data
  getAllJourneyList = async () => {
    try {
      const allOptions = {
        url: serverUrl + "/tasks/",
      };
      let initialLoad = await getData(allOptions);
      this.setState({ journeyList: initialLoad.data.data });
    } catch (e) {
      console.log(e);
    }
  };

  // load all journeys data
  getAllData = async (paramss) => {
    let startDate = moment(this.state.startDate).format("YYYY-MM-DD");
    let endDate = moment(this.state.endDate).format("YYYY-MM-DD");
    try {
      this.setState({ loadingCard: true });
      // "otherParams" containes page, perpage, pagnation, all available filters object
      const otherParams = {
        page: this.state.pageNo,
        perPage: this.state.rowPageSize,
        filter: {
          ...(this.state.searchJoutName && { name: this.state.searchJoutName }),
          ...(this.state.amountRange[1] && {
            amount: {
              ...{ min: this.state.amountRange[0] },
              ...(this.state.amountRange[1] && {
                max: this.state.amountRange[1],
              }),
            },
          }),
          ...(this.state.startDate && { startDate: startDate }),
          ...(this.state.endDate && { endDate: endDate }),
        },
      };
      paramss = {
        ...otherParams,
        ...paramss,
      };
      const allOptions = {
        url: serverUrl + "/billing-details",
        params: { ...paramss },
      };
      let initialLoad = await getData(allOptions);
      this.setState({
        billingSummary: initialLoad.data.data.docs,
        totalAmount: initialLoad.data.totalAmount,
        totalJrnys: initialLoad.data.totalJourneys,
        totalPages: initialLoad.data.data.pages,
        loadingCard: false,
      });
    } catch (e) {
      console.log(e);
    }
  };

  loadNewData = async () => {
    try {
      const allOptions = {
        url: serverUrl + "/billing-details/latest",
      };
      let initialLoad = await getData(allOptions);
      this.setState({
        billingSummary: initialLoad.data.data.docs,
        totalAmount: initialLoad.data.totalAmount,
        totalJrnys: initialLoad.data.totalJourneys,
        totalPages: initialLoad.data.data.pages,
        loadingCard: false,
      });
    } catch (e) {
      console.log(e);
    }
  };

  // show filter function
  showFilter = () => {
    this.setState({ showSearchFilter: true });
  };
  // close filter function
  closeFilter = () => {
    this.setState({ showSearchFilter: false });
  };

  // to previousPage function
  previousPage = () => {
    let PreviousSetNo = this.state.pageNo - 1;
    this.setState({ pageNo: PreviousSetNo });
    const paramsSet = {
      page: PreviousSetNo,
    };
    this.getAllData(paramsSet);
  };

  // to nextpage function
  nextPage = () => {
    let PreviousSetNo = this.state.pageNo + 1;
    this.setState({ pageNo: PreviousSetNo });
    const paramsSet = {
      page: PreviousSetNo,
    };
    this.getAllData(paramsSet);
  };

  // to normal sort function
  normalSort = () => {
    const paramsSet = {
      sort: { name: "" },
    };
    this.getAllData(paramsSet);
    this.setState({
      sortNormal: true,
      sortUp: false,
      sortDown: false,
    });
  };

  // to ascending sort function
  ascSort = () => {
    const paramsSet = {
      sort: { name: "ASC" },
    };
    this.getAllData(paramsSet);
    this.setState({
      sortNormal: false,
      sortUp: true,
      sortDown: false,
    });
  };

  // to descending sort function
  descSort = () => {
    const paramsSet = {
      sort: { name: "DESC" },
    };
    this.getAllData(paramsSet);
    this.setState({
      sortNormal: false,
      sortUp: false,
      sortDown: true,
    });
  };

  // show number of cards per page
  rowPerPageChange = (e) => {
    const perPage = e.target.value;
    localStorage.setItem("rowPerPage", e.target.value);
    const paramsSet = {
      perPage: e.target.value,
    };
    this.getAllData(paramsSet);
    this.setState({ rowPageSize: perPage });
  };

  // to get autoComplete journey list value
  autoComplete = (e) => {
    let searchValue = e.currentTarget.textContent;
    this.setState({ searchJoutName: searchValue });
  };
  // to set amount range value
  amountRangeSlider = (event, newValue) => {
    this.setState({ amountRange: newValue });
  };

  // month and year picker function to show picker
  _handleClickRangeBox = () => {
    this.pickRange.current.show();
  };
  // month and year picker function to show assign value
  handleRangeChange = () => {};
  // month and year picker function to get selected value after close
  handleRangeDissmis = async (value) => {
    this.setState({ rangeValue: value }, () => {
      let lastday = function (y, m) {
        return new Date(y, m, 0).getDate();
      };
      this.setState({
        startDate:
          this.state.rangeValue.from.year +
          "-" +
          this.state.rangeValue.from.month +
          "-" +
          1,
        endDate:
          this.state.rangeValue.to.year +
          "-" +
          this.state.rangeValue.to.month +
          "-" +
          lastday(2015, this.state.rangeValue.to.month),
      });
    });
  };

  // to remove the amount range from the top prestine filter
  removeAmountrange = async () => {
    this.setState({ amountRange: ["", ""] }, () => {
      this.applyFilter();
    });
  };
  // to remove the journey name from the top prestine filter
  removeJourName = async () => {
    this.setState({ searchJoutName: "" }, () => {
      this.applyFilter();
    });
  };
  // to remove the month range from the top prestine filter
  removeMonthRange = async () => {
    this.setState(
      {
        startDate: "",
        endDate: "",
      },
      () => {
        this.applyFilter();
      }
    );
  };

  // to clear all filter
  clearAll = () => {
    this.removeAmountrange();
    this.removeJourName();
    this.removeMonthRange();
  };

  // to apply filer
  applyFilter = () => {
    this.getAllData();
    this.setState({ showFilterBlock: true, showSearchFilter: false });
  };

  journeyBanner = () => {
    return (
      <div className="display-flex table_head font13 fontWeight700 cardFull cardSpaceArrow">
        <div className="table_grid billSumBody">
          <p>
            Journey Name
            <span className="sortIconbg">
              {this.state.sortNormal ? (
                <img
                  alt="sortNormalIcon"
                  src={sortIcon}
                  id="normalIconClick"
                  onClick={this.ascSort}
                />
              ) : (
                ""
              )}
              {this.state.sortUp ? (
                <img
                  alt="sortDescIcon"
                  src={sortUpIcon}
                  id="descIconClick"
                  onClick={this.descSort}
                />
              ) : (
                ""
              )}
              {this.state.sortDown ? (
                <img
                  alt="sortAscIcon"
                  src={sortDownIcon}
                  id="ascIconClick"
                  onClick={this.normalSort}
                />
              ) : (
                ""
              )}
            </span>
          </p>
        </div>
        <div className="table_grid billSumBody">
          <p>Billing Date</p>
        </div>
        <div className="table_grid text-center billSumBody">
          <p>No of Journeys</p>
        </div>
        <div className="table_grid text-center billSumBody">
          <p>Billing Config</p>
        </div>
        <div className="table_grid billSumBody">
          <p>Amount</p>
        </div>
      </div>
    );
  };

  journeyHeader = (item, index) => {
    return (
      <div className="display-flex text_black font13 w100 pr15 pl15">
        <LoaderSpinner loadingCard={this.state.loadingCard} />
        <div className="table_grid billSumHead">
          <p>{item.name}</p>
        </div>
        <div className="table_grid billSumHead">
          <p className="flex" style={{ marginTop: "8px" }}>
            <img alt="CalenderIcon" src={calender} className="vertical-7" />
            <span
              style={{ color: "#000", marginTop: "5px", marginLeft: "4px" }}
              className="date-text"
            >
              {moment(item.billingDate).format("DD-MMM-YYYY")}
            </span>
          </p>
        </div>
        <div className="table_grid text-center billSumHead">
          <p className="statusMsg" style={{ marginLeft: 0 }}>
            {item.total}
          </p>
        </div>
        <div className="table_grid text-center billSumHead">
          <p>
            <Link to="/billing-config" className={`linkBtn${index}`}>LINK</Link>
          </p>
        </div>
        <div className="table_grid billSumHead">
          <p className="statusMsg" style={{ paddingRight: "25px" }}>
            {item.amount}
          </p>
        </div>
      </div>
    );
  };

  render() {
    const pickerLang = {
      months: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      from: "From",
      to: "To",
    };
    const { rangeValue } = this.state;

    const makeText = (m) => {
      if (m && m.year && m.month)
        return pickerLang.months[m.month - 1] + ". " + m.year;
      return "?";
    };

    return (
      <div className="parent mt10">
        <div className="display-flex">
          <h4 className="m0 in-block">
            Billing Summary
            <span className="label_pink in-block mb10">
              Billing computed on 08/Jul/2020 - 09/Sep/2020
            </span>
            <div className="in-block">
              <span className="m0 includedBadge">
                Total Amount: {this.state.totalAmount}
              </span>
              <span className="m0 includedBadge">
                Total no of Journeys: {this.state.totalJrnys}
              </span>
            </div>
          </h4>
          <div className="nowrap">
            <div className="pos-rel in-block">
              <button
                style={{ marginRight: "10px" }}
                className="sxp-btn btn-primary filterBtn"
                onClick={this.loadNewData}
              >
                Load New Data
              </button>
              <button
                id="showFilterCard"
                className="sxp-btn btn-primary filterBtn"
                onClick={this.showFilter}
              >
                <img alt="filterIcon" src={filterIcon} /> FILTER
              </button>
            </div>
          </div>
        </div>
        <div
          className={`${
            this.state.showSearchFilter ? "activeFilter" : ""
          } filterMainBlock forCalend`}
        >
          <div className="display-flex" style={{ alignItems: "baseline" }}>
            <h3 className="mi0">Search Filter</h3>
            <span className="clearAllText" id="clearFilter" onClick={this.clearAll}>
              Clear all
            </span>
          </div>
          <div className="searchInput customAutoComplStyl">
            <img alt="SearchIcon" src={searchIcon} className="searchIcon" />
            <Autocomplete
              id="custom-input-demo"
              options={this.state.journeyList.map((a) => a.name)}
              onChange={(event) => this.autoComplete(event)}
              value={this.state.searchJoutName}
              renderInput={(params) => (
                <div ref={params.InputProps.ref}>
                  <input
                    type="text"
                    placeholder="Search Journey Name"
                    {...params.inputProps}
                  />
                </div>
              )}
            />
            <img
              alt="cheveonDown"
              src={chevronDown}
              className="downArrrowChev"
            />
          </div>
          <div className="recentSearches">
            <div className="display-flex" style={{ alignItems: "baseline" }}>
              <h4 className="m0 searchhead2"> Bill Duration </h4>
            </div>
            <div>
              <div className="edit">
                <Picker
                  ref={this.pickRange}
                  years={{ min: 2013 }}
                  value={rangeValue}
                  lang={pickerLang}
                  format="YYYY-MM-DD"
                  theme="light"
                  id="monthPickerChange"
                  onChange={this.handleRangeChange}
                  onDismiss={this.handleRangeDissmis}
                >
                  <MonthBox
                    value={
                      makeText(rangeValue.from) +
                      " ~ " +
                      makeText(rangeValue.to)
                    }
                    onClick={this._handleClickRangeBox}
                  />
                </Picker>
              </div>
            </div>
          </div>
          <div className="recentSearches">
            <div
              className="display-flex"
              style={{ alignItems: "baseline", marginTop: "14px" }}
            >
              <h4 className="m0 searchhead2">Amount Range</h4>
            </div>
            <Slider
              className="sliderStyles"
              value={this.state.amountRange}
              defaultValue={30}
              onChange={this.amountRangeSlider}
              id="amountRangeSlider"
              valueLabelDisplay="auto"
              aria-labelledby="range-slider"
              getAriaValueText={this.state.amountValue}
              marks={marks}
              step={10}
              min={0}
              max={5000}
            />
          </div>
          <button
            className="sxp-btn btn-primary block-btn"
            style={{ marginBottom: "10px" }}
            onClick={this.applyFilter}
            id="applyFilterBtn"
          >
            APPLY
          </button>
          <button
            className="sxp-btn btn-danger block-btn"
            onClick={this.closeFilter}
            id="cancelFilterBtn"
          >
            CANCEL
          </button>
        </div>
        {this.state.showFilterBlock ? (
          <div className="predefinedFilter">
            <p>
              <span style={{ fontStyle: "italic" }}>Preview Filters</span>
              {this.state.amountRange[1] == "" ? (
                ""
              ) : (
                <span className="FilterBadge">
                  min:{this.state.amountRange[0]} max:
                  {this.state.amountRange[1]}
                  <img
                    alt="CloseIcon"
                    src={closeIcon}
                    id="priceRangeRemoveImg"
                    onClick={this.removeAmountrange}
                  />
                </span>
              )}
              {this.state.searchJoutName ? (
                <span className="FilterBadge">
                  {this.state.searchJoutName}{" "}
                  <img
                    alt="CloseIcon"
                    src={closeIcon}
                    id="journeyNameRemove"
                    onClick={this.removeJourName}
                  />
                </span>
              ) : (
                ""
              )}

              {this.state.startDate && this.state.endDate ? (
                <span className="FilterBadge">
                  From {this.state.startDate} :: To {this.state.endDate}
                  <img
                    alt="CloseIcon"
                    src={closeIcon}
                    id="removeDateRange"
                    onClick={this.removeMonthRange}
                  />
                </span>
              ) : (
                ""
              )}
            </p>
          </div>
        ) : null}

        {this.journeyBanner()}
        {this.state.billingSummary.length ? (
          <div className="flex-jrny">
            {this.state.billingSummary.map((item, index) => (
              <ExpansionPanel
                className="CustomexpansionPannel"
                key={item.id}
                expanded={
                  this.state.expansionJrnyPanelOpen &&
                  this.state.journeyIndex === index
                }
              >
                <ExpansionPanelSummary className="boxShadow">
                  {this.journeyHeader(item, index)}
                </ExpansionPanelSummary>
              </ExpansionPanel>
            ))}
          </div>
        ) : (
          <EmptyCard />
        )}

        <Pagination
          pageNo={this.state.pageNo}
          totalPages={this.state.totalPages}
          rowPerPageChange={this.rowPerPageChange}
          previousPage={this.previousPage}
          nextPage={this.nextPage}
          pageSize={this.state.rowPageSize}
        />
      </div>
    );
  }
}

export default BillingSummary;
