import React, { Component } from "react";
import { NavLink, Link } from "react-router-dom";
import mainLogo from "../../assets/images/logo.png";
import mainLogo1 from "../../assets/images/logo1.png";
import "./sidebar.scss";

import Dashboard from "../../assets/images/menuIcons/Dashboard.png";
import DashboardWhite from "../../assets/images/menuIcons/DashboardWhite.png";
import Journeys from "../../assets/images/menuIcons/Journeys.png";
import JourneysWhite from "../../assets/images/menuIcons/JourneysWhite.png";
import Billing from "../../assets/images/menuIcons/Billing.png";
import BillingWhite from "../../assets/images/menuIcons/BillingWhite.png";
import JourneyModallar from "../../assets/images/menuIcons/JourneyModallar.png";
import JourneyModallarWhite from "../../assets/images/menuIcons/JourneyModallarWhite.png";
import services from "../../assets/images/menuIcons/services.png";
import ServicesWhite from "../../assets/images/menuIcons/ServicesWhite.png";
import BusinessModules from "../../assets/images/menuIcons/BusinessModules.png";
import BusinessModulesWhite from "../../assets/images/menuIcons/BusinessModulesWhite.png";
import UserFunctions from "../../assets/images/menuIcons/UserFunctions.png";
import UserFunctionsWhite from "../../assets/images/menuIcons/UserFunctionsWhite.png";
import GlobalVarriables from "../../assets/images/menuIcons/GlobalVarriables.png";
import GlobalVarriablesWhite from "../../assets/images/menuIcons/GlobalVarriablesWhite.png";
import AdminConfigWhite from "../../assets/images/menuIcons/AdminConfigWhite.png";
import channelconfig from "../../assets/images/menuIcons/channelconfig.png";
import ChannelConfigWhite from "../../assets/images/menuIcons/ChannelConfigWhite.png";
import RecommendationConfig from "../../assets/images/menuIcons/RecommendationConfig.png";
import RecommendationConfigWhite from "../../assets/images/menuIcons/RecommendationConfigWhite.png";
import IntentClass from "../../assets/images/menuIcons/intent.png";
import IntentClassWhite from "../../assets/images/menuIcons/intentWhite.png";
import FAQUpload from "../../assets/images/menuIcons/FAQUpload.png";
import FAQUploadWhite from "../../assets/images/menuIcons/FAQUploadWhite.png";
import versioning from "../../assets/images/menuIcons/versioning.png";
import VersioningWhite from "../../assets/images/menuIcons/VersioningWhite.png";
import OAuth from '../../assets/images/menuIcons/oauth.png';
import OAuthWhite from '../../assets/images/menuIcons/oauthwhite.png';
import { connect } from "react-redux";

class LeftMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeListBill: false,
      activeListConfigB: false,
      activeListConfigA: false,
      activeListParse:false,
      permissions: {},
      billingDynHei: "",
      modellerDynHei: "",
      adminDynHei: "",
      parseDynHei: "",
    };
  }
  componentDidMount = () => {
    this.onloadMenu();
  };
  onloadMenu = () => {
    const menu = localStorage.getItem("menu");
    if (menu !== undefined) {
      if (menu === "activeListBill") {
        this.expandBillingDropDown();
      }
      if (menu === "activeListConfigB") {
        this.showConfigDropDownB();
      }
      if (menu === "activeListConfigA") {
        this.showConfigDropDownA();
      }
      if (menu === "activeListParse") {
        this.showParseDropDown();
      }
    }
  };

  expandBillingDropDown = () => {
    const currentState = this.state.activeListBill;
    const billingChildLen = this.billingEle && this.billingEle.children.length;
    let billingComHei = billingChildLen * 45;
    this.setState({ billingDynHei: billingComHei });
    this.setState({
      activeListBill: !currentState,
      activeListConfigB: false,
      activeListConfigA: false,
      activeListParse:false
    });
    localStorage.setItem("menu", "activeListBill");
  };
  showConfigDropDownB = () => {
    const currentState = this.state.activeListConfigB;
    const modellerChildLen =
      this.modellerEle && this.modellerEle.children.length;
    let modellerComHei = modellerChildLen * 45 + 15 + "px";
    this.setState({ modellerDynHei: modellerComHei });
    this.setState({
      activeListConfigB: !currentState,
      activeListBill: false,
      activeListConfigA: false,
      activeListParse: false
    });
    localStorage.setItem("menu", "activeListConfigB");
  };
  showConfigDropDownA = () => {
    const currentState = this.state.activeListConfigA;
    const adminChildLen = this.adminEle && this.adminEle.children.length;
    let adminComHei = adminChildLen * 45 + 34 + "px";
    this.setState({ adminDynHei: adminComHei });
    this.setState({
      activeListConfigA: !currentState,
      activeListBill: false,
      activeListConfigB: false,
      activeListParse:false
    });
    localStorage.setItem("menu", "activeListConfigA");
  };
  showParseDropDown = () => {
    const currentState = this.state.activeListParse;
    const adminChildLen = this.parseEle && this.parseEle.children.length;
    let adminComHei = adminChildLen * 45 + "px";
    this.setState({ parseDynHei: adminComHei });
    this.setState({
      activeListParse: !currentState,
      activeListBill: false,
      activeListConfigB: false,
      activeListConfigA:false
    });
    localStorage.setItem("menu", "activeListParse");
  }

  render() {
    return (
      // <div className={`leftMenuComp text-center ${this.props.toggleMenu ? '' : "smallMenuBar"}`}>
      <div className={`leftMenuComp text-center ${localStorage.getItem('smallSidbar') === 'true' ? '' : "smallMenuBar"}`}>
        <Link to={"/analytics"} id="sxpLogo">
        {localStorage.getItem('smallSidbar') === 'true' ?
          <img
            src={mainLogo}
            alt="SXP Logo"
            style={{ height: "47px" }}
            className="mt10"
          />
        :
          <img
            src={mainLogo1}
            alt="SXP Logo"
            style={{ height: "47px" }}
            className="mt10"
          />
        }
        </Link>
        {/* <h1>LOGO</h1> */}
        <ul className="listData pl0 mb0">
          <li>
            <NavLink to="/analytics" id="analyticsDashboard" className="">
              <img
                alt="dashboardIcon"
                src={Dashboard}
                className="imageActive"
              />
              <img
                alt="dashboardWhiteIcon"
                src={DashboardWhite}
                className="ImgInactive"
              />
              <span className="nowrap font13">Journey Analytics</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/journey-listing" id="journeyListing" className="">
              <img alt="JourneysIcon" src={Journeys} className="imageActive" />
              <img
                alt="JourneysWhiteIcon"
                src={JourneysWhite}
                className="ImgInactive"
              />
              <span className="nowrap font13">Journey Listings</span>
            </NavLink>
          </li>
          <li>
            <a href="#" onClick={this.expandBillingDropDown} id="billingExpandDropDown" className="" >
              <img src={BillingWhite} alt="billingIcon" />
              <span>Billing</span>
              <span className="iconsDropNo">
                {this.state.activeListBill ? "-" : "+"}
              </span>
            </a>
            <ul
              style={{
                height: this.state.activeListBill
                  ? this.state.billingDynHei
                  : "",
              }}
              className={this.state.activeListBill ? "slideDropDown" : ""}
              ref={(ele) => (this.billingEle = ele)}
            >
              <li>
                <NavLink to="/billing-summay" id="billingSummary" className="">
                  <img
                    alt="BillingIcon"
                    src={Billing}
                    className="imageActive"
                  />
                  <img
                    alt="BillingWhiteIcon"
                    src={BillingWhite}
                    className="ImgInactive"
                  />
                  <span className="nowrap">Billing Summary</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/billing-config" id="billingConfig" className="" >
                  <img
                    alt="BillingIcon"
                    src={Billing}
                    className="imageActive"
                  />
                  <img
                    alt="BillingWhiteIcon"
                    src={BillingWhite}
                    className="ImgInactive"
                  />
                  <span className="nowrap">Billing Config</span>
                </NavLink>
              </li>
            </ul>
          </li>
          <li>
            <a href="#" onClick={this.showConfigDropDownB} id="journeymodelingDropDown">
              <img src={JourneyModallarWhite} />
              <span className="nowrap">Journey Modeling</span>
              <span className="iconsDropNo">
                {this.state.activeListConfigB ? "-" : "+"}
              </span>
            </a>
            <ul
              style={{
                height: this.state.activeListConfigB
                  ? this.state.modellerDynHei
                  : "",
              }}
              className={
                this.state.activeListConfigB ? "slideDropDownConfB" : ""
              }
              ref={(ele) => (this.modellerEle = ele)}
            >
              <li>
                <NavLink to="/modeller" id="modelerMenu" >
                  <img
                    alt="JourneyModallarIcon"
                    src={JourneyModallar}
                    className="imageActive"
                  />
                  <img
                    alt="JourneyModallarWhiteIcon"
                    src={JourneyModallarWhite}
                    className="ImgInactive"
                  />
                  <span className="nowrap">Journey Modeler</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/api-services" className="" id="apiServiceMenu">
                  <img
                    alt="servicesIcon"
                    src={services}
                    className="imageActive"
                  />
                  <img
                    alt="ServicesWhiteIcon"
                    src={ServicesWhite}
                    className="ImgInactive"
                  />
                  <span className="nowrap">APIs</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/business-module" className="" id="businessModuleMenu">
                  <img
                    alt="BusinessModulesIcon"
                    src={BusinessModules}
                    className="imageActive"
                  />
                  <img
                    alt="BusinessModulesWhiteIcon"
                    src={BusinessModulesWhite}
                    className="ImgInactive"
                  />
                  <span className="nowrap">Business Module</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/user-functions" className="" id="userFunctionMenu">
                  <img
                    alt="UserFunctionsIcon"
                    src={UserFunctions}
                    className="imageActive"
                  />
                  <img
                    alt="UserFunctionsWhiteIcon"
                    src={UserFunctionsWhite}
                    className="ImgInactive"
                  />
                  <span className="nowrap">User Function </span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/global-variables" className="" id="globalVarMenu">
                  <img
                    alt="GlobalVarriablesIcon"
                    src={GlobalVarriables}
                    className="imageActive"
                  />
                  <img
                    alt="GlobalVarriablesWhiteIcon"
                    src={GlobalVarriablesWhite}
                    className="ImgInactive"
                  />
                  <span className="nowrap">Global Variables </span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/oauth-config" className="" id="oauthconfigMenu">
                  <img
                    alt="GlobalVarriablesIcon"
                    src={OAuth}
                    className="imageActive"
                  />
                  <img
                    alt="GlobalVarriablesWhiteIcon"
                    src={OAuthWhite}
                    className="ImgInactive"
                  />
                  <span className="nowrap">OAuth Config </span>
                </NavLink>
              </li>
              
            </ul>
          </li>
          {localStorage.getItem('hasBackendServer') === 'true' ? (
          <li>
            <a href="#" onClick={this.showParseDropDown} id="parseExpandDropDown" className="" >
              <img src={BillingWhite} alt="billingIcon" />
              <span>Backend Schema</span>
              <span className="iconsDropNo">
                {this.state.activeListParse ? "-" : "+"}
              </span>
            </a>
            <ul
              style={{
                height: this.state.activeListParse
                  ? this.state.parseDynHei
                  : "",
              }}
              className={this.state.activeListParse ? "slideDropDown" : ""}
              ref={(ele) => (this.parseEle = ele)}
            >
              <li>
                <NavLink to="/app-schema" className="" id="parseUiMenu">
                  <img
                    alt="GlobalVarriablesIcon"
                    src={OAuth}
                    className="imageActive"
                  />
                  <img
                    alt="GlobalVarriablesWhiteIcon"
                    src={OAuthWhite}
                    className="ImgInactive"
                  />
                  <span className="nowrap"> App Schema </span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/rest-api-docs" id="billingConfig" className="" >
                  <img
                    alt="BillingIcon"
                    src={Billing}
                    className="imageActive"
                  />
                  <img
                    alt="BillingWhiteIcon"
                    src={BillingWhite}
                    className="ImgInactive"
                  />
                  <span className="nowrap">Rest-Api Docs</span>
                </NavLink>
              </li>
            </ul>
          </li>
          ): null}
          <li>
            <a href="#" onClick={this.showConfigDropDownA} className="" id="adminConfigDropDown">
              <img src={AdminConfigWhite} alt="" />
              <span className="nowrap"> Admin Config</span>
              <span className="iconsDropNo">
                {this.state.activeListConfigA ? "-" : "+"}
              </span>
            </a>
            <ul
              style={{
                height: this.state.activeListConfigA
                  ? this.state.adminDynHei
                  : "",
              }}
              className={
                this.state.activeListConfigA ? "slideDropDownConfA" : ""
              }
              ref={(ele) => (this.adminEle = ele)}
            >
              <li>
                <NavLink to="/channel-config" id="channelConfigMenu" className="">
                  <img
                    alt="channelconfigIcon"
                    src={channelconfig}
                    className="imageActive"
                  />
                  <img
                    alt="ChannelConfigWhiteIcon"
                    src={ChannelConfigWhite}
                    className="ImgInactive"
                  />
                  <span className="nowrap">Channel Config</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/recommendation-config" id="recomendationConfigMenu" className="" >
                  <img
                    alt="RecommendationConfigIcon"
                    src={RecommendationConfig}
                    className="imageActive"
                  />
                  <img
                    alt="RecommendationConfigWhiteIcon"
                    src={RecommendationConfigWhite}
                    className="ImgInactive"
                  />
                  <span>Recommedation config </span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/intent-classification" id="intentClassMenu" className="" >
                  <img
                    alt="IntentClassIcon"
                    src={IntentClass}
                    style={{ width: "20px" }}
                    className="imageActive"
                  />
                  <img
                    alt="IntentClassWhiteIcon"
                    src={IntentClassWhite}
                    style={{ width: "20px" }}
                    className="ImgInactive"
                  />
                  <span className="nowrap">Intent Classification</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/faq-upload" id="faqUploadMenu" className="" >
                  <img
                    alt="FAQUploadIcon"
                    src={FAQUpload}
                    className="imageActive"
                  />
                  <img
                    alt="FAQUploadWhiteIcon"
                    src={FAQUploadWhite}
                    className="ImgInactive"
                  />
                  <span className="nowrap">FAQ Upload</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/versioning" id="versioningMenu" className="" >
                  <img
                    alt="versioningIcon"
                    src={versioning}
                    className="imageActive"
                  />
                  <img
                    alt="VersioningWhiteIcon"
                    src={VersioningWhite}
                    className="ImgInactive"
                  />
                  <span className="nowrap">Versioning</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/journey-routes" id="journeyRouteMenu" className="" >
                  <img
                    alt="JourneysIcon"
                    src={Journeys}
                    className="imageActive"
                  />
                  <img
                    alt="JourneysWhiteIcon"
                    src={JourneysWhite}
                    className="ImgInactive"
                  />
                  <span className="nowrap">Journey Routes</span>
                </NavLink>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    );
  }
}

// const mapStateToProps = (state) => ({
//   ...state,
// });

// export default connect(mapStateToProps)(LeftMenu);
export default LeftMenu;
