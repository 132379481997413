import React, { Component } from "react";

class SuperSetFrame extends Component {
  render() {
    return (
      <>
        {localStorage.getItem("pDashboardId") !== "undefined" ? (
          <iframe
            id="superSetFrame"
            src={`https://sxp.superset.techsophy.com/superset/dashboard/${localStorage.getItem(
              "pDashboardId"
            )}/?standalone=true`}
            style={{ width: "100%", height: "1000px", border: 0 }}
          ></iframe>
        ) : (
          <p
            className="text-center"
            style={{ backgroundColor: "#e0e0e0", padding: "40px", borderRadius:'8px', marginTop:'20px' }}
          >
            No data available in this project
          </p>
        )}
      </>
    );
  }
}

export default SuperSetFrame;
