function FindError(e){
  console.log(e.response)
  let errorObj = {
    status: e.response.status,
    ...(e.response.status === 404 && { message: e.response.data.error.message || 'The Requested URL / Page / Data not found' }),     
    // ...(e.response.status === 400 && { message:  }),
    ...(e.response.status === 422 && { message: e.response.data.error.details.map((d) =>  (<p className="m0">{(d.path ? d.path +' :: ' : '' ) + d.message + ':"'+d.info?.additionalProperty+'",\n' }</p>) )}),
    ...(e.response.status === 400 && { message: e.response.data.error.message }),
    ...(e.response.status === 409 && { message: e.response.data.error.message }),
    ...(e.response.status === 500 && { message: e.response.data.error.message }),
    ...(e.response.status === 403 && { message: e.response.data.error.message }),
  }   
  return errorObj;
}

export default FindError;