import React, { Component } from "react";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AddIcon from "@material-ui/icons/Add";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import {
  FormBuilder,
  FieldGroup,
  FieldControl,
  Validators,
} from "react-reactive-form";
import { FormHelperText, MenuItem, Select } from "@material-ui/core";
import VersionSelect from "../../commons/versions-dropdown";
import {
  deleteData,
  getData,
  saveData,
  updateData,
  updateDataPut,
} from "../../dataProvider/apiHandler";
import ConfirmAlert from "../../commons/uicomponents/confirm";
import Pagination from "../../commons/pagination";
import EmptyCard from "../../commons/uicomponents/emptycard";
import LoaderSpinner from "../../commons/uicomponents/loader";
import PositionedSnackbar from "../../commons/uicomponents/toast";
import "./businessmodule.scss";
import searchIcon from "../../assets/images/search.png";
import jrnyEdit from "../../assets/images/jrny-edit.png";
import deletewhiteIcon from "../../assets/images/deletewhite.png";
import FindError from "../../dataProvider/errorHandler";

const serverUrl = window._env_.REACT_APP_SXP_URL;

class BusinessModule extends Component {
  constructor(props) {
    super(props);
    this.state = {
      businessModule: [],
      conversations: [],
      expansionJrnyPanelOpen: false,
      expansionIGPanelOpen: false,
      expansionCCPanelOpen: false,
      journeyIndex: "",
      conversationID: "",
      newConv: false,
      convList: false,
      confirmAlert: false,
      slabsIndex: "",
      businessModuleNIndex: "",
      journeyList: "",
      componentData: "",
      apiServiceList: "",
      userFunctionList: "",
      selectedTaskId: "",
      loadingCard: false,
      selectedComponentId: "",
      selectedApi: "",
      selectedFunction: "",
      pageNo: 1,
      businessModuleId: "",
      rowPageSize: localStorage.getItem("rowPerPage"),
      versionVal: localStorage.getItem("version"),
      permissions: {},
      searchTextData:'',
    };
    this.conversation = React.createRef();
  }

  componentDidMount = () => {
    this.getAllModules();
    this.getAllJourneyList();
    this.getApiService();
    this.getUserFunction();
  };

  // EXPANSIONS HANDLERS
  switchJrnyExpansionpanel(index) {
    this.setState({ conversationID: "" });
    if (this.state.journeyIndex === index) {
      this.setState({
        expansionJrnyPanelOpen: false,
        journeyIndex: "",
      });
    } else {
      this.setState({
        expansionJrnyPanelOpen: true,
        journeyIndex: index,
        convList: true,
        newConv: false,
      });
    this.buildingConversationForm(index);
    }
  }

  // to patch the values in form fields when the card is expanded
  buildingConversationForm = async (index) => {
    let servData = [...this.state.businessModule];
    if (servData[index] && servData[index].id) {
      let TaskId = servData[index].journeyId;
      let jourId = servData[index].componentId;
      let service = servData[index].service
        ? servData[index].service
        : "";
      let SelectedIdCompo = [{}];
      try {
        const allOptions = {
          url: serverUrl + "components/"+localStorage.getItem('pId'),
          params: { 
            component: "API",
            journeyId: TaskId
          },
        };
        let selectedJrnyData = await getData(allOptions);
        console.log((SelectedIdCompo = selectedJrnyData.data));
      } catch (e) {
        const errorObj = FindError(e)
      this.setState({
        displayToast: true,
        messageToast: errorObj.message,
        severity: "error",
        confirmAlert: false,
      });
      }
      // let ComponentId = servData[index].componentId.id;
      this.setState({ componentData: SelectedIdCompo });
      this.conversationFormBuilder.patchValue({
        businessModuleName: servData[index].businessModuleName,
        journeyId: TaskId,
        componentId: jourId,
        execute: servData[index].execute,
        api: servData[index].api,
        service: service,
        function: servData[index].function,
        event: servData[index].event,
      });
    }
  };

  // to render all business module data from api
  getAllModules = async (pararms) => {
    try {
      this.setState({ loadingCard: true });
      const initialVal = {
        version: localStorage.getItem("version"),
        perPage: localStorage.getItem("rowPerPage"),
        projectId: localStorage.getItem("pId"),
        searchWord: this.state.searchTextData === '.*' ? null: this.state.searchTextData,
        searchKey: 'businessModuleName'
      };
      pararms = {
        ...initialVal,
        ...pararms,
      };
      const allOptions = {
        url: serverUrl + "business-functions/"+localStorage.getItem('pId'),
        params: { ...pararms },
      };
      let initialLoad = await getData(allOptions);
      initialLoad.data.data.docs.filter( d => d.id )
      this.setState({
        businessModule: initialLoad.data.data.docs,
        totalPages: initialLoad.data.data.pages,
        loadingCard: false,
      });
    } catch (e) {
      const errorObj = FindError(e)
      this.setState({
        displayToast: true,
        messageToast: errorObj.message,
        severity: "error",
        confirmAlert: false,
        loadingCard: false,
      });
    }
  };

  // to render all journey name data from api
  getAllJourneyList = async () => {
    try {
      const allOptions = {
        url: serverUrl + "tasks/"+localStorage.getItem('pId'),
        params: { version: localStorage.getItem("version") },
      };
      let initialLoad = await getData(allOptions);
      this.setState({ journeyList: initialLoad.data.data.docs });
    } catch (e) {
      const errorObj = FindError(e)
      this.setState({
        displayToast: true,
        messageToast: errorObj.message,
        severity: "error",
        confirmAlert: false,
      });
    }
  };

  // to render all api services data from api
  getApiService = async () => {
    try {
      const allOptions = {
        url: serverUrl + "services/" + localStorage.getItem('pId'),
      };
      let initialLoad = await getData(allOptions);
      this.setState({ apiServiceList: initialLoad.data.data.docs });
    } catch (e) {
      const errorObj = FindError(e)
      this.setState({
        displayToast: true,
        messageToast: errorObj.message,
        severity: "error",
        confirmAlert: false,
      });
    }
  };

  // to render all userfunction data from api
  getUserFunction = async () => {
    try {
      const allOptions = {
        url: serverUrl + "be/userfunctions/"+localStorage.getItem('pId')+"/keys/"+localStorage.getItem('version'),
        params: { version: localStorage.getItem("version") },
      };
      let initialLoad = await getData(allOptions);
      this.setState({ userFunctionList: initialLoad.data.functionNames });
    } catch (e) {
      const errorObj = FindError(e)
      this.setState({
        displayToast: true,
        messageToast: errorObj.message,
        severity: "error",
        confirmAlert: false,
      });
    }
  };

  // form builder initial values
  conversationFormBuilder = FormBuilder.group({
    businessModuleName: ["", Validators.required],
    journeyId: ["", Validators.required],
    componentId: ["", Validators.required],
    execute: ["", Validators.required],
    // api: ["", Validators.required],
    service: ["", Validators.required],
    function: ["", Validators.required],
    event: ["", Validators.required],
  });

  // to add a new business module card
  onNewModule = () => {
    this.conversationFormBuilder.reset();
    let businessMod = [...this.state.businessModule];
    businessMod.push({ _id: 1, name: "" });
    this.setState({
      businessModule: businessMod,
      displayToast: true,
      messageToast: "New Business Module is added in bottom",
      severity: "success",
    });
  };

  // to show inplace edit option for required card
  showInPlaceEdit = (index) => {
    let businessMod = [...this.state.businessModule];
    businessMod[index].inplaceEdit = !businessMod[index].inplaceEdit;
    this.setState({ businessModule: businessMod });
  };

  // to show inplace edit option for required card
  InputPlaceEdit = (index) => {
    this.showInPlaceEdit(index);
  };

  // to change name for required card on change function
  changeName = (evt, index) => {
    let businessMod = [...this.state.businessModule];
    businessMod[index].businessModuleName = evt.target.value;
    if (evt.keyCode === 13) {
      this.formSubmit(null, index, businessMod[index], true);
    }

    this.setState({
      businessModule: businessMod,
    });
  };

  // when the input is blue call function to update the inplace edit value
  onTitleBlur = async (index) => {
    let businessMod = [...this.state.businessModule];
    this.formSubmit(null, index, businessMod[index], true);
    businessMod[index].inplaceEdit = !businessMod[index].inplaceEdit;
    this.setState({ businessModule: businessMod });
  };

  // function to add a new business module or update a existing businessModule
  formSubmit = async (e, index, value, isNewForm = false) => {
    let businessMod = [...this.state.businessModule];
    let dataId = businessMod[index]["id"];
    value.enable = true;
    value.version = localStorage.getItem("version");
    value.projectId = localStorage.getItem('pId')
    if(value.execute === 'api'){
      delete value.function;
    }
    if(value.execute === 'function')
    {
      delete value.service;
    }
    e?.preventDefault();
    try {
      this.setState({ loadingCard: true });
      if (!isNewForm) {
        const allOptions = {
          url: serverUrl + "business-functions/"+localStorage.getItem('pId'),
          body: value,
        };
        await saveData(allOptions);
        this.setState({
          displayToast: true,
          messageToast: "Created Successfully",
          severity: "success",
          loadingCard: false,
        });
        this.getAllModules();
      } else {
        delete value.id;
        delete value.inplaceEdit;
        delete value.createdOn;
        delete value.modifiedOn;
        delete value.modifiedBy;
        const allOptions = {
          url: serverUrl + "business-functions/" +localStorage.getItem('pId')+"/"+ dataId,
          body: {...value},
        };
        await updateDataPut(allOptions);
        this.setState({
          displayToast: true,
          messageToast: "Updated Successfully",
          severity: "success",
          businessModule: businessMod,
          loadingCard: false,
        });
        this.getAllModules();
      }
    } catch (e) {
      const errorObj = FindError(e)
      this.setState({
        displayToast: true,
        messageToast: errorObj.message,
        severity: "error",
        confirmAlert: false,
        loadingCard: false,
      });
    }
    this.setState({ businessModule: businessMod });
    this.switchJrnyExpansionpanel();
  };

  // function to make the card active or inactive
  setToggle = async (index, value) => {
    let businessMod = [...this.state.businessModule];
    if (value.enable) {
      businessMod[index].enable = false;
    } else {
      businessMod[index].enable = true;
    }
    const bmId = value.id
    delete value.id;
    delete value.uid;
    delete value.createdOn;
    delete value.createdBy;
    delete value.modifiedOn;
    delete value.modifiedBy;

    value.version = localStorage.getItem("version");
    value.projectId = localStorage.getItem('pId');
    
    try {
      const allOptions = {
        url: serverUrl + "business-functions/" + localStorage.getItem('pId') +'/' + bmId,
        body: value,
      };
      await updateDataPut(allOptions);
      this.setState({
        displayToast: true,
        messageToast: "Updated Successfully",
        severity: "success",
      });
    } catch (e) {
      const errorObj = FindError(e)
      this.setState({
        displayToast: true,
        messageToast: errorObj.message,
        severity: "error",
        confirmAlert: false,
      });
    }
    this.setState({ businessModule: businessMod });
  };

  // to remove a businessModule showing a confirmation card
  removeBussMod = (index, id) => {
    let businessMod = [...this.state.businessModule];
    if (id) {
      this.setState({
        confirmAlert: true,
        businessModuleNIndex: index,
        businessModuleId: id,
      });
    } else {
      businessMod.splice(index, 1);
      this.setState({ businessModule: businessMod });
    }
  };

  closeToast = () => {
    this.setState({ displayToast: false });
  };

  // to close a  confirmation card
  cancelBtn = () => {
    this.setState({ confirmAlert: false });
  };

  confirmBtn = () => {
    this.confirmBtnBusiness(this.state.businessModuleId);
  };

  // function to delete a card from the backend
  confirmBtnBusiness = async (id) => {
    this.setState({ loadingCard: true });
    try {
      const allOptions = {
        url: serverUrl + "business-functions/"+ localStorage.getItem('pId') +'/'+ id,
        pararms: { version: localStorage.getItem("version") },
      };
      await deleteData(allOptions);
      this.setState({
        displayToast: true,
        messageToast: "Deleted Successfully",
        severity: "success",
        confirmAlert: false,
      });
    } catch (e) {
      const errorObj = FindError(e)
      this.setState({
        displayToast: true,
        messageToast: errorObj.message,
        severity: "error",
        confirmAlert: false,
      });
    }
    this.getAllModules();
  };

  // to show inplace edit option for required card
  showInPlaceEdit = (index) => {
    let businessMod = [...this.state.businessModule];
    businessMod[index].inplaceEdit = !businessMod[index].inplaceEdit;
    this.setState({ businessModule: businessMod });
  };

  // to show inplace edit option for required card
  InputPlaceEdit = (index) => {
    this.showInPlaceEdit(index);
  };

  // function to search card
  searchCard = async (event) => {
    let searchText = await event.target.value;
    const paramsmet = {
      filter: {
        businessModuleName: searchText,
      },
    };
    this.setState({searchTextData: searchText + '.*'},() => 
    this.getAllModules(paramsmet)
    )
  };

  // select option on change show required field
  onChangeexecuteWhat = (e, index) => {
    let executeValue = e.target.value;
    let businessMod = [...this.state.businessModule];
    businessMod[index].execute = executeValue;
    this.setState({ businessModule: businessMod });
    this.conversationFormBuilder.patchValue({
      execute: businessMod[index].execute,
    });
  };

  // function to call api to fetch data for selected journey
  JourneyWhere = async (e, index) => {
    let journeyId = e.target.value;
    try {
      const allOptions = {
        url: serverUrl + "components/"+localStorage.getItem('pId'),
        params: { 
          component: "API",
          journeyId: journeyId
        },
      };
      let selectedJrnyData = await getData(allOptions);
      this.setState({ componentData: selectedJrnyData.data });
    } catch (e) {
      const errorObj = FindError(e)
      this.setState({
        displayToast: true,
        messageToast: errorObj.message,
        severity: "error",
        confirmAlert: false,
      });
    }
    let businessMod = [...this.state.businessModule];
    businessMod[index].journeyId = journeyId;
    this.conversationFormBuilder.patchValue({
      journeyId: businessMod[index].journeyId,
    });
  };

  rowPerPageChange = (e) => {
    const perPage = e.target.value;
    localStorage.setItem("rowPerPage", e.target.value);
    const paramsSet = {
      perPage: e.target.value,
    };
    this.getAllModules(paramsSet);
    this.setState({ rowPageSize: perPage });
  };

  // to previousPage function
  previousPage = () => {
    let PreviousSetNo = this.state.pageNo - 1;
    this.setState({ pageNo: PreviousSetNo, loadingCard: true });
    const paramsSet = {
      page: PreviousSetNo,
    };
    this.getAllModules(paramsSet);
  };

  nextPage = () => {
    let PreviousSetNo = this.state.pageNo + 1;
    this.setState({ pageNo: PreviousSetNo, loadingCard: true });
    const paramsSet = {
      page: PreviousSetNo,
    };
    this.getAllModules(paramsSet);
  };

  cancelExpand = () => {
    this.setState({
      expansionJrnyPanelOpen: false,
      journeyIndex: "",
      value: 0,
    });
  };

  bannerHeader = () => {
    return (
      <div className="display-flex table_head font13 fontWeight700 cardFull cardSpaceArrow">
        <div className="table_grid">
          <p>Business Module Name</p>
        </div>
        <div className="table_grid">
          <p>Journey</p>
        </div>
        <div className="table_grid">
          <p>API / Function</p>
        </div>
        <div className="table_grid">
          <p>Enable / Disable</p>
        </div>
      </div>
    );
  };

  bannerCard = (item, index) => {
    return (
      <div className="display-flex text_black font13 w100 pr15 pl15">
        <LoaderSpinner loadingCard={this.state.loadingCard} />
        {item.businessModuleName ? (
          <div className="table_grid">
            {item.inplaceEdit !== false ? (
              <div>
                <p
                  className={`in-block overflowTextdots ${
                    this.state.permissions ? "" : "disable-click"
                  }`}
                  id={`businessNameClick${index}`}
                  onClick={() => this.InputPlaceEdit(index)}
                  style={{ maxWidth: "118px" }}
                >
                  {item.businessModuleName}
                </p>
                {this.state.permissions ? (
                  <img
                    alt="editIcon"
                    id={`businessEditImg${index}`}
                    src={jrnyEdit}
                    className="pointer-coursor"
                    onClick={() => this.showInPlaceEdit(index)}
                    style={{ verticalAlign: "13px" }}
                  />
                ) : null}
              </div>
            ) : (
              <p className="">
                <input
                  type="text"
                  value={item.businessModuleName}
                  name="name"
                  style={{
                    height: "34px",
                    border: "1px solid #999",
                    borderRadius: "8px",
                  }}
                  id={`apiServiceInput${index}`}
                  onChange={(e) => this.changeName(e, index)}
                  onBlur={() => this.onTitleBlur(index)}
                  onKeyDown={(e) => this.changeName(e, index)}
                />
              </p>
            )}
          </div>
        ) : (
          <p style={{ fontSize: "13px" }}>Business Module Name </p>
        )}

        <div className="table_grid">
          <p>{this.state.journeyList && this.state.journeyList.find((d) => d.uid === item.journeyId)?.name}</p>
        </div>
        <div className="table_grid">
          <p>{item.execute === "api" ? "API" : "Function"}</p>
        </div>
        <div className="table_grid">
          <div
            className={`toggle-parent-modeller ${
              item.enable ? "" : "inactiveToggle"
            }`}
            id={`businessToggle${index}`}
            onClick={this.setToggle.bind(this, index, item)}
            style={{ marginTop: "7px" }}
          >
            <div
              className={` ${
                item.enable ? "toggle-button" : "disabled-toggle"
              }`}
            >
            </div>
          </div>
        </div>

        <img
          src={deletewhiteIcon}
          alt="trash Icon"
          id={`businessDeleteImg${index}`}
          onClick={() => this.removeBussMod(index, item.id)}
          className="deleteIcon"
        />
      </div>
    );
  };

  render() {
    return (
      <div className="parent mt10">
        {this.state.confirmAlert === true && (
          <ConfirmAlert
            indexValue={this.state.slabsIndex}
            mainIndex={this.state.businessModuleNIndex}
            onConfirmBtn={this.confirmBtn}
            onCancelBtn={this.cancelBtn}
            heading="Are you sure ?"
            paragraph="If you proceed you will loose the data. Are you sure you want to delete ?"
          />
        )}
        <PositionedSnackbar
          display={this.state.displayToast}
          msg={this.state.messageToast}
          closeToast={this.closeToast}
          severity={this.state.severity}
        />
        <div className="display-flex">
          <h4 className="m0">
            Business Module
            <span className="label_pink">Define a Business Module</span>
          </h4>
          <div>
            <div
              className="pos-rel in-block Version_modeller_dropdwn"
              style={{ marginRight: "10px" }}
            >
              <VersionSelect final={this.getAllModules} />
            </div>
            <div className="pos-rel in-block searchFilter">
              <img src={searchIcon} alt="search Icon" className="searchIcon" />
              <input
                type="text"
                placeholder="Search Business Module"
                onChange={this.searchCard}
                id="searchBusinessCardInput"
              />
            </div>
            {this.state.permissions ? (
              <button
                className="sxp-btn btn-primary"
                onClick={this.onNewModule}
                id={`addNewBusiness`}
              >
                <AddIcon className="vertical-7" /> BUSINESS MODULE
              </button>
            ) : null}
          </div>
        </div>

        <div className="">
          {this.bannerHeader()}
          {this.state.businessModule.length ? (
            <div>
              {this.state.businessModule.map((item, index) => (
                <ExpansionPanel
                  className="CustomexpansionPannel"
                  key={item.id + index}
                  expanded={
                    this.state.expansionJrnyPanelOpen &&
                    this.state.journeyIndex === index
                  }
                >
                  <ExpansionPanelSummary
                    expandIcon={
                      <ExpandMoreIcon
                        className="iconExpand"
                        id={`businessArrow${index}`}
                        onClick={this.switchJrnyExpansionpanel.bind(
                          this,
                          index,
                        )}
                      />
                    }
                    style={{ order: -1 }}
                    className="boxShadow"
                  >
                    {this.bannerCard(item, index)}
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails className="inner_expandBlock bussinesMExpan">
                    <div className="">
                      <p className="m0 description">
                        <span>Description:</span> The Bussiness module (API or
                        User defined Function) will be executed in a particular
                        Journey before a particular Component, when a particular
                        event occurs (or a condition becomes True)
                      </p>
                      <FieldGroup
                        control={this.conversationFormBuilder}
                        render={({ value }) => (
                          <form
                            onSubmit={(e) =>
                              this.formSubmit(
                                e,
                                index,
                                value,
                                item.id ? true : false
                              )
                            }
                          >
                            <div className="formField">
                              <div className="flex">
                                <FieldControl
                                  name="businessModuleName"
                                  options={{ validators: Validators.required }}
                                  render={({ handler, touched, hasError }) => (
                                    <div className="">
                                      <input
                                        {...handler()}
                                        placeholder="Business Module Name"
                                        className={`${
                                          touched &&
                                          hasError("required") &&
                                          "errorInput"
                                        }`}
                                      />
                                      <span
                                        className={`${
                                          touched &&
                                          hasError("required") &&
                                          "errorFieldMsg"
                                        }`}
                                      >
                                        {touched &&
                                          hasError("required") &&
                                          "Required Filed"}
                                      </span>
                                    </div>
                                  )}
                                />
                              </div>
                              <div className="flex">
                                <span className="label_orange">WHERE</span>
                                <FieldControl
                                  name="journeyId"
                                  options={{ validators: Validators.required }}
                                  render={({ handler, touched, hasError }) => (
                                    <div>
                                      <Select
                                        {...handler()}
                                        onChange={(e) =>
                                          this.JourneyWhere(e, index)
                                        }
                                        id={`taskIdWhere${index}`}
                                        className={`sxp-defaultDropDown_filed ${
                                          touched &&
                                          hasError("required") &&
                                          "errorInput"
                                        }`}
                                      >
                                        <MenuItem value="">
                                          Journey Type
                                        </MenuItem>
                                        {this.state.journeyList
                                          ? this.state.journeyList.map(
                                              (d, i) => (
                                                <MenuItem key={i} value={d.uid}>
                                                  {d.name}
                                                </MenuItem>
                                              )
                                            )
                                          : ""}
                                      </Select>
                                      {/* <FormHelperText className="select_Placeholder">
                                        Placeholder
                                      </FormHelperText> */}
                                      <span
                                        className={`${
                                          touched &&
                                          hasError("required") &&
                                          "errorFieldMsg"
                                        }`}
                                      >
                                        {touched &&
                                          hasError("required") &&
                                          "Required Filed"}
                                      </span>
                                    </div>
                                  )}
                                />
                                <FieldControl
                                  name="componentId"
                                  options={{ validators: Validators.required }}
                                  render={({ handler, touched, hasError }) => (
                                    <div>
                                      <Select
                                        {...handler()}
                                        id={`componentId${index}`}
                                        className={`sxp-defaultDropDown_filed ${
                                          touched &&
                                          hasError("required") &&
                                          "errorInput"
                                        }`}
                                      >
                                        <MenuItem value="">
                                          Select Component
                                        </MenuItem>
                                        {this.state.componentData
                                          ? this.state.componentData.map(
                                              (d, i) => (
                                                <MenuItem key={i} value={d.id}>
                                                  {d.name}
                                                </MenuItem>
                                              )
                                            )
                                          : '<MenuItem value="">No Data</MenuItem>'}
                                      </Select>
                                      <span
                                        className={`${
                                          touched &&
                                          hasError("required") &&
                                          "errorFieldMsg"
                                        }`}
                                      >
                                        {touched &&
                                          hasError("required") &&
                                          "Required Filed"}
                                      </span>
                                    </div>
                                  )}
                                />
                              </div>

                              <div className="flex">
                                <span className="label_orange">WHAT</span>
                                <FieldControl
                                  name="execute"
                                  options={{ validators: Validators.required }}
                                  render={({ handler, touched, hasError }) => (
                                    <div>
                                      <Select
                                        {...handler()}
                                        id={`executeWhat${index}`}
                                        onChange={(e) =>
                                          this.onChangeexecuteWhat(e, index)
                                        }
                                        className={`sxp-defaultDropDown_filed ${
                                          touched &&
                                          hasError("required") &&
                                          "errorInput"
                                        }`}
                                        value={item.execute}
                                      >
                                        <MenuItem value="">
                                          Execute What
                                        </MenuItem>
                                        <MenuItem value="api">
                                          Enterprise Services (APIs)
                                        </MenuItem>
                                        <MenuItem value="function">
                                          User Functions (Javascript code)
                                        </MenuItem>
                                      </Select>
                                      <span
                                        className={`${
                                          touched &&
                                          hasError("required") &&
                                          "errorFieldMsg"
                                        }`}
                                      >
                                        {touched &&
                                          hasError("required") &&
                                          "Required Filed"}
                                      </span>
                                    </div>
                                  )}
                                />
                                {item.execute === "api" && (
                                  <FieldControl
                                    name="service"
                                    options={{
                                      validators: Validators.required,
                                    }}
                                    render={({
                                      handler,
                                      touched,
                                      hasError,
                                    }) => (
                                      <div>
                                        <Select
                                          id={`apiOnChange${index}`}
                                          {...handler()}
                                          className={`sxp-defaultDropDown_filed  ${
                                            touched &&
                                            hasError("required") &&
                                            "errorInput"
                                          }`}
                                        >
                                          <MenuItem value="">
                                            Enterprise Services (APIs)
                                          </MenuItem>
                                          {this.state.apiServiceList
                                            ? this.state.apiServiceList.map(
                                                (d, i) => (
                                                  <MenuItem
                                                    key={i}
                                                    value={d.id}
                                                    selected={
                                                      d.name ===
                                                      this.state.selectedApi
                                                    }
                                                  >
                                                    {d.name}
                                                  </MenuItem>
                                                )
                                              )
                                            : ""}
                                        </Select>
                                        <span
                                          className={`${
                                            touched &&
                                            hasError("required") &&
                                            "errorFieldMsg"
                                          }`}
                                        >
                                          {touched &&
                                            hasError("required") &&
                                            "Required Filed"}
                                        </span>
                                      </div>
                                    )}
                                  />
                                )}
                                {item.execute === "function" && (
                                  <FieldControl
                                    id={`functionOnChange${index}`}
                                    name="function"
                                    options={{
                                      validators: Validators.required,
                                    }}
                                    render={({
                                      handler,
                                      touched,
                                      hasError,
                                    }) => (
                                      <div>
                                        <Select
                                          {...handler()}
                                          className={`sxp-defaultDropDown_filed ${
                                            touched &&
                                            hasError("required") &&
                                            "errorInput"
                                          }`}
                                        >
                                          <MenuItem value="">
                                            User Functions (Javascript code)
                                          </MenuItem>
                                          {this.state.userFunctionList
                                            ? this.state.userFunctionList.map(
                                                (d, i) => (
                                                  <MenuItem key={i} value={d}>
                                                    {d}
                                                  </MenuItem>
                                                )
                                              )
                                            : ""}
                                        </Select>
                                        <span
                                          className={`${
                                            touched &&
                                            hasError("required") &&
                                            "errorFieldMsg"
                                          }`}
                                        >
                                          {touched &&
                                            hasError("required") &&
                                            "Required Filed"}
                                        </span>
                                      </div>
                                    )}
                                  />
                                )}
                              </div>
                              <div className="flex">
                                <span className="label_orange">WHEN</span>
                                <FieldControl
                                  name="event"
                                  options={{ validators: Validators.required }}
                                  render={({ handler, touched, hasError }) => (
                                    <div>
                                      <textarea
                                        {...handler()}
                                        placeholder="Event / Condition (JSON Rules)"
                                        className={`${
                                          touched &&
                                          hasError("required") &&
                                          "errorInput"
                                        }`}
                                      ></textarea>
                                      <span
                                        className={`${
                                          touched &&
                                          hasError("required") &&
                                          "errorFieldMsg"
                                        }`}
                                      >
                                        {touched &&
                                          hasError("required") &&
                                          "Required Filed"}
                                      </span>
                                    </div>
                                  )}
                                />
                              </div>
                            </div>
                            <div className="buttonClmns text-center">
                              <button
                                className="sxp-btn btn-primary primaryLarge"
                                type="submit"
                                style={{ height: "34px", margin: "0 5px" }}
                              >
                                Save
                              </button>
                              <span
                                className="sxp-btn btn-danger primaryLarge"
                                onClick={() => this.cancelExpand(index)}
                                style={{
                                  height: "34px",
                                  width: "220px",
                                  textAlign: "center",
                                  margin: "0 5px",
                                  padding: "9px 0",
                                  display: "inline-block",
                                  verticalAlign: "top",
                                }}
                              >
                                Cancel
                              </span>
                            </div>
                          </form>
                        )}
                      />
                    </div>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              ))}
            </div>
          ) : (
            <EmptyCard />
          )}
        </div>
        <Pagination
          pageNo={this.state.pageNo}
          totalPages={this.state.totalPages}
          rowPerPageChange={this.rowPerPageChange}
          previousPage={this.previousPage}
          nextPage={this.nextPage}
        />
      </div>
    );
  }
}

export default BusinessModule;
