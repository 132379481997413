import React from "react";
import ConfirmAlert from "../../../commons/uicomponents/confirm";
import PositionedSnackbar from "../../../commons/uicomponents/toast";

export class FormButtons extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmAlert: false,
      displayToast: false,
      messageToast: "",
    };
  }
  deleteNode = () => {
    this.setState({ confirmAlert: true });
  };
  closeToast = () => {
    this.setState({ displayToast: false });
  };
  onCancelBtn = () => {
    const collapseForm = this.props.formProps.formAction;
    collapseForm();
  };
  onConfirmButtonClick = () => {
    this.props.formProps.removeElement([this.props.formProps.data]);
    const collapseForm = this.props.formProps.formAction;
    collapseForm();
    this.setState({ displayToast: true, messageToast: "Deleted Successfully" });
  };
  render() {
    return (
      <>
        {this.state.confirmAlert == true && (
          <ConfirmAlert
            onConfirmBtn={this.onConfirmButtonClick}
            onCancelBtn={this.onCancelBtn}
            heading=""
            paragraph="Are you sure you want to delete  Component ?"
          />
        )}
        <PositionedSnackbar
          display={this.state.displayToast}
          msg={this.state.messageToast}
          closeToast={this.closeToast}
        />
        <button type="submit" className="applyBtn">
          Apply
        </button>
        <button
          type="button"
          className="deleBtn"
          onClick={() => this.deleteNode(this.props.formProps)}
        >
          Delete
        </button>
      </>
    );
  }
}
