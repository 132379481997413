import React, { useState } from "react";
import { Formik } from "formik";
import PositionedSnackbar from "../../commons/uicomponents/toast";
import { updateState } from "./jmApiHandler";

const CHANNEL_ADAPTER_URL = window._env_.REACT_APP_SOCKET_URL;

const TestState = () => {
  const [toastState, setToastState] = useState({
    displayToast: false,
    messageToast: "",
    severity: "success",
  });

  const closeToast = () => {
    setToastState({ ...toastState, displayToast: false });
  };

  const handleFormSubmit = async (values, { resetForm }) => {
    const stateStr = values.state;
    try {
      const state = JSON.parse(stateStr);
      const userId = localStorage.getItem("userName");
      const body = {
        userId: userId,
        state: state,
      };
      const options = {
        url: `${CHANNEL_ADAPTER_URL}state/projectId/${localStorage.getItem(
          "pId"
        )}`,
        body: body,
      };
      await updateState(options);
      window["chatSendMsg"](values.message, userId);
      setToastState({
        displayToast: true,
        messageToast: `Journey Started with message ${values.message}`,
        severity: "success",
      });
      resetForm();
    } catch (err) {
      setToastState({
        displayToast: true,
        messageToast: err.message,
        severity: "error",
      });
    }
  };

  return (
    <div>
      <PositionedSnackbar
        display={toastState.displayToast}
        msg={toastState.messageToast}
        severity={toastState.severity}
        closeToast={closeToast}
      />
      <h5>Test State:</h5>
      <Formik
        initialValues={{ message: "", state: "{}" }}
        onSubmit={handleFormSubmit}
      >
        {({ values, handleChange, handleBlur, handleSubmit }) => (
          <form className="state-form" onSubmit={handleSubmit}>
            <div>
              <label>Message</label>
              <input
                type="text"
                name="message"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.message}
                required
                autoFocus
              />
            </div>
            <div>
              <label>State</label>
              <textarea
                name="state"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.state}
                rows="10"
                cols="40"
              />
            </div>

            <button type="submit" className="stateBtn">
              Start
            </button>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default TestState;
