import React from "react";
import uuid from "react-uuid";
import { FormButtons } from "./formbuttons";
import { submit } from "./formActions";

export class ABConnection extends React.Component {
  state = {};
  render() {
    let formUniqueId = uuid();

    return (
      <form className="form-style" onSubmit={(e) => submit(e, this.props)}>
        <label>Weight</label>
        <div className="pos-rel">
          <input
            type="number"
            name="abWeight"
            min="0"
            max="100"
            defaultValue={this.props.data.data.abWeight}
            key={formUniqueId}
            autoFocus
          />
          <span style={{ position: "absolute", right: "30px", top: "6px" }}>
            %
          </span>
        </div>
        <FormButtons formProps={this.props} />
      </form>
    );
  }
}
