import React, { Component } from "react";

class OAuthTwoDotO extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nonSocialChannelAuthCredentials: {
        cache: false,
        rateLimit: false,
        jwksRequestsPerMinute: Number,
        jwksUri: "",
        audience: "",
        issuer: "",
        algorithms: "",
      },
    };
  }

  componentDidMount = () => {
    this.checkIfDataAvil();
  };
  checkIfDataAvil = () => {
    if (this.props.jwtData) {
      this.setState({ nonSocialChannelAuthCredentials: this.props.jwtData });
    }
  };

  handleProjectCache = () => {
    let createObj = { ...this.state.nonSocialChannelAuthCredentials };
    createObj.cache = !this.state.nonSocialChannelAuthCredentials.cache;
    this.setState({ nonSocialChannelAuthCredentials: createObj }, () => {
      if (this.props.handleJWTFormCallBack) {
        this.props.handleJWTFormCallBack(this.state);
      }
    });
  };

  handleProjectRateLimit = () => {
    let createObj = { ...this.state.nonSocialChannelAuthCredentials };
    createObj.rateLimit = !this.state.nonSocialChannelAuthCredentials.rateLimit;
    this.setState({ nonSocialChannelAuthCredentials: createObj }, () => {
      if (this.props.handleJWTFormCallBack) {
        this.props.handleJWTFormCallBack(this.state);
      }
    });
  };

  handleChange = (e) => {
    let filedName = e.target.name;
    let createObj = { ...this.state.nonSocialChannelAuthCredentials };
    createObj[filedName] = e.target.value;
    this.setState({ nonSocialChannelAuthCredentials: createObj }, () => {
      if (this.props.handleJWTFormCallBack) {
        this.props.handleJWTFormCallBack(this.state);
      }
    });
  };

  handleChangeAlgo = (e) => {
    let filedName = e.target.name;
    let createObj = { ...this.state.nonSocialChannelAuthCredentials };
    let algoVal = [];
    if(e.target.value) {
        if(!Array.isArray(e.target.value)){
            algoVal = e.target.value.split(",")
        }      
    }
    createObj[filedName] = algoVal;
    this.setState({ nonSocialChannelAuthCredentials: createObj }, () => {
      if (this.props.handleJWTFormCallBack) {
        this.props.handleJWTFormCallBack(this.state);
      }
    });
  }

  render() {
    return (
      <div>
        <div className="flex jwtFields" style={{ flexWrap: "wrap" }}>
          <div
            className="customInputStyle nowrap"
            style={{ marginTop: "15px" }}
          >
            <label className="mt0" style={{ verticalAlign: "super" }}>
              {" "}
              Cache{" "}
            </label>
            <div
              className={`toggle-parent-modeller insideCard ${
                this.state.nonSocialChannelAuthCredentials?.cache
                  ? ""
                  : "inactiveToggle"
              }`}
              style={{ marginTop: "3px" }}
              onClick={this.handleProjectCache}
            >
              <div
                className={`${
                  this.state.nonSocialChannelAuthCredentials?.cache
                    ? "toggle-button"
                    : " disabled-toggle"
                }`}
              ></div>
            </div>
          </div>

          <div
            className="customInputStyle nowrap"
            style={{ marginTop: "15px" }}
          >
            <label className="mt0" style={{ verticalAlign: "super" }}>
              {" "}
              Rate Limit{" "}
            </label>
            <div
              className={`toggle-parent-modeller insideCard ${
                this.state.nonSocialChannelAuthCredentials?.rateLimit
                  ? ""
                  : "inactiveToggle"
              }`}
              style={{ marginTop: "3px" }}
              onClick={this.handleProjectRateLimit}
            >
              <div
                className={`${
                  this.state.nonSocialChannelAuthCredentials?.rateLimit
                    ? "toggle-button"
                    : " disabled-toggle"
                }`}
              ></div>
            </div>
          </div>
          <input
            type="number"
            className="hideInputNumberArrows"
            placeholder="jwks Requests Per Minute"
            value={ this.state.nonSocialChannelAuthCredentials?.jwksRequestsPerMinute}
            name="jwksRequestsPerMinute"
            onChange={this.handleChange}
          />
          <input
            type="text"
            placeholder="jwks Uri"
            name="jwksUri"
            value={this.state.nonSocialChannelAuthCredentials?.jwksUri}
            onChange={this.handleChange}
          />
          <input
            type="text"
            placeholder="Audience"
            name="audience"
            value={this.state.nonSocialChannelAuthCredentials?.audience}
            onChange={this.handleChange}
          />
          <input
            type="text"
            placeholder="Issuer"
            name="issuer"
            value={this.state.nonSocialChannelAuthCredentials?.issuer}
            onChange={this.handleChange}
          />
          <input
            type="text"
            placeholder="Algorithms"
            name="algorithms"
            value={this.state.nonSocialChannelAuthCredentials?.algorithms}
            onChange={this.handleChangeAlgo}
          />
        </div>
      </div>
    );
  }
}

export default OAuthTwoDotO;
