import React, { memo } from "react";
import { Handle } from "react-flow-renderer";
import waitIcon from './../../../assets/images/modeler/wait.png';


export default memo(({ data }) => {
  const compColor = data?.style?.backgroundColor;
  const isValidConnection = (connection) => {
    return connection.target !== connection.source;
  };
  return (
    <>
     <Handle
        type="target"
        position="top"
        className="react_flow_handle_wait_top"
        style={{ background: "white", border: `1px solid ${compColor}`, zIndex:1 }}
        isValidConnection={isValidConnection}
      />
      <div style={{width:'74px', position:'relative'}}>
        <div className="img_node_overlay"></div>
        <img className="w100" src={waitIcon} />
      </div>
      <Handle
        type="source"
        position="bottom"
        className="react_flow_handle_wait_bottom"
        style={{ background: "white", border: `1px solid ${compColor}` }}
        isValidConnection={isValidConnection}
      />
    </>
  );
});
