import React, { Component } from "react";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AddIcon from "@material-ui/icons/Add";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import moment from "moment";
import Tooltip from "@material-ui/core/Tooltip";
import ConfirmAlert from "../../commons/uicomponents/confirm";
import EmptyCard from "../../commons/uicomponents/emptycard";
import PositionedSnackbar from "../../commons/uicomponents/toast";
import LoaderSpinner from "../../commons/uicomponents/loader";
import {
  deleteData,
  getData,
  saveData,
  updateData,
  updateDataPut
} from "../../dataProvider/apiHandler";
import "./reco.scss";
import calender from "../../assets/images/calender.png";
import deletewhiteIcon from "../../assets/images/deletewhite.png";
import RecoConfigInstance from "./recoConfigInstance";
import FindError from "../../dataProvider/errorHandler";

const serverUrl = window._env_.REACT_APP_SXP_URL;
const url = 'https//prod.sxp.techsophy.com/recoengine/reco3';

class RecommendationConfig extends Component {
  constructor(props) {
    super(props);
    this.state = {
      recommend: [],
      expansionJrnyPanelOpen: false,
      journeyIndex: "",
      displayconv: false,
      conversationID: "",
      getConvList: false,
      componentType: "",
      newIg: false,
      newConv: false,
      convList: false,
      channelEdit: false,
      journeyIndexEdit: "",
      jrnyBorder: false,
      displayMenu: false,
      confirmAlert: false,
      displayToast: false,
      loadingCard: false,
      recoIndex: "",
      recoBaseId: "",
      recoTypeId: "",
      typesList: [],
      filteredRecoList: [],
    };
  }

  componentDidMount = () => {
    this.getRecoConfigs();
  };

  switchJrnyExpansionPanel = (index, baseId, typeId) => {
    if (this.state.journeyIndex === index) {
      this.setState({
        expansionJrnyPanelOpen: false,
        journeyIndex: "",
      });
    } else {
      this.setState({
        expansionJrnyPanelOpen: true,
        journeyIndex: index,
        convList: true,
        newConv: false,
        recoTypeId: typeId,
        recoBaseId: baseId,
      });
    }
  };

  getRecoConfigTypes = async () => {
    try {
      const allOption = {
        url: serverUrl + "reco-config/type/" + localStorage.getItem('pId'),
      };
      let initialLoad = await getData(allOption);
      this.setState({ typesList: initialLoad.data.data.docs });
      this.filterRecoList();
    } catch (e) {
      const errorObj = FindError(e)
      this.setState({
        displayToast: true,
        messageToast: errorObj.message,
        severity: "error",
        confirmAlert: false,
      });
    }
  };

  filterRecoList = () => {
    let allRecoData = this.state.recommend.map((d) => d.name);
    const allRecoList = this.state.typesList.map((d) => d.name);
    let setAllRecoData = new Set(allRecoData);
    let filteredRecos = allRecoList.filter((x) => !setAllRecoData.has(x));
    this.setState({ filteredRecoList: filteredRecos });
  };

  getRecoConfigs = async () => {
    this.setState({ loadingCard: true });
    try {
      const allOption = {
        url: serverUrl + "reco-config/base/" + localStorage.getItem('pId'),
        params: { projectId: localStorage.getItem("pId") },
      };
      let recoConfigs = await getData(allOption);
      this.setState({
        recommend: recoConfigs.data.data.docs,
        loadingCard: false,
      });
      this.getRecoConfigTypes();
    } catch (e) {
      const errorObj = FindError(e)
      this.setState({
        displayToast: true,
        messageToast: errorObj.message,
        severity: "error",
        confirmAlert: false,
        loadingCard: false
      });
    }
  };

  DisplayingMenu = () => {
    this.setState({ displayMenu: !this.state.displayMenu });
  };

  createRecoConfig = async (obj) => {
    try {
      this.setState({ loadingCard: true });
      const allOption = {
        url: serverUrl + "reco-config/base/" + localStorage.getItem('pId'),
        body: obj,
      };
      await saveData(allOption);
      this.getRecoConfigs();
      this.setState({
        displayToast: true,
        messageToast: "Created Successfully",
        loadingCard: false,
        severity: "success",
      });
    } catch (e) {
      const errorObj = FindError(e)
      this.setState({
        displayToast: true,
        messageToast: errorObj.message,
        severity: "error",
        confirmAlert: false,
        loadingCard: false
      });
    }
  };

  updateRecoConfig = async (index, id, obj) => {
    try {
      this.setState({ loadingCard: true });
      delete obj.id;
      delete obj.createdOn;
      delete obj.modifiedOn
      const allOption = {
        url: serverUrl + "reco-config/base/" + localStorage.getItem('pId') +'/'+ id,
        body: obj,
      };
      await updateDataPut(allOption);
      this.getRecoConfigs();
      this.setState({
        displayToast: true,
        messageToast: "Updated Successfully",
        loadingCard: false,
        severity: "success",
      });
    } catch (e) {
      const errorObj = FindError(e)
      this.setState({
        displayToast: true,
        messageToast: errorObj.message,
        severity: "error",
        confirmAlert: false,
        loadingCard: false
      });
    }
  };

  deleteRecoConfig = async (id) => {
    try {
      this.setState({ loadingCard: true });
      const allOption = {
        url: serverUrl + "reco-config/base/" + localStorage.getItem('pId') + '/' +id,
      };
      await deleteData(allOption);
      this.setState({
        displayToast: true,
        messageToast: "Deleted Successfully",
        loadingCard: false,
        severity: "success",
      });
      this.getRecoConfigs();
    } catch (e) {
      const errorObj = FindError(e)
      this.setState({
        displayToast: true,
        messageToast: errorObj.message,
        severity: "error",
        confirmAlert: false,
        loadingCard: false
      });
    }
  };

  setToggle = (index, value, id) => {
    let recommendations = [...this.state.recommend];
    if (value) {
      recommendations[index].active = false;
    } else {
      recommendations[index].active = true;
    }
    this.updateRecoConfig(index, id, recommendations[index]);
  };

  onClosePanel = () => {
    this.setState({ expansionJrnyPanelOpen: false });
  };

  onSelectTypeReco = (name) => {
    let allTypes = [...this.state.typesList];
    let selectedRecoObj = allTypes.filter((item) => item.name == name);
    let newReco = {
      name: selectedRecoObj[0].name,
      version: localStorage.getItem("version"),
      projectId: localStorage.getItem("pId"),
      userid: localStorage.getItem("botUser"),
      type: selectedRecoObj[0].type,
      typeId: selectedRecoObj[0].id,
      inputJson: { key: "value" },
      outPutJson: { key: "value" },
      url: url,
    };
    this.setState({ displayMenu: false });
    this.createRecoConfig(newReco);
  };

  onDeleteRecommend(id, type) {
    this.setState({ confirmAlert: true, recoBaseId: id, recoType: type });
  }

  onConfirmButtonClick = () => {
    this.setState({ confirmAlert: false });
    this.deleteRecoConfig(this.state.recoBaseId);
  };

  onCancelBtn = () => {
    this.setState({ confirmAlert: false });
  };

  closeToast = () => {
    this.setState({ displayToast: false });
  };

  bannerHeader = () => (
    <div className="display-flex table_head font13 fontWeight700 cardFull cardSpaceArrow">
      <div className="table_grid">
        <p>Recommendation Title</p>
      </div>
      <div className="table_grid">
        <p>Created By</p>
      </div>
      <div className="table_grid">
        <p>Created On</p>
      </div>
      <div className="table_grid">
        <p>Last Modified </p>
      </div>
      <div className="table_grid">
        <p>Active / InActive </p>
      </div>
    </div>
  );

  bannerCard = (item, index) => (
    <div className="display-flex text_black font13 w100 pr15 pl15">
      <LoaderSpinner loadingCard={this.state.loadingCard} />
      <div className="table_grid">
        <Tooltip title={item.name}>
          <p className="overflowTextdots">{item.name}</p>
        </Tooltip>
      </div>
      <div className="table_grid">
        <p className="" style={{ marginLeft: "15px" }}>
          {item.userid}
        </p>
      </div>
      <div className="table_grid">
        <p>
          <img src={calender} className="vertical-7" alt="calendar Icon" />
          <span
            style={{
              color: "#000",
              marginTop: "6px",
            }}
          >
            {moment(item.createdOn).format("DD-MMM-YYYY")}
          </span>
        </p>
      </div>
      <div className="table_grid">
        <p>
          <img src={calender} className="vertical-7" alt="calendar Icon" />
          <span
            style={{
              color: "#000",
              marginTop: "6px",
            }}
          >
            {moment(item.modifiedOn).format("DD-MMM-YYYY")}
          </span>
        </p>
      </div>
      <div className="table_grid text-center">
        <div
          className={`toggle-parent-modeller ${
            item.active ? "" : "inactiveToggle"
          }`}
          onClick={this.setToggle.bind(this, index, item.active, item.id)}
          style={{ marginTop: "9px" }}
        >
          <div
            className={` ${item.active ? "toggle-button" : "disabled-toggle"}`}
          >
            {" "}
          </div>
        </div>
      </div>
      <img
        src={deletewhiteIcon}
        alt="trash Icon"
        onClick={() => this.onDeleteRecommend(item.id, item.type)}
        className="deleteIcon"
      />
    </div>
  );

  render() {
    return (
      <div className="parent mt10">
        {this.state.confirmAlert === true && (
          <ConfirmAlert
            indexValue={this.state.recoIndex}
            onConfirmBtn={this.onConfirmButtonClick}
            onCancelBtn={this.onCancelBtn}
            heading="Are you sure ?"
            paragraph="If you proceed you will loose all your Recommendation Type  Configuration. Are you sure you want to delete your Reco Type "
          />
        )}
        <PositionedSnackbar
          display={this.state.displayToast}
          msg={this.state.messageToast}
          closeToast={this.closeToast}
          severity={this.state.severity}
        />

        <div className="display-flex">
          <h4>
            Recommendation Configuration
            <span className="label_pink">
              Configure the Recommendation Parameters
            </span>
          </h4>
          <div>
            <div className="pos-rel in-block">
              <button
                className="recommend-btn"
                onClick={this.DisplayingMenu.bind(this)}
              >
                <AddIcon className="vertical-7" /> Recommendation Config{" "}
              </button>
              <Menu
                id="simple-menu"
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: "bottom ", horizontal: "right" }}
                transformOrigin={{ vertical: "top", horizontal: "right" }}
                open={this.state.displayMenu}
                onClose={this.DisplayingMenu.bind(this)}
              >
                {this.state.filteredRecoList.length ? (
                  this.state.filteredRecoList.map((item, i) => (
                    <MenuItem
                      key={i}
                      onClick={this.onSelectTypeReco.bind(this, item)}
                      className="menuitem"
                      value={item}
                    >
                      {item}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem className="menuitem" disabled>
                    No Recommendations to add
                  </MenuItem>
                )}
              </Menu>
            </div>
          </div>
        </div>

        {this.bannerHeader()}

        {this.state.recommend.length ? (
          <div>
            {this.state.recommend.map((item, index) => (
              <ExpansionPanel
                className="CustomexpansionPannel"
                key={item.id}
                expanded={
                  this.state.expansionJrnyPanelOpen &&
                  this.state.journeyIndex === index
                }
              >
                <ExpansionPanelSummary
                  className="boxShadow"
                  expandIcon={
                    <ExpandMoreIcon
                      style={{ order: "-1" }}
                      className="iconExpand"
                      onClick={this.switchJrnyExpansionPanel.bind(
                        this,
                        index,
                        item.id,
                        item.typeId
                      )}
                    />
                  }
                >
                  {this.bannerCard(item, index)}
                </ExpansionPanelSummary>
                <ExpansionPanelDetails className="inner_expandBlock">
                  <RecoConfigInstance
                    recoBaseId={this.state.recoBaseId}
                    recoTypeId={this.state.recoTypeId}
                    onClosePanel={this.onClosePanel}
                    displayToast={this.displayToast}
                  />
                </ExpansionPanelDetails>
              </ExpansionPanel>
            ))}
          </div>
        ) : (
          <EmptyCard />
        )}
      </div>
    );
  }
}

export default RecommendationConfig;
