import React, { Component } from "react";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import "./uicomponent.scss";
import successIcon from "../../assets/images/sentiment_satisfied.png";
import errorIcon from "../../assets/images/sentiment_neutral.png";
import warningIcon from "../../assets/images/sentiment_very_dissatisfied.png";
import infoIcon from "../../assets/images/mood_bad.png";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class PositionedSnackbar extends Component {
  render() {
    return (
      <Snackbar
        className="customToastStyle"
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={this.props.display}
        autoHideDuration={5000}
        onClose={this.props.closeToast}
      >
        <Alert
          onClose={this.props.closeToast}
          className="iconSetSize"
          severity={this.props.severity}
        >
          {this.props.severity === "error" && (
            <img src={errorIcon} alt="error Icon" />
          )}
          {this.props.severity === "warning" && (
            <img src={warningIcon} alt="warning Icon" />
          )}
          {this.props.severity === "info" && (
            <img src={infoIcon} alt="info Icon" />
          )}
          {this.props.severity === "success" && (
            <img src={successIcon} alt="success Icon" />
          )}
          <span className="toastMsg">{this.props.msg}</span>
        </Alert>
      </Snackbar>
    );
  }
}

export default PositionedSnackbar;
