import React, { Component } from "react";
import close from "../../assets/images/close.png";
import "./uicomponent.scss";

class ImportDialog extends Component {
  render() {
    return (
      <>
        <div className="alertOverlay"></div>
        <div className="importAlert text-center">
          <img
            className="right"
            alt="close Icon"
            src={close}
            onClick={() => this.props.onClose()}
          />
          <h4>{this.props.heading}</h4>
          <div className="flex-div ">
            <p>Journey Names : {this.props.journeynames}</p>{" "}
          </div>
          <div className="flex-div ">
            <p>No of Components Imported : {this.props.data?.components}</p>{" "}
          </div>
          <div className="flex-div ">
            <p>No of Edges : {this.props.data?.edges} </p>{" "}
          </div>
          <div className="flex-div ">
            <p>No of Business Functions : {this.props.data?.businessmodules}</p>{" "}
          </div>
        </div>
      </>
    );
  }
}

export default ImportDialog;
