import React, { Component } from "react";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AddIcon from "@material-ui/icons/Add";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import moment from "moment";
import { Formik, Field, Form, FieldArray } from "formik";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import {
  getData,
  saveData,
  deleteData,
  updateData,
} from "../../dataProvider/apiHandler";
import ConfirmAlert from "../../commons/uicomponents/confirm";
import EmptyCard from "../../commons/uicomponents/emptycard";
import LoaderSpinner from "../../commons/uicomponents/loader";
import PositionedSnackbar from "../../commons/uicomponents/toast";
import "./billing.scss";
import deletewhiteIcon from "../../assets/images/deletewhite.png";
import searchIcon from "../../assets/images/search.png";
import calender from "../../assets/images/calender.png";
import chevronDown from "../../assets/images/chevron-down.png";
import DeleteIcon from "../../assets/images/subtract.png";

const projectId = localStorage.getItem("pId");
const serverUrl =
  window._env_.REACT_APP_SERVER_URL + `api/projects/` + projectId;

// initial Values loading for slabs
const initialValues = {
  journey: "",
  slabs: [
    {
      min: "",
      max: "",
      price: "",
    },
  ],
};

class BillingConfig extends Component {
  constructor(props) {
    super(props);
    this.setFormValues = [];
    this.state = {
      billingConfigs: [],
      expansionJrnyPanelOpen: false,
      expansionIGPanelOpen: false,
      expansionCCPanelOpen: false,
      billingsIndex: "",
      confirmAlert: false,
      JourneyconfirmAlert: false,
      slabsIndex: "",
      billingConfigsNIndex: "",
      journeyList: [],
      FjourneyList: [],
      journeyListForSearch: [],
      journeyListdiv: false,
      titleName: true,
      loadingCard: false,
      displayToast: false,
      pageNo: 1,
      submitBtnDisable: false,
      billConfigId: "",
      displayMenu: false,
      permissions: {},
    };
    this.conversation = React.createRef();
  }

  componentDidMount = () => {
    this.getAllData();
    this.getAllJourneyList();
  };

  filterJourneysToAdd = () => {
    let billConfigs = this.state.billingConfigs.map(
      (d) => d.journey && d.journey.name
    );
    const allJourneys = this.state.journeyList.map((d) => d.name);
    let setAllBillConfig = new Set(billConfigs);
    let filterJourneys = allJourneys.filter((x) => !setAllBillConfig.has(x));
    this.setState({ FjourneyList: filterJourneys });
  };

  // EXPANSIONS HANDLERS
  switchJrnyExpansionpanel(index) {
    this.setState({
      conversationID: "",
    });
    if (this.state.billingsIndex === index) {
      this.setState({
        expansionJrnyPanelOpen: false,
        billingsIndex: "",
      });
    } else {
      this.setState({
        expansionJrnyPanelOpen: true,
        billingsIndex: index,
      });
      this.getSelectedData(index);
    }
  }

  getAllData = async (paramss) => {
    try {
      this.setState({ loadingCard: true });
      const otherParams = {
        paginate: true,
        filter: {
          journeyName: "",
        },
      };
      paramss = {
        ...otherParams,
        ...paramss,
      };
      const allOptions = {
        url: serverUrl + "/billing-config",
        params: { ...paramss },
      };
      let initialLoad = await getData(allOptions);
      this.setState({
        billingConfigs: initialLoad.data.data,
        totalPages: initialLoad.data.data.pages,
        loadingCard: false,
      });
      this.filterJourneysToAdd();
    } catch (e) {
      console.log(e);
    }
  };

  getAllJourneyList = async () => {
    try {
      const allOptions = {
        url: serverUrl + "/tasks",
      };
      let initialLoad = await getData(allOptions);
      this.setState({
        journeyList: initialLoad.data.data,
        journeyListForSearch: initialLoad.data.data,
      });
      this.filterJourneysToAdd();
    } catch (e) {
      console.log(e);
    }
  };

  // initializing the values to fileds which are rendering from api
  getSelectedData = (index) => {
    this.setFormValues[index]({
      slabs: this.state.billingConfigs[index].slabs,
    });
  };

  DisplayingMenu = () => {
    this.setState({ displayMenu: !this.state.displayMenu });
  };

  // to add a new billing config card
  addNewJourney = (data) => {
    let journeys = [...this.state.journeyList];
    let selectedJourObj = journeys.filter((item) => item.name === data);
    this.onNewJourney(selectedJourObj[0]);
  };

  // to add a new journey from the journey lis dropdown
  onNewJourney = async (obj) => {
    let billConfig = [...this.state.billingConfigs];
    billConfig.push({
      id: obj._id,
      name: obj.name,
      owner: "name",
      active: true,
      slabs: [{ min: "1", max: "", price: "" }],
    });
    this.setState({
      billingConfigs: billConfig,
      journeyListdiv: false,
      displayMenu: false,
      displayToast: true,
      messageToast: "New Billing config card added in bottom",
      severity: "success",
    });
  };

  // to select journeyname filter
  searchDataFilter = async (event) => {
    let searchText = event.currentTarget.textContent;
    this.setState({ searchData: searchText });
    const paramsmet = {
      paginate: true,
      filter: {
        journeyName: event.currentTarget.textContent,
      },
    };
    this.getAllData(paramsmet);
  };

  setToggle = async (index, value) => {
    let billConfig = [...this.state.billingConfigs];
    if (value) {
      billConfig[index].active = false;
    } else {
      billConfig[index].active = true;
    }
    this.formSubmit(billConfig[index], index, billConfig[index]);
    this.setState({ billingConfigs: billConfig });
  };

  formSubmit = async (item, index, values) => {
    let billConfig = [...this.state.billingConfigs];
    let dataId = billConfig[index]["_id"];
    let newJourneyId = item.id;
    values.journey = newJourneyId;
    if (values.slabs.every((d) => d.price) && values.slabs.every((d) => d.max)) {
      try {
        this.setState({ loadingCard: true });
        if (item._id) {
          values["id"] = dataId;
          // values['journey'] = newJourneyId;
          const allOptions = {
            url: serverUrl + "/billing-config/" + item._id,
            body: values,
          };
          await updateData(allOptions);
          this.setState({
            displayToast: true,
            messageToast: "Updated Successfully",
            severity: "success",
          });
          this.getAllData();
          this.switchJrnyExpansionpanel();
        } else {
          const allOptions = {
            url: serverUrl + "/billing-config/",
            body: values,
          };
          await saveData(allOptions);
          this.setState({
            displayToast: true,
            messageToast: "Created Successfully",
            severity: "success",
          });
          this.filterJourneysToAdd();
          this.getAllData();
          this.switchJrnyExpansionpanel();
        }
        this.setState({ loadingCard: false });
      } catch (e) {
        console.log(e);
      }
    } else {
      this.setState({
        displayToast: true,
        messageToast: "All Slabs 'Max & Price' value are required",
        JourneyconfirmAlert: false,
        severity: "error",
        loadingCard: false,
      });
    }
  };

  // onChange slabs max value update to the next slab index min value
  slabMaxPrice = async (e, val, billingSlabsIndex) => {
    // condition to make submit button disable/enable
    if (val.slabs[billingSlabsIndex].max === "") {
      val.addSubmitBtnDis = false;
      this.setState({ submitBtnDisable: false });
    } else {
      val.addSubmitBtnDis = true;
      this.setState({ submitBtnDisable: true });
    }
    // slabs max value update to the next slab index min value
    let getIndexMaxVal = (val.slabs[billingSlabsIndex].max = e.target.value);
    if (billingSlabsIndex < val.slabs.length - 1) {
      val.slabs[billingSlabsIndex + 1].min = Number(getIndexMaxVal) + 1;
    }
  };

  // on key press slabs max value update to the next slab index min value
  slabNextMinPrice = async (e, val, billingSlabsIndex) => {
    this.slabMaxPrice(e, val, billingSlabsIndex);
  };

  // if remove the single slab update its min value to the next slabs min value
  removeSingleSlab = (slabs, billingSlabsIndex, remove) => {
    let checkLastObjIndex = billingSlabsIndex + 1;
    if (slabs.length === checkLastObjIndex) {
      remove(billingSlabsIndex);
    } else {
      let deleteSlabMinVal = slabs[billingSlabsIndex].min;
      slabs[billingSlabsIndex + 1].min = deleteSlabMinVal;
      remove(billingSlabsIndex);
    }
  };

  // to check if the slab max field empty or not and change the state to make submit button and add button disable
  validateSlabMaxField = (value) => {
    let error;
    if (value === "") {
      this.setState({ submitBtnDisable: false });
    } else {
      this.setState({ submitBtnDisable: true });
    }
    return error;
  };

  validateSlabPriceField = (value) => {
    let error;
    if (value === "") {
      this.setState({ submitBtnDisable: false });
    } else {
      this.setState({ submitBtnDisable: true });
    }
    return error;
  };

  removeJourney = (index, billId) => {
    if (billId) {
      this.setState({
        JourneyconfirmAlert: true,
        billingConfigsNIndex: index,
        billConfigId: billId,
      });
    } else {
      let billingList = [...this.state.billingConfigs];
      billingList.splice(index, 1);
      this.setState({ billingConfigs: billingList });
    }
  };

  onConfirmButtonClick = () => {
    this.confirmDelete(this.state.billConfigId);
  };

  // Close Toast
  closeToast = () => {
    this.setState({ displayToast: false });
  };

  confirmDelete = async (id) => {
    if (id) {
      try {
        this.setState({ loadingCard: true });
        const allOptions = {
          url: serverUrl + "/billing-config/" + id,
        };
        await deleteData(allOptions);
        this.setState({
          JourneyconfirmAlert: false,
          loadingCard: false,
          displayToast: true,
          messageToast: "Deleted Successfully",
          severity: "success",
        });
        this.getAllData();
      } catch (e) {
        console.log(e);
      }
    }
  };
  cancelBtn = () => {
    this.setState({
      JourneyconfirmAlert: false,
    });
  };

  journeyBanner = () => {
    return (
      <div className="display-flex table_head font13 fontWeight700 cardFull cardSpaceArrow">
        <div className="table_grid">
          <p>Journey Type </p>
        </div>
        <div className="table_grid">
          <p>No of slabs</p>
        </div>
        <div className="table_grid">
          <p>Created By</p>
        </div>
        <div className="table_grid">
          <p>Created on</p>
        </div>
        <div className="table_grid">
          <p>Last Modified on</p>
        </div>
        <div className="table_grid">
          <p>Enable / Disable</p>
        </div>
      </div>
    );
  };

  journeyHeader = (item, index) => {
    return (
      <div className="display-flex text_black font13 w100 pr15 pl15">
        <LoaderSpinner loadingCard={this.state.loadingCard} />
        <div className="table_grid">
          {item.journey ? (
            <div>
              {" "}
              {item && item.journey && (
                <p className="in-block"> {item.journey.name} </p>
              )}
            </div>
          ) : (
            <div> {<p className="in-block"> {item.name}</p>}</div>
          )}
        </div>
        <div className="table_grid">
          <p> {item.slabs.length} </p>
        </div>
        <div className="table_grid">
          {item && item.user && <p className=""> {item.user.name} </p>}
        </div>
        <div className="table_grid">
          <p>
            <img src={calender} className="vertical-7" alt="calendar Icon" />
            <span
              style={{
                color: "#000",
                marginTop: "6px",
              }}
            >
              {moment(item.created_at).format("DD-MMM-YYYY")}
            </span>
          </p>
        </div>
        <div className="table_grid">
          <p className="flex" style={{ marginTop: "8px" }}>
            <img src={calender} className="vertical-7" alt="calendar Icon" />
            <span
              style={{
                color: "#000",
                marginTop: "6px",
              }}
            >
              {moment(item.created_at).format("DD-MMM-YYYY")}
            </span>
          </p>
        </div>
        <div className="table_grid text-center">
          <div
            className={`toggle-parent-modeller ${
              item.active ? "" : "inactiveToggle"
            }`}
            id={`billignConfigToggle${index}`}
            onClick={this.setToggle.bind(this, index, item.active)}
            style={{ marginTop: "9px" }}
          >
            <div
              className={` ${
                item.active ? "toggle-button" : "disabled-toggle"
              }`}
            >
            </div>
          </div>
        </div>
        <img
          src={deletewhiteIcon}
          id={`billigConfigDeleteImg${index}`}
          alt="trash Icon"
          onClick={() => this.removeJourney(index, item._id)}
          className="deleteIcon"
        />
      </div>
    );
  };

  render() {
    return (
      <div className="parent mt10">
        {this.state.JourneyconfirmAlert === true && (
          <ConfirmAlert
            onConfirmBtn={this.onConfirmButtonClick}
            onCancelBtn={this.cancelBtn}
            heading="Are you Sure?"
            paragraph="If you proceed you will loose the data. Are you sure you want to delete ?"
          />
        )}
        <PositionedSnackbar
          display={this.state.displayToast}
          msg={this.state.messageToast}
          closeToast={this.closeToast}
          severity={this.state.severity}
        />
        <div className="display-flex">
          <h4>
            Billing Configuration
            <span className="label_pink">
              Assign a Slab rate to the journey
            </span>
          </h4>
          <div>
            <div className="pos-rel in-block searchFilter">
              <img src={searchIcon} alt="searchIcon" className="searchIcon" />
              <Autocomplete
                id="custom-input-demo"
                options={this.state.journeyListForSearch.map((a) => a.name)}
                onChange={(event) => this.searchDataFilter(event)}
                renderInput={(params) => (
                  <div ref={params.InputProps.ref}>
                    <input
                      type="text"
                      placeholder="Search Journey Name"
                      {...params.inputProps}
                    />
                  </div>
                )}
              />
              <img
                src={chevronDown}
                alt="chevron down"
                className="downArrrowChev"
                style={{ right: "10px" }}
              />
            </div>

            {this.state.permissions ? (
              <div className="pos-rel in-block">
                <button
                  className="recommend-btn"
                  onClick={this.DisplayingMenu.bind(this)}
                  id="addNewBillingConfig"
                >
                  <AddIcon className="vertical-7" />
                  Billing Config
                </button>
                <Menu
                  id="simple-menu"
                  getContentAnchorEl={null}
                  anchorOrigin={{ vertical: "bottom ", horizontal: "right" }}
                  transformOrigin={{ vertical: "top", horizontal: "right" }}
                  open={this.state.displayMenu}
                  onClose={this.DisplayingMenu.bind(this)}
                >
                  {this.state.FjourneyList.length ? (
                    this.state.FjourneyList.map((item, i) => (
                      <MenuItem
                        onClick={this.addNewJourney.bind(this, item)}
                        id={`journeysList${i}`}
                        className="menuitem"
                        value={item}
                        key={i}
                      >
                        {item}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem className="menuitem" disabled>
                      No Journeys to add
                    </MenuItem>
                  )}
                </Menu>
              </div>
            ) : null}
          </div>
        </div>
        {this.journeyBanner()}
        {this.state.billingConfigs.length ? (
          <div>
            {this.state.billingConfigs.map((item, index) => (
              <ExpansionPanel
                className="CustomexpansionPannel"
                key={item._id + index}
                expanded={
                  this.state.expansionJrnyPanelOpen &&
                  this.state.billingsIndex === index
                }
              >
                <ExpansionPanelSummary
                  expandIcon={
                    <ExpandMoreIcon
                      className="iconExpand"
                      id={`expandCardArrow${index}`}
                      onClick={this.switchJrnyExpansionpanel.bind(
                        this,
                        index
                      )}
                    />
                  }
                  style={{
                    order: -1,
                  }}
                  className="boxShadow"
                >
                  {this.journeyHeader(item, index)}
                </ExpansionPanelSummary>
                <ExpansionPanelDetails className="inner_expandBlock">
                  <div className="text-center">
                    <div className="formField">
                      <Formik
                        onSubmit={(e) => this.formSubmit(item, index, e)}
                        initialValues={initialValues}
                        enableReinitialize={true}
                      >
                        {({
                          values,
                          ...rest
                        }) => {
                          this.setFormValues[index] = rest.setValues;
                          return (
                            <Form>
                              <FieldArray name="slabs">
                                {({ remove, push }) => (
                                  <div>
                                    <div className="text-right">
                                      <button
                                        type="button"
                                        id={`addNewSlab${index}`}
                                        className="sxp-btn btn-primary"
                                        disabled={
                                          values
                                            ? false
                                            : this.state.submitBtnDisable &&
                                              !values.addSubmitBtnDis
                                        }
                                        onClick={async () => {
                                          values.addSubmitBtnDis = false;
                                          let getCardLastSlab =
                                            values.slabs.length - 1;
                                          push({
                                            min:
                                              Number(
                                                values.slabs[getCardLastSlab]
                                                  .max
                                              ) + 1,
                                            max: "",
                                            price: "",
                                          });
                                        }}
                                      >
                                        <AddIcon className="vertical-7" /> NEW
                                        SLAB
                                      </button>
                                    </div>
                                    <div className="filedsHeader">
                                      <div>Min</div>
                                      <div>Max</div>
                                      <div>Price</div>
                                    </div>
                                    {values.slabs.length > 0 &&
                                      values.slabs.map(
                                        (slab, billingSlabsIndex) => (
                                          <div
                                            className="flex"
                                            style={{
                                              marginTop: "8px",
                                              justifyContent: "center",
                                            }}
                                            key={billingSlabsIndex}
                                          >
                                            <div className="">
                                              <Field
                                                name={`slabs.${billingSlabsIndex}.min`}
                                                placeholder="min"
                                                type="number"
                                                disabled={true}
                                              />
                                            </div>
                                            <div className="">
                                              <Field
                                                name={`slabs.${billingSlabsIndex}.max`}
                                                placeholder="max"
                                                type="number"
                                                className="arrowIconNone"
                                                validate={
                                                  this.validateSlabMaxField
                                                }
                                                onBlur={(e) =>
                                                  this.slabMaxPrice(
                                                    e,
                                                    values,
                                                    billingSlabsIndex
                                                  )
                                                }
                                                onKeyUp={(e) =>
                                                  this.slabNextMinPrice(
                                                    e,
                                                    values,
                                                    billingSlabsIndex
                                                  )
                                                }
                                              />
                                            </div>
                                            <div className="">
                                              <Field
                                                name={`slabs.${billingSlabsIndex}.price`}
                                                placeholder="price"
                                                validate={
                                                  this.validateSlabPriceField
                                                }
                                                className="arrowIconNone"
                                                type="number"
                                              />
                                            </div>
                                            <div className="">
                                              <button
                                                type="button"
                                                disabled={
                                                  values.slabs.length < 2
                                                }
                                                className="trashTransparent"
                                                id={`removeBillingSlab${billingSlabsIndex}`}
                                                onClick={() =>
                                                  this.removeSingleSlab(
                                                    values.slabs,
                                                    billingSlabsIndex,
                                                    remove
                                                  )
                                                }
                                              >
                                                <img
                                                  src={DeleteIcon}
                                                  alt="trash Icon"
                                                />
                                              </button>
                                            </div>
                                          </div>
                                        )
                                      )}
                                  </div>
                                )}
                              </FieldArray>
                              <div className="mt20">
                                <button
                                  className="sxp-btn btn-primary primaryLarge"
                                  type="submit"
                                  id={`saveBillingConfig${index}`}
                                  style={{
                                    height: "34px",
                                  }}
                                >
                                  Save
                                </button>
                                <span
                                  className="sxp-btn btn-danger primaryLarge"
                                  onClick={() => this.cancelExpand(index)}
                                  id={`cancelBillingConfig${index}`}
                                  style={{
                                    height: "34px",
                                    width: "200px",
                                    margin: "0 5px",
                                    padding: "9px 0",
                                    display: "inline-block",
                                    verticalAlign: "top",
                                  }}
                                >
                                  Cancel
                                </span>
                              </div>
                            </Form>
                          );
                        }}
                      </Formik>
                    </div>
                  </div>
                </ExpansionPanelDetails>
              </ExpansionPanel>
            ))}
          </div>
        ) : (
          <EmptyCard />
        )}
      </div>
    );
  }
}

export default BillingConfig;
