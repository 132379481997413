import React from "react";
import "./versioning.scss";

/*
@method: Backdrop
@description: to add the transparent backdrop around the modal
*/
const Backdrop = (props) =>
  props.show ? <div className="Backdrop" onClick={props.clicked}></div> : null;

/*
@method: Function based Modal Component
@description: to render Modal child data
*/
const Modal = (props) => {
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <Backdrop show={props.show} clicked={props.modalClosed} />
      <div
        className="Modal"
        style={{
          transform: props.show ? "translateY(0)" : "translateY(-100vh)",
          opacity: props.show ? 1 : 0,
        }}
      >
        {props.modalContent}
      </div>
    </div>
  );
};

export default Modal;
