import React, { memo } from "react";
import { Handle } from "react-flow-renderer";
import endIcon from './../../../assets/images/modeler/end.png';

export default memo(({ data }) => {
  const compColor = data?.style?.backgroundColor;
  const isValidConnection = (connection) => {
    return connection.target !== connection.source;
  };
  return (
    <>
      <div style={{width:'74px', position:'relative'}}>
        <div className="img_node_overlay"></div>
        <img className="w100" src={endIcon} />
      </div>
      <Handle
        type="target"
        position="top"
        className="react_flow_handle_bottom_end"
        style={{ background: "white", border: `1px solid ${compColor}` }}
        isValidConnection={isValidConnection}
      />
    </>
  );
});
