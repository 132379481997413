import React, { Component } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import AnalyticsDashboard from "./containers/analytics";
import JourneyListing from "./containers/journeys";
import BillingSummary from "./containers/billing/billing-summary";
import BillingConfig from "./containers/billing/billing-config";
import Modeler from "./containers/modeler";
import ApiServices from "./containers/api";
import DashboardRenderer from "./containers/dashboardRender";
import BusinessModule from "./containers/businessmodule";
import UserFunction from "./containers/userfunction";
import GlobalVariable from "./containers/globalvariables";
import ChannelConfig from "./containers/channelconfig";
import RecommendationConfig from "./containers/recommendationconfig";
import IntentClassification from "./containers/intentclassification";
import Editor from "./nluEditor/web/components/Editor/Editor"
import FAQUpload from "./containers/faq";
import Versioning from "./containers/versioning";
import Profile from "./containers/profile";
import OAuthConfig from "./containers/oauthconfig";
import ParseUi from "./containers/parse-ui";
import RestApiDocs from "./containers/parse-ui/restApiDoc";
import JourneyRoutes from "./containers/journeyroutes";
// main layout routing setup page
class Layout extends Component {
  render() {
    return (
      <Switch>
        <Route exact path="/">
          <Redirect to="/analytics" component={AnalyticsDashboard} />
        </Route>
        
        <Route exact path="/layout" component={DashboardRenderer} />
        <DashboardRenderer>
          <Route exact path="/analytics" component={AnalyticsDashboard} />
          <Route exact path="/journey-listing" component={JourneyListing} />
          <Route exact path="/billing-summay" component={BillingSummary} />
          <Route exact path="/billing-config" component={BillingConfig} />
          <Route exact path="/modeller" component={Modeler} />
          <Route exact path="/api-services" component={ApiServices} />
          <Route exact path="/business-module" component={BusinessModule} />
          <Route exact path="/user-functions" component={UserFunction} />
          <Route exact path="/global-variables" component={GlobalVariable} />
          <Route exact path="/oauth-config" component={OAuthConfig} />
          <Route exact path="/app-schema" component={ParseUi} />
          <Route exact path="/rest-api-docs" component={RestApiDocs} />          
          <Route exact path="/channel-config" component={ChannelConfig} />
          <Route
            exact
            path="/recommendation-config"
            component={RecommendationConfig}
          />
          <Route
            exact
            path="/intent-classification"
            component={Editor}
          />
          <Route exact path="/faq-upload" component={FAQUpload} />
          <Route exact path="/versioning" component={Versioning} />
          <Route exact path="/journey-routes" component={JourneyRoutes} />
          <Route exact path="/profile" component={Profile} />
        </DashboardRenderer>
      </Switch>
    );
  }
}

// export default withRouter(Layout);
export default Layout;
