import React, { Component } from "react";
import warningIcon from "../../assets/images/warning.png";
import "./uicomponent.scss";

class ConfirmAlert extends Component {
  render() {
    return (
      <>
        <div className="overlay"></div>
        <div className="confirmAlert text-center">
          <img src={warningIcon} alt="warning Icon" />
          <h4>{this.props.heading}</h4>
          <p>{this.props.paragraph}</p>
          <button
            className="cancelBtn"
            id="cancelConfirmBtn"
            onClick={() => this.props.onCancelBtn()}
          >
            Cancel
          </button>
          <button
            id="saveConfirmBtn"
            className="confirmBtn"
            onClick={() => this.props.onConfirmBtn()}
          >
            Confirm
          </button>
        </div>
      </>
    );
  }
}

export default ConfirmAlert;
