import React, { Component } from "react";
import AddIcon from "@material-ui/icons/Add";
import { MenuItem, Select } from "@material-ui/core";
import { getData, getSingleData, saveData, updateData, updateDataPut } from "../../dataProvider/apiHandler";
import { fileUpload } from "../faq/fileUploadApi";
import PositionedSnackbar from "../../commons/uicomponents/toast";
import chevronDown from "../../assets/images/chevron-down.png";
import chevronUp from "../../assets/images/chevron-down.png";
import trash from "../../assets/images/subtract.png";
import hide from "../../assets/images/visibility_off.png";
import attachment from "../../assets/images/attachment.png";
import view from "../../assets/images/visibility.png";
import FindError from "../../dataProvider/errorHandler";

const serverUrl = window._env_.REACT_APP_SXP_URL ;

class RecoConfigInstance extends Component {
  constructor(props) {
    super(props);
    this.inputOpenFileRef = React.createRef();
    this.state = {
      recoInstance: "",
      displayApiDetails: false,
      files: [],
      filesList: [],
      instance: {},
      noOfReco: 0,
      baseDetails: "",
      datasetValues: [],
      dataSets: [],
      parameters: [],
      parametersValues: [],
      confirmAlert: false,
      datasetIndex: "",
      instanceVal: [],
      displayToast: false,
    };
  }

  componentDidMount = () => {
    // this.getAllReferences();
  };

  componentDidUpdate = (prevProps) => {
    if (
      prevProps &&
      prevProps.recoBaseId != this.props.recoBaseId &&
      this.props.recoBaseId != undefined
    ) {
      this.getRecoConfigType(this.props.recoTypeId);
      this.getRecoConfigInstancebyBaseId(this.props.recoBaseId);
      this.getRecoConfig(this.props.recoBaseId);
      this.getAllFiles();
      this.getJourneysList();
    }
  };

  getAllReferences = async (baseId) => {
    try {
      const allOption = {
        url: serverUrl + "reco-config/references/"+localStorage.getItem('pId'),
        params:{baseId:baseId}
      };
      let allRef = await getSingleData(allOption);
      this.setState({ instanceVal: allRef.data.data });
    } catch (e) {
      const errorObj = FindError(e)
      this.setState({
        displayToast: true,
        messageToast: errorObj.message,
        severity: "error",
        confirmAlert: false,
      });
    }
  };

  getRecoConfigType = async (id) => {
    try {
      if (id) {
        const allOption = {
          url: serverUrl + "reco-config/type/" + localStorage.getItem('pId') + '/'+id,
        };
        let recoConfigtype = await getData(allOption);
        let data = recoConfigtype.data.data;
        this.setState({ dataSets: data.dataSets });
      }
    } catch (e) {
      const errorObj = FindError(e)
      this.setState({
        displayToast: true,
        messageToast: errorObj.message,
        severity: "error",
        confirmAlert: false,
      });
    }
  };

  getRecoConfigInstancebyBaseId = async (id) => {
    try {
      if (id) {
        const allOption = {
          url: serverUrl + "reco-config/instance/"+localStorage.getItem('pId')+"/by-base-id",
          params: { baseId: id },
        };
        let recoConfigs = await getSingleData(allOption);
        if (recoConfigs) {
          let data = recoConfigs.data.data;
          this.setState({
            parametersValues: data.parametersValues,
            datasetValues: data.datasetValues,
            noOfReco: data.noOfReco,
            instance: data,
          });
        }
      }
    } catch (e) {
      const errorObj = FindError(e)
      this.setState({
        displayToast: true,
        messageToast: errorObj.message,
        severity: "error",
        confirmAlert: false,
      });
    }
  };

  getRecoConfig = async (baseId) => {
    try {
      const allOption = {
        url: serverUrl + "reco-config/base/" + localStorage.getItem('pId') + '/' + baseId,
      };
      let recoConfigs = await getData(allOption);
      this.getAllReferences(baseId);
      this.setState({ baseDetails: recoConfigs.data.data });
    } catch (e) {
      const errorObj = FindError(e)
      this.setState({
        displayToast: true,
        messageToast: errorObj.message,
        severity: "error",
        confirmAlert: false,
      });
    }
  };

  getAllFiles = async () => {
    try {
      const allOption = {
        url: serverUrl + "reco-config/file/"+localStorage.getItem('pId'),
      };
      let files = await getData(allOption);
      this.setState({ filesList: files.data.data.docs });
    } catch (e) {
      const errorObj = FindError(e)
      this.setState({
        displayToast: true,
        messageToast: errorObj.message,
        severity: "error",
        confirmAlert: false,
      });
    }
  };

  getJourneysList = async () => {
    try {
      const allOption = {
        url: serverUrl + "tasks/" + localStorage.getItem('pId'),
        params:{projectId:localStorage.getItem('pId')}
      };
      let initialLoad = await getData(allOption);
      this.setState({ parameters: initialLoad.data.data });
    } catch (e) {
      const errorObj = FindError(e)
      this.setState({
        displayToast: true,
        messageToast: errorObj.message,
        severity: "error",
        confirmAlert: false,
      });
    }
  };

  onUploadFile(e, index) {
    var files = e.target.files[0];
    this.uploadRecoConfigFile(files);
    let data = [...this.state.datasetValues];
    data[index].fileName = files.name;
    this.setState({
      datasetValues: data,
    });
  }
  uploadRecoConfigFile = async (fileObj) => {
    try {
      const allOption = {
        url: serverUrl + "reco-config/file/"+ localStorage.getItem('pId') +"/upload",
        file: fileObj,
      };
      await fileUpload(allOption);
    } catch (e) {
      const errorObj = FindError(e)
      this.setState({
        displayToast: true,
        messageToast: errorObj.message,
        severity: "error",
        confirmAlert: false,
      });
    }
  };

  removeFile(f) {
    this.setState({ files: this.state.files.filter((x) => x !== f) });
  }

  removeData(index, value) {
    if (value === "dataset") {
      let data = [...this.state.datasetValues];
      data.splice(index, 1);
      this.setState({ datasetValues: data });
    } else {
      let data = [...this.state.parametersValues];
      data.splice(index, 1);
      this.setState({ parametersValues: data });
    }
  }

  onAddParameter() {
    let newParams = [...this.state.parametersValues];
    newParams.push({ name: "", value: 0, range: "[1 to 100]" });
    this.setState({ parametersValues: newParams });
  }

  onAddDataFile() {
    let newData = [...this.state.datasetValues];
    newData.push({ name: "", fileName: "", fileUrl: "" });
    this.setState({ datasetValues: newData });
  }

  onIncreaseNumber(index, value) {
    let data = [...this.state.parametersValues];
    data[index].value = value + 1;
    this.setState({ parametersValues: data });
  }

  onDecreaseNumber(index, value) {
    let data = [...this.state.parametersValues];
    data[index].value = value - 1;
    this.setState({ parametersValues: data });
  }

  onIncreaseNoReco() {
    let data = this.state.noOfReco;
    data = data + 1;
    this.setState({ noOfReco: data });
  }

  onDecreaseNoReco() {
    let data = this.state.noOfReco;
    data = data - 1;
    this.setState({ noOfReco: data });
  }

  onDisplayDetails() {
    this.setState({ displayApiDetails: !this.state.displayApiDetails });
  }

  // DROPDOWN FUNCTIONALITIES
  selectDataset = (e, child) => {
    const value = e.target.value;
    const index = child.props.index;
    let data = [...this.state.datasetValues];
    data[index].name = value;
    this.setState({
      datasetValues: data,
    });
  };

  selectComponent = (e, child) => {
    const value = e.target.value;
    const index = child.props.index;
    let data = [...this.state.parametersValues];
    data[index].parameterReference = value;
    this.setState({
      parametersValues: data,
    });
  };

  selectFile = (e, child) => {
    const value = e.target.value;
    const index = child.props.index;
    let data = [...this.state.datasetValues];
    data[index].fileName = value;
    this.setState({
      datasetValues: data,
    });
  };

  onSave = () => {
    let data = {
      noOfReco: this.state.noOfReco,
      datasetValues: this.state.datasetValues,
      parametersValues: this.state.parametersValues,
      typeId: this.props.recoTypeId,
      baseId: this.props.recoBaseId,
    };
    if (this.state.instance.id) {
      this.updateRecoConfig(data, this.state.instance.id);
    } else {
      this.createRecoConfig(data);
    }
  };

  createRecoConfig = async (obj) => {
    try {
      const extObj = {
        ...obj,
        projectId: localStorage.getItem("pId"),
        version: localStorage.getItem("version")?.toUpperCase(),
      }
      const allOption = {
        url: serverUrl + "reco-config/instance/" + localStorage.getItem('pId'),
        body: extObj,
      };
      let newInstance = await saveData(allOption);
      let data = newInstance.data.data;
      this.setState({
        parametersValues: data.parametersValues,
        datasetValues: data.datasetValues,
        noOfReco: data.noOfReco,
        instance: data,
        displayToast: true,
        messageToast: "Created Successfully",
        loadingCard: false,
        severity: "success",
      });
      setTimeout(() => this.onCancel(), 2000);
    } catch (e) {
      const errorObj = FindError(e)
      this.setState({
        displayToast: true,
        messageToast: errorObj.message,
        severity: "error",
        confirmAlert: false,
      });
    }
  };

  updateRecoConfig = async (obj, id) => {
    try {
      delete obj.id;
      const extObj = {
        ...obj,
        projectId: localStorage.getItem("pId"),
        version: localStorage.getItem("version")?.toUpperCase(),
      }
      const allOption = {
        url: serverUrl + "reco-config/instance/" + localStorage.getItem('pId') +'/'+ id,
        body: extObj,
      };
      await updateDataPut(allOption);
      this.setState({
        displayToast: true,
        messageToast: "Updated Successfully",
        loadingCard: false,
        severity: "success",
      });
      setTimeout(() => this.onCancel(), 2000);
    } catch (e) {
      const errorObj = FindError(e)
      this.setState({
        displayToast: true,
        messageToast: errorObj.message,
        severity: "error",
        confirmAlert: false,
      });
    }
  };

  onCancel = () => {
    this.props.onClosePanel();
  };

  closeToast = () => {
    this.setState({ displayToast: false });
  };

  uploadData = () => (
    <div>
      <div className="display-flex">
        <h4 className="m0 mt5">1. Upload / Update Static Data-sets required</h4>
        <button
          className="recommend-btn"
          onClick={this.onAddDataFile.bind(this)}
        >
          <AddIcon className="vertical-7" />
          DATA FILE
        </button>
      </div>
      <p className="desc">
        <b>Description:</b> This recommendation type returns a combination of
        Facility-location-service-doctor (Output) for a patient (input:
        patient-ID) in response to the API invocation.
      </p>
      <div className="card">
        {this.state.datasetValues.map((item, index) => (
          <div className="flex" key={index}>
            <Select
              onChange={this.selectDataset}
              disableUnderline
              className="sxp-defaultDropDown_filed entity-ip customHover reco-drpdwn"
              value={item.name}
              labelId="label"
              id="select"
            >
              {this.state.dataSets.map((itemData, indexDatasets) => {
                return (
                  <MenuItem
                    value={itemData.name}
                    key={indexDatasets}
                    index={index}
                  >
                    {itemData.name}
                  </MenuItem>
                );
              })}
            </Select>
            <div className="pos-rel flex handleSecondUpload">
              <Select
                onChange={this.selectFile}
                disableUnderline
                className="sxp-defaultDropDown_filed uploadfile-text reco-drpdwn"
                value={item.fileName}
                defaultValue={item.fileName}
                labelId="label"
                id="select"
              >
                {this.state.filesList.map((itemData, indexDatasets) => {
                  return (
                    <MenuItem
                      key={indexDatasets}
                      value={itemData.fileName}
                      index={index}
                    >
                      {itemData.fileName}
                    </MenuItem>
                  );
                })}
              </Select>
              <div className="flex-div left-percentage">
                <label className="custom-file-upload flex-div">
                  <input
                    type="file"
                    onChange={(e) => {
                      this.onUploadFile(e, index);
                    }}
                  />
                  <div
                    className="file-preview"
                    onClick={this.removeFile.bind(this, item.fileName)}
                  ></div>
                  <img
                    alt="attachmentIcon"
                    type="file"
                    className="icons-upload move-right"
                    src={attachment}
                  />
                </label>
              </div>
            </div>
            <img
              alt="removeIcon"
              className="removeIcon"
              onClick={this.removeData.bind(this, index, "dataset")}
              src={trash}
            />
          </div>
        ))}
      </div>
    </div>
  );

  configuration = () => (
    <div className="ml20">
      <div className="display-flex">
        <h4 className="m0 mt5">2. Configure the Recommendations</h4>
        <button
          className="recommend-btn"
          onClick={this.onAddParameter.bind(this)}
        >
          <AddIcon className="vertical-7" /> PARAMETERS
        </button>
      </div>
      <p className="desc">
        <b>Description:</b> This recommendation type returns a combination of
        Facility-location-service-doctor (Output) for a patient (input:
        patient-ID) in response to the API invocation.
      </p>
      <div className="card ">
        {this.state.parametersValues.map((item, index) => (
          <div className="flex" key={index}>
            <Select
              onChange={this.selectComponent}
              disableUnderline
              className="sxp-defaultDropDown_filed param-ip customHover reco-drpdwn"
              value={item.parameterReference}
              labelId="label"
              id="select"
            >
              {this.state.instanceVal
                ? this.state.instanceVal.map((data, indexParam) => {
                    return (
                      <MenuItem value={data} key={indexParam} index={index}>
                        {data}
                      </MenuItem>
                    );
                  })
                : []}
            </Select>
            <div className="handleArrowIncDec flex">
              <img
                alt="chevronUpIcon"
                className={`chevron-range ${
                  item.value < 2 ? "disablePagBtn" : ""
                }`}
                onClick={this.onDecreaseNumber.bind(this, index, item.value)}
                src={chevronUp}
              />
              <span className="text-val">{item.value}</span>
              <img
                alt="chevronDownIcon"
                className={`chevron-range ${
                  item.value > 99 ? "disablePagBtn" : ""
                }`}
                onClick={this.onIncreaseNumber.bind(this, index, item.value)}
                style={{ transform: "rotate(180deg)" }}
                src={chevronDown}
              />
            </div>
            <div className="description left-space top-space">{item.range}</div>
            <img
              alt="removeIcon"
              className="removeIcon icons-upload less-top"
              onClick={this.removeData.bind(this, index, "parameter")}
              style={{ marginTop: "-15px" }}
              src={trash}
            />
          </div>
        ))}
      </div>
    </div>
  );

  numOfRecommendations = () => (
    <div className="flex">
      <h4 className="m0 mt5">3. No of Recommendations - Configurable</h4>
      <div
        className="handleArrowIncDec flex"
        style={{ marginTop: "0", marginLeft: "15px" }}
      >
        <img
          alt="chevronDownIcon"
          className="chevron-range "
          onClick={this.onDecreaseNoReco.bind(this)}
          src={chevronDown}
        />
        <span className="text-val"> {this.state.noOfReco} </span>
        <img
          alt="chevronUpIcon"
          className="chevron-range chevron-up"
          onClick={this.onIncreaseNoReco.bind(this)}
          src={chevronDown}
          style={{ transform: "rotate(180deg)" }}
        />
      </div>
    </div>
  );

  apiDetails = () => (
    <div className="handleVisiIcon top-space">
      <div className="heading flex">
        4. Api Details(Read only)
        {this.state.displayApiDetails ? (
          <img
            alt="hideIcon"
            className="icons-hide"
            onClick={this.onDisplayDetails.bind(this)}
            src={hide}
          />
        ) : (
          <img
            alt="showIcon"
            className="icons-hide"
            onClick={this.onDisplayDetails.bind(this)}
            src={view}
          />
        )}
      </div>
      {this.state.displayApiDetails ? (
        <div className="handleFields display-flex">
          <div className="part">
            <div className="description left-space"> URL</div>
            <input
              disabled
              className="url-ip"
              value={this.state.baseDetails.url}
            />
          </div>
          <div className="part">
            <div className="description left-extra-space"> Input JSON body</div>
            <textarea disabled className="json-txtarea">
              {this.state.baseDetails.inputJson}
            </textarea>
          </div>
          <div className="part">
            <div className="description left-extra-space">Output JSON body</div>
            <textarea disabled className="json-txtarea">
              {this.state.baseDetails.outPutJson}
            </textarea>
          </div>
        </div>
      ) : null}
    </div>
  );

  actionButtons = () => (
    <div className="mt10 text-center">
      <button
        className="sxp-btn btn-primary ml5 mr5 primaryLarge"
        style={{ background: "#086284" }}
        onClick={this.onSave}
      >
        submit
      </button>
      <button
        className="sxp-btn btn-danger ml5 mr5 primaryLarge"
        style={{ background: "#EC5B56" }}
        onClick={this.onCancel}
      >
        cancel
      </button>
    </div>
  );

  render() {
    return (
      <div className="handleHeadReco">
        <PositionedSnackbar
          display={this.state.displayToast}
          msg={this.state.messageToast}
          closeToast={this.closeToast}
          severity={this.state.severity}
        />
        <div className="flex">
          {this.uploadData()}
          {this.configuration()}
        </div>
        {this.numOfRecommendations()}
        {this.apiDetails()}
        {this.actionButtons()}
      </div>
    );
  }
}

export default RecoConfigInstance;
