import React, { Component } from 'react';
import UserService from './auth/KeycloakLogin';

const projId = localStorage.getItem('pId');
const versionId = localStorage.getItem('version');

class LoadSxpChat extends Component{
  constructor(props){
    super(props);
    this.state = {
      userToken :'',
    }
  }

  componentDidMount = () => {
    this.getUserToken();
  }

  getUserToken = () => {
    this.setState({userToken : UserService.getToken()},() => this.loadIt());
  }

  loadIt = () => {
    let mainObj = {
      socketUrl : window._env_.REACT_APP_SOCKET_URL, //sxp URL
      socketPath : window._env_.REACT_APP_SOCKET_PATH,
      SSL : true, //secure connection, true for https, false for http
      accessToken : `Bearer ${this.state.userToken}`,
      currentProject : projId, // name of the project / client you are working on / for
      fileServerUrl : window._env_.REACT_APP_CHAT_FILE_SERVER_URL,
      mainTitle:'SXP',
      // call : '+919876543210',
      // titleLogo:'https://sxp.v1.techsophy.com/static/media/logo.7ad470b4.png',
      // customLauncher:'https://sxp.v1.techsophy.com/static/media/logo.7ad470b4.png',
      subTitle : "Service eXperience Platform",
      chatRefresh: true,
      autoLaunch: false,
      lazyAutoLaunch:false,
      editChat: true,
      uploadDoc: true,
      defaultMessage:'Hi, welcome to SXP',
      languages:[],
      version: versionId,
      journeyTray: true,
    }
    window.embedSXPChat(mainObj);
  }

  render(){
    return(
      <></>
    )
  }
}

export default LoadSxpChat;
