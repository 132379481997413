import React, { Component } from "react";
import EditIcon from "@material-ui/icons/Edit";
import LockIcon from "@material-ui/icons/Lock";
import {
  FormBuilder,
  FieldGroup,
  FieldControl,
  Validators,
} from "react-reactive-form";
import PositionedSnackbar from "../../commons/uicomponents/toast";
import { getData, updateData } from "../../dataProvider/apiHandler";
import "./profile.scss";
import CameraIcon from "../../assets/images/add_a_photo.png";
import Users from "../users";
import Projects from "../projects";
import Roles from "../roles";
import ChangePassword from "./change-password";

const projectId = localStorage.getItem("pId");
const serverUrl =
  window._env_.REACT_APP_SERVER_URL + `api/projects/` + projectId;

const profileTab = ["Overview", "Projects", "Users", "Roles"];

class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viewProfile: true,
      updateProfile: false,
      updatePassword: false,
      defTabVal: this.props.location.tabName ? this.props.location.tabName: 'Overview',
      loadingCard: false,
      singleUserDetails: {},
      displayToast: false,
    };
  }

  componentDidMount = () => {
    this.getUserDetail();
  };

  getUserDetail = async () => {
    try {
      const allOptions = {
        url: serverUrl + "/profile/" + localStorage.getItem("botUser"),
      };
      let userData = await getData(allOptions);
      this.setState({ singleUserDetails: userData.data.data });
    } catch (e) {
      console.log(e);
    }
  };

  // initial form load
  conversationFormBuilder = FormBuilder.group({
    name: ["", Validators.required],
    email: ["", Validators.required],
    phone: [""],
  });

  buildingConversationForm = () => {
    let userVal = this.state.singleUserDetails;
    if (userVal) {
      this.conversationFormBuilder.patchValue({
        name: userVal.name,
        email: userVal.email,
        phone: userVal.phone,
        systemRole: userVal.systemRole,
        designation: userVal.designation,
        address: userVal.address,
      });
    }
  };

  updateProfileForm = async (e, value) => {
    e.preventDefault();
    try {
      const allOption = {
        url: serverUrl + "/profile/" + localStorage.getItem("botUser"),
        body: value,
      };
      await updateData(allOption);
      this.setState({
        displayToast: true,
        messageToast: "Details Update Successfully",
        severity: "success",
      });
      this.cancelUpdate();
      this.getUserDetail();
    } catch (e) {
      console.log(e);
    }
  };

  changeTab = (val) => {
    this.setState({ defTabVal: val });
  };

  editProfile = () => {
    this.setState({
      updateProfile: true,
      viewProfile: false,
      updatePassword: false,
    });
    this.buildingConversationForm();
  };

  cancelUpdate = () => {
    this.setState({
      updateProfile: false,
      viewProfile: true,
      updatePassword: false,
    });
  };

  showResetPassword = () => {
    this.setState({
      updatePassword: true,
      updateProfile: false,
      viewProfile: false,
    });
  };

  // Close Toast
  closeToast = () => {
    this.setState({ displayToast: false });
  };

  render() {
    return (
      <div>
        <PositionedSnackbar
          display={this.state.displayToast}
          msg={this.state.messageToast}
          closeToast={this.closeToast}
          severity={this.state.severity}
        />
        <div className="profileTab">
          <ul className="pl0 mb0">
            {profileTab.map((d, i) => (
              <li
                key={i}
                className={d === this.state.defTabVal ? "active-tab" : ""}
                onClick={() => this.changeTab(d)}
              >
                {d}
              </li>
            ))}
          </ul>
        </div>

        {this.state.defTabVal === "Overview" && (
          <>
            {this.state.updateProfile === true && (
              <div className="chartbox display-flex">
                <div className="profileImg flex1 m30">
                  <div className="profileImage margin0a">
                    <img
                      src={CameraIcon}
                      alt="camera Icon"
                      onClick={this.selectFile}
                    />
                    <input
                      type="file"
                      hidden
                      ref={(input) => (this.inputElement = input)}
                    />
                  </div>
                </div>
                <div className="formField user_data flex2 m30 pl0">
                  <FieldGroup
                    control={this.conversationFormBuilder}
                    render={({ invalid, value }) => (
                      <form onSubmit={(e) => this.updateProfileForm(e, value)}>
                        <div className="flex">
                          <label className="label">Name</label>
                          <FieldControl
                            name="name"
                            options={{ validators: Validators.required }}
                            render={({ handler, touched, hasError }) => (
                              <div className="inputField">
                                <input
                                  {...handler()}
                                  placeholder="Name"
                                  className={`${
                                    touched &&
                                    hasError("required") &&
                                    "errorInput"
                                  }`}
                                />
                              </div>
                            )}
                          />
                        </div>

                        <div className="flex">
                          <label className="label">Email</label>
                          <FieldControl
                            name="email"
                            options={{ validators: Validators.required }}
                            render={({ handler, touched, hasError }) => (
                              <div className="inputField">
                                <input
                                  {...handler()}
                                  placeholder="Email"
                                  className={`${
                                    touched &&
                                    hasError("required") &&
                                    "errorInput"
                                  }`}
                                />
                              </div>
                            )}
                          />
                        </div>
                        <div className="flex">
                          <label className="label">Phone Number</label>
                          <FieldControl
                            name="phone"
                            options={{ validators: Validators.required }}
                            render={({ handler, touched, hasError }) => (
                              <div className="inputField">
                                <input
                                  {...handler()}
                                  placeholder="Phone Number"
                                  className={`${
                                    touched &&
                                    hasError("required") &&
                                    "errorInput"
                                  }`}
                                />
                              </div>
                            )}
                          />
                        </div>

                        <div className="buttonClmns">
                          <button
                            className={`sxp-btn btn-primary`}
                            type="submit"
                            style={{ height: "34px", marginRight: "10px" }}
                            disabled={invalid}
                          >
                            Update Profile
                          </button>
                          <button
                            className="sxp-btn btn-danger"
                            onClick={this.cancelUpdate}
                            style={{ height: "34px" }}
                          >
                            Cancel
                          </button>
                        </div>
                      </form>
                    )}
                  />
                </div>
              </div>
            )}

            {this.state.viewProfile === true && (
              <div className="chartbox display-flex">
                <div className="profileImg flex1 m30">
                  <div className="profileImage margin0a">
                    <img src={CameraIcon} alt="camera Icon" />
                  </div>
                </div>
                <div className="user_data flex2 m30">
                  <div>
                    Name: <p>{this.state.singleUserDetails.name}</p>
                  </div>
                  <div>
                    Email: <p>{this.state.singleUserDetails.email}</p>
                  </div>
                  <div>
                    Phone No: <p>{this.state.singleUserDetails.phone}</p>
                  </div>
                  <button
                    className="sxp-btn mt10 btn-primary"
                    onClick={this.editProfile}
                    style={{ marginRight: "7px" }}
                  >
                    <EditIcon className="vertical-7" /> Edit Profile
                  </button>
                  <button
                    className="sxp-btn mt10 btn-danger"
                    onClick={this.showResetPassword}
                  >
                    <LockIcon className="vertical-7" /> Change Password
                  </button>
                </div>
              </div>
            )}

            {this.state.updatePassword === true && (
              <div className="chartbox display-flex">
                <div className="profileImg flex1 m30">
                  <div className="profileImage margin0a">
                    <img src={CameraIcon} alt="camera Icon" />
                  </div>
                </div>
                <div className="user_data flex2 m30">
                  <ChangePassword cancelUpdate={this.cancelUpdate} />
                </div>
              </div>
            )}
          </>
        )}

        {this.state.defTabVal === "Projects" && <Projects />}
        {this.state.defTabVal === "Users" && <Users />}
        {this.state.defTabVal === "Roles" && <Roles />}
      </div>
    );
  }
}

export default Profile;
