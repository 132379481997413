import React, { Component } from "react";
import { Button, Step, StepLabel, Stepper, Typography} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "../../assets/images/subtract.png";
import PositionedSnackbar from "../../commons/uicomponents/toast";

const steps = ["Language Data", "Meta Data Description", "Publish App Details"];
const props = {};
const labelProps = {};
const classes = {
  root: {
    width: "90%",
  },
  button: {
    marginRight: "8px",
  },
  instructions: {
    marginTop: "8px",
    marginBottom: "8px",
  },
};

class TranslationTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeStep: 0,
      skipped:new Set(),
      textareaPublishRelease:'',
      displayToast:false,
      metadata:[],
    };
  }

  isStepSkipped(step) {
    return this.state.skipped.has(step);
  }

  handleBack = () => {
    this.setState({ activeStep: this.state.activeStep - 1 });
  };

  handleSkip = () => {
    if (!this.isStepOptional(this.state.activeStep)) {
      throw new Error("You can't skip a step that isn't optional.");
    }
    this.setState((state) => {
      const skipped = new Set(state.skipped.values());
      skipped.add(this.state.activeStep);
      return {
        activeStep: state.activeStep + 1,
        skipped,
      };
    });
  };

  handleNext = () => {
    if (this.isStepSkipped(this.state.activeStep)) {
      this.state.skipped = new Set(this.state.skipped.values());
      this.state.skipped.delete(this.state.activeStep);
    }
    this.setState({
      activeStep: this.state.activeStep + 1,
      
    });
  };

  handleMetaDataLabel = (e, i) => {
    let data = [...this.state.metadata];
    data[i].label = e.target.value
    this.setState({metadata: data})
  }
  handleMetaDataValue = (e, i) => {
    let data = [...this.state.metadata];
    data[i].value = e.target.value
    this.setState({metadata: data})
  } 
  newMetaData = () => {
    let data = [...this.state.metadata];
    data.push({label:'', value:''});
    this.setState({metadata: data})
  }
  deleteDataSet = (i) => {
    let data = [...this.state.metadata];
    data.splice(i, 1);
    this.setState({metadata: data})
  }
  handlePublishTxt = (e) => {
      this.setState({ textareaPublishRelease:e.target.value })
  }
  publishRelease = () => {
    if(this.state.textareaPublishRelease === '')
    {
      this.setState({
          displayToast: true,
          messageToast: 'Release Notes is Required',
          severity: "error",
      })
    }
    else
    { 
      this.props.handlePublishReleaseCallBack(this.state.textareaPublishRelease, this.state.metadata);
    }
  }

  languageUpdateHandler = () => {
    this.props.langUpdateHandlerCallBack(this.props.langArr);
  }

  closeToast = () => {
    this.setState({ displayToast: false });
  };

  getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <div>
            <table className="table-responsive">
              <thead>
                <tr>
                  <td>English</td>
                  {this.props.fullFormsList.map((lan) => {
                    return <td key={lan.code}>{lan.name}</td>;
                  })}
                </tr>
              </thead>
              <tbody>
                {this.props.langArr.length > 0 &&
                  this.props.langArr.map((translateItem, translationIndex) => (
                    <>
                      <tr className="tab-style" key={translationIndex}>
                        {translateItem.utterance ? (
                          <td>
                            <input
                              defaultValue={translateItem.utterance["en"]}
                              disabled={true}
                              type="text"
                              onChange={(e) => {
                                this.checkTextUpdated({
                                  value: e.target.value,
                                  item: "en",
                                  globalIndex: translationIndex,
                                  globalItem: "utterance",
                                });
                              }}
                            />
                          </td>
                        ) : (
                          <td>
                            <input
                              defaultValue={
                                translateItem.notificationMessage["en"]
                              }
                              type="text"
                              disabled={true}
                              onChange={(e) => {
                                this.checkTextUpdated({
                                  value: e.target.value,
                                  item: "en",
                                  globalIndex: translationIndex,
                                  globalItem: "notificationMessage",
                                });
                              }}
                            />
                          </td>
                        )}
                        {translateItem.utterance
                          ? Object.keys(translateItem.utterance)
                              .sort()
                              .map((lang, langIndex) => {
                                if (lang !== "en") {
                                  return (
                                    <td key={langIndex}>
                                      <input
                                        defaultValue={
                                          translateItem.utterance[lang]
                                        }
                                        type="text"
                                        onChange={(e) => {
                                          this.checkTextUpdated({
                                            value: e.target.value,
                                            item: lang,
                                            globalIndex: translationIndex,
                                            globalItem: "utterance",
                                          });
                                        }}
                                      />
                                    </td>
                                  );
                                }
                              })
                          : Object.keys(translateItem.notificationMessage)
                              .sort()
                              .map((lang, langIndex) => {
                                if (lang !== "en") {
                                  return (
                                    <td key={langIndex}>
                                      <input
                                        key={langIndex}
                                        defaultValue={
                                          translateItem.notificationMessage[
                                            lang
                                          ]
                                        }
                                        type="text"
                                        onChange={(e) => {
                                          this.checkTextUpdated({
                                            value: e.target.value,
                                            item: lang,
                                            globalIndex: translationIndex,
                                            globalItem: "notificationMessage",
                                          });
                                        }}
                                      />
                                    </td>
                                  );
                                }
                              })}
                      </tr>
                      <tr className="tr-height">
                        {translateItem.richText?.en ? (
                          <td>
                            <input
                              className="child-input"
                              defaultValue={translateItem.richText["en"]}
                              disabled={true}
                              type="text"
                              onChange={(e) => {
                                this.checkTextUpdated({
                                  value: e.target.value,
                                  item: "en",
                                  globalIndex: translationIndex,
                                  globalItem: "richText",
                                });
                              }}
                            />
                          </td>
                        ) : null}
                        {translateItem.richText
                          ? Object.keys(translateItem.richText)
                              .sort()
                              .map((lang, langIndex) => {
                                if (lang !== "en") {
                                  return (
                                    <td key={langIndex}>
                                      <input
                                        className="child-input"
                                        defaultValue={
                                          translateItem.richText[lang]
                                        }
                                        type="text"
                                        onChange={(e) => {
                                          this.checkTextUpdated({
                                            value: e.target.value,
                                            item: lang,
                                            globalIndex: translationIndex,
                                            globalItem: "richText",
                                          });
                                        }}
                                      />
                                    </td>
                                  );
                                }
                              })
                          : null}
                      </tr>
                    </>
                  ))}
              </tbody>
            </table>
            <div
              style={{
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
                paddingTop: "1.6vh",
              }}
            >
              <button
                className="sxp-btn btn-primary"
                onClick={this.languageUpdateHandler}
              >
                Update
              </button>
              <button
                className="sxp-btn btn-danger"
                onClick={this.modalHandler}
                style={{ marginLeft: "5px" }}
              >
                Cancel
              </button>
            </div>
          </div>
        );
        case 1:
        return(
            <div className="verMetaDa text-center">
            <div className="text-right">
             <button
                className="sxp-btn btn-primary"
                onClick={this.newMetaData}
              >
                <AddIcon className="vertical-7" /> MetaData
              </button>
              </div>
            <div className="labelsGrid text-left">
              <span>MetaData Label</span>
              <span>MetaData Value</span>
            </div>
              {this.state.metadata.map((dataa, indx) => (
                <div className="formField">
                  <input type="text" placeholder="Enter Label" value={dataa.label} name="label" onChange={(e) => this.handleMetaDataLabel(e, indx)}/>
                  <input type="text" placeholder="Enter Value" value={dataa.value} name="value" onChange={(e) => this.handleMetaDataValue(e, indx)}/>
                  <span
                    className="deleteUserIcon"
                    onClick={() => this.deleteDataSet(indx)} >
                      <img
                        src={DeleteIcon}
                        alt="trash Icon"
                      />
                  </span>
                </div>
              ))}
            
          </div>
        )
        case 2:
            return(
                <div className="appDetialsBlock">
                    <textarea placeholder="Release Notes" onChange={(e) => this.handlePublishTxt(e)}></textarea>
                    <button className="sxp-btn btn-primary w100" onClick={this.publishRelease}>PUBLISH</button>
                </div>
            )
    }
  };

  render() {
    return (
      <div>
        <PositionedSnackbar
          display={this.state.displayToast}
          msg={this.state.messageToast}
          closeToast={this.closeToast}
          severity={this.state.severity}
        />
        <Stepper activeStep={this.state.activeStep}>
          {steps.map((label, index) => (
            <Step key={label} {...props}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <div>
          {this.state.activeStep === steps.length ? (
            <div>
              <Typography className={classes.instructions}>
                All steps completed - you&apos;re finished
              </Typography>
              <Button onClick={this.handleReset} className={classes.button}>
                Reset
              </Button>
            </div>
          ) : (
            <div>
              <div className={classes.instructions}>
                {this.getStepContent(this.state.activeStep)}
              </div>
              <div>
                <Button
                  disabled={this.state.activeStep === 0}
                  onClick={this.handleBack}
                  className="sxp-btn"
                  style={{ marginTop: "5px" }}
                >
                  Back
                </Button>
                {this.state.activeStep !== steps.length - 1 ? (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={this.handleNext}
                    className={classes.button}
                  >
                    Next
                  </Button>
                ) : null}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default TranslationTable;
