import React, { Component } from "react";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Tooltip from "@material-ui/core/Tooltip";
import PositionedSnackbar from "../../commons/uicomponents/toast";
import LoaderSpinner from "../../commons/uicomponents/loader";
import { saveData, updateDataPut } from "../../dataProvider/apiHandler";
import eyeIcon from "../../assets/images/eye-view.png";
import closeIcon from "../../assets/images/close-theme.png";
import chevronDown from "../../assets/images/chev-top.png";
import upload from "../../assets/images/upload.png";
import { fileUpload } from "../faq/fileUploadApi";
import FindError from "../../dataProvider/errorHandler";

const projectId = localStorage.getItem("pId");
const serverUrl = window._env_.REACT_APP_SXP_URL;
const aimlUrl =
  window._env_.REACT_APP_AIML_URL || "https://aiml.sxp-dev.techsophy.com/";

const vectoriserData = ["count", "tfidf"];

class ClassificationInstance extends Component {
  constructor(props) {
    super(props);
    this.inputOpenFileRef = React.createRef();
    this.state = {
      baseDetails: "",
      selectedVectoriser:
        "Vectoriser Select the type of vectoriser (Count or tfidf)",
      selectedVisual: "Visualization Technique",
      visualizedData: "",
      displayVisualGrap: false,
      trainingData: {},
      testingData: {},
      domainData: {},
      visualizationStatus: "Training in progress",
      perplexityVal: 1,
      loadingCard: false,
      displayToast: false,
    };
  }

  componentDidMount = () => {
    this.refreshStatus();
    this.loadDefVectoriser();
  };

  refreshStatus = async () => {
    this.setState({ visualizationStatus: "Training in progress" });
    const intentObj = {
      active: true,
      intentData: {},
      configParameter: {},
      apiDetails: {},
      projectId: "string",
      userid: "string",
    };
    try {
      const allOption = {
        url: serverUrl + `intent-config/`+localStorage.getItem('pId')+`/refresh/` + true,
        body: intentObj,
      };
      let resStatus = await updateDataPut(allOption);
      this.setState({ visualizationStatus: resStatus.data.data });
    } catch (e) {
      const errorObj = FindError(e)
      this.setState({
        displayToast: true,
        messageToast: errorObj.message,
        severity: "error",
        confirmAlert: false,
      });
    }
  };

  loadDefVectoriser = () => {
    this.setState({
      selectedVectoriser:
        this.props.classfigData &&
        this.props.classfigData.configParameter &&
        this.props.classfigData.configParameter.vectoriser,
    });
  };

  // DROPDOWN FUNCTIONALITIES
  selectComponent = (e) => {
    const value = e.target.value;
    this.setState({ selectedVectoriser: value });
  };

  visualizationOnchange = (e) => {
    const value = e.target.value;
    this.setState({ selectedVisual: value });
  };

  onCancel = () => {
    this.props.onClosePanel();
  };

  trigerFileUpload = () => {
    this.fileInput.click();
  };

  trigerFileUpload1 = () => {
    this.fileInput1.click();
  };

  trigerFileUpload2 = () => {
    this.fileInput2.click();
  };

  closeVisualPopup = () => {
    this.setState({
      displayVisualGrap: false,
      visualizedData: "",
      loadingCard: false,
    });
  };

  increaseVal = () => {
    this.setState({ perplexityVal: this.state.perplexityVal + 1 });
  };

  decreaseVal = () => {
    this.setState({ perplexityVal: this.state.perplexityVal - 1 });
  };

  closeToast = () => {
    this.setState({ displayToast: false });
  };

  onSave = () => {
    let ext = this.props.classfigData.intentData;
    let data = {
      intentData: {
        trainingData: {
          name: this.state.trainingData.name
            ? this.state.trainingData.name
            : ext && ext.trainingData.name,
          url: this.state.trainingData.url
            ? this.state.trainingData.url
            : ext && ext.trainingData.url,
        },
        testingData: {
          name: this.state.testingData.name
            ? this.state.testingData.name
            : ext && ext.testingData.name,
          url: this.state.testingData.url
            ? this.state.testingData.url
            : ext && ext.testingData.url,
        },
        domainData: {
          name: this.state.domainData.name
            ? this.state.domainData.name
            : ext && ext.domainData.name,
          url: this.state.domainData.url
            ? this.state.domainData.url
            : ext && ext.domainData.url,
        },
      },
      configParameter: {
        vectoriser: this.state.selectedVectoriser,
      },
      apiDetails: {
        url: "",
        inputjson: {},
        outputjson: {},
      },
    };
    if (this.props.classfigData.id) {
      this.updateIntentConfig(data, this.props.classfigData.id);
    } else {
      this.createIntentConfig(data);
    }
  };

  createIntentConfig = async (data) => {
    try {
      const extObj = {
        ...data,
        projectId: localStorage.getItem("pId"),
      };
      const allOption = {
        url: serverUrl + `intent-config/` + localStorage.getItem('pId'),
        body: extObj,
      };
      let updatedreco = await saveData(allOption);
      if (updatedreco.status === 201) {
        this.refreshStatus();
      }
    } catch (e) {
      const errorObj = FindError(e)
      this.setState({
        displayToast: true,
        messageToast: errorObj.message,
        severity: "error",
        confirmAlert: false,
      });
    }
  };

  updateIntentConfig = async (obj, id) => {
    try {
      delete obj.id;
      const extObj = {
        ...obj,
        projectId: localStorage.getItem("pId"),
      };
      const allOption = {
        url: serverUrl + `intent-config/` + localStorage.getItem('pId') +`/` + id,
        body: extObj,
      };
      await updateDataPut(allOption);
      this.setState({
        displayToast: true,
        messageToast: "Successfully saved the Intent Configs",
        severity: "success",
      });
    } catch (e) {
      const errorObj = FindError(e)
      this.setState({
        displayToast: true,
        messageToast: errorObj.message,
        severity: "error",
        confirmAlert: false,
      });
    }
  };

  onUploadFile(e) {
    var files = e.target.files[0];
    let inputName = e.target.name;
    this.uploadRecoConfigFile(files, inputName);
  }

  onUploadFile1(e) {
    var files = e.target.files[0];
    let inputName = e.target.name;
    this.uploadRecoConfigFile(files, inputName);
  }

  onUploadFile2(e) {
    var files = e.target.files[0];
    let inputName = e.target.name;
    this.uploadRecoConfigFile(files, inputName);
  }

  uploadRecoConfigFile = async (fileObj, inputName) => {
    try {
      const allOptions = {
        url: serverUrl + "file/upload",
        file: fileObj,
      };
      // let responseData = await saveData(allOptions);

      let responseData = await fileUpload(allOptions);
      if (inputName === "trainingData") {
        this.setState({ trainingData: responseData.data.data });
      }
      if (inputName === "testingData") {
        this.setState({ testingData: responseData.data.data });
      }
      if (inputName === "domainData") {
        this.setState({ domainData: responseData.data.data });
      }
    } catch (e) {
      const errorObj = FindError(e)
      this.setState({
        displayToast: true,
        messageToast: errorObj.message,
        severity: "error",
        confirmAlert: false,
      });
    }
  };

  showVisualGraph = () => {
    if (
      this.state.selectedVisual !== "Visualization Technique" &&
      this.state.selectedVectoriser !==
        "Vectoriser Select the type of vectoriser (Count or tfidf)"
    ) {
      this.setState({ displayVisualGrap: true, loadingCard: true });
      this.renderVisual();
    } else {
      this.setState({
        displayToast: true,
        severity: "error",
        messageToast: "Select Visualization Technique and Vectoriser ",
      });
    }
  };

  renderVisual = async () => {
    let backLink = "intent-config/" +localStorage.getItem('pId')+ "/display-" + this.state.selectedVectoriser;
    try {
      let obj = {
        visual: this.state.selectedVisual,
        perplexity: this.state.perplexityVal,
        project: projectId,
      };
      const allOption = {
        url: serverUrl + backLink,
        body: obj,
      };
      let data = await saveData(allOption);
      this.setState({ visualizedData: data.data, loadingCard: false });
      if (this.state.visualizedData.message) {
        this.setState({
          displayToast: true,
          severity: "error",
          messageToast: this.state.visualizedData.message,
          displayVisualGrap: false,
          loadingCard: false,
        });
      }
    } catch (e) {
      const errorObj = FindError(e)
      this.setState({
        displayToast: true,
        messageToast: errorObj.message,
        severity: "error",
        confirmAlert: false,
      });
    }
  };

  uploadData() {
    return (
      <div className="part">
        <div className="flex-div space-btwn">
          <div className="heading">1. Upload Intent Data</div>
        </div>
        <div className="description">
          {" "}
          Description: This Intent Classification requires to upload three
          files, Training Data, Domain Data and Testing Data
        </div>
        <div className="card">
          <div className="single_upload display-flex">
            <p>
              <Tooltip title="(Upload your ‘csv’ or ‘Excel’ Training Data)">
                <span className="title_head1">Training Data</span>
              </Tooltip>
              <Tooltip title="Download Trainig data Template">
                <a
                  href="https://fileserver.techsophy.com/file/file-1613715170841.csv"
                  download
                >
                  <span className="dwnldTemplate">
                    <img
                      alt="uploadIcon"
                      src={upload}
                      style={{ transform: "rotate(180deg)" }}
                    />
                  </span>
                </a>
              </Tooltip>
            </p>
            <input
              type="file"
              className="hideIt"
              name="trainingData"
              ref={(fileInput) => (this.fileInput = fileInput)}
              onChange={(e) => {
                this.onUploadFile(e);
              }}
            />
            <p className="fileUploadTxt" onClick={this.trigerFileUpload}>
              File Upload <img alt="uploadeIcon" src={upload} type="file" />
            </p>
            <div className="fileNameSide">
              {this.props.classfigData.intentData &&
                this.props.classfigData.intentData.trainingData.name}
            </div>
          </div>

          <div className="single_upload display-flex">
            <p>
              <Tooltip title="(Upload your ‘csv’ or ‘Excel’ Testing Data)">
                <span className="title_head1">Testing Data</span>
              </Tooltip>
              <Tooltip title="Download Testing data Template">
                <a
                  href="https://fileserver.techsophy.com/file/file-1613715189653.csv"
                  download
                >
                  <span className="dwnldTemplate">
                    <img
                      alt="UploadIcon"
                      src={upload}
                      style={{ transform: "rotate(180deg)" }}
                    />
                  </span>
                </a>
              </Tooltip>
            </p>
            <input
              type="file"
              className="hideIt"
              name="testingData"
              ref={(fileInput1) => (this.fileInput1 = fileInput1)}
              onChange={(e) => {
                this.onUploadFile1(e);
              }}
            />
            <p className="fileUploadTxt" onClick={this.trigerFileUpload1}>
              File Upload <img alt="uploadIcon" src={upload} type="file" />
            </p>
            <div className="fileNameSide">
              {this.props.classfigData.intentData &&
                this.props.classfigData.intentData.testingData.name}
            </div>
          </div>

          <div className="single_upload display-flex">
            <p>
              <Tooltip title="(Upload your ‘csv’ or ‘Excel’ Domain Data)">
                <span className="title_head1">Domain Data</span>
              </Tooltip>
              <Tooltip title="Download Domain data Template">
                <a
                  href="https://fileserver.techsophy.com/file/file-1613715088324.xlsx"
                  download
                >
                  <span className="dwnldTemplate">
                    <img
                      alt="uploadIcon"
                      src={upload}
                      style={{ transform: "rotate(180deg)" }}
                    />
                  </span>
                </a>
              </Tooltip>
            </p>
            <input
              type="file"
              name="domainData"
              className="hideIt"
              ref={(fileInput2) => (this.fileInput2 = fileInput2)}
              onChange={(e) => {
                this.onUploadFile2(e);
              }}
            />
            <p className="fileUploadTxt" onClick={this.trigerFileUpload2}>
              File Upload <img alt="uploadIcon" src={upload} type="file" />
            </p>
            <div className="fileNameSide">
              {this.props.classfigData.intentData &&
                this.props.classfigData.intentData.domainData.name}
            </div>
          </div>
        </div>
      </div>
    );
  }

  configuration = () => {
    return (
      <div className="part left">
        <div className="flex-div space-btwn">
          <div className="heading"> 2. Config Parameter Tuning</div>
        </div>
        <div className="description">
          Configuration for Tuning the Algorithm: These parameters will fine
          tune the Intent Classification Algorithm.
        </div>
        <div className="card ">
          <div className="flex">
            <div className="vectorise_div_dd">
              <span className="mergeTxt">Vectoriser</span>
              <Select
                onChange={this.selectComponent}
                disableUnderline
                className="param-ip customHover reco-drpdwn"
                value={this.state.selectedVectoriser}
                labelId="label"
                id="select"
              >
                <MenuItem value="Vectoriser Select the type of vectoriser (Count or tfidf)">
                  Select the type of vectoriser (Count or tfidf)
                </MenuItem>
                {vectoriserData.map((data, indexParam) => {
                  return (
                    <MenuItem key={indexParam} value={data}>
                      {data}
                    </MenuItem>
                  );
                })}
              </Select>
            </div>
          </div>
        </div>
      </div>
    );
  };

  actionButtons() {
    return (
      <div className="mt20 text-center">
        <button
          className={`sxp-btn btn-primary primaryLarge ${
            this.state.visualizationStatus.uploadIntentResp === "Success"
              ? "disablePagBtn"
              : this.state.visualizationStatus.uploadIntentResp ===
                "Training in progress"
              ? "disablePagBtn"
              : ""
          }`}
          style={{ margin: "0px 6px" }}
          onClick={this.onSave}
        >
          submit
        </button>
        <button
          className="sxp-btn btn-danger primaryLarge"
          style={{ margin: "0px 6px" }}
          onClick={this.onCancel}
        >
          cancel
        </button>
        <div
          dangerouslySetInnerHTML={{ __html: this.state.visualizedData }}
        ></div>
      </div>
    );
  }

  visualization = () => {
    return (
      <div className="part">
        <div className="flex-div space-btwn">
          <div className="heading">
            4. visualization
            {this.state.visualizationStatus.uploadIntentData ===
              "Training in progress" && (
              <span className="label_status">File Processing</span>
            )}
            {this.state.visualizationStatus.uploadIntentData ===
              "Training Completed" && (
              <span className="label_status green_succ">Processed</span>
            )}
            {this.state.visualizationStatus.uploadIntentData === "Success" && (
              <span className="label_status green_succ">Processed</span>
            )}
            {this.state.visualizationStatus.uploadIntentData === "Failure" && (
              <span className="label_status green_succ">Processed</span>
            )}
            <span className="refreshBtn " onClick={this.refreshStatus}>
              Refresh
            </span>
            <span
              className={`eyeIconBtn ${
                this.state.visualizationStatus.uploadIntentData === "Success"
                  ? "disablePagBtn"
                  : this.state.visualizationStatus.uploadIntentData ===
                    "Training in progress"
                  ? "disablePagBtn"
                  : ""
              }`}
              onClick={this.showVisualGraph}
            >
              <img alt="eyeIcon" src={eyeIcon} />
            </span>
          </div>
        </div>
        <div className="description">
          {" "}
          Description: This is the visualization technique used to visualize
          Multi-Dimensional data into 2D/3D visualization (PCA/TSNE)
        </div>
        <div className="fullWidthDD">
          <Select
            className="param-ip customHover reco-drpdwn"
            value={this.state.selectedVisual}
            style={{ marginLeft: 0, maxWidth: "90%" }}
            disableUnderline
            onChange={this.visualizationOnchange}
          >
            <MenuItem value="Visualization Technique">
              Visualization Technique
            </MenuItem>
            <MenuItem value="pca">PCA</MenuItem>
            <MenuItem value="tsne">TSNE</MenuItem>
          </Select>
        </div>
      </div>
    );
  };

  hyperParamVisual = () => {
    return (
      <div className="part">
        <div className="flex-div space-btwn">
          <div className="heading">4.1 Hyper Parameter for Visualization</div>
        </div>
        <div className="description">
          Description: This is Hyper Parameter for the Visualization (Perplexity
          Value).
        </div>
        <div className="perplexity_div">
          <h4 className="in-block">Perplexity Value </h4>
          <div className="in-block block_per">
            <span>(1 to Datapoints / 2)</span> {this.state.perplexityVal}
            <img
              alt="chevronUp"
              src={chevronDown}
              className="chevUp"
              onClick={this.increaseVal}
            />
            <img
              alt="chevronDown"
              src={chevronDown}
              className={`chevDwn ${
                this.state.perplexityVal < 2 ? "disablePagBtn" : ""
              }`}
              onClick={this.decreaseVal}
            />
          </div>
        </div>
      </div>
    );
  };

  render() {
    return (
      <div>
        <PositionedSnackbar
          display={this.state.displayToast}
          msg={this.state.messageToast}
          closeToast={this.closeToast}
          severity={this.state.severity}
        />
        <div className="reco-parent">
          {this.state.displayVisualGrap ? (
            <>
              <div className="overlay" onClick={this.closeVisualPopup}></div>
              <div className="visualPopup">
                <div
                  className="display-flex"
                  style={{ alignItems: "baseline" }}
                >
                  <span className="label_red">OUTPUT</span>
                  <img
                    alt="closeIcon"
                    className="closeBtn_v"
                    src={closeIcon}
                    onClick={this.closeVisualPopup}
                  />
                </div>
                <div className="pos-rel">
                  {this.state.visualizedData ? (
                    <iframe
                      srcdoc={this.state.visualizedData}
                      title="visiual Graph"
                      className=""
                    ></iframe>
                  ) : (
                    <LoaderSpinner
                      className="makeDown"
                      loadingCard={this.state.loadingCard}
                    />
                  )}
                </div>
              </div>
            </>
          ) : (
            ""
          )}
          <div className="flex">
            <div className="flexSpaceEqual">
              <div className="singleBlock">{this.uploadData()}</div>
              <div className="singleBlock">{this.configuration()}</div>
            </div>
          </div>
        </div>
        {this.actionButtons()}
        <div className="flexSpaceEqual" style={{ marginTop: "30px" }}>
          <div className="singleBlock"> {this.visualization()}</div>
          <div className="singleBlock"> {this.hyperParamVisual()} </div>
        </div>
      </div>
    );
  }
}

export default ClassificationInstance;
