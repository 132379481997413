import fileDownload from "js-file-download";
import FormData from "form-data";

import HttpService from '../../../HttpService';
import FindError from "../../../dataProvider/errorHandler";
const axios = HttpService.getAxiosClient();

const PID = localStorage.getItem("pId");
const SERVER_URL = window._env_.REACT_APP_SXP_URL;
const VERSION_DATA_URL = SERVER_URL + `versions/`+localStorage.getItem('pId')+`/export`;
const IMPORT_DATA_URL = SERVER_URL + `versions/`+localStorage.getItem('pId')+`/import`;
const FUNCTION_URL = SERVER_URL + `be/userfunctions/`+localStorage.getItem('pId')+`/keys/`+localStorage.getItem('version');
const JOURNEYS_TASK_URL = SERVER_URL + `tasks/` + localStorage.getItem('pId');
const SERVICE_URL = SERVER_URL + `services/` + localStorage.getItem('pId');
const FLOW_URL = SERVER_URL + `flow/` + localStorage.getItem('pId');
const COMPONENTS_TASK_URL = SERVER_URL + `components/` + localStorage.getItem('pId');
const FUNCTION_DATA_URL = SERVER_URL + `be/userfunctions/`+localStorage.getItem('pId')+`/function`;

export const getFunctions = () => {
  return axios.get(FUNCTION_URL, {
    data: {},
    headers: { token: localStorage.getItem("token") },
    params: { version: localStorage.getItem("version"), projectId: PID },
  });
};

export const getServices = () => {
  return axios.get(SERVICE_URL, {
    headers: { },
    params: { 
      filter: {
        where: {
          projectId: PID
          },
        }
      },
  });
};

export const getAllJourneys = () => {
  return axios.get(JOURNEYS_TASK_URL, {
    headers: {},
    params: { 
      filter: {
        where: {
          projectId: PID,
          version: localStorage.getItem("version") },
        }
      }      
  });
};

export const getComponentsByTaskId = (journeyId) => {
  return axios.get(COMPONENTS_TASK_URL, {
    headers: {},
    params: { 
      filter: {
        where: {
          journeyId,
          version: localStorage.getItem("version"),
          },
        }
      }
  });
};

export const ExportIndividual = async (version, journeys) => {
  try {
    const resp = await axios.request({
      responseType: "blob",
      url: VERSION_DATA_URL,
      method: "post",
      headers: {
        token: localStorage.getItem("token"),
      },
      data: {
        projectId: PID,
        version,
        journeys,
      },
    });
    const filename = resp.headers.filename || version + "_" + PID + ".zip";
    fileDownload(resp.data, filename);
    return { success: true, data: resp.data };
  } catch (e) {
    const errorObj = FindError(e)
      this.setState({
        displayToast: true,
        messageToast: errorObj.message,
        severity: "error",
        confirmAlert: false,
      });
    return { success: false, data: e };
  }
};

export const exportJourneys = async (journeys) => {
  return axios.request({
    responseType: 'blob',
    url: VERSION_DATA_URL,
    method: 'post',
    data: {
      projectId: localStorage.getItem('pId') || '',
      version: localStorage.getItem('version').toUpperCase(),
      journeys
    }
  });
};


export const Import = async (file) => {
  const formData = new FormData()
  formData.append(
    "file", 
    file, 
    file.name 
  )
  formData.append(
    "projectId", 
    localStorage.getItem('pId') || '', 
  )
  formData.append(
    "version", 
    localStorage.getItem('version').toUpperCase(), 
  )
  formData.append(
    "userid", 
    localStorage.getItem('botUser'), 
  )
  return axios.request({
    url: IMPORT_DATA_URL,
    method: 'post',
    data: formData
  })
};

export const getFunctionData = (functionKey) => {
  const url = `${FUNCTION_DATA_URL}/${functionKey}/${localStorage.getItem('version')}`;
  return axios.get(url);
};

export const saveFlow = (data) => {
  return axios.post(FLOW_URL, data)
};