import React from "react";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";
import { Component } from "react";
import FormHelperText from "@material-ui/core/FormHelperText";
import Whatsapp from "../../assets/images/watsapp.png";
import fbmsngr from "../../assets/images/fbmsngr.png";
import slack from "../../assets/images/slack.png";
import line from "../../assets/images/line.png";
import telegram from "../../assets/images/telegram.png";
import webchat from "../../assets/images/webchat.png";

class MultipleSelect extends Component {
  state = {
    selectedChannels: [],
    callApi: false,
  };

  componentWillReceiveProps = (props) => {
    this.updateSelectedChannels(props.defVal);
  };

  updateSelectedChannels = async (data) => {
    this.setState({ selectedChannels: data });
  };

  handleChange = async (event) => {
    this.setState(
      { selectedChannels: event.target.value, callApi: true },
      () => {
        this.props.selectedChannels(event.target.value);
      }
    );
  };

  handleChangeBlur = async () => {
    if (this.state.callApi) {
      this.props.updatejourneyChannel(this.state.selectedChannels);
      this.setState({ callApi: false });
    }
  };

  render() {
    return (
      <div className="multi-select" id={`multiChannelSelectDD${this.props.jrnyId}`}>
        <FormControl>
          <Select
            className={`channel-select-dd ${
              this.state.selectedChannels?.length ? "" : "noChannelStyle"
            }`}
            labelId="demo-mutiple-checkbox-label"
            id="demo-mutiple-checkbox"
            multiple
            disabled={!this.props.permissionClic}
            disableUnderline
            value={this.state.selectedChannels || []}
            onChange={this.handleChange}
            onClose={this.handleChangeBlur}
            renderValue={() => ""}
          >
            {this.props.allChannels.length ? (
              this.props.allChannels.map((name) => (
                <MenuItem className="menuCustomItem" key={name} value={name}>
                  <Checkbox
                    checked={this.state.selectedChannels?.indexOf(name) > -1}
                  />
                  <ListItem>
                    {name.includes("whatsapp") && (
                      <img src={Whatsapp} alt="whatsApp Icon" />
                    )}
                    {name.includes("facebook") && (
                      <img src={fbmsngr} alt="messenget Icon" />
                    )}
                    {name.includes("slack") && (
                      <img src={slack} alt="slack Icon" />
                    )}
                    {name.includes("line") && (
                      <img src={line} alt="line Icon" />
                    )}
                    {name.includes("telegram") && (
                      <img src={telegram} alt="telegram Icon" />
                    )}
                    {name.includes("webchat") && (
                      <img src={webchat} alt="webchat Icon" />
                    )}
                    <ListItemText primary={name} />
                  </ListItem>
                </MenuItem>
              ))
            ) : (
              <ListItem style={{ pointerEvents: "none" }} disabled>
                {" "}
                ~ No Channels Available~
              </ListItem>
            )}
          </Select>

          <FormHelperText className="jourLvlChanBtn">
            {this.props.defVal
              ? this.props.defVal?.length
              : this.props.defVal?.length < 1
              ? "0"
              : "0"} 
             &nbsp; Channels
          </FormHelperText>
        </FormControl>
      </div>
    );
  }
}

export default MultipleSelect;
