import React, { Component } from "react";
import { FieldGroup, FieldControl, Validators } from "react-reactive-form";
import PositionedSnackbar from "../../commons/uicomponents/toast";
import { updateData } from "../../dataProvider/apiHandler";

const projectId = localStorage.getItem("pId");
const serverUrl =
  window._env_.REACT_APP_SERVER_URL + `api/projects/` + projectId;

class ChangePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      displayToast: false,
    };
  }

  closeToast = () => {
    this.setState({ displayToast: false });
  };

  updateProfilePwdForm = async (e, val) => {
    e.preventDefault();
    try {
      const allOption = {
        url: serverUrl + "/profile/" + localStorage.getItem("botUser"),
        body: val,
      };
      let response = await updateData(allOption);
      this.setState({
        displayToast: true,
        messageToast: "Password Updated Successfully",
        severity: "success",
      });
      if (response) {
        setTimeout(() => this.props.cancelUpdate(), 1000);
      }
    } catch (e) {
      if (e.response.status === 404)
        this.setState({
          displayToast: true,
          messageToast: "Wrong Password Entered",
          severity: "error",
        });
    }
  };

  render() {
    return (
      <>
        <PositionedSnackbar
          display={this.state.displayToast}
          msg={this.state.messageToast}
          closeToast={this.closeToast}
          severity={this.state.severity}
        />
        <FieldGroup
          control={this.conversationFormBuilder1}
          render={({ invalid, value }) => (
            <form onSubmit={(e) => this.updateProfilePwdForm(e, value)}>
              <div className="flex">
                <label className="label">Current Password</label>
                <FieldControl
                  name="old_password"
                  options={{ validators: Validators.required }}
                  render={({ handler, touched, hasError }) => (
                    <div className="inputField">
                      <input
                        {...handler()}
                        type="password"
                        placeholder="Current Password"
                        className={`${
                          touched && hasError("required") && "errorInput"
                        }`}
                      />
                    </div>
                  )}
                />
              </div>
              <div className="flex">
                <label className="label">New Password</label>
                <FieldControl
                  name="password"
                  options={{ validators: Validators.required }}
                  render={({ handler, touched, hasError }) => (
                    <div className="inputField">
                      <input
                        {...handler()}
                        type="password"
                        placeholder="New Password"
                        className={`${
                          touched && hasError("required") && "errorInput"
                        }`}
                      />
                    </div>
                  )}
                />
              </div>
              <div className="buttonClmns">
                <button
                  className={`sxp-btn btn-primary`}
                  type="submit"
                  style={{ height: "34px", marginRight: "10px" }}
                  disabled={invalid}
                >
                  Update Password
                </button>
                <button
                  className="sxp-btn btn-danger "
                  onClick={this.props.cancelUpdate}
                  style={{ height: "34px" }}
                >
                  Cancel
                </button>
              </div>
            </form>
          )}
        />
      </>
    );
  }
}

export default ChangePassword;
