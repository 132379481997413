import React, { Component } from 'react'
import ConfirmAlert from '../../commons/uicomponents/confirm';
import PositionedSnackbar from '../../commons/uicomponents/toast';
import { deleteData, getData, saveData, updateData } from '../../dataProvider/apiHandler';
import AddIcon from "@material-ui/icons/Add";
import EmptyCard from '../../commons/uicomponents/emptycard';
import { ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary } from '@material-ui/core';
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import LoaderSpinner from '../../commons/uicomponents/loader';
import deletewhiteIcon from "../../assets/images/deletewhite.png";
import calender from "../../assets/images/calender.png";
import moment from 'moment';
import { FieldControl, FieldGroup, FormBuilder, Validators } from 'react-reactive-form';
import './oauth.scss'
import FindError from '../../dataProvider/errorHandler';

const serverUrl = window._env_.REACT_APP_SXP_URL;

class OAuthConfig extends Component {
  constructor(props){
    super(props);
    this.state={
      oauthData : [],
      expansionJrnyPanelOpen: false,
      journeyIndex: "",
      confirmAlert: false,
      loadingCard: false,
      displayToast: false,
      oauthId:'',
    };
  }

  // EXPANSIONS HANDLERS
  switchJrnyExpansionpanel(index) {
    
    if (this.state.journeyIndex === index) {
      this.setState({
        expansionJrnyPanelOpen: false,
        journeyIndex: "",
      });
    } else {
      this.setState({
        expansionJrnyPanelOpen: true,
        journeyIndex: index,
      });
    }
    this.conversationFormBuilder.reset();
    this.buildingConversationForm(index);
  }

  conversationFormBuilder = FormBuilder.group({
    name:["", Validators.required],
    serverUrl:["", Validators.required],
    tokenEndpoint:["", Validators.required],
    clientId:["", Validators.required],
    clientSecret:["", Validators.required],
    scopes:[""],
  })

  // to patch the value in form field when the card is expanded
  buildingConversationForm = (data) => {
    let servData = [...this.state.oauthData];
    if (servData[data]?.id) {     
      this.conversationFormBuilder.patchValue({
        name:servData[data].name,
        serverUrl:servData[data].serverUrl,
        tokenEndpoint:servData[data].tokenEndpoint,
        clientId:servData[data].clientId,
        clientSecret:servData[data].clientSecret,
        scopes:servData[data].scopes,        
      });
    }
  };

  componentDidMount = () => {
    this.getAllData();
  };

  getAllData = async() => {
    try{
      this.setState({ loadingCard: true });
      const allOptions = {
        url: serverUrl + "oauth-config/" + localStorage.getItem('pId'),
      };
      let initialLoad = await getData(allOptions);
      this.setState({
        oauthData: initialLoad.data,
        loadingCard: false,
      });
    } catch (e) {
      const errorObj = FindError(e)
      this.setState({
        displayToast: true,
        messageToast: errorObj.message,
        severity: "error",
        confirmAlert: false,
        loadingCard: false,
      });
    }
  };

  aNewoauth = () => {
    let oauthDataVar = [...this.state.oauthData];
    oauthDataVar.push({_id:1});
    this.setState({oauthData:oauthDataVar})
  };

  // Close Toast
  closeToast = () => {
    this.setState({ displayToast: false });
  };

  OAuthFormSubmit = async(e, index, value, isNewForm = false) => {
    e?.preventDefault();
    let oauthDataVar = [...this.state.oauthData];
    let dataId = oauthDataVar[index]["id"];   
    if(value.scopes) {
      if(!Array.isArray(value.scopes)){
        value.scopes = value.scopes.split(",")
      }      
    }
    try{
      this.setState({ loadingCard: true });
      if(!isNewForm){
        const allOptions = {
          url: serverUrl + "oauth-config/" + localStorage.getItem('pId'),
          body: { 
            ...value,
            projectId: localStorage.getItem("pId"),
          },
        };
        await saveData(allOptions);
        this.setState({
          displayToast: true,
          messageToast: "Created Successfully",
          severity: "success",
          loadingCard: false,
        });
        this.switchJrnyExpansionpanel();
        this.getAllData();
      }
      else{
        const allOptions = {
          url: serverUrl + "oauth-config/" + localStorage.getItem('pId') + '/' + dataId,
          body: {
            ...value,
            projectId: localStorage.getItem("pId"),
          },
        }
        await updateData(allOptions);
        this.setState({
          displayToast: true,
          messageToast: "Updated Successfully",
          severity: "success",
          loadingCard: false,
        });
        this.switchJrnyExpansionpanel();
        this.getAllData();
      }
    }
    catch(e){
      const errorObj = FindError(e)
      this.setState({
        displayToast: true,
        messageToast: errorObj.message,
        severity: "error",
        confirmAlert: false,
        loadingCard: false,
      });
    }
  }

  removeoauth =(index, oauthid) => {
    let oauthDataVar = [...this.state.oauthData];
    if (oauthid) {
      this.setState({
        confirmAlert: true,
        oauthId: oauthid,
      });
    } else {
      oauthDataVar.splice(index, 1);
      this.setState({ oauthData: oauthDataVar });
    }
  }

  onConfirmButtonClick = () => {
    this.confirmBtnDelete(this.state.oauthId);
  };

  // to close confirmation dialogue
  cancelBtn = () => {
    this.setState({ confirmAlert: false });
  };

  // function to delete selected service using api
  confirmBtnDelete = async (id) => {
    this.setState({ confirmAlert: true });
    try {
      const allOptions = {
        url: serverUrl + "oauth-config/" + localStorage.getItem('pId') + '/'+ id,
      };
      await deleteData(allOptions);
      this.setState({
        displayToast: true,
        messageToast: "Deleted Successfully",
        severity: "success",
      });
      this.setState({ confirmAlert: false });
    } catch (e) {
      const errorObj = FindError(e)
      this.setState({
        displayToast: true,
        messageToast: errorObj.message,
        severity: "error",
        confirmAlert: false,
      });
    }
    this.getAllData();
  };

  cancelExpand = () => {
    this.setState({
      expansionJrnyPanelOpen: false,
      journeyIndex: "",
      value: 0,
    });
  };

  bannerHeader = () => {
    return(
      <div className="display-flex table_head font13 fontWeight700 cardFull cardSpaceArrow">
        <div className="table_grid">
          <p>API Name </p>
        </div>
        <div className="table_grid">
          <p>Created By</p>
        </div>
        <div className="table_grid">
          <p>Created on</p>
        </div>
        <div className="table_grid">
          <p>Last Modified on</p>
        </div>
      </div>
    )
  }

  bannerCard = (item, index) => {
    return(
      <div className="display-flex text_black font13 w100 pr15 pl15">
        <LoaderSpinner loadingCard={this.state.loadingCard} />
        {item.name ? (
          <div className="table_grid">
            <p
              id={`apiServiceName${index}`}
              className={`in-block ${
                this.state.permissions ? "" : "disable-click"
              }`}
            >
              {item.name}
            </p>
          </div>
        ): (
          <p style={{ fontSize: "13px" }}>New OAuth Config</p>
        )}
        <div className="table_grid">
          <p className="">{item.createdBy} </p>
        </div>
        <div className="table_grid">
          <p>
            <img src={calender} className="vertical-7" alt="calendar Icon" />
            <span
              style={{
                color: "#000",
                marginTop: "6px",
              }}
            >
              {moment(item.createdOn).format("DD-MMM-YYYY")}
            </span>
          </p>
        </div>
        <div className="table_grid">
          <p className="flex" style={{ marginTop: "8px" }}>
            <img src={calender} className="vertical-7" alt="calendar Icon" />
            <span
              style={{
                color: "#000",
                marginTop: "6px",
              }}
            >
              {moment(item.modifiedOn).format("DD-MMM-YYYY")}
            </span>
          </p>
        </div>
        <img
          src={deletewhiteIcon}
          alt="trash Icon"
          id={`oauthDeleteImg${index}`}
          onClick={() => this.removeoauth(index, item.id)}
          className="deleteIcon"
        />
      </div>
    )
  }

  render() {
    return (
      <div className="parent mt10">
        {this.state.confirmAlert === true && (
          <ConfirmAlert
            onConfirmBtn={this.onConfirmButtonClick}
            onCancelBtn={this.cancelBtn}
            heading="Are you Sure?"
            paragraph="If you proceed you will loose the data. Are you sure you want to delete ?"
          />
        )}
        <PositionedSnackbar
          display={this.state.displayToast}
          msg={this.state.messageToast}
          closeToast={this.closeToast}
          severity={this.state.severity}
        />
        <div className="display-flex">
          <div style={{flex:1}}>
            <h4 className="mt0 in-block">
              OAuth Config <span className="label_pink">Configure OAuth</span>
            </h4>
          </div>
          <div>
            <button
              className="sxp-btn btn-primary"
              id="createNewEnterpriseAPI"
              onClick={this.aNewoauth}
            >
              <AddIcon className="vertical-7" /> OAuth Config
            </button>
          </div>
        </div>

        <div>
          {this.bannerHeader()}
          {this.state.oauthData.length ? (
            <div>
              {this.state.oauthData.map((item, index) => (
                <ExpansionPanel
                  className="CustomexpansionPannel"
                  key={item.id + index}
                  expanded={
                    this.state.expansionJrnyPanelOpen &&
                    this.state.journeyIndex === index
                  }
                >
                  <ExpansionPanelSummary
                    expandIcon={
                      <ExpandMoreIcon
                        className="iconExpand"
                        id={`expansionArrow${index}`}
                        onClick={this.switchJrnyExpansionpanel.bind(
                          this,
                          index
                        )}
                      />
                    }
                    style={{ order: -1 }}
                    className="boxShadow"
                  >
                    {this.bannerCard(item, index)}
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails className="inner_expandBlock">
                  <FieldGroup
                      control={this.conversationFormBuilder}
                      render={({ invalid, value }) => (
                        <form
                          onSubmit={(e) =>
                            this.OAuthFormSubmit(
                              e,
                              index,
                              value,
                              item.id ? true : false
                            )
                          }
                        >
                          <div className="">
                            <div className="formField imb10">
                              <div>
                                <FieldControl
                                  name="name"
                                  options={{ validators: Validators.required }}
                                  render={({ handler, touched, hasError }) => (
                                    <div className="">
                                      <input
                                        {...handler()}
                                        id={`serverUrl${index}`}
                                        placeholder="OAuth Name"
                                        className={`${
                                          touched &&
                                          hasError("required") &&
                                          "errorInput"
                                        }`}
                                      />
                                      <span
                                        className={`${
                                          touched &&
                                          hasError("required") &&
                                          "errorFieldMsg"
                                        }`}
                                      >
                                        {touched &&
                                          hasError("required") &&
                                          "Required Filed"}
                                      </span>
                                    </div>
                                  )}
                                />
                              </div>
                              <h4 className="varHead">OAuth Config Fields</h4>
                              <div className="flex" style={{flexWrap: 'wrap'}}>
                                <FieldControl
                                  name="serverUrl"
                                  options={{ validators: Validators.required }}
                                  render={({ handler, touched, hasError }) => (
                                    <div className="">
                                      <input
                                        {...handler()}
                                        id={`serverUrl${index}`}
                                        placeholder="Server URL"
                                        className={`${
                                          touched &&
                                          hasError("required") &&
                                          "errorInput"
                                        }`}
                                      />
                                      <span
                                        className={`${
                                          touched &&
                                          hasError("required") &&
                                          "errorFieldMsg"
                                        }`}
                                      >
                                        {touched &&
                                          hasError("required") &&
                                          "Required Filed"}
                                      </span>
                                    </div>
                                  )}
                                />
                                <FieldControl
                                  name="tokenEndpoint"
                                  options={{ validators: Validators.required }}
                                  render={({ handler, touched, hasError }) => (
                                    <div className="">
                                      <input
                                        {...handler()}
                                        id={`tokenEndpoint${index}`}
                                        placeholder="Token Endpoint"
                                        className={`${
                                          touched &&
                                          hasError("required") &&
                                          "errorInput"
                                        }`}
                                      />
                                      <span
                                        className={`${
                                          touched &&
                                          hasError("required") &&
                                          "errorFieldMsg"
                                        }`}
                                      >
                                        {touched &&
                                          hasError("required") &&
                                          "Required Filed"}
                                      </span>
                                    </div>
                                  )}
                                />
                                <FieldControl
                                  name="clientId"
                                  options={{ validators: Validators.required }}
                                  render={({ handler, touched, hasError }) => (
                                    <div className="">
                                      <input
                                        {...handler()}
                                        id={`clientId${index}`}
                                        placeholder="client ID"
                                        className={`${
                                          touched &&
                                          hasError("required") &&
                                          "errorInput"
                                        }`}
                                      />
                                      <span
                                        className={`${
                                          touched &&
                                          hasError("required") &&
                                          "errorFieldMsg"
                                        }`}
                                      >
                                        {touched &&
                                          hasError("required") &&
                                          "Required Filed"}
                                      </span>
                                    </div>
                                  )}
                                />
                                <FieldControl
                                  name="clientSecret"
                                  options={{ validators: Validators.required }}
                                  render={({ handler, touched, hasError }) => (
                                    <div className="">
                                      <input
                                        {...handler()}
                                        id={`clientSecret${index}`}
                                        placeholder="client Secret"
                                        className={`${
                                          touched &&
                                          hasError("required") &&
                                          "errorInput"
                                        }`}
                                      />
                                      <span
                                        className={`${
                                          touched &&
                                          hasError("required") &&
                                          "errorFieldMsg"
                                        }`}
                                      >
                                        {touched &&
                                          hasError("required") &&
                                          "Required Filed"}
                                      </span>
                                    </div>
                                  )}
                                />
                                <FieldControl
                                  name="scopes"
                                  options={{ validators: Validators.required }}
                                  render={({ handler, touched, hasError }) => (
                                    <div className="">
                                      <input
                                        {...handler()}
                                        id={`scopes${index}`}
                                        placeholder="scopes"
                                        className={`${
                                          touched &&
                                          hasError("required") &&
                                          "errorInput"
                                        }`}
                                      />
                                      <span
                                        className={`${
                                          touched &&
                                          hasError("required") &&
                                          "errorFieldMsg"
                                        }`}
                                      >
                                        {touched &&
                                          hasError("required") &&
                                          "Required Filed"}
                                      </span>
                                    </div>
                                  )}
                                />
                                
                            </div>
                            
                          </div>
                          <div className="buttonClmns footer text-center">
                              <button
                                className={`sxp-btn btn-primary primaryLarge ml5 mr5 ${
                                  invalid && "disablePagBtn"
                                }`}
                                id={`apiServiceSubmit${index}`}
                                type="submit"
                                style={{ height: "34px" }}
                                disabled={invalid}
                              >
                                Save
                              </button>
                              <span
                                className="sxp-btn btn-danger primaryLarge"
                                onClick={() => this.cancelExpand(index)}
                                style={{
                                  height: "34px",
                                  width: "220px",
                                  textAlign: "center",
                                  margin: "0 5px",
                                  padding: "9px 0",
                                  display: "inline-block",
                                  verticalAlign: "top",
                                }}
                              >
                                Cancel
                              </span>
                            </div>
                          </div>
                        </form>
                      )}
                      /> 
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              ))}
            </div>
          ):(<EmptyCard />)}
        </div>

      </div>
    )
  }
}

export default OAuthConfig;