import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import MenuIcon from "@material-ui/icons/Menu";
import User from "@material-ui/icons/Person";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import { Slide, useScrollTrigger } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import GroupIcon from "@material-ui/icons/Group";
import FolderSpecialIcon from "@material-ui/icons/FolderSpecial";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import KeycloakUserService from '../../auth/KeycloakLogin';
import HttpService from '../../HttpService';
import "./header.scss";
import { NavLink } from "react-router-dom";
import PositionedSnackbar from "../uicomponents/toast";
import toggleMenuAction from "../../actions/menuAction";
import { connect } from "react-redux";

const axios = HttpService.getAxiosClient();

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  sectionDesktop: {
    display: "flex",
  },
}));

const SERVER_URL = window._env_.REACT_APP_SXP_URL;
// const userName = localStorage.getItem('userName');
const PROJECTS_URL = SERVER_URL + `projects`;

function HeaderComponent(props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [projects, setProjects] = useState([]);
  const [age, setAge] = React.useState(localStorage.getItem("pId"));
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const [displayToast, setDisplayToast] = useState(false); // displayToast
  const [severity, setSeverity] = useState('error'); // severity
  const [messageToast, setMessageToast] = useState('error'); // severity
  const [callProjects, setCallProjects] = useState(true)
  const isMenuOpen = Boolean(anchorEl); 

  useEffect(() => {
    if(props.callHeder.value === true && callProjects === false)
    {
      setCallProjects(true);
    }
    if(callProjects){
      fetchAllProjects();
      setInitialVersion();
    }
  });

  const setInitialVersion = async () => {
    if (!localStorage.getItem('version')) {
      localStorage.setItem('version', 'WORKING');
    }
    if(!localStorage.getItem('rowPerPage')){
      localStorage.setItem('rowPerPage', 10);
    }
  };

  const fetchAllProjects = async () => {
    try {
      const res = await axios.get(PROJECTS_URL);  
      props.callHeder['value'] = false;     
      if(res && res.data && res.data.length){
        const data = res.data;
        if(!localStorage.getItem('pId')){
          localStorage.setItem('pId', data[0].id)
          setAge(data[0].id)
        }
        setCallProjects(false)
        props.callHeder['value'] = false; 
        setProjects(data);
        const projectIdInData = obj => obj.id === localStorage.getItem('pId');
        if(!data.some(projectIdInData)){
          localStorage.setItem('pId', data[0].id)
          setAge(data[0].id)
          setDisplayToast(true)
          setSeverity('success')
          setMessageToast('Your are now assigned to "' +data[0].name+ '" project')
        }
        if(!localStorage.getItem('pDashboardId')){
          let getDashboardId = data.find(x => x.id === localStorage.getItem('pId'));
          localStorage.setItem('pDashboardId', getDashboardId.dashboardId)
        }
      }
      else if(res.data.length === 0){
        setCallProjects(false)
        props.callBackToHeader(true);   
      }
      else{
        setDisplayToast(true)
        setSeverity('error')
        setMessageToast('No projects found')
      }
    } catch (error) {
      console.log(error)
    }
  };

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleSideMenu = () => {
    props.toggleMenuAction(!props.toggleMenu);
    if(!localStorage.getItem('smallSidbar'))
    {
      localStorage.setItem('smallSidbar', 'false');
    }
    if(localStorage.getItem('smallSidbar') === 'false'){      
      localStorage.setItem('smallSidbar', 'true')
    }
    else{
      localStorage.setItem('smallSidbar', 'false')
    }
  };

  const closeToast = () => {
    setDisplayToast(false);
  }

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleChange = (event) => {
    let allProjects = projects;
    let getDashboardId = allProjects.find(x => x.id === event.target.value);
    setAge(event.target.value);
    localStorage.setItem("pId", event.target.value);
    localStorage.setItem('pDashboardId', getDashboardId.dashboardId)
    localStorage.setItem('hasBackendServer', getDashboardId.hasBackendServer)
    window.location.reload();
  };

  const menuId = "primary-search-account-menu";
  const role = localStorage.getItem("role");
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      id={menuId}
      keepMounted
      className="profileIconDD handleProfileDD"
      transformOrigin={{ vertical: "bottom", horizontal: "right" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem>
        <NavLink id="profileDDMenu" to={{ pathname:"/profile", tabName: 'Overview' }}>
          <User /> Profile
        </NavLink>
      </MenuItem>
      <MenuItem>
        <NavLink id="projectDDMenu" to={{ pathname:"/profile", tabName: 'Projects' }}>
          <FolderSpecialIcon /> Projects
        </NavLink>
      </MenuItem>
      <MenuItem>
        <NavLink id="userDDMenu" to={{ pathname:"/profile", tabName: 'Users' }}>
          <GroupIcon /> Users
        </NavLink>
      </MenuItem>
      <MenuItem>
        <NavLink id="roleDDMenu" to={{ pathname:"/profile", tabName: 'Roles' }}>
          <AssignmentIndIcon /> Roles
        </NavLink>
      </MenuItem>
      <MenuItem id="logoutDDMenu" onClick={KeycloakUserService.doLogout}>
        <PowerSettingsNewIcon /> Logout
      </MenuItem>
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";

  const HideOnScroll = ({ children }) => {
    const trigger = useScrollTrigger();
    return (
      <Slide appear={false} direction="down" in={!trigger}>
        {children}
      </Slide>
    );
  };

  return (
    <div className={classes.grow}>
      <HideOnScroll>
        <AppBar position="fixed" className="headerBgColor not-scrolled">        
          <PositionedSnackbar
            display={displayToast}
            msg={messageToast}
            closeToast={closeToast}
            severity={severity}
          />
          <Toolbar>
            <IconButton
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="open drawer"
              onClick={handleSideMenu}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" className="handleTitle" noWrap>
              SXP-Dashboard
            </Typography>
            <div className={classes.grow} />
            <div className={classes.sectionDesktop}>
              <div className="projectsDD mt8 p12">
                <FormControl className="">
                  <Select
                    value={age}
                    onChange={handleChange}
                    displayEmpty
                    disableUnderline
                    className="handleDDStyle"
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    {projects.map((d) => (
                     <MenuItem style={{fontSize:13}} id={`projectId${d.id}`} data-dashboardId={d.dashboardId} key={d.id} value={d.id}>
                     {d.name}
                     </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>

              <div className="p12 pr0 text-right">
                <p className="m0 font15">{localStorage.getItem("userName")}</p>
                <h5 className="m0 font13 fontWeight600">
                  {role ? role.slice(1, -1) : "Super Admin"}
                </h5>
              </div>
              <IconButton
                edge="end"
                aria-label="account of current user"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={handleProfileMenuOpen}
                color="inherit"
              >
                <img
                  src="https://cdn2.f-cdn.com/contestentries/1316431/24595406/5ae8a3f2e4e98_thumb900.jpg"
                  width={42}
                  height={42}
                  className="borderRadius6"
                  alt="dashboard-logo"
                />
              </IconButton>
            </div>
          </Toolbar>
        </AppBar>
      </HideOnScroll>
      {renderMenu}
    </div>
  );
}

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = (dispatch) => ({
  // startAction: () => dispatch(startAction),
  // stopAction: () => dispatch(stopAction),
  toggleMenuAction: (payload) => dispatch(toggleMenuAction(payload))

});

export default connect(mapStateToProps, mapDispatchToProps)(HeaderComponent);