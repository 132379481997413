import React, {useEffect, useRef} from 'react';
import "ace-builds";
import "ace-builds/webpack-resolver";
import AceEditor from "react-ace";

import "ace-builds/src-noconflict/mode-javascript";
import "ace-builds/src-noconflict/theme-monokai";
import "ace-builds/src-noconflict/ext-language_tools";
import "ace-builds/src-min-noconflict/ext-searchbox";

const UFEditor = ({funcData, onChange, onSave, funcName=null, mode, loaded}) => {
  const aceEditor = useRef();

  useEffect(() => {
    const editor = aceEditor.current.editor;
    if (mode === "all") {
      const lines = editor.session.getLength();
      editor.gotoLine(lines, Infinity, true);
    } else if (mode === "edit") {
      editor.find(funcName);
    } else {
      editor.find("exports");
      const line = editor.getSelectionRange().end.row;
      editor.gotoLine(line + 1, Infinity, true);
      editor.session.insert(editor.getCursorPosition(), `\n  ${funcName}: (data, state) => {},`);
    }
    editor.focus();
  }, [mode, funcName, loaded]);

  return (
    <>
      <AceEditor
        ref={aceEditor}
        style={{marginTop:'12px'}}
        placeholder="Enter JS Functions here"
        mode="javascript"
        theme="monokai"
        name="user_functions"
        width="500"
        onChange={onChange}
        fontSize={16}
        showPrintMargin={true}
        showGutter={true}
        highlightActiveLine={true}
        value={funcData}
        setOptions={{
          enableBasicAutocompletion: true,
          enableLiveAutocompletion: true,
          enableSnippets: false,
          showLineNumbers: true,
          tabSize: 2,
        }}
      />
      <button
        className="sxp-btn btn-primary"
        // disabled={localStorage.getItem('version') === 'WORKING' ? false: true}
        style={{ margin: "10px 0" }}
        onClick={(e) => {
          onSave();
          e.preventDefault();
        }}
        id="saveUserFunctionBtn"
      >
        Save
      </button>
    </>
  );
};

export default UFEditor;