import { ComponentType } from "../constants/componentType";

export const submit = (e, props, state) => {
  e.preventDefault();
  const data = new FormData(e.target);
  let values = {
    alwaysAsk: false,
    conversationRefresh: false,
    viewMore:false,
    isDialog: false,
    isABActive: false,
    isBillable: false,
    recommend: false,
    isButtonsDynamic: false,
    optional: false,
    onChange: false,
    isSearchable: false,
    multiSelect: false,
    transactionalConversation: false,
    form: false,
    edit: false,
    dynamicJourney: false,
    apiLoop:false
  };
  // Notification multiselect channel related block
  if (state?.channels) {
    values["channels"] = state.channels;
  }

  if(state?.component === "UTTERANCE"){
    if(!state.isButtonsDynamic)
    { 
      state.buttonsDynamicValue = '';
    }
    if(state?.buttonFields.length > 0){
      state.isButtonsDynamic = false;
      if(Object.keys(state.buttonFields).length < 2)
      {
        if(Object.keys(state.buttonFields[0]).length === 0)
        {
          state.buttonFields = []
        }
      }
      data.append('buttonFields',state.buttonFields);
      data.delete('label');
      data.delete('value')
    }
    if(state?.evalAnswer === '' || state?.evalAnswer === 'String' || state?.evalAnswer === 'Image' || state?.evalAnswer === 'Location'){
      state.buttonFields = []
    }
    if(state?.isUserRegistrationComponent){
      delete values['isButtonsDynamic'];
      delete values['optional']
      delete values['onChange'];
      delete values['multiSelect'];
      delete values['isSearchable'];
    }
    if(state?.evalAnswer === 'Confirm'){
      data.append('buttonFields',state.buttonFields);
      state.buttonFields = [
        {label: 'Yes', value:'yes'},
        {label: 'No', value:'no'},
      ];
      values["isButtonsDynamic"]= false;
    }
  }

  // Notification multiselect channel related block
  for (var [key, value] of data.entries()) {
    if (
      (key === "alwaysAsk" ||
        key === "conversationRefresh" ||
        key === "viewMore" ||
        key === "isDialog" ||
        key === "isABActive" ||
        key === "recommend" ||
        key === "isButtonsDynamic" ||
        key === "optional" ||
        key === "onChange" ||
        key === "isSearchable" ||
        key === "multiSelect" ||
        key === "transactionalConversation" ||
        key === 'form' ||
        key === 'dynamicJourney' ||
        key === 'edit' ||
        key === 'apiLoop' ||
        key === "isBillable") &&
      value === "on"
    ) {
      values[key] = true;
    } else if (key === "isUserRegistrationComponent") {
      //Cheating the component by changing the user register type to utterance.
      // Since in the backend we are using utterance component for user register as well
      values["component"] = ComponentType.QUESTION;
      values["isUserRegistrationComponent"] = true;
      // values["buttonFields"] = [];
      values["buttonsDynamicValue"] = "Yes,No";
      values["reference"] = "___registerQ___";
      values["evalAnswer"] = "Button";
      values["isButtonsDynamic"]= true;
      values["onChange"]= true;
      values["isSearchable"]= true;
      values["multiSelect"]= true;
    } else if (key === "serviceId") {
      if (!value) {
        delete data[key];
      } else values[key] = value;
    } else if (key === "utterance") {
      var mapped = state.utterance.map(item => ({ [item.lang]: item.label }) );
      var object = Object.assign({}, ...mapped );
      values[key] = object;
    } else if (key === "notificationMessage") {
      // values[key] = { en: value };
      var mapped = state.notificationMessage.map(item => ({ [item.lang]: item.label }) );
      var object = Object.assign({}, ...mapped );
      values[key] = object;
    } else if (key === "richText") {
      values[key] = { en: value };
    } else if (key === 'buttonFields') {
      data.delete('buttonFields[]')
      values[key] = state.buttonFields
    }else {
      values[key] = value;
    }
  }

  if (props?.data?.data?.component === ComponentType.EDGE) {
    delete values['isButtonsDynamic'];
    delete values['optional'];
    delete values['onChange'];
    delete values['isSearchable'];
    delete values['multiSelect'];
    delete values['transactionalConversation'];
    delete values['form'];
    delete values['edit'];
    delete values['dynamicJourney'];
    delete values['dynamicReferences'];
    delete values['viewMore'];
    delete values['apiLoop'];
  }

  if (props?.data?.data?.component === ComponentType.ABEDGE) {
    delete values['isButtonsDynamic'];
    delete values['optional'];
    delete values['onChange'];
    delete values['isSearchable'];
    delete values['multiSelect']
    delete values['transactionalConversation'];
    delete values['form'];
    delete values['edit'];
    delete values['dynamicJourney'];
    delete values['dynamicReferences'];
  }

  if(props?.data?.data?.component !== 'START_JOURNEY')
  {
    delete values['form']; 
    delete values['edit'];
    delete values['dynamicJourney'];
    delete values['dynamicReferences'];
  }
  if(props?.data?.data?.component === 'START_NODE'){
    delete value['alwaysAsk'];
    delete value['apiLoop'];
    delete value['conversationRefresh'];
    delete value['isABActive'];
    delete value['isBillable'];
    delete value['isButtonsDynamic'];
    delete value['isDialog'];
    delete value['isSearchable'];
    delete value['label'];
    delete value['multiSelect'];
    delete value['onChange'];
    delete value['optional'];
    delete value['recommend'];
    delete value['transactionalConversation'];
    delete value['viewMore'];
  }
  
  values["function"]
    ? (values.label = values["function"])
    : values["abWeight"]
    ? (values.label = values["abWeight"])
    : (values.label = "");

  const collapseForm = props.formAction;
  collapseForm();
  props.refreshElements(props.data, values);
};

export const deleteNode = (props) => {
  props.removeElement([props.data]);
  const collapseForm = props.formAction;
  collapseForm();
};
