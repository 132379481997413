import React, { Component } from 'react'
import ConfirmAlert from '../../commons/uicomponents/confirm';
import PositionedSnackbar from '../../commons/uicomponents/toast';
import AddIcon from "@material-ui/icons/Add";
import EmptyCard from '../../commons/uicomponents/emptycard';
import { ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary,
     FormHelperText, MenuItem, Select } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import LoaderSpinner from '../../commons/uicomponents/loader';
import deletewhiteIcon from "../../assets/images/deletewhite.png";
import subtractIcon from "../../assets/images/subtract.png";
import calender from "../../assets/images/calender.png";
import moment from 'moment';
import { FieldControl, FieldGroup, FormBuilder, Validators } from 'react-reactive-form';
import '../oauthconfig/oauth.scss'
import FindError from '../../dataProvider/errorHandler';
import { addBackendData, deleteBackendData, getBackendData, updateBackendData } from './backendApiHandler';

const serverUrl = window._env_.REACT_APP_PARSE_DASHBOARD_URL + 'backend-services/app/';

class ParseUi extends Component {
  constructor(props){
    super(props);
    this.state={
      oauthData : [],
      expansionJrnyPanelOpen: false,
      journeyIndex: "",
      confirmAlert: false,
      loadingCard: false,
      displayToast: false,
      className:'',
      isRequired:false,
      confirmAlertChild: false,
      filedsClass:''
    };
  }

  // EXPANSIONS HANDLERS
  switchJrnyExpansionpanel(index) {    
    if (this.state.journeyIndex === index) {
      this.setState({
        expansionJrnyPanelOpen: false,
        journeyIndex: "",
      });
    } else {
      this.setState({
        expansionJrnyPanelOpen: true,
        journeyIndex: index,
      });
      this.conversationFormBuilder.reset();
      this.buildingConversationForm(index);
    }
  }

  conversationFormBuilder = FormBuilder.group({
    name:["", Validators.required],
  })

  // to patch the value in form field when the card is expanded
  buildingConversationForm = (data) => {
    let servData = [...this.state.oauthData];
    if(servData[data]==null || servData[data] == undefined || servData[data].length == 0)
    {
    }
    else{
      if (Object.keys(servData[data]).length) {     
        this.conversationFormBuilder.patchValue({
          className:servData[data].className        
        });
      }
    }   
  };  
  
  componentDidMount = () => {
    this.getAllData();
  
  };

  componentDidUpdate=()=> {
    if(this.conversationFormBuilder)
    {
      this.conversationFormBuilder.get('type')?.valueChanges.subscribe((value) => {
        if(value !== 'Pointer' || value !== 'Relation'){
          this.conversationFormBuilder.removeControl('targetClass');
        }
      })
    }
  }

  getAllData = async() => {
    try{
      this.setState({ loadingCard: true });
      const allOptions = {
        url: serverUrl + localStorage.getItem('pId')+"/schemas",
      };
      let initialLoad = await getBackendData(allOptions);
      const filteredClasses = initialLoad.data.results.filter(data => !/^[^a-z]/i.test(data.className))
      this.setState({
        oauthData: filteredClasses,
        loadingCard: false,
      });
    } catch (e) {
      const errorObj = FindError(e)
      this.setState({
        displayToast: true,
        messageToast: errorObj.message,
        severity: "error",
        confirmAlert: false,
        loadingCard: false
      });
    }
    this.conversationFormBuilder.reset();
  };

  aNewoauth = () => {
    let oauthDataVar = [...this.state.oauthData];
    oauthDataVar.push({});
    this.setState({ 
      oauthData:oauthDataVar,
      displayToast: true,
      messageToast: "New Schema added in bottom",
      severity: "success",
    })
  };

  // Close Toast
  closeToast = () => {
    this.setState({ displayToast: false });
  };

  removeoauth =(className) => {
      this.setState({
        confirmAlert: true,
        className,
      });
  }

  onConfirmButtonClick = () => {
    this.confirmBtnDelete(this.state.className);
  };

  // to close confirmation dialogue
  cancelBtn = () => {
    this.setState({ confirmAlert: false });
  };

  // function to delete selected service using api
  confirmBtnDelete = async (className) => {
    this.setState({ confirmAlert: true });
    try {
      const allOptions = {
        url: serverUrl + "project1/schemas/" + className,
      };
      await deleteBackendData(allOptions);
      this.setState({
        displayToast: true,
        messageToast: "Deleted Successfully",
        severity: "success",
      });
      this.setState({ confirmAlert: false });
    } catch (e) {
      const errorObj = FindError(e)
      this.setState({
        displayToast: true,
        messageToast: errorObj.message,
        severity: "error",
        confirmAlert: false,
      });
    }
    this.getAllData();
  };

  bannerHeader = () => {
    return(
      <div className="display-flex table_head font13 fontWeight700 cardFull cardSpaceArrow">
        <div className="table_grid">
          <p>API Name </p>
        </div>
        <div className="table_grid">
          <p>Created By</p>
        </div>
        <div className="table_grid">
          <p>Created on</p>
        </div>
        <div className="table_grid">
          <p>Last Modified on</p>
        </div>
      </div>
    )
  }

  bannerCard = (item, index) => {
    return(
      <div className="display-flex text_black font13 w100 pr15 pl15">
        <LoaderSpinner loadingCard={this.state.loadingCard} />
        {item.className ? (
          <div className="table_grid">
            <p
              id={`apiServiceName${index}`}
              className={`in-block ${
                this.state.permissions ? "" : "disable-click"
              }`}
              onClick={() => this.InputPlaceEdit(index)}
            >
              {item.className}
            </p>
          </div>
        ): (
          <p style={{ fontSize: "13px" }}>New Class Schema</p>
        )}
        <div className="table_grid">
          <p className="">{'me'} </p>
        </div>
        <div className="table_grid">
          <p>
            <img src={calender} className="vertical-7" alt="calendar Icon" />
            <span
              style={{
                color: "#000",
                marginTop: "6px",
              }}
            >
              {moment(item.createdOn).format("DD-MMM-YYYY")}
            </span>
          </p>
        </div>
        <div className="table_grid">
          <p className="flex" style={{ marginTop: "8px" }}>
            <img src={calender} className="vertical-7" alt="calendar Icon" />
            <span
              style={{
                color: "#000",
                marginTop: "6px",
              }}
            >
              {moment(item.modifiedOn).format("DD-MMM-YYYY")}
            </span>
          </p>
        </div>
        <img
          src={deletewhiteIcon}
          alt="trash Icon"
          id={`oauthDeleteImg${index}`}
          onClick={() => this.removeoauth(item.className)}
          className="deleteIcon"
        />
      </div>
    )
  }

  cancelBtn1 = () => {
    this.setState({ confirmAlertChild: false });
  };

  deleteSchema = (className, field) => {
    this.setState({
      confirmAlertChild: true,
      className: className,
      filedsClass: field,
    });
  }

  confirmBtn1 = async () => {
    this.deleteSchemaSubmit(this.state.className, this.state.filedsClass);
  }

  deleteSchemaSubmit = async (className, field) => {
    try{
      const allOptions = {
        url: serverUrl + `project1/schemas/${className}`,
        headers: {
            "X-Parse-Application-Id": 'project1',
            "X-Parse-Master-Key": '111111',
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*"
        },
        body: {
            className, fields: JSON.parse(`{"${field}": { "__op": "Delete" }}`)
        }
      };
      await updateBackendData(allOptions);
      this.getAllData();
      this.setState({
        confirmAlertChild:false,
        displayToast: true,
        messageToast: "Removed Saved Schema Successfully",
        severity: "success",
      });
    }
    catch(e) {
      this.setState({
        displayToast: true,
        messageToast: e.response.data.error,
        severity: "error",
        confirmAlert: false,
        loadingCard:false
      });
    }
  }

  updateObjectSchema = async (index, className, formValueData) => {
    try{
      const allOptions = {
        url: serverUrl + `project1/schemas/${className}`,
        body: {
            className, fields: JSON.parse(`{"${formValueData.field}": { "type": "${formValueData.type}" ${formValueData.type === 'Relation' || formValueData.type === 'Pointer' ? `, "targetClass": "${formValueData.targetClass}"`: ''} ${this.state.isRequired == true && formValueData.type !== 'Relation' ? `, "required": "${this.state.isRequired}"`: ''} }}`)
        }
      };
    await updateBackendData(allOptions);
    this.getAllData();
    this.setState({
      loadingCard: false,
      displayToast: true,
      messageToast: "Updated Schema Successfully",
      severity: "success",
    }, ()=> this.switchJrnyExpansionpanel(index));
    }
    catch(e){
      this.setState({
        displayToast: true,
        messageToast: e.response.data.error,
        severity: "error",
        confirmAlert: false,
        loadingCard:false
      });
    }   
  }

  addObjectSchema = async (index,formValueData) => {
    this.setState({loadingCard: true})
    try{
      const allOptions = {
        url: serverUrl + `project1/schemas/${formValueData.className}`,
        body: {
            className: formValueData.className, 
            fields: JSON.parse(`{"${formValueData.field}": { "type": "${formValueData.type}" ${formValueData.type === 'Relation' || formValueData.type === 'Pointer' ? `, "targetClass": "${formValueData.targetClass}"`: ''} ${this.state.isRequired == true && formValueData.type !== 'Relation' ? `, "required": "${this.state.isRequired}"`: ''} }}`)
        }
      };
      await addBackendData(allOptions);
      this.getAllData();      
      this.conversationFormBuilder.reset();
      this.setState({
        loadingCard: false,
        displayToast: true,
        messageToast: "Added New Schema Successfully",
        severity: "success",
      }, ()=> this.switchJrnyExpansionpanel(index));
    }
    catch(e){
      this.setState({
        displayToast: true,
        messageToast: e.response.data.error,
        severity: "error",
        confirmAlert: false,
        loadingCard:false
      });
    }    
    
  }

  handleSwitchChange = () => {
    this.setState({isRequired: !this.state.isRequired});
    this.conversationFormBuilder.patchValue({})
  }

  render() {
    return (
      <div className="parent mt10">
        {this.state.confirmAlert === true && (
          <ConfirmAlert
            onConfirmBtn={this.onConfirmButtonClick}
            onCancelBtn={this.cancelBtn}
            heading="Are you Sure?"
            paragraph="If you proceed you will loose the data. Are you sure you want to delete ?"
          />
        )}
        {this.state.confirmAlertChild === true && (
          <ConfirmAlert
            indexValue={this.state.slabsIndex}
            mainIndex={this.state.apiServicesNIndex}
            onConfirmBtn={this.confirmBtn1}
            onCancelBtn={this.cancelBtn1}
            heading="Are you sure ?"
            paragraph="If you proceed key will be removed from the schema"
          />
        )}
        <PositionedSnackbar
          display={this.state.displayToast}
          msg={this.state.messageToast}
          closeToast={this.closeToast}
          severity={this.state.severity}
        />
        <div className="display-flex">
          <div style={{flex:1}}>
            <h4 className="mt0 in-block">
              App Database <span className="label_pink">Configure Schema</span>
            </h4>
          </div>
          <div>
            <button
              className="sxp-btn btn-primary"
              id="createNewEnterpriseAPI"
              onClick={this.aNewoauth}
            >
              <AddIcon className="vertical-7" /> New Schema
            </button>
          </div>
        </div>

        <div>
          {this.bannerHeader()}
          {this.state.oauthData.length ? (
            <div>
              {this.state.oauthData.map((item, index) => (
                <ExpansionPanel
                  className="CustomexpansionPannel"
                  key={item.className + index}
                  expanded={
                    this.state.expansionJrnyPanelOpen &&
                    this.state.journeyIndex === index
                  }
                >
                  <ExpansionPanelSummary
                    expandIcon={
                      <ExpandMoreIcon
                        className="iconExpand"
                        id={`expansionArrow${index}`}
                        onClick={this.switchJrnyExpansionpanel.bind(
                          this,
                          index
                        )}
                      />
                    }
                    style={{ order: -1 }}
                    className="boxShadow"
                  >
                    {this.bannerCard(item, index)}
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails className="inner_expandBlock">
                  <FieldGroup
                      control={this.conversationFormBuilder}
                      render={({ invalid, value }) => (
                        <form>
                          <div className="">
                            <div className="formField imb10">
                            {item.className ? null :
                                <FieldControl
                                    name="className"
                                    options={ item.className ? null :{ validators: Validators.required }}
                                    render={({ handler, touched, hasError }) => (
                                        <div>
                                        <input
                                            {...handler()}
                                            id={`serverUrl${index}`}
                                            placeholder="New Class Name"
                                            className={`${
                                            touched &&
                                            hasError("required") &&
                                            "errorInput"
                                            }`}
                                        />
                                        <span
                                            className={`${
                                            touched &&
                                            hasError("required") &&
                                            "errorFieldMsg"
                                            }`}
                                        >
                                            {touched &&
                                            hasError("required") &&
                                            "Required Filed"}
                                        </span>
                                        </div>
                                    )}
                                />
                            }
                              <div className="flex" style={{flexWrap: 'wrap'}}>
                                <FieldControl
                                  name="name"
                                  options={{ validators: Validators.required }}
                                  render={({ handler, touched, hasError }) => (
                                    <div className="">
                                      <input
                                        {...handler()}
                                        id={`serverUrl${index}`}
                                        placeholder="Schema Field Name"
                                        className={`${
                                          touched &&
                                          hasError("required") &&
                                          "errorInput"
                                        }`}
                                      />
                                      <span
                                        className={`${
                                          touched &&
                                          hasError("required") &&
                                          "errorFieldMsg"
                                        }`}
                                      >
                                        {touched &&
                                          hasError("required") &&
                                          "Required Filed"}
                                      </span>
                                    </div>
                                  )}
                                />
                                <FieldControl
                                  name="type"
                                  options={{ validators: Validators.required }}
                                  render={({ handler, touched, hasError }) => (
                                    <div className="pos-rel">                                      
                                      <Select
                                        value={item.type}
                                        id={`serverUrl${index}`}
                                        style={{ width: "194px", marginRight:'38px', marginBottom:'20px' }}
                                        {...handler()}
                                        className={`${
                                          touched &&
                                          hasError("required") &&
                                          "errorInput"
                                        } sxp-defaultDropDown_filed pos-rel`}
                                      >
                                        <MenuItem value="" disabled>
                                          Schema Field Type
                                        </MenuItem>
                                        <MenuItem value="String">String</MenuItem>
                                        <MenuItem value="Number">Number</MenuItem>
                                        <MenuItem value="Boolean">Boolean</MenuItem>
                                        <MenuItem value="Date">Date</MenuItem>
                                        <MenuItem value="Object">Object</MenuItem>
                                        <MenuItem value="Array">Array</MenuItem>
                                        <MenuItem value="File">File</MenuItem>
                                        <MenuItem value="Pointer">Pointer</MenuItem>
                                        <MenuItem value="Relation">Relation</MenuItem>
                                      </Select>
                                      {item.method === "" ?
                                      <FormHelperText>
                                        Select Method
                                      </FormHelperText>
                                      : null}
                                      <span
                                        className={`${
                                          touched &&
                                          hasError("required") &&
                                          "errorFieldMsg"
                                        }`}
                                      >
                                        {touched &&
                                          hasError("required") &&
                                          "Required Filed"}
                                      </span>
                                    </div>
                                  )}
                                />
                                {this.conversationFormBuilder.value.type === 'Pointer' || 
                                    this.conversationFormBuilder.value.type === 'Relation' ?
                                    <FieldControl
                                    name="targetClass"
                                    options={{ validators: Validators.required }}
                                    render={({ handler, touched, hasError }) => (
                                      <div className="pos-rel">                                      
                                        <Select
                                          value={item.relation}
                                          id={`relationtype${index}`}
                                          style={{ width: "194px", marginRight:'38px', marginBottom:'20px' }}
                                          {...handler()}
                                          className={`${
                                            touched &&
                                            hasError("required") &&
                                            "errorInput"
                                          } sxp-defaultDropDown_filed pos-rel`}
                                        >
                                          <MenuItem value="" disabled>
                                          {this.conversationFormBuilder.value.type}
                                          </MenuItem>
                                          {this.state.oauthData.filter(cls => cls.className !== item.className).map((classData) => (
                                                <MenuItem value={classData.className}>{classData.className}</MenuItem>
                                          ) )}
                                        </Select>
                                        {item.method === "" ?
                                        <FormHelperText>
                                          Select Method
                                        </FormHelperText>
                                        : null}
                                        <span
                                          className={`${
                                            touched &&
                                            hasError("required") &&
                                            "errorFieldMsg"
                                          }`}
                                        >
                                          {touched &&
                                            hasError("required") &&
                                            "Required Filed"}
                                        </span>
                                      </div>
                                    )}
                                  />:null                                 
                                }
                               
                                <div className="customInputStyle">
                                  <label className="mt0" style={{ verticalAlign: "super" }}
                                  >
                                    Is Required
                                  </label>
                                  <div
                                    className={`toggle-parent-modeller insideCard ${
                                      this.state.isRequired ? "" : "inactiveToggle"
                                    }`}
                                    id={`apiServiceUrlEncoded${index}`}
                                    style={{ marginTop: "3px" }}
                                    onClick={this.handleSwitchChange}
                                  >
                                    <div
                                      className={` ${
                                        this.state.isRequired
                                          ? "toggle-button"
                                          : " disabled-toggle"
                                      }`}
                                    ></div>
                                  </div>
                                </div>

                                {item.className ? 
                                    <button
                                        className={`sxp-btn btn-primary ${
                                          invalid && "disablePagBtn"
                                      }`}
                                        id={`apiServiceSubmit${index}`}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            this.updateObjectSchema(
                                                index,
                                                item.className,
                                                {
                                                    field: this.conversationFormBuilder.value.name,
                                                    type: this.conversationFormBuilder.value.type,
                                                    targetClass: this.conversationFormBuilder.value.targetClass
                                                }
                                            )}}
                                        style={{ height: "34px" }}
                                        disabled={invalid}
                                        >
                                        Add
                                    </button> :
                                    <button
                                        className={`sxp-btn btn-primary ${
                                            invalid && "disablePagBtn"
                                        }`}
                                        id={`apiServiceSubmit${index}`}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            this.addObjectSchema(index,
                                                {
                                                    className: this.conversationFormBuilder.value.className,
                                                    field: this.conversationFormBuilder.value.name,
                                                    type: this.conversationFormBuilder.value.type,
                                                    targetClass: this.conversationFormBuilder.value.targetClass
                                                }
                                        )}}
                                        style={{ height: "34px" }}
                                        disabled={invalid}>
                                        Submit
                                    </button>
                                }
                              </div>
                              <h4 className="varHead">Saved Schema Fields</h4>
                              {item.className && item.fields ? 
                                    <div>
                                        {Object.keys(item.fields).map((field) => (
                                            <div className="schemaFields flex" style={{flexWrap: 'wrap'}}>
                                              <div>{field}</div>
                                              <div>{item.fields[field].type}</div>
                                              {item.fields[field].type === 'Pointer' || item.fields[field].type === 'Relation' ? (
                                                <div>{item.fields[field].targetClass}</div>
                                              ):null }
                                              <img
                                                src={subtractIcon}
                                                alt="trash Icon"
                                                style={{alignSelf:'baseline', marginTop:'10px', cursor:'pointer'}}
                                                onClick={() => this.deleteSchema(item.className, field)}
                                                className=""
                                              />
                                            </div>
                                        ))}
                                    </div> : null
                                  }
                            </div>
                          </div>
                        </form>
                      )}
                      /> 
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              ))}
            </div>
          ):(<EmptyCard />)}
        </div>

      </div>
    )
  }
}

export default ParseUi;