import HttpService from "../../HttpService";

const axios = HttpService.getAxiosClient();

const save = async(allOptions) => {
    const headers = {};
    headers["X-Secret"] = allOptions.secret;
    return await axios.post(allOptions.url, allOptions.body, {headers: headers});
}

const update = async(allOptions) => {
    const headers = {};
    headers["X-Secret"] = allOptions.oldSecret;
    headers["X-New-Secret"] = allOptions.newSecret;
    return await axios.put(allOptions.url, allOptions.body, {headers: headers});
}

const updateState = async(allOptions) => {
    return await axios.put(allOptions.url, allOptions.body, {headers: {}});
}

const deleteConvRes = async(allOptions) => {
    const headers = {};
    return await axios.delete(allOptions.url, {headers: headers});
}

export { save, update, updateState, deleteConvRes };
