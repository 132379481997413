import { FormHelperText, MenuItem, Select } from "@material-ui/core";
import React, { Component } from "react";
import OAuthTwoDotO from "../../../containers/projects/jwtForms/oauthTwoO";
import { saveData } from "../../../dataProvider/apiHandler";
import FindError from "../../../dataProvider/errorHandler";
import PositionedSnackbar from "../../uicomponents/toast";
import "./firstProject.scss";

const serverUrl = window._env_.REACT_APP_SXP_URL;

class FirstProject extends Component {
  constructor(props) {
    super(props);
    this.state = {
        name:'',
        description:'',
        counter:5,
        showCounterMessage: false,
    };
  }
  
  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  // Close Toast
  closeToast = () => {
    this.setState({ displayToast: false });
  };

  handleSubmit = async() => {    
    if(this.state.name === '')
    {
        this.setState({
            displayToast: true,
            messageToast: "Name is required",
            severity: "error",
        });
    }
    else if(this.state.description === '')
    {
        this.setState({
            displayToast: true,
            messageToast: "Description is required",
            severity: "error",
        });
    }
    else
    {
        try{
            const bodyObj = {
                name: this.state.name,
                description: this.state.description,
                nonSocialChannelAuthCredentials: {}
            }
            const allOptions = {
                url: serverUrl + "projects/",
                body: {...bodyObj},
              };
              await saveData(allOptions);
              this.setState({
                displayToast: true,
                messageToast: "Project Created Successfully :: To config project go project in profile to  update  details",
                severity: "success",
                showCounterMessage:true
             });
             setTimeout(() => {
                this.props.callBackRes(false);
                this.setState({showCounterMessage:false})
            }, 5000);
            setInterval(() => this.setState({counter : this.state.counter- 1}), 1000);
        }
        catch(e){
            const errorObj = FindError(e)
            this.setState({
              displayToast: true,
              messageToast: errorObj.message,
              severity: "error",
              confirmAlert: false,
              loadingCard: false
            });
        }
    }
  }
  render() {
    return (
      <div className="FirstProj">
        <PositionedSnackbar
          display={this.state.displayToast}
          msg={this.state.messageToast}
          closeToast={this.closeToast}
          severity={this.state.severity}
        />
        <div className="mainFPdiv">
          <h2 className="text-center">Welcome to SXP!</h2>
          <p className="text-center">Create a New Project to Get Started</p>
          <div className="FieldpGroup">
            <h3 className="mb0">Create New project</h3>
            <div className="jwtFields">
              <div><input type="text" id="projectNameField" placeholder="Enter Project Name" name="name" onChange={this.handleChange} /></div>
              <div><input type="text" id="projectDescField" placeholder="Enter Project Description" name="description" onChange={this.handleChange} /></div>
              <button onClick={this.handleSubmit} id="submitProjectBtn" className={`sxp-btn btn-primary mt20 ml5`} style={{ height: "34px", width: "220px", marginBottom:'10px' }}
                >Save</button>
                {this.state.showCounterMessage ? 
                <p className="m0">please wait while we redirect u in {this.state.counter} secs...</p> : null}  
            </div>         
          </div>
        </div>
      </div>
    );
  }
}

export default FirstProject;
