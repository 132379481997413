import React from "react";
import Select from "react-select";
import { getComponentsByTaskId, getAllJourneys } from "../api/api";
import { FormButtons } from "./formbuttons";
import { submit } from "./formActions";

const style = {
  control: (base, state) => ({
    ...base,
    border: state.isFocused ? 0 : 0,
    // This line disable the blue border
    boxShadow: state.isFocused ? 0 : 0,
    "&:hover": {
      border: state.isFocused ? 0 : 0,
    },
  }),
};

export class Component extends React.Component {
  state = {};

 

  static getDerivedStateFromProps(nextProps, currentState) {
    let propsState;
    if (
      currentState.formUniqueId &&
      currentState.formUniqueId === nextProps.formUniqueId
    ) {
      propsState = { ...(nextProps.data.data || {}), ...currentState };
    } else {
      propsState = { ...currentState, ...(nextProps.data.data || {}) };
    }
    propsState.formUniqueId = nextProps.formUniqueId;
    if (propsState.conversations) {
      let conversation = propsState.conversations.find(
        (x) => x.value === propsState.startComponentDialogId
      );
      if (conversation) propsState.conversationObj = conversation;
    }
    if (propsState.components) {
      let component = propsState.components.find(
        (x) => x.value === propsState.startComponentId
      );
      if (component) {
        propsState.componentObj = component;
      } else {
        propsState.componentObj = { label: "", value: "" };
      }
    }
    return propsState;
  }

  componentDidMount() {
    let onLoadState = { ...this.state };
    
    getAllJourneys().then((response) => {
      let conversations = response.data.data.docs.map((conversation) => {
        return { value: conversation.uid, label: conversation.name, jUID: conversation.uid };
      });
      onLoadState.conversations = conversations;
      if (onLoadState.startComponentDialogId) {
        getComponentsByTaskId(onLoadState.startComponentDialogId).then(
          (response) => {
            let components = response.data;
            let options = components.map((element) => {
              return { value: element.id, label: element.name };
            });
            this.setState({
              components: options,
            });
          }
        );
      }
      this.setState(onLoadState);
    });
  }
  
  componentWillUnmount() {
    this.setState({});
  }

  handleChange = (e) => {
    let updatedState = { ...this.state };
    updatedState.errorMsg = false;
    this.setState(updatedState);
    //passing journey uid in place of e.value
    getComponentsByTaskId(e.value).then((response) => {
      let components = response.data;
      let options = components.map((element) => {
        return { value: element.id, label: element.name };
      });
      this.setState({
        components: options,
        startComponentDialogId: e.value,
        startComponentId: "",
      });
    });
  };
  
  handleComponentChange = (e) => {
    let updatedState = { ...this.state };
    updatedState.errorMsg1 = false;
    this.setState(updatedState);
    this.setState({ startComponentId: e.value });
  };

  checkValidForm = (e) => {
    e.preventDefault();
    if (this.state.startComponentDialogId && this.state.startComponentId) {
      submit(e, this.props, this.state);
    } else {
      if (this.state.startComponentDialogId === undefined || "") {
        let updatedState = { ...this.state };
        updatedState.errorMsg = true;
        this.setState(updatedState);
      }
      if (this.state.startComponentId === undefined || "") {
        let updatedState = { ...this.state };
        updatedState.errorMsg1 = true;
        this.setState(updatedState);
      }
    }
  };

  render() {
    return (
      <form className="form-style" onSubmit={(e) => this.checkValidForm(e)}>
        <div>
          <label> Name </label>
          <input
            type="text"
            name="name"
            defaultValue={this.props.data.data.name}
            key={this.state.formUniqueId}
            required
            autoFocus
          />
        </div>
        <div>
          <label> Conversation: </label>
          <Select
            styles={style}
            name="startComponentDialogId"
            className={`searchSelectDDCS customSelect ${
              this.state.errorMsg ? "error-Input" : ""
            }`}
            options={this.state.conversations}
            defaultValue={
              this.state.conversationObj
                ? this.state.conversationObj
                : { value: "", label: "" }
            }
            value={this.state.conversationObj}
            key={this.state.formUniqueId}
            isSearchable
            onChange={this.handleChange}
          />
          {this.state.errorMsg ? (
            <p className="errorFieldMsg mt0" style={{ fontWeight: 600 }}>
              Select a Conversation
            </p>
          ) : null}
        </div>

        <label> Node:</label>
        <Select
          styles={style}
          name="startComponentId"
          options={this.state.components}
          className={`searchSelectDDCS customSelect ${
            this.state.errorMsg1 ? "error-Input" : ""
          }`}
          defaultValue={
            this.state.componentObj
              ? this.state.componentObj
              : { value: "", label: "" }
          }
          value={this.state.componentObj}
          key={this.state.formUniqueId}
          isSearchable
          onChange={this.handleComponentChange}
        />
        {this.state.errorMsg1 ? (
          <p className="errorFieldMsg mt0" style={{ fontWeight: 600 }}>
            Select a Node
          </p>
        ) : null}

        <FormButtons formProps={this.props} />
      </form>
    );
  }
}
