import axios from 'axios';

const APP_URL = window._env_.REACT_APP_SERVER_URL;
const LOGIN_END_POINT = 'api/auth/login';
const PROJECTS_URL = APP_URL + `api/user-projects`;

export default {
  login: async ({ username, password }) => {
    let resp = await axios.post(APP_URL + LOGIN_END_POINT, {
      email: username,
      password: password,
    });
    if (resp.status === 200) {
      localStorage.setItem('userName', resp.data.data.user.name);
      localStorage.setItem('isAdmin', resp.data.data.user.isAdmin)
      if (!resp.data.data.user.isAdmin) localStorage.setItem('role', resp.data.data.user.projectRoles[0].role.name);
      localStorage.setItem('token', resp.data.data.token);
      if(resp.data.data.user.isAdmin) {
        const projectResponse = await axios.get(PROJECTS_URL, { headers: { token: resp.data.data.token } });
        const data = projectResponse.data.data;
        localStorage.setItem('pId', data[0].id);
      } else {
        localStorage.setItem('pId', resp.data.data.user.projectRoles[0].project);
      }
      if (!resp.data.data.user.isAdmin) localStorage.setItem('permissions', JSON.stringify(resp.data.data.user.projectRoles[0].role.accessControl))
      localStorage.setItem('botUser', resp.data.data.user._id);
      window.location.href = `projects/${localStorage.getItem('pId')}/dashboard`
      return true
    } else {
      return;
    }
  },
  postLoginSteps: async (username, email) => {
    localStorage.setItem('userName', username);
    localStorage.setItem('botUser', email);
    localStorage.setItem('isAdmin', true);
  },
  logout: () => {
    localStorage.removeItem('token');
    localStorage.removeItem('permissions');
    localStorage.removeItem('pId');
    localStorage.removeItem('botUser');
    localStorage.removeItem('userName');
    localStorage.removeItem('role');
    return Promise.resolve();
  },

  checkError: ({ status }) => {
    return status === 401
      ? Promise.reject()
      : Promise.resolve();
  },

  // called when the user navigates to a new location, to check for authentication
  checkAuth: () => {
    return localStorage.getItem('token') ? Promise.resolve() : Promise.reject();
  },
  // called when the user navigates to a new location, to check for permissions / roles
  getPermissions: () => {
    const roles = localStorage.getItem('permissions') || ''
    return Promise.resolve(roles ? JSON.parse(roles) : {})
  },
};
