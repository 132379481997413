// import axios from "axios";
import HttpService from "../HttpService";

const axios = HttpService.getAxiosClient();

let headers = {
  Accept: "application/json",
  "Content-Type": "application/json",
  token: localStorage.getItem("token"),
};

// function to get all data using GET method
const getData = async (allOptions) => {
  // "allOptions" is a object passing from the getData function url, body object, params to get data
  let options = {
    url: allOptions.url,
    params: allOptions.params,
    headers: allOptions.headers
  };  
  // search filter create object with dynamic key name
  const searchObj = {};
  // checking if search filter passing value or not
  if (allOptions?.params?.searchWord) {
    const searchKey = allOptions.params.searchKey;
    searchObj[searchKey] = { like: allOptions?.params?.searchWord };
  }
  const response = await axios.get(options.url, {
    headers: allOptions.headers? allOptions.headers : {},
    params: {
      filter: {
        //get data with specific limit
        ...(allOptions.params?.perPage && { limit: allOptions.params.perPage }),
        ...(allOptions.params?.page && { skip: allOptions.params?.page }),
        //get data with selected project, version, users, or ID.
        where: {
          ...(allOptions.params?.projectId && {
            projectId: allOptions.params.projectId,
          }),
          ...(allOptions.params?.version && {
            version: allOptions.params.version,
          }),
          ...(allOptions.params?.userid && {
            userid: allOptions.params.userid,
          }),
          ...(allOptions.params?.journeyId && {
            journeyId: allOptions.params.journeyId,
          }),
          ...(allOptions.params?.component && {
            component: allOptions.params.component,
          }),
          ...allOptions.params?.id && {id: allOptions.params?.id}, 
          ...allOptions.params?.uid && {uid: allOptions.params?.uid}, 
          ...(allOptions.params?.task_status === true && {active: allOptions.params.task_status}),
          ...(allOptions.params?.task_status === false  && {active: allOptions.params.task_status}),
          ...(allOptions.params?.type && { type: allOptions.params.type }),
          ...(allOptions.params?.conversationStarter && { conversationStarter: allOptions.params.conversationStarter }),
          ...(allOptions.params?.start && { start: allOptions.params.start }),
          ...(allOptions.params?.end && { end: allOptions.params.end }),          
          ...searchObj,
        },
        //get data with the aditional filters.
      },
    },
  });
  return response;
};

// function to get single data using GET method
const getSingleData = async (allOptions) => {
  let options = {
    url: allOptions.url,
    params: allOptions.params,
  };
  const response = await axios.get(options.url, {
    headers: {},
    params: options.params,
  });
  return response;
};

// function to create / post data using POST method
const saveData = async (allOptions) => {
  // "allOptions" is a object passing from the getData function url, body object, params to post data
  const options = {
    url: allOptions.url,
    body:
      allOptions.pageType === "modeler"
        ? {
            ...allOptions.body,
            projectId: localStorage.getItem("pId"),
            // userid: localStorage.getItem("botUser"),
            version: localStorage.getItem("version")?.toUpperCase(),
          }
        : allOptions.body,
    ...(allOptions.params && { params: allOptions.params }),
    headers: allOptions.headers
  };
  const response = axios.post(options.url, options.body, {
    headers:  options.headers? options.headers : {},
    params: options.params,
  });
  return response;
};

// function to update data using PATCH method
const updateData = async (allOptions) => {
  if (allOptions.pageType === "modeler") {
    delete allOptions.body.id;
    delete allOptions.body.version;
  }
  // "allOptions" is a object passing from the getData function url, body object, params to Update data
  const options = {
    url: allOptions.url,
    body: allOptions.body,
  };
  const response = axios.patch(options.url, options.body, { headers: headers });
  return response;
};

// function to update data using PUT method
const updateDataPut = async (allOptions) => {
  if (allOptions.pageType === "modeler") {
    delete allOptions.body.id;
  }
  // "allOptions" is a object passing from the getData function url, body object, params to Update data
  const options = {
    url: allOptions.url,
    body: allOptions.body,
    headers:allOptions.headers
  };
  const response = axios.put(options.url, options.body, { headers: options.headers? options.headers : {}, });
  return response;
};

// function to delete data using DELETE method
const deleteData = async (allOptions) => {
  // "allOptions" is a object passing from the getData function url, body object, params to delete data
  const options = {
    url: allOptions.url,
    params: allOptions.params,
  };
  const response = axios.delete(options.url, {
    headers: headers,
    params: options.params,
  });
  return response;
};

export {
  getData,
  getSingleData,
  saveData,
  deleteData,
  updateData,
  updateDataPut,
};
