export const ComponentType = Object.freeze({
  API: "API",
  QUESTION: "UTTERANCE",
  START_NODE: "START_NODE",
  END_NODE :"END_NODE",
  WAIT_NODE: "WAIT_NODE",
  SUBMIT_NODE: "SUBMIT_NODE",
  START_DIALOG: "START_JOURNEY",
  START_COMPONENT: "START_COMPONENT",
  EDGE: "edge",
  ABEDGE: "abedge",
  LOOPBACKEDGE: "lbedge",
  NOTIFICATION: "NOTIFICATION",
  AB: "AB",
  USER_REGISTER: "userRegister",
  DYNAMIC: "dynamic",
  API_RESPONSE:"API_RESPONSE",
});
