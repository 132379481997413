import React, { Component } from "react";
import moment from "moment";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import Autocomplete from "@material-ui/lab/Autocomplete";
import chevronDown from "../../assets/images/chevron-down.png";
import searchIcon from "../../assets/images/search.png";

class GenerateReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dateRangevalue: "",
    };
  }

  onChangeDateRange = async (value) => {
    this.setState({ dateRangevalue: value });
    this.setState({
      startDate: moment(value[0]).format("YYYY-MM-DD"), // setting startDate value in yyyy-mm-dd format
      endDate: moment(value[1]).format("YYYY-MM-DD"), // setting endDate value in yyyy-mm-dd format
    });
  };

  render() {
    return (
      <>
        <div className="display-flex" style={{ alignItems: "baseline" }}>
          <h3 className="m0">Generate Report</h3>
          <span className="clearAllText">Clear all</span>
        </div>
        <div className="pos-rel searchInput">
          <img alt="SearchIcon" src={searchIcon} className="searchIcon" />
          <Autocomplete
            id="custom-input-demo"
            options={this.props.journeyList.map((a) => a.name)}
            onChange={(event) => this.props.searchDataFilter(event)}
            renderInput={(params) => (
              <div ref={params.InputProps.ref}>
                <input
                  type="text"
                  className="w100"
                  placeholder="Search Journey Name"
                  {...params.inputProps}
                />
              </div>
            )}
          />
          <img
            alt="chevronDownIcon"
            src={chevronDown}
            className="downArrrowChev"
          />
        </div>
        <div className="searchInput">
          <img alt="searchIcon" src={searchIcon} className="searchIcon" />
          <input
            type="text"
            placeholder="Search User Name / ID"
            onChange={this.searchUserFilter}
          />
        </div>

        <div className="recentSearches">
          <div className="display-flex" style={{ alignItems: "baseline" }}>
            <h4 className="m0 searchhead2">Select From data and to date</h4>
          </div>
          <div className="dateBlockPicker">
            <DateRangePicker
              className="sxp-defaultDatePicker "
              onChange={this.onChangeDateRange}
              clearIcon={null}
              maxDate={new Date()}
              value={this.state.dateRangevalue}
              dayPlaceholder="DD"
              monthPlaceholder="MM"
              yearPlaceholder="YYYY"
              style={{ width: "100%" }}
            />
          </div>
        </div>

        <div className="recentSearches">
          <div className="display-flex" style={{ alignItems: "baseline" }}>
            <h4 className="m0 searchhead2">Channels</h4>
          </div>
          {this.props.channelList.map((data, i) => (
            <span
              key={i}
              className={`${
                data.selectedbtn ? "activeSelectedFilter" : ""
              } labelFilter`}
              onClick={(data) => this.selectFilterChannel(data, i)}
            >
              {data.name}
            </span>
          ))}
        </div>
        <div className="recentSearches">
          <div className="display-flex" style={{ alignItems: "baseline" }}>
            <h4 className="m0 searchhead2">Status</h4>
          </div>
          {this.props.statusList.map((data, i) => (
            <span
              key={i}
              className={`${
                data.selectedbtn ? "activeSelectedFilter" : ""
              } labelFilter`}
              onClick={(data) => this.selectFilterStaus(data, i)}
            >
              {data.name}
            </span>
          ))}
        </div>
        <div className="searchInput">
          <h4
            className="m0 searchhead2 font-italic"
            style={{ marginBottom: "7px" }}
          >
            Send Email to
          </h4>
          <input
            type="text"
            placeholder="Email id"
            style={{ paddingLeft: "10px" }}
            onChange={this.emailId}
          />
        </div>

        <button
          className="sxp-btn btn-primary block-btn"
          onClick={this.requestReport}
          style={{ marginBottom: "10px" }}
        >
          REQUEST REPORT
        </button>
        <button
          className="sxp-btn btn-danger block-btn"
          onClick={this.props.closeReport}
        >
          CANCEL
        </button>
      </>
    );
  }
}

export default GenerateReport;
