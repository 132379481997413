import React, { Component } from "react";
import arrowLeft from "../../assets/images/chevron_left.png";
import arrowRight from "../../assets/images/chevron_right.png";
import "./pagination.scss";

class Pagination extends Component {
  render() {
    return (
      <div className="pagination text-right" style={{ marginRight: "60px" }}>
        <span>
          Rows per page :
          <select
            className="rowsperpage"
            onChange={this.props.rowPerPageChange}
          >
            {[10, 20, 30, 40, 50].map((pageSize) => (
              <option
                key={pageSize}
                value={pageSize}
                selected={localStorage.getItem("rowPerPage") == pageSize}
              >
                {pageSize}
              </option>
            ))}
          </select>
        </span>
        <span>
          Page: {this.props.pageNo} of {this.props.totalPages}
        </span>
        <button
          id="previous_page_btn"
          className={ this.props.pageNo > 1 ? "ebable" : "disablePagBtn"}
          onClick={() => this.props.previousPage()}
        >
          <img src={arrowLeft} alt="arrow-left" />
        </button>
        <button
          id="next_page_btn"
          className={
            this.props.pageNo < this.props.totalPages
              ? "ebable"
              : "disablePagBtn"
          }
          onClick={() => this.props.nextPage()}
        >
          <img src={arrowRight} alt="arrow-right" />
        </button>
      </div>
    );
  }
}

export default Pagination;
