import React from "react";
import Select from "react-select";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { getFunctions, getServices } from "../api/api";
import { FormButtons } from "./formbuttons";
import { submit } from "./formActions";
import FunctionPreview from "./FunctionPreview";

const style = {
  control: (base, state) => ({
    ...base,
    border: state.isFocused ? 0 : 0,
    // This line disable the blue border
    boxShadow: state.isFocused ? 0 : 0,
    "&:hover": {
      border: state.isFocused ? 0 : 0,
    },
  }),
};

export class API extends React.Component {
  state = {
    servicesObj: '',
    apiLoop:false,
  };

  static getDerivedStateFromProps(nextProps, currentState) {
    let propsState;
    if (
      currentState.formUniqueId &&
      currentState.formUniqueId === nextProps.formUniqueId
    ) {
      propsState = { ...(nextProps.data.data || {}), ...currentState };
    } else {
      propsState = { ...currentState, ...(nextProps.data.data || {}) };
    }
    propsState.formUniqueId = nextProps.formUniqueId;
    if (propsState.functions && propsState.funcName) {
      let func = propsState.functions.find(
        (x) => x.value === propsState.funcName
      );
      if (func) propsState.functionObj = func;
    }
    if (propsState.services && propsState.serviceId) {

      let service = propsState.services.find(
        (x) => x.value === propsState.serviceId
      );
      if (service) propsState.servicesObj = service;
    }
    return propsState;
  }

  componentDidMount() {
    let onLoadState = { ...this.state };

    getFunctions().then((response) => {
      let functions = response.data.functionNames.map((functionName) => {
        return { value: functionName, label: functionName };
      });
      getServices().then((response) => {
        let services = response.data.data.docs.map((service) => {
          return {
            value: service.id,
            label: service.name,
            bodyJson: service.bodyJson,
          };
        });
        Array.prototype.unshift.apply(functions, [{ value: '', label: 'Select a Function' }]);
        onLoadState.services = services;
        onLoadState.functions = functions;
        this.setState(onLoadState);
      });
    });
  }

  componentWillUnmount() {
    this.setState({});
  }

  handleChange = (e) => {
    let updatedState = { ...this.state };
    updatedState.errorMsg = false;
    this.setState(updatedState);
    if (this.state.services) {
      this.state.services.forEach((service) => {
        if (e.value === service.value) {
          let updatedState = { ...this.state };
          updatedState.bodyJson = service.bodyJson;
          updatedState.serviceId = e.value;
          this.setState(updatedState);
        }
      });
    }
  };

  handleTextInputChange = (e) => {
    let updatedState = { ...this.state };
    updatedState[e.target.name] = e.target.value;
    this.setState(updatedState);
  };

  handleSelectChange = (e) => {
    let updatedState = { ...this.state };
    updatedState.funcName = e.value;
    this.setState(updatedState);
  };

  checkValidForm = (e) => {
    e.preventDefault();
    if (this.state.serviceId) {
      submit(e, this.props, this.state);
    } else {
      let updatedState = { ...this.state };
      updatedState.errorMsg = true;
      this.setState(updatedState);
    }
  };

  handleOnCreate = async (functionName) => {
    if (functionName) {
      const fnData = await getFunctions();
      const allFunctions = fnData.data.functionNames.map((fn) => {
        return { value: fn, label: fn };
      });
      this.setState({
        functions: allFunctions
      });
      const fnIsPresent = allFunctions.some(fn => fn.value === functionName);
      if (fnIsPresent) {
        this.setState({
          funcName: functionName,
          functionObj: { value: functionName, label: functionName },
        });
      }
    }
  };

  handleChageApiLoop = (e) => {
    let statusVal = e.target.checked;
    this.setState({apiLoop:statusVal})
  }

  handleChangeText = (e) => {
    this.setState({[e.target.name] : e.target.value});
  }

  render() {
    return (
      <>
        <form className="form-style" onSubmit={(e) => this.checkValidForm(e)}>
          <div>
            <label> Name </label>
            <input
              type="text"
              name="name"
              defaultValue={this.state.name}
              key={this.state.formUniqueId}
              onChange={this.handleTextInputChange}
              required
              autoFocus
            />
          </div>
          <div>
            <label> API Service:</label>
            <Select
              styles={style}
              name="serviceId"
              className={`searchSelectDDCS customSelect ${this.state.errorMsg ? "error-Input" : ""
                }`}
              options={this.state.services}
              defaultValue={
                this.state.servicesObj
                  ? this.state.servicesObj
                  : { value: "", label: "" }
              }
              value={this.state.servicesObj}
              key={this.state.formUniqueId}
              isSearchable
              onChange={this.handleChange}
            />

            {this.state.errorMsg ? (
              <p className="errorFieldMsg mt0" style={{ fontWeight: 600 }}>
                Select API Service
              </p>
            ) : null}
          </div>
          <div>
            <label> Body JSON:</label>
            <textarea
              name="bodyJson"
              defaultValue={this.state.bodyJson}
              // value={this.state.bodyJson}
              rows="6"
              cols="30"
              key={this.state.formUniqueId}
            />
          </div>

          <div>
            <label> Function: </label>
            <FunctionPreview
              functionKey={this.state.funcName}
              onCreate={this.handleOnCreate}
            />
            <Select
              styles={style}
              name="funcName"
              className="searchSelectDDCS customSelect"
              options={this.state.functions}
              defaultValue={
                this.state.functionObj
                  ? this.state.functionObj
                  : { value: "", label: "" }
              }
              value={this.state.functionObj}
              key={this.state.formUniqueId}
              isSearchable
              onChange={this.handleSelectChange}
            />
          </div>

          {/* <div>
            <label> Say </label>
            <input
              type="text"
              name="say"
              defaultValue={this.state.say}
              key={this.state.formUniqueId}
            />
          </div> */}
          {/* <Tabs>
            <TabList>
              <Tab>Input</Tab>
              <Tab>Preview</Tab>
            </TabList>
            <TabPanel>
              <label>
                Formatted Text
                <textarea
                  name="richText"
                  defaultValue={this.state.richText}
                  key={this.state.formUniqueId}
                />
              </label>
            </TabPanel>
            <TabPanel>
              {this.state.richText && (
                <div
                  style={{ border: "2px solid black", padding: 5 }}
                  dangerouslySetInnerHTML={{ __html: this.state.richText }}
                />
              )}
            </TabPanel>
          </Tabs> */}

          <div>
            <label> Transaction Name</label>
            <input
              type="text"
              name="transactionName"
              defaultValue={this.state.transactionName}
              key={this.state.formUniqueId}
            />
          </div>

          <div>
            <input
              type="checkbox"
              key={this.state.formUniqueId}
              defaultChecked={this.state.isBillable === true ? true : false}
              name="isBillable"
            />
            <label>Billable </label>
          </div>

          <div>
            <input
              type="checkbox"
              key={this.state.formUniqueId}
              defaultChecked={this.state.apiLoop === true ? true : false}
              name="apiLoop"
              onChange={this.handleChageApiLoop}
            />
            <label>Api Loop </label>
          </div>

          {this.state.apiLoop && (
            <div>
              <label> Api Loop Reference</label>
              <input
                type="text"
                name="apiLoopReference"
                required
                defaultValue={this.state.apiLoopReference}
                key={this.state.formUniqueId}
                onChange={this.handleChangeText}
              />
            </div>
          )}


          <FormButtons formProps={this.props} />
        </form>
      </>
    );
  }
}
