import React, { memo } from "react";

import { Handle } from "react-flow-renderer";
import { Style } from "../constants/style";

const whilte = 'red'
export default memo(({ data }) => {
  const allStyleProps = data?.style;
  const compColor = data?.style?.backgroundColor;
  const isValidConnection = (connection) => {
    return connection.target !== connection.source;
  };

  let fetchColor="";
  if(data.component === 'START_COMPONENT'){ fetchColor=Style.STARTCOMPONENTNODESTYLE.backgroundColor}
  if(data.component === 'API'){ fetchColor=Style.APINODESTYLE.backgroundColor}
  if(data.component === 'API_RESPONSE'){ fetchColor=Style.APIRESPNODESTYLE.backgroundColor}
  if(data.component === 'START_JOURNEY'){ fetchColor=Style.DIALOGNODESTYLE.backgroundColor}
  if(data.component === 'NOTIFICATION'){ fetchColor=Style.NOTIFICATIONNODESTYLE.backgroundColor}
  if(data.component === 'AB'){ fetchColor=Style.ABNODESTYLE.backgroundColor}
  if(data.component === 'dynamic'){ fetchColor=Style.DYNAMICNODESTYLE.backgroundColor}
  if(data.isUserRegistrationComponent === true ){ fetchColor=Style.URNODESTYLE.backgroundColor}
  if(data.component === 'UTTERANCE' && !data.isUserRegistrationComponent ){ fetchColor=Style.QUESTIONNODESTYLE.backgroundColor}

  return (
    <>
      <Handle
        type="target"
        position="top"
        className="react_flow_handle_top"
        style={{ background: "white", border: `1px solid ${fetchColor}` }}
        onConnect={(params) => console.log("handle onConnect", params)}
        isValidConnection={isValidConnection}
      />
      <div className="mainComponent" style={{border: `1px solid ${fetchColor}`}}>
        <h3 className="holdStyles" style={{...allStyleProps, backgroundColor:`${fetchColor}`,  border: `1px solid ${fetchColor}` }} >
          { (data.component === 'UTTERANCE' && !data.isUserRegistrationComponent) && 'Question' }
          { data.component === 'START_COMPONENT' && 'Component'}
          { data.component === 'API' && 'API'}
          { data.component === 'API_RESPONSE' && 'API Response'}
          { data.component === 'START_JOURNEY' && 'Dialog'}
          { data.component === 'NOTIFICATION' && 'Notification'}
          { data.component === 'AB' && 'AB'}
          { data.component === 'dynamic' && 'Dynamic'}
          { data.isUserRegistrationComponent === true && 'User Register' }
        </h3>
      <h4 className="text-center" style={{ fontFamily: "courier", color:`${fetchColor}` }}>
        <b>{data.name}</b>
      </h4>
      </div>
      <Handle
        type="source"
        position="bottom"
        className="react_flow_handle_bottom"
        style={{ background: "white", border: `1px solid ${fetchColor}` }}
        isValidConnection={isValidConnection}
      />
    </>
  );
});
