import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { ActionCreators } from "redux-undo";
import ReactFlow, {
  MiniMap,
  Controls,
  Background,
  isNode,
  removeElements,
  ReactFlowProvider,
} from "react-flow-renderer";
import axios from "axios";
import uuid from "react-uuid";
import ConfirmAlert from "../../../commons/uicomponents/confirm";
import PositionedSnackbar from "../../../commons/uicomponents/toast";
import { Action } from "../constants/common";
import { Style } from "../constants/style";
import { ComponentType } from "../constants/componentType";
import component from "../nodes/component";
import startnode from "../nodes/startnode";
import { Header } from "../commons/header";
import { ActionPack } from "../commons/actionpack";
import { nodeBuilder } from "./nodebuilder";
import { setElementState, loadFlow } from "./util";
import { saveFlow } from "../api/api";
import FindError from "../../../dataProvider/errorHandler";
import endnode from "../nodes/endnode";
import waitnode from "../nodes/waitnode";
import minimize from './../../../assets/images/modeler/minimize.svg';
import maximize from './../../../assets/images/modeler/maximize.svg';
import submitnode from "../nodes/submitnode";

const serverUrl = window._env_.REACT_APP_SXP_URL;

let reactFlowWrapper;
let flow;
let journeyId;
let dispatch;
let baseFlow = [];
let selectedVersion = "";
let singleConv = {};
let setNodes;
let setConversationState;
let displayToast;
let displayMessageToast;
let setseverityState;
let jUID;

const onLoad = (reactFlowInstance) => {
  flow = reactFlowInstance;
  flow.setTransform({ x: 100, y: 100, zoom: 0.7 });
};

const onNodeDragStop = (event, node) => {
  let elements = flow.getElements();
  if (elements) {
    elements.forEach((element) => {
      if (element.id === node.id) {
        let draggedElement = { ...element };
        draggedElement.position = { ...element.position };
        draggedElement.position.x = node.position.x;
        draggedElement.position.y = node.position.y;
        dispatcher(Action.DRAG, draggedElement);
      }
    });
  }
};

const nodeTypes = {
  component: component,
  startNode: startnode,
  endNode: endnode,
  waitNode: waitnode,
  submitNode: submitnode
};

const dispatcher = (action, node) => {
  let userAction = { type: action, node: node };
  setFlowChangeInState(true);
  if (journeyId) {
    if (action === Action.RESET) {
      dispatch(ActionCreators.jumpToPast(0));
      dispatch(ActionCreators.clearHistory());
    } else {
      dispatch(userAction);
    }
  }
};

const setFlowChangeInState = (flag) => {
  setConversationState((prevState) => {
    return { ...prevState, ...{ flowChanged: flag, rightToggle: false } };
  });
};

const refreshFlow = (historyState) => {
  let elements = historyState.undoableNodes.present;
  elements = baseFlow.concat(elements);
  let nodeTrackMap = {};
  let deletedNodes = [];
  elements.forEach((element) => {
    switch (element.type) {
      case Action.ADD:
      case Action.DRAG:
        // This particular step is to define the non exising default values to undefined else react flow renderer overriding the old with new one
        let previousNode = nodeTrackMap[element.node.id];
        for (const [key, value] of Object.entries(element.node.data)) {
          if (previousNode)
            if (!previousNode.data[key]) {
              previousNode.data[key] = undefined;
            }
        }
        nodeTrackMap[element.node.id] = element.node;
        break;
      case Action.DEL:
        deletedNodes.push(element.node.id);
        delete nodeTrackMap[element.node.id];
        break;
      default:
        if (!element.node && element.id) nodeTrackMap[element.id] = element;
        break;
    }
  });
  if (deletedNodes.length > 0) {
    // Removed edges for the delete nodes
    elements.forEach((element) => {
      let node = element.node ? element.node : element;
      if (!isNode(node)) {
        if (
          deletedNodes.includes(node.source) ||
          deletedNodes.includes(node.target)
        ) {
          delete nodeTrackMap[node.id];
        }
      }
    });
  }

  let trackedElements = Object.values(nodeTrackMap);
  if (setNodes) {
    setNodes(() => trackedElements);
  }
};

const saveElements = () => {
  let nodes = [];
  nodes = flow.getElements();
  nodes.map(d =>{ 
    if(Array.isArray(d.data.utterance)){
      var mapped = d.data.utterance.map(item => ({ [item.lang]: item.label }) );
      var object = Object.assign({}, ...mapped );
      d.data.utterance = object;
    }
    if(Array.isArray(d.data.notificationMessage)){
      var mapped = d.data.notificationMessage.map(item => ({ [item.lang]: item.label }) );
      var object = Object.assign({}, ...mapped );
      d.data.notificationMessage = object;
    }
    d.style = d.data.style;
    delete d.data.style;
    return d
  })

  let body = {
    journeyId: journeyId,
    flow: nodes,
    version: selectedVersion?.toUpperCase() || 'WORKING',
    projectId: localStorage.getItem('pId'),
  };
    saveFlow(body)
    .then((x) => {
      setFlowChangeInState(false);
      displayToast(true);
      displayMessageToast("Saved Successfully");
      setseverityState("success");
    })
    .catch((e) => {
      const errObj = FindError(e);
      displayToast(true);
      displayMessageToast(errObj.message);
      setseverityState("error");
    });
};

let ConversationFlow = (props) => {
  const [elements, setElements] = useState([]);
  const [state, setState] = useState({});
  const [alert, setAlert] = useState(false);
  const [toast, setToast] = useState(false);
  const [messageToast, setMessageToast] = useState("");
  const [severity, setseverity] = useState("");
  const [fullScreenMode, setFullScreenMode] = useState(false);

  selectedVersion = props.currentVersion;
  singleConv = props.singleConversation;
  displayToast = setToast;
  displayMessageToast = setMessageToast;
  setConversationState = setState;
  setseverityState = setseverity;
  dispatch = props.dispatch;
  journeyId = props.journeyId;
  jUID = props.journeyUID

  // const [reactFlowInstance, setReactFlowInstance] = useState(null);
  reactFlowWrapper = useRef(null);

  const toggleFullScreen = () => {
   setFullScreenMode(!fullScreenMode)
  }

  const onDragOver = (event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "move";
  };

  const onDrop = (event) => {
    event.preventDefault();
    const type = event.dataTransfer.getData("application/reactflow");
    const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
    const position = flow.project({
      x: event.clientX - reactFlowBounds.left - 25,
      y: event.clientY - reactFlowBounds.top - 10,
    });
    nodeBuilder.addNodeOnDrop(dispatcher, position, flow, type);
  };

  let instantiateFlow = () => {
    const version = {
      version: selectedVersion,
    };
    loadFlow(props.journeyUID, version).then((flw) => {
      dispatcher(Action.RESET, null);
      setFlowChangeInState(false);
      baseFlow = flw;
      setElements(() => flw);
    });
  };

  useEffect(() => {
    instantiateFlow();
    setState((prevState) => {
      if (prevState.flowChanged) {
         setAlert(true)
        const flag = window.confirm(
          "Unsaved changes found. Do you want to switch conversation?"
        );
        if (flag === true) {
          instantiateFlow();
        }
      }

      return {
        ...prevState,
        ...{ journeyId: journeyId },
        ...{ jUID: jUID},
        rightFormStyle: Style.RIGHTFORMCOLLAPSE,
        leftFormStyle: Style.LEFTFORMCOLLAPSE,
      };
    });
  }, [props.journeyId]);

  const closeToast = () => {
    setToast(false);
  };

  const onElementsRemove = (elementsToRemove, recreateEdge = false) => {
    if (isNode(elementsToRemove[0]) || !recreateEdge) {
      dispatcher(Action.DEL, elementsToRemove[0]);
    }
  };

  const onConnect = (params) => {
    console.log('connect', params)
    let els = flow.getElements();
    params.id = uuid();
    let sourceElement;
    let loopBack = false;

    els.forEach((x) => {
      if (x.id === params.source) sourceElement = x;
      if (x.target === params.target) {
        loopBack = true;
      }
    });
    if (sourceElement.data.component === ComponentType.AB) {
      let style = { ...Style.ABCONNECTIONLABELSTYLE };

      params.data = { component: ComponentType.ABEDGE };
      params.label = "%";
      if (loopBack) {
        style.type = Style.LOOPBACKCONNECTIONSTYLE;
        params.data.isLoopBack = true;
      }
      Object.assign(params, style);
    } else {
      let style = { ...Style.CONNECTIONLABELSTYLE };

      params.data = { component: ComponentType.EDGE };
      params.label = "{}";
      if (loopBack) {
        style.type = Style.LOOPBACKCONNECTIONSTYLE;
        params.data.isLoopBack = true;
      }
      Object.assign(params, style);
    }
    dispatcher(Action.ADD, params);
  };

  const refreshElements = (element, updatedData) => {
    if (!isNode(element)) {
      let newEle = { ...element };
      newEle.id = uuid();
      newEle.data = updatedData;
      if (element.data.component === ComponentType.EDGE) {
        newEle.data.component = "edge";
        Object.assign(newEle, Style.CONNECTIONLABELSTYLE);
        updatedData.label
          ? (newEle.label = `{${updatedData.label}}`)
          : (newEle.label = "{}");
      } else {
        newEle.data.component = ComponentType.ABEDGE;
        Object.assign(newEle, Style.ABCONNECTIONLABELSTYLE);
        updatedData.label
          ? (newEle.label = `${updatedData.label}%`)
          : (newEle.label = "%");
      }
      removeElements([element], flow.getElements());
      dispatcher(Action.DEL, element);
      dispatcher(Action.ADD, newEle);
    } else {
      let eles = flow.getElements();
      if (eles) {
        eles.forEach((ele) => {
          if (ele.id === element.id) {
            let elementData = { ...ele.data, ...updatedData };
            ele.data = elementData;
            dispatcher(Action.ADD, ele);
          }
        });
      }
    }
  };

  const onElementClick = (event, element) => {
    setElementState(
      element,
      setState,
      refreshElements,
      saveElements,
      onElementsRemove
    );
  };

  const callBackJName1 = (name) => {
    props.callBackJName2(name)
  }
  setNodes = setElements;
  return (
    <div className="flow-parent">
      <React.Fragment>
        {alert ? (
          <ConfirmAlert
            onConfirmBtn={this.onConfirmButtonClick}
            onCancelBtn={this.cancelBtn}
            heading="Are you Sure?"
            paragraph="If you proceed you will loose the data. Are you sure you want to delete ?"
          />
        ) : null}

        <PositionedSnackbar
          display={toast}
          msg={messageToast}
          closeToast={closeToast}
          severity={severity}
        />
        <div className={fullScreenMode ? 'fullScreenMode' : ''}>
          <div className="display-flex">
            <Header
              nodeState={state}
              flowChanged={state.flowChanged}
              singleConv={singleConv}
              callBackJName1={callBackJName1}
            />
            <button className="fullScreenIcon" onClick={toggleFullScreen}>
              {fullScreenMode ? <img src={minimize} /> : <img src={maximize} />   }
            </button>
          </div>
        <div className="flow-content">
          <ActionPack />
          {journeyId && (
            <ReactFlowProvider>
              <div
                className="reactflow-wrapper"
                style={{ flexGrow: 1, height: "100%" }}
                ref={reactFlowWrapper}
              >
                <ReactFlow
                  elements={elements}
                  onLoad={onLoad}
                  onElementClick={onElementClick}
                  onElementsRemove={onElementsRemove}
                  onConnect={(p) => onConnect(p)}
                  onNodeDragStop={onNodeDragStop}
                  onDragOver={onDragOver}
                  onDrop={onDrop}
                  nodeTypes={nodeTypes}
                >
                  <MiniMap
                    nodeColor={(n) => {
                      if (n.data.component === ComponentType.API)
                        return Style.APINODESTYLE.backgroundColor;
                      if (n.data.component === ComponentType.API_RESPONSE)
                        return Style.APIRESPNODESTYLE.backgroundColor;
                      if (n.data.component === ComponentType.QUESTION)
                        return Style.QUESTIONNODESTYLE.backgroundColor;
                      if (n.data.component === ComponentType.START_NODE)
                        return Style.STARTNODESTYLE.backgroundColor;
                      if (n.data.component === ComponentType.START_DIALOG)
                        return Style.DIALOGNODESTYLE.backgroundColor;
                      if (n.data.component === ComponentType.START_COMPONENT)
                        return Style.STARTCOMPONENTNODESTYLE.backgroundColor;
                      if (n.data.component === ComponentType.NOTIFICATION)
                        return Style.NOTIFICATIONNODESTYLE.backgroundColor;
                      if (n.data.component === ComponentType.AB)
                        return Style.ABNODESTYLE.backgroundColor;
                      if (n.data.component === ComponentType.USER_REGISTER)
                        return Style.URNODESTYLE.backgroundColor;
                      if (n.data.component === ComponentType.DYNAMIC)
                        return Style.DYNAMICNODESTYLE.backgroundColor;
                      if (n.data.component === ComponentType.END_NODE)
                        return Style.ENDNODESTYLE.backgroundColor;
                      if (n.data.component === ComponentType.WAIT_NODE)
                        return Style.WAITNODESTYLE.backgroundColor;
                      if (n.data.component === ComponentType.SUBMIT_NODE)
                        return Style.SUBMITNODESTYLE.backgroundColor;  
                      return "#eee";
                    }}
                  />
                  <Background color="#aaa" gap={16} />
                  <Controls />
                </ReactFlow>
              </div>              
            </ReactFlowProvider>
          )}
        </div>
        </div>
      </React.Fragment>
    </div>
  );
};

ConversationFlow = connect((state) => refreshFlow(state))(ConversationFlow);
export { ConversationFlow, saveElements };
