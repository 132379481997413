import React, { Component } from "react";
import LoaderIcon from "../../assets/images/loader.gif";
import "./uicomponent.scss";

class LoaderSpinner extends Component {
  render() {
    return (
      <div className={this.props.loadingCard ? "load" : "hideIt"}>
        <img src={LoaderIcon} alt="sxp-loader" style={{ width: "246px" }} />
      </div>
    );
  }
}

export default LoaderSpinner;
