export const UserType = Object.freeze({
  TRANSACTIONAL: "TRANSACTIONAL",
  REGISTERED: "REGISTERED",
  DIRECT: "DIRECT",
});

export const Action = Object.freeze({
  ADD: "ADD",
  DRAG: "DRAG",
  DEL: "DEL",
  RESET: "RESET",
});
