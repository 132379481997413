import React, { Component } from "react";
import "./journey.scss";
import personIcon from "../../assets/images/person.png";
import clientIcon from "../../assets/images/logo.png";

class JourneyDetails extends Component {
  userMsg = (data) => {
    if (data.actor === "bot") {
      return (
        <div className="receiver">
          <div className="flex">
            <span className="clientIcon">
              <img alt="SXP Icon" src={clientIcon} className="user Icon" />
            </span>
            <div className="message">
              <p className="m0">{data.message}</p>
              <span className="dateTime">10/9/20, 10:39AM</span>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="sender">
          <div className="flex">
            <div className="message">
              <p className="m0">{data.message}</p>
              <span className="dateTime">10/9/20, 10:39AM</span>
            </div>
            <span className="userIcon">
              <img alt="user Icon" src={personIcon} className="person Icon" />
            </span>
          </div>
        </div>
      );
    }
  };

  render() {
    return (
      <div>
        {this.props.journeyDetails ? (
          <div className="chat_block ">
            <div className="chatHeader flex">
              <h5 className="m0">User - SXP Conversation</h5>
            </div>
            <div className="chatbody">
              {this.props.journeyDetails.history
                ? this.props.journeyDetails.history.map((element, ii) => {
                    return <div key={ii}>{this.userMsg(element)}</div>;
                  })
                : ""}
            </div>
          </div>
        ) : (
          <div className={this.state.loadingCard ? "load" : "hideIt"}>
            <img
              src="https://www.theothercheek.com/images/ajax-loader.gif"
              alt="spinner"
              className="loading Gif"
              style={{ width: "246px" }}
            />
          </div>
        )}
      </div>
    );
  }
}

export default JourneyDetails;
