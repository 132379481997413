import React, { memo } from "react";
import { Handle } from "react-flow-renderer";
import startIcon from './../../../assets/images/modeler/start.png';

export default memo(({ data }) => {
  const compColor = data?.style?.backgroundColor;
  const isValidConnection = (connection) => {
    return connection.target !== connection.source;
  };
  return (
    <>
      <h4 className="m0" style={{ fontFamily: "courier" }}>
      <div style={{width:'74px', position:'relative'}}>
        <div className="img_node_overlay"></div>
        <img className="w100" src={startIcon} />
      </div>
      </h4>
      <Handle
        type="source"
        position="bottom"
        className="react_flow_handle_bottom_start"
        style={{ background: "white", border: `1px solid ${compColor}` }}
        isValidConnection={isValidConnection}
      />
    </>
  );
});
