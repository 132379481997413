import React, { Component } from "react";
import uuid from "react-uuid";
import { submit } from "./formActions";
import { FormButtons } from "./formbuttons";


class AB extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let formUniqueId = uuid();
    return (
      <form className="form-style" onSubmit={(e) => submit(e, this.props)}>
        <div>
          <label>Name</label>
          <input
            type="text"
            name="name"
            defaultValue={this.props.data.data.name}
            key={formUniqueId}
            required
            autoFocus
          />
        </div>
        <div>
          <input
            type="checkbox"
            key={formUniqueId}
            defaultChecked={
              this.props.data.data.isABActive === true ? true : false
            }
            name="isABActive"
          />
          <label>Activate </label>
        </div>
        <FormButtons formProps={this.props} />
      </form>
    );
  }
}

export default AB;
