import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
} from "@material-ui/core";
import React, { Component } from "react";
import { restApiData } from "./restapidata";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ReactJson from "react-json-view";
import "./parse.scss";

class RestApiDocs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      restApiData: restApiData,
      expansionJrnyPanelOpen: false,
      journeyIndex: "",
    };
  }

  // EXPANSIONS HANDLERS
  switchJrnyExpansionpanel(index) {
    if (this.state.journeyIndex === index) {
      this.setState({
        expansionJrnyPanelOpen: false,
        journeyIndex: "",
      });
    } else {
      this.setState({
        expansionJrnyPanelOpen: true,
        journeyIndex: index,
      });
    }
  }

  bannerCard = (item, index) => {
    return (
      <div className="display-flex text_black font13 w100 pr15 pl15">
        <div className="table_grid">
          <p
            id={`apiServiceName${index}`}
            className={`in-block ${
              this.state.permissions ? "" : "disable-click"
            }`}
            onClick={() => this.InputPlaceEdit(index)}
          >
            {item.name}
          </p>
        </div>
      </div>
    );
  };

  render() {
    return (
      <div>
        <div className="display-flex">
          <div style={{ flex: 1 }}>
            <h4 className="mt0 in-block">
              App Database <span className="label_pink">Configure Schema</span>
            </h4>
          </div>
        </div>
        <div>
          {this.state.restApiData.map((item, index) => (
            <ExpansionPanel
              className="CustomexpansionPannel"
              key={item.className + index}
              expanded={
                this.state.expansionJrnyPanelOpen &&
                this.state.journeyIndex === index
              }
            >
              <ExpansionPanelSummary
                expandIcon={
                  <ExpandMoreIcon
                    className="iconExpand"
                    id={`expansionArrow${index}`}
                    onClick={this.switchJrnyExpansionpanel.bind(this, index)}
                  />
                }
                style={{ order: -1 }}
                className="boxShadow"
              >
                {this.bannerCard(item, index)}
              </ExpansionPanelSummary>
              <ExpansionPanelDetails className="inner_expandBlock docs_data">

                <h4>{item.heading}</h4>
                
                {item.paragraph && <p>{item.paragraph}</p>}
                
                {item.data && (
                    <ReactJson
                    src={item.data}
                    className="rvjStyles"
                    name={false}
                    displayDataTypes={false}
                    defaultValue={""}
                    enableClipboard={false}
                    onEdit={false}
                    /> 
                )}
                
                {item.curl && (
                    <div className="curlBg">
                        <span>cURL</span>
                        <pre><code>{item.curl}</code></pre>
                    </div>
                )}

                {item.url && <p className="urlBg">{item.url}</p>}
                
                
              </ExpansionPanelDetails>
            </ExpansionPanel>
          ))}
        </div>
      </div>
    );
  }
}

export default RestApiDocs;
