import React, { Component } from "react";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import ListItemText from "@material-ui/core/ListItemText";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

class RecoTypeMultipleSelect extends Component {
  state = {
    selectedRecoTypes: [],
    defaVal: "",
  };

  componentDidUpdate = (prevProps) => {
    if (this.props.selectedRecoTypes && !this.state.selectedRecoTypes?.length) {
      this.updateDataInSelect(this.props.selectedRecoTypes);
    }
  };
  updateDataInSelect = async () => {
    let renderArray =
      this.props.selectedRecoTypes &&
      this.props.selectedRecoTypes.map((d) => d.type);
    if (renderArray.length) {
      this.setState({ selectedRecoTypes: renderArray });
    }
  };

  handleChange = async (event) => {
    this.setState({ selectedRecoTypes: event.target.value }, () =>
      this.sendData()
    );
  };
  sendData = () => {
    this.props.getCallBackData(this.state.selectedRecoTypes);
  };

  render() {
    return (
      <div className="recotypeSelectStyle">
        <FormControl>
          {this.state.selectedRecoTypes.length ? (
            ""
          ) : (
            <FormHelperText className="handlePlaceholder">
              Select Recommendation Type
            </FormHelperText>
          )}
          <Select
            labelId="demo-mutiple-checkbox-label"
            id="demo-mutiple-checkbox"
            multiple
            className="custom-select"
            value={this.state.selectedRecoTypes}
            onChange={this.handleChange}
            input={<Input />}
            disableUnderline
            renderValue={(selected) =>
              " Selected " + selected.length + "Reco Types"
            }
            MenuProps={MenuProps}
            inputProps={{ "aria-label": "Without label" }}
          >
            <MenuItem disabled value="none">
              Select Recommendation Type
            </MenuItem>
            {this.props.recoTypeList.map((name, i) => (
              <MenuItem key={name.name} value={name.name}>
                <Checkbox
                  checked={this.state.selectedRecoTypes.indexOf(name.name) > -1}
                />
                <ListItemText primary={name.name} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    );
  }
}
export default RecoTypeMultipleSelect;
