import Keycloak from "keycloak-js";
import AuthProvider from "./auth";

const keycloak = new Keycloak({
  url: window._env_.REACT_APP_AUTH_SERVER_URL || "https://sxp.keycloak-dev.techsophy.com/auth",
  realm: window._env_.REACT_APP_REALM || "sxp-microservices",
  "ssl-required": "external",
  "public-client": true,
  clientId: window._env_.REACT_APP_CLIENT_ID || "sxp",
});

const initKeycloak = (onAuthenticatedCallback) => {
  keycloak
    .init({
      onLoad: "check-sso",
      silentCheckSsoRedirectUri:
        window.location.origin + "/silent-check-sso.html",
      pkceMethod: "S256",
      checkLoginIframe: false,
    })
    .then((authenticated) => {
      if (authenticated) {
        sessionStorage.setItem('react-token', keycloak.token);
        AuthProvider.postLoginSteps(getUsername(), getUserEmail());
        onAuthenticatedCallback();
      } else {
        doLogin();
      }
    });
};

const clearAllLocalstorage = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("permissions");
  // localStorage.removeItem("pId");
  localStorage.removeItem("botUser");
  localStorage.removeItem("userName");
  localStorage.removeItem("role");
  localStorage.removeItem("isAdmin");
  localStorage.removeItem("menu");
  localStorage.removeItem("appid");
  localStorage.removeItem("version");
  // localStorage.removeItem("pDashboardId");
  localStorage.removeItem("smallSidbar");
  localStorage.removeItem("rowPerPage");
  localStorage.removeItem("hasBackendServer");
};

const doLogin = keycloak.login;

const doLogout = () => {
  clearAllLocalstorage();
  keycloak.logout();
};

const getToken = () => keycloak.token;

const isLoggedIn = () => !!keycloak.token;

const updateToken = (successCallback) =>
  keycloak.updateToken(5).then(successCallback).catch(doLogin);

keycloak.onTokenExpired = () => {
  updateToken().then(() => {
    sessionStorage.setItem('react-token', keycloak.token);
  });
};

const getUsername = () => keycloak.tokenParsed?.preferred_username;

const getUserEmail = () => keycloak.tokenParsed?.email;

const hasRole = (roles) => roles.some((role) => keycloak.hasRealmRole(role));

const UserService = {
  initKeycloak,
  doLogin,
  doLogout,
  isLoggedIn,
  getToken,
  updateToken,
  getUsername,
  getUserEmail,
  hasRole,
};

export default UserService;
