import { v4 as uuid } from 'uuid';
import { Action } from "../constants/common";
import { ComponentType } from "../constants/componentType";
import { Style } from "../constants/style";

const defaultPosition = { x: 250, y: 0 };

export const nodeBuilder = {
  addStartNode(dispatcher, position, flow) {
    const nodeId = uuid();
    const newNode = {
      id: nodeId,
      type: "startNode",
      data: {
        component: ComponentType.START_NODE,
        name: "start",
        style: Style.STARTNODESTYLE,
      },
      
      position: position ? position : defaultPosition,
    };
    let els = flow.getElements();
    if (els) {
      const startNodeFound = els.find((x) => x.type === "startNode");
      if (startNodeFound) {
      } else {
        dispatcher(Action.ADD, newNode);
      }
    }
  },
  
  addEndNode(dispatcher, position, flow) {
    const nodeId = uuid();
    const newNode = {
      id: nodeId,
      type: "endNode",
      data: {
        component: ComponentType.END_NODE,
        name: "end",
        style: Style.ENDNODESTYLE,
      },
      
      position: position ? position : defaultPosition,
    };
        dispatcher(Action.ADD, newNode);
   },

  addWaitNode(dispatcher, position) {
    const nodeId = uuid();
    const newNode = {
      id: nodeId,
      type: "waitNode",
      data: {
        component: ComponentType.WAIT_NODE,
        name: "wait",
        style: Style.WAITNODESTYLE,
      },
      
      position: position ? position : defaultPosition,
    };
    dispatcher(Action.ADD, newNode);
  },
  addSubmitNode(dispatcher, position) {
    const nodeId = uuid();
    const newNode = {
      id: nodeId,
      type: "submitNode",
      data: {
        component: ComponentType.SUBMIT_NODE,
        name: "submit",
        style: Style.SUBMITNODESTYLE,
      },
      
      position: position ? position : defaultPosition,
    };
    dispatcher(Action.ADD, newNode);
  },

  addQuestionNode(dispatcher, position) {
    const nodeId = uuid();
    const newNode = {
      id: nodeId,
      type: "component",
      data: {
        component: ComponentType.QUESTION,
        name: "",
        style: Style.QUESTIONNODESTYLE,
      },
      
      position: position ? position : defaultPosition,
    };
    dispatcher(Action.ADD, newNode);
  },

  addNotificationNode(dispatcher, position) {
    const nodeId = uuid();
    console.log('******************', nodeId);
    const newNode = {
      id: nodeId,
      type: "component",
      data: {
        component: ComponentType.NOTIFICATION,
        name: "",
        style: Style.NOTIFICATIONNODESTYLE,
      },
      
      position: position ? position : defaultPosition,
    };
    dispatcher(Action.ADD, newNode);
  },

  addABNode(dispatcher, position) {
    const nodeId = uuid();
    const newNode = {
      id: nodeId,
      type: "component",
      data: {
        component: ComponentType.AB,
        name: "",
      style: Style.ABNODESTYLE,
      },
      position: position ? position : defaultPosition,
    };
    dispatcher(Action.ADD, newNode);
  },

  addUserRegisterNode(dispatcher, position) {
    const nodeId = uuid();
    const newNode = {
      id: nodeId,
      type: "component",
      data: {
        component: ComponentType.QUESTION,
        isUserRegistrationComponent: true,
        name: "",
        style: Style.URNODESTYLE,
      },
      position: position ? position : defaultPosition,
    };
    dispatcher(Action.ADD, newNode);
  },

  addAPINode(dispatcher, position) {
    const nodeId = uuid();
    const newNode = {
      id: nodeId,
      type: "component",
      data: { component: ComponentType.API, name: "", style: Style.APINODESTYLE, },      
      position: position ? position : defaultPosition,
    };
    dispatcher(Action.ADD, newNode);
  },

  addAPIResponseNode(dispatcher, position) {
    const nodeId = uuid();
    const newNode = {
      id: nodeId,
      type: "component",
      data: { component: ComponentType.API_RESPONSE, name: "", style: Style.APIRESPNODESTYLE, },      
      position: position ? position : defaultPosition,
    };
    dispatcher(Action.ADD, newNode);
  },

  addDynamicNode(dispatcher, position) {
    const nodeId = uuid();
    const newNode = {
      id: nodeId,
      type: "component",
      data: { component: ComponentType.DYNAMIC, name: "", style: Style.DYNAMICNODESTYLE },      
      position: position ? position : defaultPosition,
    };
    dispatcher(Action.ADD, newNode);
  },
  addDialogNode(dispatcher, position) {
    const nodeId = uuid();
    const newNode = {
      id: nodeId,
      type: "component",
      data: { component: ComponentType.START_DIALOG, name: "", style: Style.DIALOGNODESTYLE },      
      position: position ? position : defaultPosition,
    };
    dispatcher(Action.ADD, newNode);
  },
  addStartComponentNode(dispatcher, position) {
    const nodeId = uuid();
    const newNode = {
      id: nodeId,
      type: "component",
      data: { component: ComponentType.START_COMPONENT, name: "", style: Style.STARTCOMPONENTNODESTYLE },      
      position: position ? position : defaultPosition,
    };
    dispatcher(Action.ADD, newNode);
  },
  addNodeOnDrop(dispatcher, position, flow, type) {
    switch (type) {
      case ComponentType.START_NODE:
        this.addStartNode(dispatcher, position, flow);
        break;
      case ComponentType.END_NODE:
        this.addEndNode(dispatcher, position, flow);
        break;
      case ComponentType.WAIT_NODE:
        this.addWaitNode(dispatcher, position);
        break;
      case ComponentType.SUBMIT_NODE:
        this.addSubmitNode(dispatcher, position);
        break;        
      case ComponentType.QUESTION:
        this.addQuestionNode(dispatcher, position);
        break;
      case ComponentType.API:
        this.addAPINode(dispatcher, position);
        break;
      case ComponentType.API_RESPONSE:
        this.addAPIResponseNode(dispatcher, position);
        break;
      case ComponentType.NOTIFICATION:
        this.addNotificationNode(dispatcher, position);
        break;
      case ComponentType.START_DIALOG:
        this.addDialogNode(dispatcher, position);
        break;
      case ComponentType.START_COMPONENT:
        this.addStartComponentNode(dispatcher, position);
        break;
      case ComponentType.AB:
        this.addABNode(dispatcher, position);
        break;
      case ComponentType.USER_REGISTER:
        this.addUserRegisterNode(dispatcher, position);
        break;
      case ComponentType.DYNAMIC:
        this.addDynamicNode(dispatcher, position);
        break;
      default:
        break;
    }
  },
};
