import React from "react";
import ConfirmAlert from "../../../commons/uicomponents/confirm";
import { deleteNode } from "./formActions";

export class SubmitNode extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmAlert: false,
      displayToast: false,
      messageToast: "",
    };
  }
  displayAlert = () => {
    this.setState({ confirmAlert: true });
  };
  closeToast = () => {
    this.setState({ displayToast: false });
  };
  onCancelBtn = () => {
    const collapseForm = this.props.formAction;
    collapseForm();
  };
  onConfirmButtonClick = () => {
    deleteNode(this.props);
    this.setState({ displayToast: true, messageToast: "Deleted Successfully" });
  };

  render() {
    return (
      <>
        {this.state.confirmAlert == true && (
          <ConfirmAlert
            onConfirmBtn={this.onConfirmButtonClick}
            onCancelBtn={this.onCancelBtn}
            heading=""
            paragraph="Are you sure you want to delete component ?"
          />
        )}
        <form className="form-style">
          <button
            type="button"
            className="deleBtn"
            onClick={() => this.displayAlert()}
          >
            Delete
          </button>
        </form>
      </>
    );
  }
}
