import React, { Component } from "react";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import AddIcon from "@material-ui/icons/Add";
import {
  FormBuilder,
  FieldGroup,
  FieldControl,
  Validators,
} from "react-reactive-form";
import axios from "axios";
import moment from "moment";
import EmptyCard from "../../commons/uicomponents/emptycard";
import PositionedSnackbar from "../../commons/uicomponents/toast";
import calender from "../../assets/images/calender.png";
import './faq.scss';
import { fileUpload } from "./fileUploadApi";
import { getSingleData } from "../../dataProvider/apiHandler";
import FindError from "../../dataProvider/errorHandler";

const serverUrl = window._env_.REACT_APP_SXP_URL;

class FAQUpload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      journeyIndexEdit: "",
      jrnyBorder: false,
      convform: "",
      confirmAlert: false,
      displayToast: false,
      selectedFile: "",
      faqlist: [],
      loadingCard: false,
      newFaq: false,
      params: {
        filter: {},
        pagination: { page: 1, perPage: 10 },
        sort: { field: "id", order: "ASC" },
      },
    };
    this.fileSelected = this.fileSelected.bind(this);
    this.conversation = React.createRef();
  }

  componentDidMount = () => {
    this.getFaqList();
  };

  faqFormBuilder = FormBuilder.group({
    name: [""],
    role: [""],
    email: [""],
  });

  getFaqList = async () => {
    this.setState({ loadingCard: true });
    try {
      const allOption = {
        url: serverUrl + 'faq/' + localStorage.getItem('pId'),
        params:{projectId: localStorage.getItem("pId")}
      }
      let faqlist = await getSingleData(allOption);
      this.setState({ faqlist: faqlist.data.data, loadingCard: false });
    } catch (e) {
      const errorObj = FindError(e)
      this.setState({
        displayToast: true,
        messageToast: errorObj.message,
        severity: "error",
        confirmAlert: false,
        loadingCard: false
      });
    }
  };

  onNewFaq = () => {
    this.setState({ newFaq: true, convform: "new" });
  };

  hideNewFaq = () => {
    this.setState({ newFaq: false });
  };

  fileSelected = (e) => {
    if (e.target.files && e.target.files.length) {
      this.setState({ selectedFile: e.target.files[0] });
    }
  };

  // Close Toast
  closeToast = () => {
    this.setState({ displayToast: false });
  };

  fileUploadFormSubmit = async (event) => {
    event.preventDefault();
    this.setState({ loadingCard: true });
    if(this.state.selectedFile !== "")
    {
      try {
        const allOptions = {
          url: serverUrl + "faq/" + localStorage.getItem('pId'),
          file: this.state.selectedFile,
          params:{projectId: localStorage.getItem("pId")}
        };
        let res = await fileUpload(allOptions);
        if (res.data.success) {
          this.setState({
            displayToast: true,
            messageToast: "Uploaded Successfully",
            severity: "success",
            newFaq: false,
            selectedFile:'',
          });
        }
        this.getFaqList();
      } catch (e) {
        const errorObj = FindError(e)
        this.setState({
          displayToast: true,
          messageToast: errorObj.message,
          severity: "error",
          confirmAlert: false,
        });
      }
    }
    else{
      this.setState({
        displayToast: true,
        messageToast: "File not found, please upload again",
        severity: "error",
      });
    }
    this.setState({ loadingCard: false });
  };

  createFaqForm = () => {
    return (
      <div className="formField" style={{ paddingLeft: 0 }}>
        <FieldGroup
          control={this.conversationFormBuilder}
          render={({ invalid, value }) => (
            <form
              className="flex newVar"
              onSubmit={(e) => this.fileUploadFormSubmit(e)}
              style={{ justifyContent: "center" }}
            >
              <FieldControl
                name="key"
                options={{ validators: Validators.required }}
                render={({ handler, touched, hasError }) => (
                  <div className="">
                    <input
                      {...handler()}
                      style={{ marginRight: "10px" }}
                      placeholder="Enter File Name"
                      className={`${
                        touched && hasError("required") && "errorInput"
                      }`}
                    />
                    <span
                      className={`${
                        touched && hasError("required") && "errorFieldMsg"
                      }`}
                    >
                      {touched && hasError("required") && "Required Filed"}
                    </span>
                  </div>
                )}
              />

              {/* <div className="flex-div"> */}
              <label
                className="sxp-btn btn-primary left-space flex-div"
                style={{ padding: "7px 10px", marginRight: "10px" }}
              >
                <input
                  type="file"
                  id="selectFileUpload"
                  className="hideIt"
                  onChange={this.fileSelected}
                />
                Select File
              </label>
              {/* </div> */}

              <button
                className="sxp-btn btn-primary  left-space"
                id="uploadSaveBtn"
                type="submit"
                style={{ height: "34px", marginRight: "10px" }}
              >
                Upload
              </button>
              <button
                className="sxp-btn btn-danger"
                id="cancelUploadBtn"
                onClick={this.hideNewFaq}
                style={{ height: "34px" }}
              >
                Cancel
              </button>
            </form>
          )}
        />
      </div>
    );
  };

  bannerHeader = () => {
    return (
      <div className="display-flex faqHead table_head font13 fontWeight700 cardFull cardSpaceArrow">
        <div className="table_grid">
          <p>Name </p>
        </div>
        <div className="table_grid">
          <p>File Link</p>
        </div>
        <div className="table_grid">
          <p>Created By</p>
        </div>
        <div className="table_grid">
          <p>Created on</p>
        </div>
        <div className="table_grid">
          <p>Active / InActive</p>
        </div>
      </div>
    );
  };

  bannerCard = (item, index) => {
    return (
      <div className="display-flex text_black font13 w100 pr15 pl15 faqBody">
        <div className="table_grid">
          <p className="overflowTextdots">
            {item.fileName}
          </p>
        </div>
        <div className="table_grid">
          <p>
            {item.link === "" ? (
              <span style={{ color: "red" }}>No-Link</span>
            ) : (
              <a href={item.link} id={`fileLink${index}`} className={`faqlink${index}`} target="_blank">
                Click here
              </a>
            )}
          </p>
        </div>
        <div className="table_grid">
          <p className=""> { item.createdBy || item.modifiedBy || '~N/A' } </p>
        </div>
        <div className="table_grid">
          <p className="flex" style={{ marginTop: "8px" }}>
            <img src={calender} className="vertical-7" alt="calendar Icon" />
            <span
              style={{
                color: "#000",
                marginTop: "6px",
              }}
            >
              {moment(item.createdOn).format("DD-MMM-YYYY")}
            </span>
          </p>
        </div>
        <div className="table_grid text-center">
          <div
            className={`toggle-parent-modeller ${
              item.active ? "" : "inactiveToggle"
            } disablePagBtn`}
            style={{ marginTop: "9px" }}
          >
            <div
              className={` ${
                item.active ? "toggle-button" : "disabled-toggle"
              }`}
            >
            </div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    return (
      <div className="parent">
        <PositionedSnackbar
          display={this.state.displayToast}
          msg={this.state.messageToast}
          closeToast={this.closeToast}
          severity={this.state.severity}
        />
        <div className="display-flex">
          <h4 className="m0">
            FAQ Upload
            <span className="label_pink">Upload / Add New FAQ’S</span>
          </h4>
          <button
            id="newFileUpload"
            className="sxp-btn btn-primary"
            onClick={this.onNewFaq.bind(this)}
          >
            <AddIcon className="vertical-7" /> New FAQ
          </button>
        </div>
        {this.state.newFaq ? this.createFaqForm() : null}

        {this.bannerHeader()}

        {this.state.faqlist?.length ? (
          <div className="flex-jrny">
            {this.state.faqlist.map((item, index) => (
              <ExpansionPanel key={index} className="CustomexpansionPannel">
                <ExpansionPanelSummary className="boxShadow">
                  {this.bannerCard(item, index)}
                </ExpansionPanelSummary>
              </ExpansionPanel>
            ))}
          </div>
        ) : (
          <EmptyCard />
        )}
      </div>
    );
  }
}

export default FAQUpload;
