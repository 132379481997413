export const restApiData = [
    {
        id:1,
        name:'Object Format',
        data:{
            "score": 1337,
            "playerName": "Sean Plott",
            "cheatMode": false
        },
        heading:'Object Format',
        paragraph:'When you retrieve objects from Parse, some fields are automatically added: createdAt, updatedAt, and objectId. These field names are reserved, so you cannot set them yourself. The object above could look like this when retrieved:',
        url:'https://sxp.v1.techsophy.com/app/project1/classes/GameScore',
    },
    {
        id:2,
        name:'Fetch API',
        heading:'Retrieving Objects',
        paragraph:'Once you’ve created an object, you can retrieve its contents by sending a GET request to the object URL returned in the location header. For example, to retrieve the object we created above:',
        curl:`
        curl -X GET \\
          -H "X-Parse-Application-Id: \${APPLICATION_ID}"          
          -H "X-Parse-REST-API-Key: \${REST_API_KEY}"
          https://sxp.v1.techsophy.com/app/project1/classes/GameScore/Ed1nuqPvcm
        `,
        url:'https://sxp.v1.techsophy.com/app/project1/classes/GameScore/Ed1nuqPvcm',
    },
    {
        id:3,
        name:'Post API',
        heading:'Creating Object',
        paragraph:'To create a new object on Parse, send a POST request to the class URL containing the contents of the object. For example, to create the object described above:',
        curl:`curl -X POST 
        -H "X-Parse-Application-Id: \${APPLICATION_ID}" 
        -H "X-Parse-REST-API-Key: \${REST_API_KEY}" 
        -H "Content-Type: application/json" 
        -d '{"score":1337,"playerName":"Sean Plott","cheatMode":false}' 
        https://sxp.v1.techsophy.com/app/project1/classes/GameScore`,
        url:'https://sxp.v1.techsophy.com/app/project1/classes/GameScore',
    },
    {
        id:3,
        name:'Put API',
        heading:'Updating Objects',
        paragraph:'To change the data on an object that already exists, send a PUT request to the object URL. Any keys you don’t specify will remain unchanged, so you can update just a subset of the object’s data. For example, if we wanted to change the score field of our object:',
        curl:`curl -X PUT 
        -H "X-Parse-Application-Id: \${APPLICATION_ID}" 
        -H "X-Parse-REST-API-Key: \${REST_API_KEY}" 
        -H "Content-Type: application/json" 
        -d '{"score":73453}' 
        https://sxp.v1.techsophy.com/app/project1/classes/GameScore/Ed1nuqPvcm`,
        url:'https://sxp.v1.techsophy.com/app/project1/classes/GameScore/Ed1nuqPvcm',
    },
    {
        id:3,
        name:'Delete API',
        heading:'Deleting Objects',
        paragraph:'To delete an object from the Parse Cloud, send a DELETE request to its object URL. For example',
        curl:`curl -X DELETE 
        -H "X-Parse-Application-Id: \${APPLICATION_ID}" 
        -H "X-Parse-REST-API-Key: \${REST_API_KEY}" 
        https://sxp.v1.techsophy.com/app/project1/classes/GameScore/Ed1nuqPvcm`,
        url:'https://sxp.v1.techsophy.com/app/project1/classes/GameScore/Ed1nuqPvcm',
    }
]