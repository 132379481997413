import React, { Component } from "react";
import AddIcon from "@material-ui/icons/Add";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import moment from "moment";
import {
  FormBuilder,
  FieldGroup,
  FieldControl,
  Validators,
} from "react-reactive-form";
import VersionSelectDropDown from "../../commons/versions-dropdown";
import PositionedSnackbar from "../../commons/uicomponents/toast";
import Pagination from "../../commons/pagination";
import ConfirmAlert from "../../commons/uicomponents/confirm";
import LoaderSpinner from "../../commons/uicomponents/loader";
import EmptyCard from "../../commons/uicomponents/emptycard";
import {
  deleteData,
  getData,
  saveData,
  updateData,
} from "../../dataProvider/apiHandler";
import jrnyEdit from "../../assets/images/jrny-edit.png";
import calender from "../../assets/images/calender.png";
import deletewhiteIcon from "../../assets/images/deletewhite.png";
import searchIcon from "../../assets/images/search.png";
import FindError from "../../dataProvider/errorHandler";

const serverUrl = window._env_.REACT_APP_SXP_URL;

class GlobalVariable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      globalVars: [],
      journeyIndex: "",
      conversationID: "",
      newConv: false,
      convList: false,
      confirmAlert: false,
      slabsIndex: "",
      globalVarNIndex: "",
      newVariable: false,
      displayToast: false,
      loadingCard: false,
      pageNo: 1,
      globalVarId: "",
      rowPageSize: localStorage.getItem("rowPerPage"),
      versionVal: localStorage.getItem("version"),
      permissions: {},
      skipData:'',
      searchTextData:'',
    };
    this.conversation = React.createRef();
  }

  componentDidMount = () => {
    this.getAllData();
  };

  // initiate the fields values
  conversationFormBuilder = FormBuilder.group({
    key: ["", Validators.required],
    value: ["", Validators.required],
  });

  // to get all global variable data
  getAllData = async (pararms) => {
    try {
      this.setState({ loadingCard: true });
      const initialVal = {
        version: localStorage.getItem("version"),
        page: pararms?.page || this.state.skipData,
        perPage: localStorage.getItem("rowPerPage"),
        projectId: localStorage.getItem("pId"),
        searchWord: this.state.searchTextData === '.*' ? null: this.state.searchTextData,
        searchKey: 'key'
      };
      pararms = {
        ...pararms,
        ...initialVal,
      };
      const allOptions = {
        url: serverUrl + "global-vars/" + localStorage.getItem('pId'),
        params: { ...pararms },
      };
      let initialLoad = await getData(allOptions);
      this.setState({
        globalVars: initialLoad.data.data.docs,
        totalPages: Math.ceil(initialLoad.data.data.total / initialLoad.data.data.limit),
        loadingCard: false,
      });
    } catch (e) {
      const errorObj = FindError(e)
      this.setState({
        displayToast: true,
        messageToast: errorObj.message,
        severity: "error",
        confirmAlert: false,
        loadingCard: false
      });
    }
  };

  // to open a new field block so user can add a new global varialble
  onNewJourney = () => {
    this.setState({ newVariable: true });
    this.conversationFormBuilder.reset();
  }

  // to close a new form  filed of globar variable
  hideNewVariable = () => {
    this.setState({ newVariable: false });
  };

  // function to show input field for the required card field
  showInPlaceEdit = (index) => {
    let globVarList = [...this.state.globalVars];
    globVarList[index].inplaceEdit = !globVarList[index].inplaceEdit;
    this.setState({ globalVars: globVarList });
  };

  // function to show input field for the required card field
  InputPlaceEdit = (index) => {
    this.showInPlaceEdit(index);
  };

  // to update the name in input field of seleted card
  changeName = async (evt, index) => {
    let globVarList = [...this.state.globalVars];
    globVarList[index].value = evt.target.value;
    if (evt.keyCode === 13) {
      this.setState({loadingCard: true})
      try {
        const allOptions = {
          url: serverUrl + "global-vars/" + localStorage.getItem('pId') + '/' + globVarList[index].id,
          body: {value: globVarList[index].value, version: localStorage.getItem('version')}
        };
        await updateData(allOptions);
        this.setState({
          displayToast: true,
          messageToast: "Updated Successfully",
          severity: "success",
          loadingCard: false
        });
        globVarList[index].inplaceEdit = !globVarList[index].inplaceEdit;
      } catch (e) {
        const errorObj = FindError(e)
        this.setState({
          displayToast: true,
          messageToast: errorObj.message,
          severity: "error",
          confirmAlert: false,
          loadingCard: false
        });
      }
    }
    this.setState({
      globalVars: globVarList,
    });
  };

  onTitleBlur = async (index) => {
    let globVarList = [...this.state.globalVars];
    try {
      this.setState({loadingCard: true})
      const allOptions = {
        url: serverUrl + "global-vars/" + localStorage.getItem('pId') + '/' +globVarList[index].id,
        body: {value: globVarList[index].value, version: localStorage.getItem('version')}
      };
      await updateData(allOptions);
      this.setState({
        displayToast: true,
        messageToast: "Updated Successfully",
        severity: "success",
        loadingCard: false
      });
      globVarList[index].inplaceEdit = !globVarList[index].inplaceEdit;
    } catch (e) {
      const errorObj = FindError(e)
      this.setState({
        displayToast: true,
        messageToast: errorObj.message,
        severity: "error",
        confirmAlert: false,
        loadingCard: false
      });
    }
    this.setState({
      globalVars: globVarList,
    });
  };

  searchCard = (event) => {
    let searchText = event.target.value;
    const searchPattern = searchText + '.*'
    const paramsmet = {
      filter: {
        name:searchPattern
      },
    };
    this.setState({searchTextData: searchText + '.*'}, () => this.getAllData(paramsmet))    
  };

  // to submit a new global variable data
  globalVarFormSubmit = async (e, value) => {
    value.version = localStorage.getItem("version") || "WORKING";
    this.setState({ loadingCard: true });
    let globVarList = [...this.state.globalVars];
    e.preventDefault();
    try {
      const allOptions = {
        url: serverUrl + "global-vars/" + localStorage.getItem('pId'),
        body: value,
        pageType : "modeler"
      };
      await saveData(allOptions);
      this.setState({
        displayToast: true,
        messageToast: "Created Successfully",
        severity: "success",
        loadingCard: false
      });
    } catch (e) {
      const errObj = FindError(e);
      console.log(errObj)
      this.setState({
        displayToast: true,
        messageToast: errObj.message,
        severity: "error",
        loadingCard: false
      });      
    }
    this.setState({
      globalVars: globVarList,
      newVariable: false,
      loadingCard: false,
    });
    this.getAllData();
  };

  // Close Toast
  closeToast = () => {
    this.setState({ displayToast: false });
  };

  // to show confirmation alert to remove a global variable
  removeGlobVar = (index, id) => {
    this.setState({ confirmAlert: true, globalVarNIndex: index });
    this.setState({ globalVarId: id });
  };

  // to show cancel alert to remove a global variable
  cancelBtn = () => {
    this.setState({ confirmAlert: false });
  };

  confirmBtn = () => {
    this.confirmBtnGlobal(this.state.globalVarId);
  };

  // to delete a global variable using api
  confirmBtnGlobal = async (id) => {
    this.setState({ loadingCard: true });
    try {
      const allOptions = {
        url: serverUrl + "global-vars/" + localStorage.getItem('pId') +'/' + id,
      };
      await deleteData(allOptions);
      this.setState({
        displayToast: true,
        messageToast: "Deleted  Successfully",
        severity: "success",
        loadingCard: false,
        confirmAlert: false
      });
    } catch (e) {
      const errorObj = FindError(e)
      this.setState({
        displayToast: true,
        messageToast: errorObj.message,
        severity: "error",
        confirmAlert: false,
        loadingCard: false
      });
    }
    this.getAllData();
  };

  // to go to next page
  nextPage = () => {
    let PreviousSetNo = this.state.pageNo + 1;
    const paramsmet = {
      page: (PreviousSetNo -1) * Number(localStorage.getItem('rowPerPage')),
    };
    this.setState({ pageNo: PreviousSetNo, skipData: paramsmet.page});
    this.getAllData(paramsmet);
  };

  // to go to previousPage
  previousPage = () => {
    let PreviousSetNo = this.state.pageNo - 1;
    const paramsmet = {
      page: (PreviousSetNo - 1) * Number(localStorage.getItem('rowPerPage')),
    };
    this.setState({ pageNo: PreviousSetNo, skipData: paramsmet.page});
    this.getAllData(paramsmet);
  };

  rowPerPageChange = async (e) => {
    this.setState({ loadingCard: true });
    localStorage.setItem("rowPerPage", e.target.value);
    const paramsmet = {
      perPage: e.target.value,
      page:(this.state.pageNo-1) * Number(localStorage.getItem('rowPerPage')),
      version: localStorage.getItem("version"),
    };
    this.getAllData(paramsmet);
  };

  bannerHeader = () => {
    return (
      <div className="display-flex table_head font13 fontWeight700 cardFull cardSpaceArrow">
        <div className="table_grid">
          <p>Name </p>
        </div>
        <div className="table_grid">
          <p>Value</p>
        </div>
        <div className="table_grid">
          <p>Created By</p>
        </div>
        <div className="table_grid">
          <p>Created on</p>
        </div>
        <div className="table_grid">
          <p>Modified By</p>
        </div>
        <div className="table_grid">
          <p>Last Modified on</p>
        </div>
      </div>
    );
  };

  bannerCard = (item, index) => {
    return (
      <div className="display-flex text_black font13 w100 pr15 pl15">
        <LoaderSpinner loadingCard={this.state.loadingCard} />
        <div className="table_grid">
          <p className="overflowTextdots" id={`CopyText${index}`} onClick={this.copyText}>
            {item.key}
          </p>
        </div>
        <div className="table_grid">
          {item.inplaceEdit !== false ? (
            <div>
              <p
                className={`in-block overflowTextdots ${
                  this.state.permissions ? "" : "disable-click"
                }`}
                id={`globalVarVal${index}`}
                onClick={() => this.InputPlaceEdit(index)}
                style={{ maxWidth: "118px" }}
              >
                {item.value}
              </p>
              &nbsp;
              {this.state.permissions ? (
                <img
                  alt="editIcon"
                  id={`glovalVarEdit${index}`}
                  src={jrnyEdit}
                  className="pointer-coursor"
                  onClick={() => this.showInPlaceEdit(index)}
                  style={{ verticalAlign: "13px" }}
                />
              ) : null}
            </div>
          ) : (
            <p className="">
              <input
                type="text"
                value={item.value}
                name="name"
                style={{
                  height: "34px",
                  border: "1px solid #999",
                  borderRadius: "8px",
                }}
                id={`globalVarinput${index}`}
                onChange={(e) => this.changeName(e, index)}
                onBlur={() => this.onTitleBlur(index)}
                onKeyDown={(e) => this.changeName(e, index)}
              />
            </p>
          )}
        </div>
        <div className="table_grid">
          <p>{item.createdBy} </p>
        </div>
        <div className="table_grid">
          <p>
            <img src={calender} className="vertical-7" alt="calendar Icon" />
            <span
              style={{
                color: "#000",
                marginTop: "6px",
              }}
            >
              {moment(item.createdOn).format("DD-MMM-YYYY")}
            </span>
          </p>
        </div>
        <div className="table_grid">
          {item.modifiedBy ? (
            <p>{item.modifiedBy} </p>
          ) : (
            <p>~N/A~</p>
          )}
        </div>
        <div className="table_grid">
          <p className="flex" style={{ marginTop: "8px" }}>
            <img src={calender} className="vertical-7" alt="calendar Icon" />
            <span
              style={{
                color: "#000",
                marginTop: "6px",
              }}
            >
              {moment(item.modifiedOn).format("DD-MMM-YYYY")}
            </span>
          </p>
        </div>
        {this.state.activeTabThridParty ? (
          <div className="part1">
            <p className="">{item.usageType}</p>
          </div>
        ) : null}

        <img
          src={deletewhiteIcon}
          alt="trash Icon"
          id={`globalVarDeleteIcon${index}`}
          onClick={() => this.removeGlobVar(index, item.id)}
          className="deleteIcon"
        />
      </div>
    );
  };

  render() {
    return (
      <div className="parent mt10">
        {this.state.confirmAlert === true && (
          <ConfirmAlert
            indexValue={this.state.slabsIndex}
            mainIndex={this.state.globalVarNIndex}
            onConfirmBtn={this.confirmBtn}
            onCancelBtn={this.cancelBtn}
            heading="Are you sure ?"
            paragraph="If you proceed you will loose the data. Are you sure you want to delete ?"
          />
        )}
        <PositionedSnackbar
          display={this.state.displayToast}
          msg={this.state.messageToast}
          closeToast={this.closeToast}
          severity={this.state.severity}
        />
        <div className="display-flex">
          <h4 className="m0">
            Global Variables
            <span className="label_pink">Assign the Global Variables</span>
          </h4>
          <div>
            <div
              className="pos-rel in-block Version_modeller_dropdwn"
              style={{ marginRight: "10px" }}
            >
              <VersionSelectDropDown final={this.getAllData} />
            </div>
            <div className="pos-rel in-block searchFilter">
              <img src={searchIcon} alt="search Icon" className="searchIcon" />
              <input
                type="text"
                placeholder="Search Global Variable"
                id="globalVarSearchInput"
                name="globalvarSearch"
                // value={this.state.searchTextData.slice(0,-2)}
                onChange={this.searchCard}
              />
            </div>
            {this.state.permissions ? (
              <button
                className="sxp-btn btn-primary"
                id="addNewGlobalVar"
                onClick={this.onNewJourney.bind(this)}
              >
                <AddIcon className="vertical-7" /> Global Variables
              </button>
            ) : null}
          </div>
        </div>

        {this.state.newVariable ? (
          <div className="formField p0">
            <FieldGroup
              control={this.conversationFormBuilder}
              render={({ get, invalid, reset, value }) => (
                <form
                  className="flex newVar"
                  onSubmit={(e) => this.globalVarFormSubmit(e, value)}
                  style={{ justifyContent: "center" }}
                >
                  <h4 className="varHead">Add Global Variable: </h4>
                  <FieldControl
                    name="key"
                    options={{ validators: Validators.required }}
                    render={({ handler, touched, hasError }) => (
                      <div>
                        <input
                          {...handler()}
                          id="globalVarNameInput"
                          placeholder="Global Variable Name"
                          className={`${
                            touched && hasError("required") && "errorInput"
                          }`}
                        />
                        <span
                          className={`${
                            touched && hasError("required") && "errorFieldMsg"
                          }`}
                        >
                          {touched && hasError("required") && "Required Filed"}
                        </span>
                      </div>
                    )}
                  />
                  <FieldControl
                    name="value"
                    options={{ validators: Validators.required }}
                    render={({ handler, touched, hasError }) => (
                      <div className="">
                        <input
                          {...handler()}
                          id="globalVarValInput"
                          placeholder="value"
                          className={`${
                            touched && hasError("required") && "errorInput"
                          }`}
                        />
                        <span
                          className={`${
                            touched && hasError("required") && "errorFieldMsg"
                          }`}
                        >
                          {touched && hasError("required") && "Required Filed"}
                        </span>
                      </div>
                    )}
                  />
                  &nbsp;
                  <button
                    className={`sxp-btn btn-primary ${invalid && 'disablePagBtn'}`}
                    type="submit"
                    id="saveGlobalVar"
                    style={{ height: "34px", marginRight: "10px" }}
                    disabled={invalid}
                  >
                    Save
                  </button>
                  <button
                    id="cancelGlobalVar"
                    className="sxp-btn btn-danger"
                    onClick={this.hideNewVariable}
                    style={{ height: "34px" }}
                  >
                    Cancel
                  </button>
                </form>
              )}
            />
          </div>
        ) : (
          ""
        )}
        {this.bannerHeader()}
        {this.state.globalVars.length ? (
          <div className="flex-jrny">
            {this.state.globalVars.map((item, index) => (
              <ExpansionPanel
                className="CustomexpansionPannel"
                id="expansionPanelBlock"
                key={item.id}
                expanded={
                  this.state.expansionJrnyPanelOpen &&
                  this.state.journeyIndex === index
                }
              >
                <ExpansionPanelSummary id="expansionSummary" className="boxShadow">
                  {this.bannerCard(item, index)}
                </ExpansionPanelSummary>
              </ExpansionPanel>
            ))}
          </div>
        ) : (
          <EmptyCard />
        )}

        <Pagination
          pageNo={this.state.pageNo}
          totalPages={this.state.totalPages}
          rowPerPageChange={this.rowPerPageChange}
          previousPage={this.previousPage}
          nextPage={this.nextPage}
        />
      </div>
    );
  }
}

export default GlobalVariable;
