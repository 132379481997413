import React, { Component } from "react";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import moment from "moment";
import EmptyCard from "../../commons/uicomponents/emptycard";
import LoaderSpinner from "../../commons/uicomponents/loader";
import PositionedSnackbar from "../../commons/uicomponents/toast";
import { deleteData, getData, updateData, updateDataPut } from "../../dataProvider/apiHandler";
import "./intentclassification.scss";
import calender from "../../assets/images/calender.png";
import ClassificationInstance from "./classificationInstance";
import FindError from "../../dataProvider/errorHandler";

const projectId = localStorage.getItem("pId");
const serverUrl = window._env_.REACT_APP_SXP_URL;

class IntentClassification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      intentClass: [],
      expansionJrnyPanelOpen: false,
      intentClassIndex: "",
      displayToast: false,
      loadingCard: false,
    };
    this.conversation = React.createRef();
  }

  componentDidMount = () => {
    this.getAllIntentConfig();
  };

  getAllIntentConfig = async () => {
    try {
      const allOption = {
        url: serverUrl + "intent-config/" + localStorage.getItem('pId'),
        params:{projectId: localStorage.getItem("pId"),}
      };
      let initialLoad = await getData(allOption);
      this.setState({ intentClass: initialLoad.data.data, loadingCard: false });
    } catch (e) {
      const errorObj = FindError(e)
      this.setState({
        displayToast: true,
        messageToast: errorObj.message,
        severity: "error",
        confirmAlert: false,
      });
    }
  };

  setToggle = async (index, value, id) => {
    let intentclassification = [...this.state.intentClass];
    if (value) {
      intentclassification[index].active = false;
    } else {
      intentclassification[index].active = true;
    }
    delete intentclassification[index].id;
    delete intentclassification[index].modifiedBy;
    delete intentclassification[index].createdOn;
    delete intentclassification[index].modifiedOn;
    try {
      const allOption = {
        url: serverUrl + "intent-config/" + localStorage.getItem('pId') + '/' +id,
        body: intentclassification[index],
      };
      await updateDataPut(allOption);
      this.setState({
        intentClass: intentclassification,
        displayToast: true,
        messageToast: "Updated Successfully",
        severity: "success",
        loadingCard: false,
      });
    } catch (e) {
      const errorObj = FindError(e)
      this.setState({
        displayToast: true,
        messageToast: errorObj.message,
        severity: "error",
        confirmAlert: false,
        loadingCard: false,
      });
    }
  };

  bannerHeader = () => {
    return (
      <div className="display-flex table_head font13 fontWeight700 cardFull cardSpaceArrow">
        <div className="table_grid intentClassTableBody">
          <p>Title</p>
        </div>
        <div className="table_grid intentClassTableBody">
          <p>Modified By</p>
        </div>
        <div className="table_grid intentClassTableBody">
          <p>Last Modified</p>
        </div>
        <div className="table_grid intentClassTableBody">
          <p>Enable / Disable</p>
        </div>
      </div>
    );
  };

  bannerCard = (item, index) => {
    return (
      <div className="display-flex text_black font13 w100 pr15 pl15">
        <LoaderSpinner loadingCard={this.state.loadingCard} />
        <div className="table_grid intentClassTableBody">
          <p>Intent Classification Config</p>
        </div>
        <div className="table_grid intentClassTableBody">
          {item && item.userid && (
            <p style={{ marginLeft: "15px" }}>{item.userid}</p>
          )}
        </div>
        <div className="table_grid intentClassTableBody">
          <p className="flex" style={{ marginTop: "8px" }}>
            <img src={calender} className="vertical-7" alt="calendar Icon" />
            <span
              style={{
                color: "#000",
                marginTop: "6px",
              }}
            >
              {moment(item.modifiedOn).format("DD-MMM-YYYY")}
            </span>
          </p>
        </div>
        <div className="table_grid text-center intentClassTableBody">
          <div
            className={`toggle-parent-modeller ${
              item.active ? "" : "inactiveToggle"
            }`}
            onClick={this.setToggle.bind(this, index, item.active, item.id)}
            style={{ marginTop: "9px" }}
          >
            <div
              className={` ${
                item.active ? "toggle-button" : "disabled-toggle"
              }`}
            >
              {" "}
            </div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    return (
      <div className="parent mt10">
        <div className="display-flex">
          <h4>
            Intent Classification
            <span className="label_pink">
              Configure the Intent Classification
            </span>
          </h4>
        </div>
        <PositionedSnackbar
          display={this.state.displayToast}
          msg={this.state.messageToast}
          closeToast={this.closeToast}
          severity={this.state.severity}
        />

        {this.bannerHeader()}

        {this.state.intentClass.length ? (
          <div className="mb50">
            {this.state.intentClass.map((item, index) => (
              <ExpansionPanel
                className="CustomexpansionPannel"
                key={item._id}
                expanded
              >
                <ExpansionPanelSummary className="boxShadow">
                  {this.bannerCard(item, index)}
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <ClassificationInstance
                    classfigData={item}
                    onClosePanel={this.onClosePanel}
                    displayToast={this.displayToast}
                  />
                </ExpansionPanelDetails>
              </ExpansionPanel>
            ))}
          </div>
        ) : (
          <EmptyCard />
        )}
      </div>
    );
  }
}

export default IntentClassification;
