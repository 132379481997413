export const Style = Object.freeze({
  RIGHTFORMCOLLAPSE: "rightSlide",
  RIGHTFORMOPEN: "rightActiveSlide",
  LEFTFORMCOLLAPSE: "leftSlide",
  LEFTFORMOPEN: "leftActiveSlide",
  LOOPBACKCONNECTIONSTYLE: "bezier",
  STARTNODESTYLE: {
    height: "70px",
    width: "70px",
    backgroundColor: "#0D82D7",
    borderRadius: "50%",
    display: "inline-block",
    color: "#fff",
    textAlign: "center",
  },
  ENDNODESTYLE : {
    height: "70px",
    width: "70px",
    backgroundColor: "#ec5b56",
    borderRadius: "50%",
    display: "inline-block",
    color: "#fff",
    textAlign: "center",
  },
  WAITNODESTYLE : {
    height: "70px",
    width: "70px",
    backgroundColor: "#FFBC52",
    borderRadius: "50%",
    display: "inline-block",
    color: "#fff",
    textAlign: "center",
  },
  SUBMITNODESTYLE : {
    height: "70px",
    width: "70px",
    backgroundColor: "#649e66",
    borderRadius: "50%",
    display: "inline-block",
    color: "#fff",
    textAlign: "center",
  },
  QUESTIONNODESTYLE: {
    backgroundColor: "#771986",
    // padding: "2px 26px",
    minWidth: "133px",
    // borderRadius: "8px",
    // border: "1px solid #c8c8c8",
    boxShadow: "1px 1px 6px #dedede",
    overflow: "hidden",
    color: "#fff",
    textAlign: "center",
  },
  URNODESTYLE: {
    backgroundColor: "rgb(204, 84, 63)",
    // padding: "2px 26px",
    minWidth: "133px",
    // borderRadius: "8px",
    // border: "1px solid #c8c8c8",
    boxShadow: "1px 1px 6px #dedede",
    overflow: "hidden",
    color: "#fff",
    textAlign: "center",
  },
  NOTIFICATIONNODESTYLE: {
    backgroundColor: "#501B4A",
    // padding: "2px 26px",
    minWidth: "133px",
    // borderRadius: "8px",
    // border: "1px solid #c8c8c8",
    boxShadow: "1px 1px 6px #dedede",
    overflow: "hidden",
    color: "#fff",
    textAlign: "center",
  },
  ABNODESTYLE: {
    backgroundColor: "#111111",
    // padding: "2px 26px",
    minWidth: "133px",
    // borderRadius: "8px",
    // border: "1px solid #c8c8c8",
    boxShadow: "1px 1px 6px #dedede",
    overflow: "hidden",
    color: "#fff",
    textAlign: "center",
  },
  APINODESTYLE: {
    backgroundColor: "#0D9482",
    // padding: "2px 26px",
    minWidth: "133px",
    // borderRadius: "8px",
    // border: "1px solid #c8c8c8",
    boxShadow: "1px 1px 6px #dedede",
    overflow: "hidden",
    color: "#fff",
    textAlign:'center'
  },
  APIRESPNODESTYLE: {
    backgroundColor: "#fb792b",
    // padding: "2px 26px",
    minWidth: "133px",
    // borderRadius: "8px",
    // border: "1px solid #c8c8c8",
    boxShadow: "1px 1px 6px #dedede",
    overflow: "hidden",
    color: "#fff",
    textAlign:'center'
  },
  DIALOGNODESTYLE: {
    backgroundColor: "#A72449",
    // padding: "2px 26px",
    minWidth: "133px",
    // borderRadius: "8px",
    // border: "1px solid #c8c8c8",
    boxShadow: "1px 1px 6px #dedede",
    overflow: "hidden",
    color: "#fff",
    textAlign:'center'
  },
  STARTCOMPONENTNODESTYLE: {
    backgroundColor: "#0c82d7",
    // padding: "2px 26px",
    minWidth: "133px",
    // borderRadius: "8px",
    // border: "1px solid #c8c8c8",
    boxShadow: "1px 1px 6px #dedede",
    overflow: "hidden",
    color: "#fff",
    textAlign:'center'
  },
  CONNECTIONLABELSTYLE: {
    type: "smoothstep",
    labelBgPadding: [4, 4],
    labelBgBorderRadius: 4,
    arrowHeadType: "arrowclosed",
    labelBgStyle: {
      fill: "#FFCC00",
      color: "#fff",
      fillOpacity: 0.7,
      cursor: "pointer",
    },
    labelStyle: { fill: "#990000", fontWeight: 700 },
  },
  ABCONNECTIONLABELSTYLE: {
    type: "smoothstep",
    labelBgPadding: [4, 4],
    labelBgBorderRadius: 4,
    arrowHeadType: "arrowclosed",
    animated: true,
    labelBgStyle: {
      fill: "#FFCC00",
      color: "#fff",
      fillOpacity: 0.7,
      cursor: "pointer",
    },
    labelStyle: { fill: "#990000", fontWeight: 700 },
  },
  DYNAMICNODESTYLE: {
    backgroundColor: "#264653",
    // padding: "2px 26px",
    minWidth: "133px",
    // borderRadius: "8px",
    // border: "1px solid #c8c8c8",
    boxShadow: "1px 1px 6px #dedede",
    overflow: "hidden",
    color: "#fff",
    textAlign:'center'
  },
});
