import React, { Component } from "react";
import AddIcon from "@material-ui/icons/Add";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { deleteData, getData, saveData, updateData, updateDataPut } from "../../dataProvider/apiHandler";
import LoaderSpinner from "../../commons/uicomponents/loader";
import ConfirmAlert from "../../commons/uicomponents/confirm";
import PositionedSnackbar from "../../commons/uicomponents/toast";
import "./role.scss";
import deletewhiteIcon from "../../assets/images/deletewhite.png";
import EmptyCard from "../../commons/uicomponents/emptycard";
import FindError from "../../dataProvider/errorHandler";

const serverUrl = window._env_.REACT_APP_SXP_URL;

class Roles extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allRoles: [],
      userAllProjects: [],
      expansionJrnyPanelOpen: false,
      confirmAlert: false,
      displayToast: false,
      roleName: "",
      projId: "",
      roleData: "",
      rolesObj:[],
      viewArray:[],
      createArray:[],
      displayArray:[],
      currentRoleName:'',    
      loadingCard: false,  
    };
  }

  componentDidMount = () => {
    this.loadAllRoles();
    // this.loadRoleObj();
  };

  switchJrnyExpansionpanel(index) {
    this.setState({ conversationID: "" });
    if (this.state.journeyIndex === index) {
      this.setState({
        expansionJrnyPanelOpen: false,
        journeyIndex: "",
      });
    } else {
      this.loadRoleObj();
      this.setState({
        expansionJrnyPanelOpen: true,
        journeyIndex: index,
        convList: true,
        newConv: false,
        currentRoleName:this.state.allRoles[index].name
      });
    }
  }

  cancelExpand = () => {
    this.setState({
      expansionJrnyPanelOpen: false,
      journeyIndex: "",
      value: 0,
    });
  };

  loadAllRoles = async () => {
    try {
      const allOption = {
        url: serverUrl +  `projects/roles/` + localStorage.getItem('pId'),
      };
      let res = await getData(allOption);
      this.setState({ allRoles: res.data });
    } catch (e) {
      const errorObj = FindError(e)
      this.setState({
        displayToast: true,
        messageToast: errorObj.message,
        severity: "error",
        confirmAlert: false,
      });
    }
  };

  loadRoleObj = async() => {
    try {
      const allOption = {
        url: serverUrl +  `projects/roles/list/` + localStorage.getItem('pId'),
      };
      let res = await getData(allOption);
      res.data = res.data.sort((a,b)=>{
        if(a.name > b.name) return 1;
        if(a.name < b.name) return -1;
        return 0;
      })
      if(this.state.currentRoleName !== undefined)
      { 
        let currentRolesData = this.state.allRoles.find((d) => d.name === this.state.currentRoleName)
        res.data.map((d) => {
          const exists = currentRolesData.roles.find((ele)=>ele.id === d.id)
          if(exists){
            d.value = true
            return d
          }
          else
          {
            d.value = false
            return d
          }
        })
      }
      const viewArray = res.data.filter((d) => d.name.match(/-view/ig))
      const createArray = res.data.filter((d) => d.name.match(/-create/ig))
      const displayArray = viewArray.map(element => element.name.slice(0,-5));
      if(this.state.currentRoleName === undefined)
      {
        viewArray.forEach(element => {
          element.value = false;
        });
        createArray.forEach(element => {
          element.value = false;
        });
      }
      createArray.unshift({name: "analytics-create", value: false, disabled: true});
      createArray.splice(8, 0, {name: "journey-routes-create", value: false, disabled: true});
      this.setState({
        viewArray: viewArray,
        createArray: createArray,
        displayArray:displayArray, 
      })
      this.setState({ rolesObj: res.data });
    } catch (e) {
      const errorObj = FindError(e)
      this.setState({
        displayToast: true,
        messageToast: errorObj.message,
        severity: "error",
        confirmAlert: false,
      });
    }
  }

  createRole = async (item, i) => {
    try {
      this.setState({ loadingCard: true });
      if(item.id)
      {
        let tempArray = this.state.viewArray.concat(this.state.createArray);
        tempArray = tempArray.filter((ele) => ele.value === true);
        tempArray = tempArray.map((ele) => {
          return {name: ele.name, id: ele.id}
        })      
        const allOption = {
          url: serverUrl + "projects/roles/" + localStorage.getItem('pId'),
          body: { name: item.name,roles:tempArray },
        };
        await updateDataPut(allOption);
        this.setState({
          loadingCard: false,
          displayToast: true,
          messageToast: 'Role Updated Successfully',
          severity: 'success',
        });
        this.loadAllRoles();
      }
      else
      {
        let tempArray = this.state.viewArray.concat(this.state.createArray);
        tempArray = tempArray.filter((ele) => ele.value === true);
        tempArray = tempArray.map((ele) => {
          return {name: ele.name, id: ele.id}
        })      
        const allOption = {
          url: serverUrl + "projects/roles/" + localStorage.getItem('pId'),
          body: { name: item.name,roles:tempArray },
        };
        await saveData(allOption);
        this.setState({
          loadingCard: false,
          displayToast: true,
          messageToast: 'Role Created Successfully',
          severity: 'success',
        });
        this.loadAllRoles();
      }      
    } catch (e) {
      const errObj = FindError(e);
      this.setState({
        displayToast: true,
        messageToast: errObj.status + ' :: '+ errObj.message,
        severity: "error",
        loadingCard: false
      });
    }
  };

  addNewRole = () => {
    this.setState({ newVariable: true });
    let allRoless = [...this.state.allRoles];
    allRoless.push({});
    this.setState({
      allRoles: allRoless,
      newAccessControl: { ...this.state.accessControl },
      displayToast: true,
      messageToast: "New Role card added in bottom",
      severity: "success",
      currentRoleName:'',
    });
  };

  handleRoleName = (e, i) => {
    let allRoless = [...this.state.allRoles];
    allRoless[i].name = e.target.value;
    this.setState({ allRoles: allRoless });
  };

  handleViewArray = (e, i) => {
    let viewArrayObj = [...this.state.viewArray];
    viewArrayObj[i].value = e.target.checked;
    this.setState({viewArray: viewArrayObj})
  }

  handleCreateArray = (e, i) => {
    let createArrayObj = [...this.state.createArray];
    createArrayObj[i].value = e.target.checked;
    this.setState({createArray: createArrayObj})
  }

  removeRole = (index, item) => {
    let allRoless = [...this.state.allRoles];
    if (item.id) {
      this.setState({ confirmAlert: true, roleData: item });
    } else {
      allRoless.splice(index, 1);
      this.setState({ allRoles: allRoless });
    }
  };

  confirmBtn = () => {
    this.confirmDeleteRole(this.state.roleData);
  };

  cancelBtn = () => {
    this.setState({ confirmAlert: false });
  };

  closeToast = () => {
    this.setState({ displayToast: false });
  };

  confirmDeleteRole = async (item) => {
    try {
      const allOption = {
        url: serverUrl + `projects/roles/` + localStorage.getItem('pId') + `/` + item.name,
      };
      await deleteData(allOption);
      this.setState({
        loadingCard: false,
        displayToast: true,
        messageToast: "Role Deleted Successfully",
        severity: "success",
        confirmAlert: false,
      });
      this.loadAllRoles();
    } catch (e) {
      const errorObj = FindError(e)
      this.setState({
        displayToast: true,
        messageToast: errorObj.message,
        severity: "error",
        confirmAlert: false,
        loadingCard: false
      });
    }
  };

  bannerHeader = () => {
    return (
      <div className="display-flex rolesSpace50 table_head font13 fontWeight700 cardFull cardSpaceArrow">
        <div className="table_grid">
          <p>Role Name</p>
        </div>
        <div className="table_grid">
          <p>Role Type</p>
        </div>
       
      </div>
    );
  };

  bannerCard = (item, index) => {
    return (
      <div className="display-flex rolesSpace50 text_black font13 w100 pr15 pl15">
        <LoaderSpinner loadingCard={this.state.loadingCard} />
        <div className="table_grid">
          {item.name ? (
            <p>{item.name}</p>
          ) : (
            <p style={{ fontSize: "13px" }}>Role{item.index}</p>
          )}
        </div>
        <div className="table_grid">
          <p className="">{item.systemRole ? "System role" : "Project role"}</p>
        </div>
      

        <img
          src={deletewhiteIcon}
          alt="trash Icon"
          id={`removeRoleImg${index}`}
          onClick={() => this.removeRole(index, item)}
          className="deleteIcon"
        />
      </div>
    );
  };
  render() {
    return (
      <div className="parent mt10">
        {this.state.confirmAlert === true && (
          <ConfirmAlert
            indexValue={this.state.slabsIndex}
            mainIndex={this.state.apiServicesNIndex}
            onConfirmBtn={this.confirmBtn}
            onCancelBtn={this.cancelBtn}
            heading="Are you sure ?"
            paragraph="If you proceed you will loose the project"
          />
        )}
        <PositionedSnackbar
          display={this.state.displayToast}
          msg={this.state.messageToast}
          closeToast={this.closeToast}
          severity={this.state.severity}
        />

        <div className="display-flex">
          <h4 className="m0">
            Roles <span className="label_pink">Create Roles</span>
          </h4>
          <div>
            <button
              id="addNewRoleBtn"
              className="sxp-btn btn-primary"
              onClick={this.addNewRole}
            >
              <AddIcon className="vertical-7" /> Add New Role
            </button>
          </div>
        </div>

        <div className="rolesDiv">
          {this.bannerHeader()}
          {this.state.allRoles.length ? (
            <div>
              {this.state.allRoles.map((item, index) => (
                <ExpansionPanel
                  className="CustomexpansionPannel"
                  key={item.id + index}
                  expanded={
                    this.state.expansionJrnyPanelOpen &&
                    this.state.journeyIndex === index
                  }
                >
                  <ExpansionPanelSummary
                    expandIcon={
                      <ExpandMoreIcon
                        className="iconExpand"
                        onClick={this.switchJrnyExpansionpanel.bind(
                          this,
                          index,
                          ""
                        )}
                      />
                    }
                    style={{ order: -1 }}
                    className="boxShadow"
                  >
                    {this.bannerCard(item, index)}
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails className="inner_expandBlock">
                    <div>
                      <h4 className="varHead">Configure Role: </h4>
                      {item.id ? (
                        <div className="accessControl">
                          <div className="formStyle flex">
                           <input
                              type="text"
                              name="name"
                              id={`handleRoleNameDataExists${index}`}
                              className="def_input"
                              value={item.name}
                              onChange={(e) => this.handleRoleName(e, index)}
                              placeholder="Role Name"
                            />
                          </div>

                          <div
                            className="userAddBLock"
                            style={{ marginTop: "15px" }}
                          >
                            <div className="userAddHeader flex">
                              <label>Role Name</label>
                              <label style={{ width: "60px" }}>Read</label>
                              <label style={{ width: "60px" }}>Write</label>
                            </div>
                            {this.state.displayArray.map((d,i) =>(
                              <div className="flex">
                                <p style={{width: "220px"}}>{d}</p>
                                <div
                                    style={{
                                      width: "137px",
                                      justifyContent: "space-around",
                                      display: "flex",
                                    }}
                                  >

                                {/* <label>{this.state.viewArray[i].name}</label> */}
                                <input 
                                  type="checkbox"
                                  id={`handleViewArrayDataExist${index}`} 
                                  onChange={(e)=>this.handleViewArray(e,i)} 
                                  checked={this.state.viewArray[i].value} 
                                  name={this.state.viewArray[i].id}
                                />
                                {/* <label>{this.state.createArray[i].name}</label> */}
                                <input 
                                  type="checkbox" 
                                  id={`handleCreateArrayDataExist${index}`}
                                  onChange={(e)=>this.handleCreateArray(e,i)} 
                                  checked={this.state.createArray[i]?.value} 
                                  name={this.state.createArray[i]?.id} 
                                  disabled={this.state.createArray[i]?.disabled}
                                />
                                </div>
                              </div>
                            ))}
                            <button
                              className="sxp-btn btn-primary"
                              type="submit"
                              style={{
                                height: "34px",
                                width: "220px",
                                margin: "0 5px",
                              }}
                              onClick={() => this.createRole(item, index)}
                            >
                              Update
                            </button>
                            <span
                              className="sxp-btn btn-danger"
                              onClick={() => this.cancelExpand(index)}
                              id={`handleCancelRoleDataExist${index}`}
                              style={{
                                height: "34px",
                                width: "220px",
                                textAlign: "center",
                                margin: "0 5px",
                                padding: "9px 0",
                                display: "inline-block",
                                verticalAlign: "top",
                              }}
                            >
                              Cancel
                            </span>
                          </div>
                        </div>
                      ) : (
                        <div className="accessControl">
                          <div className="formStyle flex">
                            <input
                              type="text"
                              name="name"
                              className="def_input"
                              value={item.name}
                              onChange={(e) => this.handleRoleName(e, index)}
                              placeholder="Role Name"
                            />
                          </div>
                          <div
                            className="userAddBLock"
                            style={{ marginTop: "15px" }}
                          >
                            <div className="userAddHeader flex">
                              <label>Role Name</label>
                              <label style={{ width: "60px" }}>Read</label>
                              <label style={{ width: "60px" }}>Write</label>
                            </div>

                            {this.state.displayArray.map((d,i) =>(
                              <div className="flex">
                                <p style={{width: "220px"}}>{d}</p>
                                <div
                                    style={{
                                      width: "137px",
                                      justifyContent: "space-around",
                                      display: "flex",
                                    }}
                                  >

                                {/* <label>{this.state.viewArray[i].name}</label> */}
                                <input type="checkbox" onChange={(e)=>this.handleViewArray(e,i)} checked={this.state.viewArray[i]?.value} name={this.state.viewArray[i]?.id}/>
                                {/* <label>{this.state.createArray[i].name}</label> */}
                                <input type="checkbox" onChange={(e)=>this.handleCreateArray(e,i)} name={this.state.createArray[i]?.id} disabled={this.state.createArray[i]?.disabled}/>
                                </div>
                              </div>
                            ))}

                            <button
                              className="sxp-btn btn-primary"
                              type="submit"
                              style={{
                                height: "34px",
                                width: "220px",
                                margin: "0 5px",
                              }}
                              onClick={() => this.createRole(item, index)}
                            > Save </button>
                            <span
                              className="sxp-btn btn-danger"
                              onClick={() => this.cancelExpand(index)}
                              style={{
                                height: "34px",
                                width: "220px",
                                textAlign: "center",
                                margin: "0 5px",
                                padding: "9px 0",
                                display: "inline-block",
                                verticalAlign: "top",
                              }}
                            >
                              Cancel
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              ))}
            </div>
          ) : (
            <EmptyCard />
          )}
        </div>
      </div>
    );
  }
}

export default Roles;
