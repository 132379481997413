import React, { Component } from "react";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { getData } from "../../dataProvider/apiHandler";
import "./version.scss";

// URL setup for api calling
const projectId = localStorage.getItem("pId");
const serverUrl = window._env_.REACT_APP_SXP_URL;

class VersionSelectDropDown extends Component {
  //version dropdown change handle
  constructor(props){
    super(props);
    this.state ={
      versionList:[],
    }
  }

  componentDidMount = () => {
    this.loadVersionHistroy();
  }

  loadVersionHistroy = async() => {
    try{
      const allOption = {
        url: serverUrl + "version/"+localStorage.getItem('pId')+"/version-history",
      };
      let initialLoad = await getData(allOption);
      this.setState({ versionList: initialLoad.data.data.docs }, () => console.log(this.state.versionList));
    }
    catch(e){

    }
  }

  changeVersionNo = async (e) => {
    let versionData = e.target.value
    localStorage.setItem("version", versionData);
    this.props.final();
  };

  render() {
    return (
      <Select
        id="versioningListDD"
        className="sxp-defaultDropDown verDD mt0"
        value={localStorage.getItem("version")}
        onChange={this.changeVersionNo}
        defaultValue={localStorage.getItem("version")}
        inputProps={{ "aria-label": "Without label" }}
      >
        {this.state.versionList.map((data, i) => (
          <MenuItem key={i} value={data.version}>
            {data.version === 'WORKING' ? data.version : <>Version: {data.version}</> }            
          </MenuItem>
        ))}
      </Select>
    );
  }
}

export default VersionSelectDropDown;
