import React, { Component } from "react";
import { submit } from "./formActions";
import { FormButtons } from "./formbuttons";

export class APIResponse extends Component {
    state = {}

    static getDerivedStateFromProps(nextProps, currentState) {
        let propsState;
        if (
            currentState.formUniqueId &&
            currentState.formUniqueId === nextProps.formUniqueId
        ) {
            propsState = { ...(nextProps.data.data || {}), ...currentState };
        } else {
            propsState = { ...currentState, ...(nextProps.data.data || {}) };
        }
        propsState.formUniqueId = nextProps.formUniqueId;
        return propsState;
    }

    componentWillUnmount() {
        this.setState({});
    }

    handleTextInputChange = (e) => {
        let updatedState = { ...this.state };
        updatedState[e.target.name] = e.target.value;
        this.setState(updatedState);
    };

    render() {
        return (
            <>
                <form className="form-style" onSubmit={(e) => submit(e, this.props)}>
                    <div>
                        <label> Name </label>
                        <input
                            type="text"
                            name="name"
                            defaultValue={this.state.name}
                            key={this.state.formUniqueId}
                            onChange={this.handleTextInputChange}
                            required
                            autoFocus
                        />
                    </div>
                    <div>
                        <label> Status </label>
                        <input
                            type="text"
                            name="status"
                            defaultValue={this.state.status}
                            key={this.state.formUniqueId}
                            onChange={this.handleTextInputChange}
                            required
                        />
                    </div>
                    <div>
                        <label> Headers:</label>
                        <textarea
                            name="headers"
                            defaultValue={this.state.headers}
                            // value={this.state.bodyJson}
                            rows="6"
                            cols="30"
                            key={this.state.formUniqueId}
                        />
                    </div>
                    <div>
                        <label> Response Body:</label>
                        <textarea
                            name="responseBody"
                            defaultValue={this.state.responseBody}
                            // value={this.state.bodyJson}
                            rows="6"
                            cols="30"
                            key={this.state.formUniqueId}
                        />
                    </div>
                    <FormButtons formProps={this.props} />
                </form>
            </>
        );
    }
}